import { log } from '../../../monitor/monitor';
import * as Sentry from '@sentry/react';
/**
 * This function is used to get the active columns in the columns array
 * @param {*} columns array of columns to be cleaned
 * @returns Array of columns without undefined values
 */
const getActiveColumns = (columns) => {
  log('AVOID_NULL_SCHEDULE_VIEW_COLUMNS');
  const activatedCols =
    columns &&
    columns.filter((col) => {
      if (col) {
        return true;
      }
    });
  return activatedCols;
};

/**
 * This function is used to delete duplicate columns in the columns array
 * @param {*} activatedCols columns array to be cleaned
 * @returns Array of columns without duplicates
 */
const deleteDuplicateColumns = (activatedCols) => {
  log('DELETE_DUPLICATES_SCHEDULE_VIEW_COLUMNS');
  const shouldColumnsBe =
    activatedCols &&
    activatedCols.reduce((unique, col) => {
      if (!unique.some((c) => c.name === col.name)) {
        unique.push(col);
      }
      return unique;
    }, []);

  return shouldColumnsBe;
};
/**
 * This function is used to clean the columns array from undefined values and duplicates
 * @param {*} columns elements to be cleaned
 * @returns Array of columns without undefined values and duplicates
 */
export const cleanColumnsIssues = (columns) => {
  try {
    log('CLEANING_SCHEDULE_VIEW_COLUMNS');
    if (!columns?.length) return columns;

    const activatedCols = getActiveColumns(columns);
    const shouldColumnsBe = deleteDuplicateColumns(activatedCols);

    return shouldColumnsBe || columns;
  } catch (error) {
    Sentry.captureMessage(error, 'warning');
    return columns;
  }
};

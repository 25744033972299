import { useState } from 'react';
import { procoreService } from '../../../services/procore.service';
import { authService } from '../../../services';
import { httpCodes } from '../../../constants/httpCodes';
import {
  sendSignInTrackingEvent,
  redirectSelectCompany
} from '../login.helpers';
import { ERROR_TYPE } from '../constants';

export function useProcoreAuth(t, beta, dataForm, setConfigNotification) {
  const [isLoadingProcoreButton, setIsLoadingProcoreButton] = useState(false);
  const SOURCE_SYSTEM_SSO = 'procore';
  const SOURCE_ENVIRONMENT_SANDBOX = 'sandbox';
  const SOURCE_ENVIRONMENT_PROD = 'prod';

  async function getLinkTokenOrThrow() {
    const { link_token: linkToken } =
      await procoreService.getAgaveLinkTokenSSO();
    if (!linkToken) {
      throw new Error('linkToken error');
    }
    return linkToken;
  }

  async function handleUserAuthenticationSuccess(publicToken) {
    const response = await authService.indexSSO({
      publicToken
    });

    if (!response.success) {
      throw new Error(response.message);
    }
    sendSignInTrackingEvent(response?.data?.user?.email, httpCodes.OK, null);
    redirectSelectCompany(response);
  }

  const getErrorMessageProcore = () => {
    setConfigNotification({
      show: true,
      type: ERROR_TYPE,
      label: t('login.notification_error_unable_profile_procore')
    });
  };

  function handleAuthenticationFailure() {
    setIsLoadingProcoreButton(false);
    getErrorMessageProcore();
    sendSignInTrackingEvent(
      dataForm?.email,
      httpCodes.INTERNAL_SERVER_ERROR,
      'procore_error'
    );
  }

  async function authenticateUserWithToken(linkToken) {
    if (window && window.AgaveLink) {
      return new Promise((resolve, reject) => {
        window.AgaveLink.openLink({
          linkToken,
          sourceSystem: SOURCE_SYSTEM_SSO,
          showSandboxSourceSystems: beta,
          showProductionSourceSystems: !beta,
          sourceSystemEnvironment: beta
            ? SOURCE_ENVIRONMENT_SANDBOX
            : SOURCE_ENVIRONMENT_PROD,
          onSuccess: resolve,
          onExit: reject
        });
      });
    }
    throw new Error('AgaveLink is not available');
  }

  const handleSSOProcore = async () => {
    try {
      setIsLoadingProcoreButton(true);

      const linkToken = await getLinkTokenOrThrow();
      const userResponse = await authenticateUserWithToken(linkToken);
      await handleUserAuthenticationSuccess(userResponse);
    } catch (err) {
      console.log(`Error: ${err}`);
      handleAuthenticationFailure();
    }
  };

  return { isLoadingProcoreButton, handleSSOProcore };
}

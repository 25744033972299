import React, { useState, useEffect } from 'react';
import {
  sectorBaselineVersionService,
  sectorService,
  baseworkingdayService,
  sectorBaselinePointService,
  productionunitService
} from '../../services';

import { Row, Col, Modal, Tooltip, Radio, message, Icon, Spin } from 'antd';
import { BaseCalendarExceptionDaysService } from '../../services/basecalendarexceptiondays.service';

import {
  EyeIcon,
  CircleCheckIcon,
  TrashIcon,
  BaselinesIcon
} from '../../icons';
import fakeAvatar from '../../assets/img/fake_user.png';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { send_warning_message } from '../../assets/js/custom_gantt_fields/custom_sucessor';
import { BaselinesModalActionButton } from '../../views/ganttContainer/gantt/components/BaselinesModalActionButton';
import {
  createBaseCalendarFromCalendar,
  getBodyForNewBaseline,
  transformHourToDays
} from '../../views/ganttContainer/gantt/gantt.helper';
import { notifyMessage } from '../../utils/lookahead-common';
import { columns } from '../../utils';

/** Function order by multiple fileds */
import { firstBy } from 'thenby';
import { trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../analytics/implements/totango';
import { getSignedUser } from '../../utils/userUtils';
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
message.config({
  maxCount: 1
});

const BaselineModal = (props) => {
  const {
    refreshBaselinesStates,
    saveBaselineModal,
    setSaveBaselineModal,
    saveBaselineModalAuto,
    setSaveBaselineModalAuto,
    allBaselines,
    setAllBaselines,
    t,
    gantt,
    permission,
    modalStyles,
    modalMetadata,
    updateModalRender,
    modalMetadataDelete,
    updateModalRenderDelete,
    colors,
    allBaselinesModal,
    setAllBaselinesModal,
    sector,
    calendars,
    handleConfigClick,
    deletedUnsavedActivities,
    saveHandler,
    setAutoschedulingVisual
  } = props;
  const [loadingSaveBaseline, setLoadingSaveBaseline] =
    useState(false); /** Allows view to set loading screen on save baseline */

  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  const sectorDateFormat = projectState.allSectors.find(
    (e) => e.id == projectState.sectorSelected
  );
  const formatDate = sectorDateFormat?.dateFormat;

  function handleModalOk(e) {
    modalMetadata.visible = false;
    if (modalMetadata.onOkPayload) {
      modalMetadata.onOkPayload();
    }
    updateModalRender();
  }

  function handleModalOkDelete(e) {
    message.loading(t('saving_alert'), 2);
    modalMetadataDelete.visible = false;
    modalMetadataDelete.isRunning = false;
    if (modalMetadataDelete.onOkPayload) {
      modalMetadataDelete.onOkPayload(saveBaselineModalAuto);
    }
    updateModalRenderDelete();
  }

  const defineModalNonSavedMessage = (
    createdTasks,
    deletedTasks,
    nonUpdatedTask
  ) => {
    let msg = '';

    if (createdTasks) {
      const activitiesText =
        createdTasks > 1
          ? t('modals.gantt.non_saved_activities.activities')
          : t('modals.gantt.non_saved_activities.activity');
      const nonSavedText = t(
        'modals.gantt.non_saved_activities.non_saved_without_base'
      );
      msg +=
        t('modals.gantt.non_saved_activities.are') +
        createdTasks +
        ' ' +
        activitiesText +
        nonSavedText;
    }
    if (deletedTasks) {
      const activitiesText =
        deletedTasks > 1
          ? t('modals.gantt.non_saved_activities.activities')
          : t('modals.gantt.non_saved_activities.activity');
      const deletedText =
        deletedTasks > 1
          ? t('modals.gantt.non_saved_activities.deleted_plural')
          : t('modals.gantt.non_saved_activities.deleted');
      const nonSavedText = t(
        'modals.gantt.non_saved_activities.non_saved_with_base'
      );
      msg +=
        t('modals.gantt.non_saved_activities.are') +
        deletedTasks +
        ' ' +
        activitiesText +
        deletedText +
        nonSavedText;
    }
    if (nonUpdatedTask) {
      const isOrAre =
        nonUpdatedTask > 1
          ? t('modals.gantt.non_saved_activities.are')
          : t('modals.gantt.non_saved_activities.is');
      const taskMsg =
        nonUpdatedTask > 1
          ? t('modals.gantt.non_saved_activities.activities')
          : t('modals.gantt.non_saved_activities.activity');
      const haveOrHas =
        nonUpdatedTask > 1
          ? t('modals.gantt.non_saved_activities.that_have')
          : t('modals.gantt.non_saved_activities.that_has');
      msg += `${isOrAre} ${nonUpdatedTask} ${taskMsg} ${haveOrHas} ${t('non_updated_without_base')}`;
    }

    return msg;
  };

  function handleModalCancel(e) {
    modalMetadata.visible = false;
    if (modalMetadata.onCancelPayload) {
      modalMetadata.onCancelPayload();
    }
    updateModalRender();
  }

  function handleModalCancelDelete(e) {
    modalMetadataDelete.visible = false;
    modalMetadataDelete.isRunning = false;
    if (modalMetadataDelete.onCancelPayload) {
      modalMetadataDelete.onCancelPayload();
    }
    updateModalRenderDelete();
  }

  const canDeleteBaseline = (baseline) =>
    !(baseline.visible || baseline.active);

  const activeBaselineVersion = async (baseline) => {
    if (permission == 'V') {
      message.error(t('without_permission_to_save'), 2);
      return;
    }

    baseline.active = true;

    /** Widget update acum duration (duda hernan) */
    if (!baseline.accumulatedDuration) {
      const accumDuration = baseline.points
        .filter((t) => {
          const tRef = gantt.getTask(t.activityDhtmlxId);
          if (tRef) {
            if (!gantt.hasChild(tRef.id)) {
              return true;
            }
          }
        })
        .reduce((ac, t) => ac + t.duration, 0);
      baseline.accumulatedDuration = accumDuration;
    }

    const res1 = await sectorBaselineVersionService.update(baseline);
    if (res1) {
      const othersBaselines = allBaselines.filter(
        (base) => base.id != baseline.id
      );
      const res2 = othersBaselines.map(async (base) => {
        base.active = false;
        await sectorBaselineVersionService.update(base);
      });
      await Promise.all(res2);
      await productionunitService.updateBases({ sectorId: sector.id });
      await refreshBaselinesStates();

      trackingEvent(
        'baseline_activation',
        {
          ...getBasicAmplitudEventProperties()
        },
        AMPLITUDE_SERVICE
      );
    }
  };

  const viewBaselineVersion = async (baseline) => {
    if (permission == 'V') {
      message.error(t('without_permission_to_save'), 2);
      return;
    }

    baseline.visible = true;
    const res1 = await sectorBaselineVersionService.update(baseline);
    if (res1) {
      const othersBaselines = allBaselines.filter(
        (base) => base.id != baseline.id
      );
      const res2 = othersBaselines.map(async (base) => {
        base.visible = false;
        await sectorBaselineVersionService.update(base);
      });
      await Promise.all(res2);
      message.info(t('changed_baseline_visible'), 1);
      setAllBaselines([...allBaselines]);

      const activities = gantt.serialize().data;
      activities.map((ac) => {
        const activity = gantt.getTask(ac.id);
        activity.baseline_points.map((base) => {
          if (base.sectorbaselineversion) {
            if (base.sectorbaselineversion.id == baseline.id) {
              base.sectorbaselineversion.visible = true;
            } else {
              base.sectorbaselineversion.visible = false;
            }
          }
        });
      });
      gantt.autoSchedule();
      gantt.render();
      gantt.refreshData();

      trackingEvent(
        'baseline_visulization',
        {
          ...getBasicAmplitudEventProperties()
        },
        AMPLITUDE_SERVICE
      );
    }
  };

  const deleteBaseline = async (baseline) => {
    if (permission == 'V') {
      message.error(t('without_permission_to_save'), 2);
      return;
    }

    if (baseline.visible || baseline.active) {
      message.error(t('modals.gantt.all_baselines.cant_delete_base'), 1);
    } else {
      const res1 = await sectorBaselineVersionService.destroy(baseline.id);
      if (res1) {
        const othersBaselines = allBaselines.filter(
          (base) => base.id != baseline.id
        );
        message.info(t('modals.gantt.all_baselines.deleted_succesfully'), 1);
        setAllBaselines([...othersBaselines]);
        trackingEvent(
          'baseline_deletion',
          {
            ...getBasicAmplitudEventProperties()
          },
          AMPLITUDE_SERVICE
        );
      }
    }
  };

  // HERE
  async function goSaveBaseline() {
    if (permission == 'V') {
      message.error(t('without_permission_to_save'), 2);
      return;
    }
    setLoadingSaveBaseline(true);

    const currentUser = getSignedUser();
    const accumulatedGanttDuration = gantt
      .getTaskBy((t) => !gantt.hasChild(t.id))
      .reduce((ac, t) => ac + t.duration, 0);

    /** Creates a new baseline version object */
    const newBaselineVersion = {
      create_date: moment().format('YYYY/MM/DD H:mm'),
      name: saveBaselineModal.name,
      description: saveBaselineModal.description,
      creatorId: currentUser.id,
      creator: currentUser,
      sectorId: sector.id,
      visible: true,
      active: true,
      accumulatedDuration: accumulatedGanttDuration,
      saveOption: saveBaselineModal.toSaveOption
    };

    let lastActiveBaselineVersion;

    /** Set all old baselines active and visible to false */
    const asyncAllBaselines = allBaselines.map(async (base) => {
      if (base.active) {
        lastActiveBaselineVersion = base;
      }

      base.active = false;
      base.visible = false;
      await sectorBaselineVersionService.update(base);
    });
    await Promise.all(asyncAllBaselines);

    /** We save the new baseline version object, and update all baselines state with this new one */
    const resVersion =
      await sectorBaselineVersionService.create(newBaselineVersion);
    newBaselineVersion.id = resVersion.id;
    setAllBaselines([...allBaselines, newBaselineVersion]);

    /** We clone current calendar config, and map calendar ID's to base calendar ID's */
    const hashTable = await createBaseCalendarFromCalendar(
      newBaselineVersion,
      lastActiveBaselineVersion,
      calendars
    );

    /** We create new points for saved baseline */
    const bodyForNewBaselinePoints = getBodyForNewBaseline(
      hashTable,
      newBaselineVersion,
      gantt,
      sector
    );
    const resPoints = await sectorBaselinePointService.createAllBaselines(
      bodyForNewBaselinePoints
    );
    const baselineOptions = {
      1: 'Entire Project',
      2: 'New Activities',
      3: 'Incompletes Activities'
    };
    /** Finally we set flag to update ponderators on the next page load, which is actually executed by location reload */
    if (resPoints) {
      await refreshBaselinesStates();
      await productionunitService.updateBases({ sectorId: sector.id });
      notifyMessage({
        title: t('saved'),
        message: t('saved_baseline_succesfully'),
        type: 'success'
      });
      setLoadingSaveBaseline(false);
      setSaveBaselineModal({ ...saveBaselineModal, visible: false });
      trackingEvent(
        'baseline_creation',
        {
          ...getBasicAmplitudEventProperties(),
          baseline_type: baselineOptions[saveBaselineModal.toSaveOption],
          event_result: 'yes'
        },
        AMPLITUDE_SERVICE
      );
      setSaveBaselineModal({ ...saveBaselineModal, visible: false });
    } else {
      trackingEvent(
        'baseline_creation',
        {
          ...getBasicAmplitudEventProperties(),
          baseline_type: baselineOptions[saveBaselineModal.toSaveOption],
          event_result: 'no'
        },
        AMPLITUDE_SERVICE
      );
    }
  }

  async function saveBaseline() {
    const loggedUser = getSignedUser();
    const currentCompany = stateCompany.currentCompany;
    const project = projectState.allProjects.find(
      (p) => p.id == projectState.projectSelected
    );

    totangoSetAccountAttributes(
      loggedUser,
      projectState.projectSelected,
      currentCompany?.name,
      currentCompany?.id,
      project?.name,
      project?.stage,
      project?.country
    );

    totangoEventTracking(
      `p_${projectState.projectSelected}`,
      loggedUser,
      'Save baseline',
      'Master Plan'
    );

    window.savingBaselineOnProcess = true;
    setAutoschedulingVisual(true);
    setSaveBaselineModal({ ...saveBaselineModal, visible: false });
    await saveHandler();
    await goSaveBaseline();
  }

  const getFooterForModal = () => {
    if (loadingSaveBaseline) {
      return (
        <div style={{ justifyContent: 'center', width: '100%' }}>
          <Spin indicator={antIcon} />
        </div>
      );
    }

    return [
      <button
        key="cancel"
        onClick={() => {
          setSaveBaselineModal({ ...saveBaselineModal, visible: false });
        }}>
        {t('cancel')}
      </button>,
      <button
        key="save"
        data-type="main"
        onClick={() => {
          const { name, description } = saveBaselineModal;

          if (name.trim() === '') {
            message.warning(t('modals.gantt.new_baseline.non_save_name'), 2);
          } else if (description.trim() === '') {
            message.warning(t('modals.gantt.new_baseline.non_save_comment'), 2);
          } else {
            saveBaseline();
          }
        }}>
        {t('master_plan.save_base_line')}
      </button>
    ];
  };

  const getFooterForModalDelete = () => {
    if (loadingSaveBaseline) {
      return (
        <div style={{ justifyContent: 'center', width: '100%' }}>
          <Spin indicator={antIcon} />
        </div>
      );
    }

    return [
      <button key="cancel" onClick={handleModalCancelDelete}>
        {modalMetadataDelete.cancelText}
      </button>,
      <button
        key="save"
        data-type="main"
        onClick={(e) => {
          const { name, description } = saveBaselineModalAuto;

          if (name.trim() === '') {
            message.warning(t('modals.gantt.new_baseline.non_save_name'), 2);
          } else if (description.trim() === '') {
            message.warning(t('modals.gantt.new_baseline.non_save_comment'), 2);
          } else {
            handleModalOkDelete(e);
          }
        }}>
        {modalMetadataDelete.okText}
      </button>
    ];
  };

  return (
    <div>
      {/* modal delete */}
      <Modal
        wrapClassName={`activity-modification-style ${modalStyles['gantt-alert-modal']} delete-modal-gantt`}
        title=" &nbsp;"
        centered
        onCancel={handleModalCancelDelete}
        visible={modalMetadataDelete.visible}
        width={530}
        footer={getFooterForModalDelete()}>
        {modalMetadataDelete.content}

        <form className="frmdeletemodal">
          <ul class="wrapper">
            <li class="form-row">
              <label for="name">{t('new_baseline_modal.baseline_name')}</label>
              <input
                type="text"
                value={saveBaselineModalAuto.name}
                onChange={({ target: { value } }) => {
                  setSaveBaselineModalAuto({
                    ...saveBaselineModalAuto,
                    name: value
                  });
                }}
              />
            </li>
            <li class="form-row">
              <label for="townborn">{t('new_baseline_modal.comment')}</label>
              <textarea
                value={saveBaselineModalAuto.description}
                onChange={({ target: { value } }) => {
                  setSaveBaselineModalAuto({
                    ...saveBaselineModalAuto,
                    description: value
                  });
                }}
              />
            </li>
          </ul>
        </form>
      </Modal>

      {/* modal standard */}
      <Modal
        cancelText={modalMetadata.cancelText}
        okText={modalMetadata.okText}
        title={modalMetadata.title}
        visible={modalMetadata.visible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}>
        {modalMetadata.content}
      </Modal>

      {/* New Baseline modal */}
      <Modal
        width={800}
        wrapClassName={`activity-modification-style ${modalStyles['facelifted-modal']} ${modalStyles['new-baseline-modal']}`}
        title={
          <>
            <BaselinesIcon color={colors.brandOrange40} />{' '}
            {saveBaselineModal.title}
          </>
        }
        visible={saveBaselineModal.visible}
        onCancel={() =>
          setSaveBaselineModal({ ...saveBaselineModal, visible: false })
        }
        footer={getFooterForModal()}>
        <div className={modalStyles['new-baseline-modal__section']}>
          <h3>
            <strong>{t('step')} 1:</strong>{' '}
            {t('new_baseline_modal.step_1_title')}
          </h3>

          <Radio.Group
            onChange={(e) => {
              saveBaselineModal.toSaveOption = e.target.value;
            }}
            defaultValue={saveBaselineModal.toSaveOption}>
            <Radio value={1}>
              <strong>{t('new_baseline_modal.option_1_name')}</strong>
              <p>{t('new_baseline_modal.option_1_description')}</p>
            </Radio>
            <Radio value={2}>
              <strong>{t('new_baseline_modal.option_2_name')}</strong>
              <p>{t('new_baseline_modal.option_2_description')}</p>
            </Radio>
            <Radio value={3}>
              <strong>{t('new_baseline_modal.option_3_name')}</strong>
              <p>{t('new_baseline_modal.option_3_description')}</p>
            </Radio>
          </Radio.Group>
        </div>
        <div className={modalStyles['new-baseline-modal__section']}>
          <h3>
            <strong>{t('step')} 2:</strong>{' '}
            {t('new_baseline_modal.step_2_title')}
          </h3>

          <label>
            {t('new_baseline_modal.baseline_name')}
            <input
              type="text"
              value={saveBaselineModal.name}
              onChange={({ target: { value } }) => {
                setSaveBaselineModal({ ...saveBaselineModal, name: value });
              }}
            />
          </label>
          <label>
            {t('new_baseline_modal.comment')}
            <textarea
              value={saveBaselineModal.description}
              onChange={({ target: { value } }) => {
                setSaveBaselineModal({
                  ...saveBaselineModal,
                  description: value
                });
              }}
            />
          </label>
        </div>
      </Modal>

      {/* Saved Baselines modal */}
      <Modal
        width={900}
        wrapClassName={`activity-modification-style ${modalStyles['facelifted-modal']} ${modalStyles['saved-baselines-modal']}`}
        title={
          <>
            <BaselinesIcon color={colors.brandOrange40} />{' '}
            {allBaselinesModal.title}
          </>
        }
        visible={allBaselinesModal.visible}
        onCancel={() =>
          setAllBaselinesModal({ ...allBaselinesModal, visible: false })
        }
        footer={[
          <button
            key="cancel"
            onClick={() => {
              setAllBaselinesModal({ ...allBaselinesModal, visible: false });
            }}>
            {t('cancel')}
          </button>,
          <button
            key="new"
            data-type="main"
            onClick={() => {
              setAllBaselinesModal({ ...allBaselinesModal, visible: false });
              handleConfigClick('save_baseline');
            }}
            disabled={permission === 'V'}>
            {t('modals.gantt.all_baselines.newbase_label')}
          </button>
        ]}>
        <Row>
          <Col>
            <Row className={modalStyles['saved-baselines-modal__grid-header']}>
              <Col span={4} style={{ textAlign: 'left' }}>
                {t('line_name')}
              </Col>
              <Col span={9}>{t('comment')}</Col>
              <Col span={3} style={{ textAlign: 'center' }}>
                {t('modals.gantt.all_baselines.created_by')}
              </Col>
              <Col span={3} style={{ textAlign: 'center' }}>
                {t('date')}
              </Col>
              <Col span={5} />
            </Row>
            <div className={modalStyles['saved-baselines-modal__grid-content']}>
              {allBaselines
                .sort(firstBy('id', { direction: 'desc', ignoreCase: true }))
                .map((baseline, i) => {
                  return (
                    <Row key={i}>
                      <Col
                        span={4}
                        className={
                          modalStyles['saved-baselines-modal__text-cell']
                        }>
                        <Tooltip placement="top" title={baseline.name}>
                          {baseline.name?.trim() || '-'}
                        </Tooltip>
                      </Col>
                      <Col
                        span={9}
                        className={
                          modalStyles['saved-baselines-modal__text-cell']
                        }>
                        <Tooltip placement="top" title={baseline.description}>
                          {baseline.description?.trim() || '-'}
                        </Tooltip>
                      </Col>
                      <Col
                        span={3}
                        className={
                          modalStyles['saved-baselines-modal__avatar-cell']
                        }
                        style={{ textAlign: 'center' }}>
                        {baseline.creatorId ? (
                          <Tooltip
                            placement="top"
                            title={
                              baseline.creator.name +
                              ' ' +
                              baseline.creator.lastname
                            }>
                            <img src={baseline.creator.image || fakeAvatar} />
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </Col>
                      <Col
                        span={3}
                        style={{ textAlign: 'center', paddingLeft: 10 }}>
                        {moment(baseline.create_date).format(formatDate)}
                      </Col>
                      <Col
                        span={5}
                        className={
                          modalStyles['saved-baselines-modal__actions-cell']
                        }
                        style={{ textAlign: 'center' }}>
                        <BaselinesModalActionButton
                          onConfirm={
                            !baseline.active &&
                            (() => activeBaselineVersion(baseline))
                          }
                          tooltip={
                            baseline.active
                              ? t(
                                  'modals.gantt.all_baselines.tooltips.current_active_baseline'
                                )
                              : t(
                                  'modals.gantt.all_baselines.tooltips.active_baseline'
                                )
                          }>
                          <CircleCheckIcon
                            color={baseline.active ? colors.white : '#7D8671'}
                          />
                        </BaselinesModalActionButton>

                        <BaselinesModalActionButton
                          onConfirm={
                            !baseline.visible &&
                            (() => viewBaselineVersion(baseline))
                          }
                          tooltip={
                            baseline.visible
                              ? t(
                                  'modals.gantt.all_baselines.tooltips.active_view_baseline'
                                )
                              : t(
                                  'modals.gantt.all_baselines.tooltips.view_other_baseline'
                                )
                          }>
                          <EyeIcon
                            color={baseline.visible ? colors.white : '#7D8671'}
                          />
                        </BaselinesModalActionButton>

                        <BaselinesModalActionButton
                          onConfirm={
                            canDeleteBaseline(baseline) &&
                            (() => deleteBaseline(baseline))
                          }
                          tooltip={
                            canDeleteBaseline(baseline)
                              ? t(
                                  'modals.gantt.all_baselines.tooltips.delete_baseline'
                                )
                              : t('modals.gantt.all_baselines.cant_delete_base')
                          }>
                          <TrashIcon
                            color={
                              canDeleteBaseline(baseline)
                                ? colors.white
                                : '#7D8671'
                            }
                          />
                        </BaselinesModalActionButton>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default BaselineModal;

/* eslint-disable prefer-const */

/** React components  */
import React, { useEffect, useState } from 'react';

/** Import Library for PropTypes */
import PropTypes from 'prop-types';

/** Import elements from library Antd */
import { Row, Col, Modal } from 'antd';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';

/** import common functions from utils */
import {
  openNotification,
  getLookaheadSubModule,
  extractTaskDetails
} from '../../../../utils';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { constraintActions } from '../../../../redux/actions/constraintActions';
import { userActions } from '../../../../redux/actions/userActions';

/** import library for handle dates */
import moment from 'moment';

import { base } from '../../../../services/base';

/** Services */
import {
  constraintService,
  userService,
  notificationService
} from '../../../../services';

/** import components */
import ConstraintTypes from './ConstraintTypes';
import ConstraintSelect from './ConstraintSelect';
import ConstraintNew from './ConstraintNew';
import { trakerServiceAppCues } from '../../../../utils/appcues-util';

import { CreateRoadBlockIcon, CloseIcon } from '../../../../icons';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../../../analytics/implements/totango';
import { getSignedUser } from '../../../../utils/userUtils';
import { trackingEvent } from '../../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';

const TYPE_DATE_DEFAULT = 'com';

const ConstraintForm = (props) => {
  /** use props */
  const { visibleForm, setVisibleForm, setConstraintAdded, initialTasks, t } =
    props;

  let old = false;

  /** Redux */
  const dispatch = useDispatch();
  const constraintState = useSelector((state) => state.constraintState);
  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);

  /** consts */
  const alertConstraintEditSuccess = {
    /** notification exit */ title: t('lookahead_constraints.title'),
    description: t('lookahead_constraints.succefull_added'),
    type: 'success'
  };

  /** object by default for Calendar Form */
  const templateForm = {
    type: null,
    taskList: [],
    calendarDate: moment(),
    typeDate: 'com',
    responsable: null,
    name: null
  };

  /** Hooks */
  const [currenTab, setCurrenTab] = useState(1); /** handle tabs (1, 2, 3, 4) */
  const [loading, setLoading] = useState(false); /** handle load */
  const [typeValid, setTypeValid] =
    useState(true); /** handle validation type */
  const [nameValid, setNameValid] =
    useState(true); /** handle validation name */
  const [haveTask, setHaveTasks] = useState(true);
  const [responsableValid, setResponsableValid] =
    useState(true); /** handle validation responsable */
  /** Array with users that belongs to the same sector that this master plan */
  const [toSelectResponsables, setToSelectResponsables] = useState([]);
  const [documentList, setDocumentList] = useState([]); /** handle load */
  const [visibleModalCreateType, setVisibleModalCreateType] = useState(false);
  const [types, setTypes] = useState([]); /** handle load */
  const [loadingTypes, setLoadingTypes] = useState(true);

  /** side effects */
  useEffect(() => {
    dispatch(constraintActions.setConstraintForm(templateForm));
    if (visibleForm) {
      getResponsables();
    }
  }, [visibleForm]);

  const getResponsables = async () => {
    /** get users  */
    const responsables = await userService.getBySector(
      projectState.sectorSelected
    );
    if (responsables) {
      const filterUsers = responsables.users.filter((user) => user.is_active);
      setToSelectResponsables(filterUsers);
    }
  };

  /** observe state constraint (constraintForm) */
  useEffect(() => {
    /** validate */
    if (constraintState.constraintForm) {
      if (constraintState.constraintForm.name) {
        setNameValid(true);
      }
      if (constraintState.constraintForm.responsable) {
        setResponsableValid(true);
      }
    }
  }, [constraintState.constraintForm]);

  /** validating logic */
  const validateName = () => {
    const form = constraintState.constraintForm;
    let ret = false;
    if (form.name) {
      ret = true;
    } else {
      setNameValid(false);
    }
    return ret;
  };

  const validateResponsable = () => {
    const form = constraintState.constraintForm;
    let ret = false;
    if (form.responsable) {
      ret = true;
    } else {
      setResponsableValid(false);
    }
    return ret;
  };

  /** Component Logic */
  const resetStateConstraint = () => {
    dispatch(constraintActions.setConstraintForm(templateForm));
    setCurrenTab(1); /** set tab to step 1 */
    setVisibleForm(false); /** close modal */
    setNameValid(true);
    setResponsableValid(true);
  };

  /** handle tabs / submit data */
  const handleContinue = async () => {
    if (!validateFormCreateConstraint()) return false;

    trakerServiceAppCues('Roadblock Creation');

    const loggedUser = getSignedUser();
    const currentCompany = stateCompany.currentCompany;
    const project = projectState.allProjects.find(
      (p) => p.id == projectState.projectSelected
    );

    totangoSetAccountAttributes(
      loggedUser,
      projectState.projectSelected,
      currentCompany?.name,
      currentCompany?.id,
      project?.name,
      project?.stage,
      project?.country
    );

    totangoEventTracking(
      `p_${projectState.projectSelected}`,
      loggedUser,
      'Roadblock Creation',
      'Lookahead'
    );

    // ConstraintActivity
    // (currenTab !== 2) { /** step */
    //  return setCurrenTab(currenTab + 1)
    // }

    // if (!validateName()) { return false }

    // if (currenTab === 2 && !validateResponsable()) { return false }

    const objConstraint = constraintState.constraintForm;
    /** find responsable data */
    const findResponsable = props.toSelectResponsables.find(
      (us) => us.id === objConstraint.responsable
    );
    /** submit data */
    setLoading(true);
    /** get lang */
    const userLang = navigator.language || navigator.userLanguage;
    const user = getSignedUser();
    const data = {
      name: objConstraint.name,
      priority: 'normal',
      status: 'notreleased',
      constraintTypeId: objConstraint.type,
      userId: objConstraint.responsable,
      sectorId: projectState.sectorSelected,
      identify_user: user.id,
      email: toSelectResponsables.find(
        (el) => el.id === constraintState.constraintForm.responsable
      ).email,
      link:
        base.front +
        'lookahead/constraints/' +
        projectState.projectSelected +
        '/' +
        projectState.sectorSelected,
      projectName: projectState.allProjects.find(
        (el) => el.id == projectState.projectSelected
      ).name,
      sectorName: projectState.allSectors.find(
        (el) => el.id == projectState.sectorSelected
      ).name,
      userName: findResponsable
        ? findResponsable.name + ' ' + findResponsable.lastname
        : t('hello'),
      lang: userLang.includes('es') ? 'es' : 'en'
    };
    if (objConstraint.typeDate === 'com') {
      data.commitmentDate = objConstraint.calendarDate;
    } else {
      data.status = 'notcompromises';
    }
    data.idsendmail = 0;
    const constraintCreated = await createConstraint(data);
    if (constraintCreated.id) {
      const subModule = getLookaheadSubModule();
      const { taskIdLinked, taskNameLinked } = extractTaskDetails(
        objConstraint.taskList
      );

      trackingEvent(
        'complete_roadblock_creation',
        {
          ...getBasicAmplitudEventProperties(),
          roadblock_name: constraintCreated.name,
          roadbloack_id: constraintCreated.id,
          task_ID_linked: taskIdLinked,
          task_name_linked: taskNameLinked,
          event_source: subModule
        },
        AMPLITUDE_SERVICE
      );

      const syncMap = objConstraint.taskList.map(async (task) => {
        await asignConstraintTask({
          constraintId: constraintCreated.id,
          taskId: task.id
        });
      });
      await Promise.all(syncMap);
      constraintCreated.tasks = objConstraint.taskList;
      setConstraintAdded(constraintCreated.tasks);
      if (props.updateConstraints) props.updateConstraints(constraintCreated);

      constraintService
        .attachDocuments(constraintCreated.id, documentList)
        .then(() => setDocumentList([]));
      openNotification(alertConstraintEditSuccess);
      setLoading(false);
      resetStateConstraint();
    }
    data.idsendmail = constraintCreated.id;
    await createConstraint(data);
    await notificationService.createConstraint({
      user: user,
      constraint: constraintCreated,
      module: 'constraints',
      type_notification: 'assign_responsible'
    });
    dispatch(userActions.setUserUpdate());
    dispatch(userActions.setUserUpdate2());
  };

  const handleClose = () => {
    resetStateConstraint();
  };

  const handleCreateType = () => {
    setVisibleModalCreateType(true);
  };

  const validateFormCreateConstraint = () =>
    constraintState?.constraintForm?.type &&
    constraintState?.constraintForm?.typeDate === TYPE_DATE_DEFAULT &&
    constraintState?.constraintForm?.name &&
    constraintState?.constraintForm?.responsable &&
    constraintState?.constraintForm?.calendarDate;

  /** services */
  const createConstraint = async (data) => {
    const constraintCreated = await constraintService.create(data);
    return constraintCreated;
  };

  async function asignConstraintTask(data) {
    const resp = await constraintService.asignConstraintTask(data);
    return resp;
  }

  /** render */
  return (
    <Modal
      closable={false}
      className="constraint__form-modal"
      visible={visibleForm}
      centered
      keyboard={false}
      maskClosable={false}
      onCancel={handleClose}
      width={748}
      footer={
        /** submit button in footer */
        /* currenTab === 2 ? <div>
          <div
            className="constraint-footer-btn"
            key="btns">
            <Button loading={loading} onClick={handleContinue} className="constraint-btn-submit clear-btn-frm-constraint">
              {currenTab != 3 ? t('lookahead_constraints.create') : t('takt_assign.assign')}
            </Button>
          </div>
        </div>
          : */ null
      }>
      <div className="form-modal__container">
        {loadingTypes && (
          <div className="container__loading">
            <LoadingOutlined />
          </div>
        )}
        <div className="container__header">
          <div className="header__title">
            <CreateRoadBlockIcon color="#7DFF8A" />
            <h6>{t('add_constraint_title')}</h6>
          </div>
          <div className="header__close" onClick={handleClose}>
            <CloseIcon color="#FFF" />
          </div>
        </div>
        <div className="container__body">
          <div className="body__list-types">
            <ConstraintTypes
              t={t}
              typeValid={typeValid}
              handleContinue={handleContinue}
              types={types}
              setTypes={setTypes}
              setLoadingTypes={setLoadingTypes}
            />
          </div>
          <div className="body__create-type">
            <h6 onClick={() => handleCreateType()}>
              {t('constraint_create_type')}
            </h6>
            <ConstraintNew
              t={t}
              visibleModal={visibleModalCreateType}
              setVisibleModal={(value) => setVisibleModalCreateType(value)}
              types={types}
              setTypes={setTypes}
            />
          </div>
          <div className="body__form">
            <ConstraintSelect
              t={t}
              visibleForm={visibleForm}
              initialTasks={initialTasks}
              nameValid={nameValid}
              responsableValid={responsableValid}
              toSelectResponsables={toSelectResponsables}
            />
          </div>
          <div className="body__buttons">
            <button className="buttons__cancel" onClick={handleClose}>
              {t('constraint_modal_cancel_button')}
            </button>
            <button
              className="buttons__create"
              onClick={handleContinue}
              disabled={!validateFormCreateConstraint() || loading}>
              {t('constraint_modal_create_button')}
            </button>
          </div>
        </div>
      </div>

      {old && (
        <>
          <div className="title-frm-add">
            {t('add_constraint_title')}
            <div className="title-close" onClick={handleClose}>
              <CloseOutlined />
            </div>
          </div>

          <Row className="frm">
            <Col span={24}>
              {/* Render tab Select Constraint Type */}
              <div className={currenTab === 1 ? null : 'hidden'}>
                <ConstraintTypes
                  t={t}
                  typeValid={typeValid}
                  handleContinue={handleContinue}
                />
              </div>

              {/* Render tab Set Name, Select Tasks, Select Responsable */}
              <div className={currenTab === 2 ? null : 'hidden'}>
                <ConstraintSelect
                  t={t}
                  visibleForm={visibleForm}
                  initialTasks={initialTasks}
                  nameValid={nameValid}
                  responsableValid={responsableValid}
                  toSelectResponsables={toSelectResponsables}
                />
                {/* <AttachDocument documentList={documentList} setDocumentList={setDocumentList} /> */}
              </div>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

/** declaration of types for comnponent */
ConstraintForm.propTypes = {
  visibleForm: PropTypes.bool /** handle modal visibility state */,
  setVisibleForm: PropTypes.func /** handle set modal visibility state */,
  initialTasks: PropTypes.array /** handle initial task to list of tasks */,
  setConstraintAdded:
    PropTypes.func /** callback to deliver added restriction */
};

/** default props for component */
ConstraintForm.defaultProps = {
  visibleForm: true,
  setVisibleForm: () => {},
  initialTasks: [],
  setConstraintAdded: () => {}
};

export default ConstraintForm;

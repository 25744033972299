import React from 'react';
import cn from 'classnames';

import addUserPath from '../assets/img/icons/icon--add-user.svg';
import arrowRigthPath from '../assets/img/icons/icon--arrow-rigth.svg';
import arrowLeftPath from '../assets/img/icons/icon--arrow-left.svg';
import arrowDownPath from '../assets/img/icons/icon--arrow-down.svg';
import previewPath from '../assets/img/icons/icon--preview.svg';
import commitIconPath from '../assets/img/icons/icon--commit.svg';
import searchIconPath from '../assets/img/icons/icon--search.svg';
import editColumnsIconPath from '../assets/img/icons/icon--edit-columns.svg';
import progressWeeklyIconPath from '../assets/img/icons/icon--progress-weekly.svg';
import productivityWeeklyIconPath from '../assets/img/icons/icon--productivity-weekly.svg';
import linksOnIconPath from '../assets/img/icons/icon--links-on.svg';
import linksOffIconPath from '../assets/img/icons/icon--links-off.svg';
import leanstatusIconPath from '../assets/img/icons/icon--leanstatus.svg';
import settingsIconPath from '../assets/img/icons/icon--settings.svg';
import newIconPath from '../assets/img/icons/icon--new.svg';
import alertIconPath from '../assets/img/icons/icon--alert.svg';
import baselinesIconPath from '../assets/img/icons/icon--baselines.svg';
import criticalPathsIconPath from '../assets/img/icons/icon--critical-paths.svg';
import slackIconPath from '../assets/img/icons/icon--slack.svg';
import baselinePathsIcon from '../assets/img/icons/icon--baseline.svg';
import numtasksPathsIcon from '../assets/img/icons/icon--numtasks.svg';
import todaylinePathsIcon from '../assets/img/icons/icon--todayline.svg';
import outdentIconPath from '../assets/img/icons/icon--outdent.svg';
import indentIconPath from '../assets/img/icons/icon--indent.svg';
import downloadIconPath from '../assets/img/icons/icon--download.svg';
import uploadIconPath from '../assets/img/icons/icon--upload.svg';
import ganttLevelsIconPath from '../assets/img/icons/icon--gantt-levels.svg';
import modificationRequestIconPath from '../assets/img/icons/icon--modification-request.svg';
import newModificationRequestIconPath from '../assets/img/icons/icon--new-modification-request.svg';
import showLinksIconPath from '../assets/img/icons/icon--show-links.svg';
import saveIconPath from '../assets/img/icons/icon--save.svg';
import xmlIconPath from '../assets/img/icons/icon--xml.svg';
import pdfIconPath from '../assets/img/icons/icon--pdf.svg';
import excelIconPath from '../assets/img/icons/icon--excel.svg';
import calendarIconPath from '../assets/img/icons/icon--calendar.svg';
import workerIconPath from '../assets/img/icons/icon--worker.svg';
import clockIconPath from '../assets/img/icons/icon--clock.svg';
import paletteIconPath from '../assets/img/icons/icon--palette.svg';
import infoIconPath from '../assets/img/icons/icon--info.svg';
import infoIconOutlinedPath from '../assets/img/icons/icon--info-outlined.svg';
import threeDotsHorizontalPath from '../assets/img/icons/icon--three-dots-horizontal.svg';
import bulletIconPath from '../assets/img/icons/icon--bullet.svg';
import tagIconPath from '../assets/img/icons/icon--tag.svg';
import cogsIconPath from '../assets/img/icons/icon--cogs.svg';
import pinIconPath from '../assets/img/icons/icon--pin.svg';
import eyeIconPath from '../assets/img/icons/icon--eye.svg';
import trashIconPath from '../assets/img/icons/icon--trash.svg';
import undoIconPath from '../assets/img/icons/icon--undo.svg';
import redoIconPath from '../assets/img/icons/icon--redo.svg';
import filterIconPath from '../assets/img/icons/icon--filter.svg';
import orderIconPath from '../assets/img/icons/icon--order.svg';
import groupIconPath from '../assets/img/icons/icon--group.svg';
import lookaheadIconPath from '../assets/img/icons/icon--lookahead.svg';
import ganttIconPath from '../assets/img/icons/icon--gantt-visualization.svg';
import columnsIconPath from '../assets/img/icons/icon--columns.svg';
import chevronDownIconPath from '../assets/img/icons/icon--chevron-down.svg';
import chevronRightIconPath from '../assets/img/icons/icon--chevron-right.svg';
import circleCheckIconPath from '../assets/img/icons/icon--circle-check.svg';
import circleCancelIconPath from '../assets/img/icons/icon--circle-cancel.svg';
import circleMinusIconPath from '../assets/img/icons/icon--circle-minus.svg';
import circleEmptyIconPath from '../assets/img/icons/icon--circle-empty.svg';
import closeIconPath from '../assets/img/icons/icon--close.svg';
import dialogIconPath from '../assets/img/icons/icon--dialog.svg';
import subRightIconPath from '../assets/img/icons/icon--sub-right.svg';
import progressCurveCriteriaIconPath from '../assets/img/icons/icon--progress-curve-criteria.svg';
import statusCriteriaIconPath from '../assets/img/icons/icon--status-criteria.svg';
import dateFormatIconPath from '../assets/img/icons/icon--date-format.svg';
import tableIconPath from '../assets/img/icons/icon--table.svg';
import timelineIconPath from '../assets/img/icons/icon--timeline.svg';
import taktIconPath from '../assets/img/icons/icon--takt.svg';
import CollapseIconPath from '../assets/img/icons/icon--row-collapse.svg';
import moreFiltersIconPath from '../assets/img/icons/icon--more-filters.svg';
import roadblockIconPath from '../assets/img/icons/icon--roadblock.svg';
import warningIconPath from '../assets/img/icons/icon--warning.svg';
import logsIconPath from '../assets/img/icons/icon--logs.svg';
import editImageIconPath from '../assets/img/icons/icon--edit-Image.svg';
import helpIconPath from '../assets/img/icons/icon--help.svg';
import zoomInIconPath from '../assets/img/icons/icon--zoom-in.svg';
import zoomOutIconPath from '../assets/img/icons/icon--zoom-out.svg';
import rotateLeftIconPath from '../assets/img/icons/icon--rotate-left.svg';
import rotateRightIconPath from '../assets/img/icons/icon--rotate-right.svg';
import taskListPath from '../assets/img/icons/icon--task-list.svg';
import calendarNewIconPath from '../assets/img/icons/icon--calendar-new.svg';
import NewDesignIconPath from '../assets/img/icons/icon--new-design.svg';
import NewLaborIconPath from '../assets/img/icons/icon--new-labor.svg';
import NewEquipmentIconPath from '../assets/img/icons/icon--new-equipment.svg';
import NewMaterialIconPath from '../assets/img/icons/icon--new-material.svg';
import NewPermitIconPath from '../assets/img/icons/icon--new-permit.svg';
import NewSupplyIconPath from '../assets/img/icons/icon--new-supply.svg';
import NewEnviromentIconPath from '../assets/img/icons/icon--new-enviroment.svg';
import NewSafetyIconPath from '../assets/img/icons/icon--new-safety.svg';
import NewPredecessorIconPath from '../assets/img/icons/icon--new-predecessor.svg';
import NewQualityIconPath from '../assets/img/icons/icon--new-quality.svg';
import NewOthersIconPath from '../assets/img/icons/icon--new-others.svg';
import NewSubmittalIconPath from '../assets/img/icons/icon--new-submittal.svg';
import NewContractIconPath from '../assets/img/icons/icon--new-contract.svg';
import NewOwnerIconPath from '../assets/img/icons/icon--new-owner.svg';
import NewChangeIconPath from '../assets/img/icons/icon--new-change.svg';
import CreateRoadBlockIconPath from '../assets/img/icons/icon--create-roadblock.svg';
import scheduleUpdatePath from '../assets/img/icons/schedule-update.svg';
import scheduleUpdateAdd from '../assets/img/icons/icon-updater.svg';
import trashAllPath from '../assets/img/icons/icon--trash-all.svg';
import moreFilledPath from '../assets/img/icons/icon--more-filled.svg';
import iconWBSPath from '../assets/img/icons/icon--wbs.svg';

import editStagePath from '../assets/img/icons/icon--edit-stage.svg';
import duplicateStagePath from '../assets/img/icons/icon--duplicate-stage.svg';
import saveAsTemplateStagePath from '../assets/img/icons/icon--save-as-template-stage.svg';
import makeVisibleScheduleIcon from '../assets/img/icons/icon--make-visible-schedule.svg';
import hideScheduleIcon from '../assets/img/icons/icon--hide-schedule.svg';
import sharingPermissionsStagePath from '../assets/img/icons/icon--sharing-permissions-stage.svg';
import deleteStagePath from '../assets/img/icons/icon--delete-stage.svg';

import projectInfoPath from '../assets/img/icons/icon--project-info.svg';
import userCircleSinglePath from '../assets/img/icons/icon--user-circle-single.svg';
import dollarCoinPath from '../assets/img/icons/icon--dollar-coin.svg';
import cardViewPath from '../assets/img/icons/icon--card-view.svg';
import timelineViewPath from '../assets/img/icons/icon--timeline-view.svg';
import textfileToolbarProjectsPath from '../assets/img/icons/icon--textfile-toolbar-projects.svg';
import pinToolbarProjectsPath from '../assets/img/icons/icon--pin-toolbar-projects.svg';
import layoutToolbarProjectsPath from '../assets/img/icons/icon--layout-toolbar-projects.svg';
import arrowsToolbarProjectsPath from '../assets/img/icons/icon--arrows-toolbar-projects.svg';
import calendarToolbarProjectsPath from '../assets/img/icons/icon--calendar-toolbar-projects.svg';
import filterToolbarProjects from '../assets/img/icons/icon--filter-toolbar-projects.svg';

/** massive icons */
import massiveArrowsIconPath from '../assets/img/icons/icon--massive-arrows.svg';
import massiveTagIconPath from '../assets/img/icons/icon--massive-tag.svg';
import massiveHelmetIconPath from '../assets/img/icons/icon--massive-helmet.svg';
import massiveAvatarIconPath from '../assets/img/icons/icon--massive-avatar.svg';
import massiveCheckIconPath from '../assets/img/icons/icon--massive-check.svg';
import massiveClockIconPath from '../assets/img/icons/icon--massive-clock.svg';
import massiveUncheckIconPath from '../assets/img/icons/icon--massive-uncheck.svg';
import massiveDuplicateIconPath from '../assets/img/icons/icon--massive-dup.svg';

import dangerousNotificationPath from '../assets/img/icons/icon--dangerous-notification.svg';
import warningNotificationPath from '../assets/img/icons/icon--warning-notification.svg';
import archivePath from '../assets/img/icons/icon--archive.svg';

import submittalIconPath from '../assets/img/icons/icon--submittals.svg';
import deletedTaskPath from '../assets/img/icons/icon--deleted-task.svg';
import deletedTaskEmptyPath from '../assets/img/icons/icon--deleted-task-empty.svg';

import informationCirclePath from '../assets/img/icons/icon--information-circle.svg';
import warningDiamontPath from '../assets/img/icons/icon--warning-diamond.svg';

import pencilOutlinePath from '../assets/img/icons/icon--pencil-outline.svg';
import duplicateOutlinePath from '../assets/img/icons/icon--duplicate-outline.svg';
import trashOutlinePath from '../assets/img/icons/icon--trash-outline.svg';
import arrowDropdownPath from '../assets/img/icons/icon--arrow-dropdown.svg';

import informationCircleOutlinePath from '../assets/img/icons/icon--information-circle-outline.svg';
import successSolidPath from '../assets/img/icons/icon--success-solid.svg';
import warningOutlinePath from '../assets/img/icons/icon--warning-outline.svg';
import errorOutlinePath from '../assets/img/icons/icon--error-outline.svg';

import closeNotificationPath from '../assets/img/icons/icon--close-notification.svg';
import horizontalMenuOutlinePath from '../assets/img/icons/icon--horizontal-menu-outline.svg';
import addPath from '../assets/img/icons/icon--add.svg';
import deleteTagOutlinePath from '../assets/img/icons/icon--delete-tag-outline.svg';
import closeTagPath from '../assets/img/icons/icon--close-tag.svg';
import scheduleTimePath from '../assets/img/icons/icon--schedule--time.svg';
import craneXmarkIcon from '../assets/img/icons/icon--crane-xmark.svg';

import checkboxCheckedPath from '../assets/img/icons/icon--checkbox-checked.svg';
import checkboxCheckedAllPath from '../assets/img/icons/icon--checkbox-checked-all.svg';
import radioCheckedPath from '../assets/img/icons/icon--radio-checked.svg';

import moreUserPath from '../assets/img/icons/icon--more-user.svg';
import openModalPath from '../assets/img/icons/icon--open-modal.svg';
import companyHelmetIconPath from '../assets/img/icons/icon--company-helmet.svg';

import userMultipleGroupOutlinePath from '../assets/img/icons/icon--user-multiple-group-outline.svg';

import { getColorFilters } from './utils';
import styles from './Icons.module.scss';
import colors from '../stylesheets/variables.scss';
import { SvgIcon } from '../utils';
const tagPathExt =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/pdf-gantt/icon--tag.svg';

export const AddUserIcon = (props) => <SvgIcon path={addUserPath} {...props} />;

export const ArrowRigthIcon = (props) => (
  <SvgIcon path={arrowRigthPath} {...props} />
);

export const ArrowLeftIcon = (props) => (
  <SvgIcon path={arrowLeftPath} {...props} />
);

export const ProductivityWeeklyIcon = (props) => (
  <SvgIcon path={productivityWeeklyIconPath} {...props} />
);

export const PreviewIcon = (props) => <SvgIcon path={previewPath} {...props} />;

export const ProgressWeeklyIcon = (props) => (
  <SvgIcon path={progressWeeklyIconPath} {...props} />
);

export const ArrowDownIcon = (props) => (
  <SvgIcon path={arrowDownPath} {...props} />
);

export const AlertIcon = (props) => <SvgIcon path={alertIconPath} {...props} />;

export const FilterIcon = (props) => (
  <SvgIcon path={filterIconPath} {...props} />
);

export const OrderIcon = (props) => <SvgIcon path={orderIconPath} {...props} />;

export const GroupIcon = (props) => <SvgIcon path={groupIconPath} {...props} />;

export const ColumnsIcon = (props) => (
  <SvgIcon path={columnsIconPath} {...props} />
);

export const LookaheadRangeIcon = (props) => (
  <SvgIcon path={lookaheadIconPath} {...props} />
);

export const GanttIcon = (props) => <SvgIcon path={ganttIconPath} {...props} />;

export const ChevronDownIcon = (props) => (
  <SvgIcon path={chevronDownIconPath} {...props} />
);

export const ChevronRightIcon = (props) => (
  <SvgIcon path={chevronRightIconPath} {...props} />
);

export const UndoIcon = (props) => <SvgIcon path={undoIconPath} {...props} />;

export const RedoIcon = (props) => <SvgIcon path={redoIconPath} {...props} />;

export const SearchIcon = (props) => (
  <SvgIcon path={searchIconPath} {...props} />
);

export const EditColumnsIcon = (props) => (
  <SvgIcon path={editColumnsIconPath} {...props} />
);

export const OutdentIcon = (props) => (
  <SvgIcon path={outdentIconPath} {...props} />
);

export const IndentIcon = (props) => (
  <SvgIcon path={indentIconPath} {...props} />
);

export const LinksOnIcon = (props) => (
  <SvgIcon path={linksOnIconPath} {...props} />
);

export const LinksOffIcon = (props) => (
  <SvgIcon path={linksOffIconPath} {...props} />
);

export const LeanstatusIcon = (props) => (
  <SvgIcon path={leanstatusIconPath} {...props} />
);

export const BaselinesIcon = (props) => (
  <SvgIcon path={baselinesIconPath} {...props} />
);

export const GanttLevelsIcon = (props) => (
  <SvgIcon path={ganttLevelsIconPath} {...props} />
);

export const SettingsIcon = (props) => (
  <SvgIcon path={settingsIconPath} {...props} />
);

export const NewIcon = (props) => <SvgIcon path={newIconPath} {...props} />;

export const ModificationRequestIcon = (props) => (
  <SvgIcon path={modificationRequestIconPath} {...props} />
);

export const NewModificationRequestIcon = (props) => (
  <SvgIcon path={newModificationRequestIconPath} {...props} />
);

export const SaveIcon = (props) => <SvgIcon path={saveIconPath} {...props} />;

export const ShowLinksIcon = (props) => (
  <SvgIcon path={showLinksIconPath} {...props} />
);

export const CriticalPathsIcon = (props) => (
  <SvgIcon path={criticalPathsIconPath} {...props} />
);

export const SlackPathsIcon = (props) => (
  <SvgIcon path={slackIconPath} {...props} />
);

export const BaselinePathsIcon = (props) => (
  <SvgIcon path={baselinePathsIcon} {...props} />
);

export const NumtasksPathsIcon = (props) => (
  <SvgIcon path={numtasksPathsIcon} {...props} />
);

export const TodaylinePathsIcon = (props) => (
  <SvgIcon path={todaylinePathsIcon} {...props} />
);

export const UploadIcon = (props) => (
  <SvgIcon path={uploadIconPath} {...props} />
);

export const DownloadIcon = (props) => (
  <SvgIcon path={downloadIconPath} {...props} />
);

export const XmlIcon = (props) => <SvgIcon path={xmlIconPath} {...props} />;

export const PdfIcon = (props) => <SvgIcon path={pdfIconPath} {...props} />;

export const ExcelIcon = (props) => <SvgIcon path={excelIconPath} {...props} />;

export const CalendarIcon = (props) => (
  <SvgIcon path={calendarIconPath} {...props} />
);

export const WorkerIcon = (props) => (
  <SvgIcon path={workerIconPath} {...props} />
);

export const ClockIcon = (props) => <SvgIcon path={clockIconPath} {...props} />;

export const PaletteIcon = (props) => (
  <SvgIcon path={paletteIconPath} {...props} />
);

export const InfoIcon = (props) => <SvgIcon path={infoIconPath} {...props} />;

export const InfoIconOutlined = (props) => (
  <SvgIcon path={infoIconOutlinedPath} {...props} />
);

export const ThreeDotsHorizontal = (props) => (
  <SvgIcon path={threeDotsHorizontalPath} {...props} />
);

export const BulletIcon = (props) => (
  <SvgIcon path={bulletIconPath} {...props} />
);

export const TagIcon = (props) => <SvgIcon path={tagIconPath} {...props} />;

export const TagIconExt = (props) => <SvgIcon path={tagPathExt} {...props} />;

export const CogsIcon = (props) => <SvgIcon path={cogsIconPath} {...props} />;

export const EyeIcon = (props) => <SvgIcon path={eyeIconPath} {...props} />;

export const TrashIcon = (props) => <SvgIcon path={trashIconPath} {...props} />;

export const CircleCheckIcon = (props) => (
  <SvgIcon path={circleCheckIconPath} {...props} />
);

export const CircleCancelIcon = (props) => (
  <SvgIcon path={circleCancelIconPath} {...props} />
);

export const CircleMinusIcon = (props) => (
  <SvgIcon path={circleMinusIconPath} {...props} />
);

export const CircleEmptyIcon = (props) => (
  <SvgIcon path={circleEmptyIconPath} {...props} />
);

export const PinIcon = (props) => <SvgIcon path={pinIconPath} {...props} />;

export const CloseIcon = (props) => <SvgIcon path={closeIconPath} {...props} />;

export const DialogIcon = (props) => (
  <SvgIcon path={dialogIconPath} {...props} />
);

export const SubRightIcon = (props) => (
  <SvgIcon path={subRightIconPath} {...props} />
);

export const ProgressCurveCriteriaIcon = (props) => (
  <SvgIcon path={progressCurveCriteriaIconPath} {...props} />
);

export const StatusCriteriaIcon = (props) => (
  <SvgIcon path={statusCriteriaIconPath} {...props} />
);

export const DateFormatIcon = (props) => (
  <SvgIcon path={dateFormatIconPath} {...props} />
);

export const TableIcon = (props) => <SvgIcon path={tableIconPath} {...props} />;

export const TimelineIcon = (props) => (
  <SvgIcon path={timelineIconPath} {...props} />
);

export const TaktIcon = (props) => <SvgIcon path={taktIconPath} {...props} />;

export const CollapseIcon = (props) => (
  <SvgIcon path={CollapseIconPath} {...props} />
);

export const MoreFiltersIcon = (props) => (
  <SvgIcon path={moreFiltersIconPath} {...props} />
);

export const HandCommitIcon = (props) => (
  <SvgIcon path={commitIconPath} {...props} />
);

export const WarningIcon = (props) => (
  <SvgIcon path={warningIconPath} {...props} />
);

export const RoadblockIcon = (props) => (
  <SvgIcon path={roadblockIconPath} {...props} />
);

export const LogsIcon = (props) => <SvgIcon path={logsIconPath} {...props} />;

export const EditImageIcon = (props) => (
  <SvgIcon path={editImageIconPath} {...props} />
);

export const ProjectInfoIcon = (props) => (
  <SvgIcon path={projectInfoPath} {...props} />
);

export const UserCircleSingleIcon = (props) => (
  <SvgIcon path={userCircleSinglePath} {...props} />
);

export const DollarCoinIcon = (props) => (
  <SvgIcon path={dollarCoinPath} {...props} />
);

export const CardViewIcon = (props) => (
  <SvgIcon path={cardViewPath} {...props} />
);

export const TimelineViewIcon = (props) => (
  <SvgIcon path={timelineViewPath} {...props} />
);

export const TextfileToolbarProjectsIcon = (props) => (
  <SvgIcon path={textfileToolbarProjectsPath} {...props} />
);

export const PinToolbarProjectsIcon = (props) => (
  <SvgIcon path={pinToolbarProjectsPath} {...props} />
);

export const LayoutToolbarProjectsIcon = (props) => (
  <SvgIcon path={layoutToolbarProjectsPath} {...props} />
);

export const ArrowsToolbarProjectsIcon = (props) => (
  <SvgIcon path={arrowsToolbarProjectsPath} {...props} />
);

export const CalendarToolbarProjectsIcon = (props) => (
  <SvgIcon path={calendarToolbarProjectsPath} {...props} />
);

export const FilterToolbarProjectsIcon = (props) => (
  <SvgIcon path={filterToolbarProjects} {...props} />
);

export const HelpIcon = (props) => <SvgIcon path={helpIconPath} {...props} />;

export const ZoomInIcon = (props) => (
  <SvgIcon path={zoomInIconPath} {...props} />
);

export const ZoomOutIcon = (props) => (
  <SvgIcon path={zoomOutIconPath} {...props} />
);

export const RotateLeftIcon = (props) => (
  <SvgIcon path={rotateLeftIconPath} {...props} />
);

export const RotateRightIcon = (props) => (
  <SvgIcon path={rotateRightIconPath} {...props} />
);

export const MassiveArrowsIcon = (props) => (
  <SvgIcon path={massiveArrowsIconPath} {...props} />
);

export const MassiveTagIcon = (props) => (
  <SvgIcon path={massiveTagIconPath} {...props} />
);

export const MassiveHelmetIcon = (props) => (
  <SvgIcon path={massiveHelmetIconPath} {...props} />
);

export const MassiveAvatarIcon = (props) => (
  <SvgIcon path={massiveAvatarIconPath} {...props} />
);

export const MassiveCheckIcon = (props) => (
  <SvgIcon path={massiveCheckIconPath} {...props} />
);

export const MassiveClockIcon = (props) => (
  <SvgIcon path={massiveClockIconPath} {...props} />
);

export const MassiveUncheckIcon = (props) => (
  <SvgIcon path={massiveUncheckIconPath} {...props} />
);

export const TaskListIcon = (props) => (
  <SvgIcon path={taskListPath} {...props} />
);

export const CalendarNewIcon = (props) => (
  <SvgIcon path={calendarNewIconPath} {...props} />
);

export const NewDesignIcon = (props) => (
  <SvgIcon path={NewDesignIconPath} {...props} />
);

export const NewLaborIcon = (props) => (
  <SvgIcon path={NewLaborIconPath} {...props} />
);

export const NewEquipmentIcon = (props) => (
  <SvgIcon path={NewEquipmentIconPath} {...props} />
);

export const NewMaterialIcon = (props) => (
  <SvgIcon path={NewMaterialIconPath} {...props} />
);

export const NewPermitIcon = (props) => (
  <SvgIcon path={NewPermitIconPath} {...props} />
);

export const NewSupplyIcon = (props) => (
  <SvgIcon path={NewSupplyIconPath} {...props} />
);

export const NewEnviromentIcon = (props) => (
  <SvgIcon path={NewEnviromentIconPath} {...props} />
);

export const NewSafetyIcon = (props) => (
  <SvgIcon path={NewSafetyIconPath} {...props} />
);

export const NewPredecessorIcon = (props) => (
  <SvgIcon path={NewPredecessorIconPath} {...props} />
);

export const NewQualityIcon = (props) => (
  <SvgIcon path={NewQualityIconPath} {...props} />
);

export const NewOthersIcon = (props) => (
  <SvgIcon path={NewOthersIconPath} {...props} />
);

export const NewSubmittalIcon = (props) => (
  <SvgIcon path={NewSubmittalIconPath} {...props} />
);

export const NewContractIcon = (props) => (
  <SvgIcon path={NewContractIconPath} {...props} />
);

export const NewOwnerIcon = (props) => (
  <SvgIcon path={NewOwnerIconPath} {...props} />
);

export const NewChangeIcon = (props) => (
  <SvgIcon path={NewChangeIconPath} {...props} />
);

export const CreateRoadBlockIcon = (props) => (
  <SvgIcon path={CreateRoadBlockIconPath} {...props} />
);

export const ScheduleUpdatePath = (props) => (
  <SvgIcon path={scheduleUpdateAdd} {...props} />
);

export const ScheduleUpdateIcon = (props) => (
  <SvgIcon path={scheduleUpdatePath} {...props} />
);

export const MassiveDuplicateIcon = (props) => (
  <SvgIcon path={massiveDuplicateIconPath} {...props} />
);

export const TrashAllIcon = (props) => (
  <SvgIcon path={trashAllPath} {...props} />
);

export const MoreFilledIcon = (props) => (
  <SvgIcon path={moreFilledPath} {...props} />
);

export const iconWBS = (props) => <SvgIcon path={iconWBSPath} {...props} />;

export const EditStageIcon = (props) => (
  <SvgIcon path={editStagePath} {...props} />
);

export const DuplicateStageIcon = (props) => (
  <SvgIcon path={duplicateStagePath} {...props} />
);

export const SaveAsTemplateStageIcon = (props) => (
  <SvgIcon path={saveAsTemplateStagePath} {...props} />
);

export const MakeVisibleScheduleIcon = (props) => (
  <SvgIcon path={makeVisibleScheduleIcon} {...props} />
);

export const HideScheduleIcon = (props) => (
  <SvgIcon path={hideScheduleIcon} {...props} />
);

export const SharingPermissionsStageIcon = (props) => (
  <SvgIcon path={sharingPermissionsStagePath} {...props} />
);

export const DeleteStageIcon = (props) => (
  <SvgIcon path={deleteStagePath} {...props} />
);

export const DangerousNotificationIcon = (props) => (
  <SvgIcon path={dangerousNotificationPath} {...props} />
);

export const WarningNotificationIcon = (props) => (
  <SvgIcon path={warningNotificationPath} {...props} />
);

export const ArchiveIcon = (props) => <SvgIcon path={archivePath} {...props} />;

export const SubmittalIcon = (props) => (
  <SvgIcon path={submittalIconPath} {...props} />
);

export const DeletedTaskIcon = (props) => (
  <SvgIcon path={deletedTaskPath} {...props} />
);

export const DeletedTaskEmptyIcon = (props) => (
  <SvgIcon path={deletedTaskEmptyPath} {...props} />
);

export const InformationCircleIcon = (props) => (
  <SvgIcon path={informationCirclePath} {...props} />
);

export const WarningDiamontIcon = (props) => (
  <SvgIcon path={warningDiamontPath} {...props} />
);

export const ArrowDropdownIcon = (props) => (
  <SvgIcon path={arrowDropdownPath} {...props} />
);

export const PencilOutlineIcon = (props) => (
  <SvgIcon path={pencilOutlinePath} {...props} />
);

export const DuplicateOutlineIcon = (props) => (
  <SvgIcon path={duplicateOutlinePath} {...props} />
);

export const TrashOutlineIcon = (props) => (
  <SvgIcon path={trashOutlinePath} {...props} />
);

export const InformationCircleOutlineIcon = (props) => (
  <SvgIcon path={informationCircleOutlinePath} {...props} />
);

export const SuccessSolidIcon = (props) => (
  <SvgIcon path={successSolidPath} {...props} />
);

export const WarningOutlineIcon = (props) => (
  <SvgIcon path={warningOutlinePath} {...props} />
);

export const ErrorOutlineIcon = (props) => (
  <SvgIcon path={errorOutlinePath} {...props} />
);

export const CloseNotificationIcon = (props) => (
  <SvgIcon path={closeNotificationPath} {...props} />
);
export const HorizontalMenuOutlineIcon = (props) => (
  <SvgIcon path={horizontalMenuOutlinePath} {...props} />
);

export const AddIcon = (props) => <SvgIcon path={addPath} {...props} />;

export const DeleteTagOutlineIcon = (props) => (
  <SvgIcon path={deleteTagOutlinePath} {...props} />
);

export const CloseTagIcon = (props) => (
  <SvgIcon path={closeTagPath} {...props} />
);

export const ScheduleTimeIcon = (props) => (
  <SvgIcon path={scheduleTimePath} {...props} />
);

export const CraneXmarkIcon = (props) => (
  <SvgIcon path={craneXmarkIcon} {...props} />
);

export const CheckboxCheckedIcon = (props) => (
  <SvgIcon path={checkboxCheckedPath} {...props} />
);

export const CheckboxCheckedAllIcon = (props) => (
  <SvgIcon path={checkboxCheckedAllPath} {...props} />
);

export const RadioCheckedIcon = (props) => (
  <SvgIcon path={radioCheckedPath} {...props} />
);

export const MoreUserIcon = (props) => (
  <SvgIcon path={moreUserPath} {...props} />
);

export const OpenModalIcon = (props) => (
  <SvgIcon path={openModalPath} {...props} />
);

export const CompanyHelmetIcon = (props) => (
  <SvgIcon path={companyHelmetIconPath} {...props} />
);

export const UserMultipleGroupOutlineIcon = (props) => (
  <SvgIcon path={userMultipleGroupOutlinePath} {...props} />
);

import { tagIconSVG } from '../../../../utils';

export const TemplateTag = (task) => {
  const gantt = window?.ganttVisualization;
  const { isTask, tags } = task;

  if (!gantt || !isTask) return null;

  let isOdd = false;
  const { oddColsConfig } = gantt;

  if (oddColsConfig) {
    isOdd = oddColsConfig.tags;
  }

  let insideHtml = '';
  tags.map(({ name, description }) => {
    const dotColorsvg = `
                <span class="svg_tag_icon" style="color: ${description};">
                    ${tagIconSVG}
                </span>
            `;
    insideHtml += `${dotColorsvg}${name}`;
  });

  return `
        <div class=" ${isOdd ? 'odd-col' : 'non-odd-col'} columnExportPDFHide">
            <div key={index} style="height: 100%; padding: 0; position: relative;">
                <div className='tag-class-span'>
                    ${insideHtml}
                </div>
            </div>
        </div>
    `;
};

/* eslint-disable prefer-const */
import React, { useEffect, useState, Fragment } from 'react';
import EventEmitter from 'react-native-eventemitter';
import {
  Avatar,
  Button,
  Row,
  Col,
  Select,
  Input,
  Upload,
  Icon,
  Spin
} from 'antd';
import ModalCropImage from '../../Settings/ModalCropImage';
/** import base contant (setting urls)  */
import { base } from '../../../services/base';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import {
  codeCountries,
  Types,
  companyContractType,
  generateFormObjectSelect,
  openNotification,
  getBase64,
  headersSendForm,
  ObjectLogoSvg,
  checkAllFieldsValid,
  reduceFormValues,
  buildPassword,
  txtFieldState,
  MsgErrorDB,
  makeSlug,
  getTodayWeekWithPdayAndCurrentDay
} from '../../../utils';
import { createCnc } from '../../../views/weeklyPlan/weeklyPlan.helper';
import { ganttAPI } from '../../../utils/customGanttPlugin';
import uploadIcon from '../../../assets/img/upload-icon.png';

/** Redux */
import { useSelector } from 'react-redux';
import { createCompany } from '../../../views/company/company.helper';
import { LoadingOutlined } from '@ant-design/icons';
import './index.css';
import { useParams } from 'react-router-dom';
import { companyService, projectService, userService } from '../../../services';

import { createUser, getUser } from '../../../views/project/project.helper';
import { getEndDateByGantt } from '../../../utils/lookahead-common';
import { getSignedUser } from '../../../utils/userUtils';
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

/** state Object */
const stateTemplate = {
  name: {
    ...txtFieldState,
    fieldName: 'name',
    required: true,
    requiredTxt: '',
    formatErrorTxt: ''
  },
  email: {
    ...txtFieldState,
    fieldName: 'email',
    required: true,
    requiredTxt: '',
    formatErrorTxt: ''
  },
  nameproject: {
    ...txtFieldState,
    fieldName: 'nameproject',
    required: true,
    requiredTxt: '',
    formatErrorTxt: ''
  },
  country: {
    ...txtFieldState,
    fieldName: 'country',
    required: true,
    requiredTxt: 'Country is required',
    formatErrorTxt: '',
    value: 'CL'
  },
  contract_type: {
    ...txtFieldState,
    fieldName: 'contract_type',
    required: true,
    requiredTxt: 'Contract type is required',
    formatErrorTxt: '',
    value: 'project_based'
  },
  allFieldsValid: false,
  showAgree: false,
  visibleTerms: false,
  isLoading: false,
  switchViewForm: false
};

function CompanyCreate(props) {
  const stateCompany = useSelector((state) => state.companyState);
  const currentCompany = stateCompany.currentCompany;
  const stateProject = useSelector((state) => state.projectState);

  const { t } = props;
  const { token } = useParams(); // handle params
  // const { week, planificationday, year } = useParams();
  const [stateUpload, setStateUpload] = useState({ loading: false });
  const [projectPcountry, setProjectPcountry] = useState('US'); // handle Project Country
  const [contractType, setContractType] = useState('project_based'); // handle Contract Type
  const [state, setState] = useState(stateTemplate); // validation
  const [text, setText] = useState({
    text: ''
  });
  const [isLoading, setIsLoading] = useState(false); // Btn Loading
  const [stateNewImage, setStateNewImage] = useState({ isLoaded: false });
  const [imgSrc, setImgSrc] = useState('');
  const [formCropVisible, setFormCropVisible] = useState(false);
  const [CompanyCurrent, setCompanyCurrent] = useState([]);

  useEffect(() => {
    if (imgSrc) {
      setFormCropVisible(true);
    }
  }, [imgSrc]);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result.toString() || '');
        setFormCropVisible(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  /** constants */
  const { Option } = Select;

  /** this function is triggered when upload image */
  const onChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setStateUpload({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setStateUpload({
          imageUrl,
          name: info.file.response.name,
          loading: false
        })
      );
    }
  };

  const ProjectIcon = (props) => <Icon component={ObjectLogoSvg} {...props} />;
  const uploadButton = (
    <div>
      {stateUpload.loading ? (
        <LoadingOutlined />
      ) : (
        <ProjectIcon className="FrmCompany" />
      )}
    </div>
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    const formValues = reduceFormValues(e.target.elements, state);
    const allFieldsValid = checkAllFieldsValid(formValues);
    setState({ ...state, ...formValues, allFieldsValid });

    formValues.country = generateFormObjectSelect(
      'country',
      'Country',
      projectPcountry
    );
    formValues.contract_type = generateFormObjectSelect(
      'contract_type',
      'Contract type',
      contractType
    );
    const user = getSignedUser();
    const userActivationToken = await getUser(user.activation_token);

    if (!allFieldsValid) {
      openNotification({
        title: t('admin.organization_option.toasts.missing_fields.title'),
        description: t(
          'admin.organization_option.toasts.missing_fields.content'
        ),
        type: 'error'
      });
    } else {
      if (userActivationToken) {
        // validate company name
        const companys = await companyService.index();
        if (companys) {
          const existCompany = companys.companys.some((el) => {
            const slugForm = makeSlug(formValues.name.value, true);
            const slugCompany = makeSlug(el.name, true);
            return slugForm == slugCompany;
          });
          if (existCompany) {
            openNotification({
              title: t(
                'admin.organization_option.toasts.organization_exists.title'
              ),
              description: t(
                'admin.organization_option.toasts.organization_exists.content'
              ),
              type: 'error'
            });
            return false;
          }
        }

        // validate new user email
        const existUser = await userService.getbyemail(formValues.email.value);
        if (existUser !== false) {
          openNotification({
            title: t('admin.organization_option.toasts.user_exists.title'),
            description: t(
              'admin.organization_option.toasts.user_exists.content'
            ),
            type: 'error'
          });
          return false;
        }

        setIsLoading(true);
        // create Company
        const dataCompany = {
          name: formValues.name.value,
          size: 'b',
          country: formValues.country.value,
          image: null,
          contract_type: formValues.contract_type.value
        };
        /** save image uploaded */
        dataCompany.image = stateUpload.name ? stateUpload.name : null;
        const companyCreated = await createCompany(dataCompany);
        const idCurrentCompany = companyCreated.id;
        const userLang = navigator.language || navigator.userLanguage;
        const dataUser = {
          role: 'superadmin',
          is_active: true,
          email: formValues.email.value,
          link: base.front + 'confirmation/',
          lang: userLang.includes('es') ? 'es' : 'en',
          companyId: companyCreated.id,
          isDummy: true
        };
        const userSuper = await createUser(dataUser);

        const dataProject = {
          userId: userSuper.id,
          name: formValues.nameproject.value,
          companyId: companyCreated.id,
          createCompanyFlow: true
        };

        await projectService.duplicateProject(dataProject);

        setIsLoading(false);
        openNotification({
          title: t('admin.organization_option.toasts.success.title'),
          description: t('admin.organization_option.toasts.success.content'),
          type: 'success'
        });

        setTimeout(() => {
          window.location.reload();
        }, 800);
      }
    }
  };

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const { isLoaded } = stateNewImage;

  useEffect(() => {
    /** preload */
    if (stateUpload.imageUrl) {
      const image = new Image();
      image.onload = () => setStateNewImage({ isLoaded: true });
      image.src = stateUpload.imageUrl;
    }
  }, [stateUpload]);

  const imgToShow = !isLoaded ? (
    <Spin indicator={antIcon} />
  ) : (
    <img
      className="img-company-edit"
      width={147}
      alt="logo company"
      src={stateUpload.imageUrl}
    />
  );

  /** component upload button */
  const uploadCropButton = (
    <div>
      {stateUpload && stateUpload.loading ? (
        <LoadingOutlined />
      ) : (
        <ProjectIcon className="FrmCompany" />
      )}
    </div>
  );

  // <General/>
  return (
    <div style={{ background: '#FFFFFF', width: 500 }}>
      <Fragment>
        <form className="crear-admin-form" onSubmit={onSubmit} noValidate>
          <Row>
            <Col span={12}>
              <h1 className="form-admin-create-title">
                {t('admin.organization_option.organization_info')}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="fix-admin-css-crop">
              <div className="Crop-Controls">
                <input
                  type="file"
                  accept="image/*"
                  onChange={onSelectFile}
                  id="file-input-edit-image"
                />
              </div>
              <label
                htmlFor="file-input-edit-image"
                className="img-to-upload-admin">
                {stateUpload && stateUpload.imageUrl
                  ? imgToShow
                  : uploadCropButton}
              </label>
            </Col>
            <Col span={12}>
              <Row>
                <div
                  className="from-admin-create-input-holder"
                  style={{ color: '#121212', textAlign: 'left' }}>
                  {t('admin.organization_option.organization_name')}
                </div>

                <div
                  style={{ textAlign: 'left' }}
                  className="from-admin-create-input-imput">
                  <span>
                    <input
                      name="name"
                      autoComplete="off"
                      required
                      placeholder={t(
                        'admin.organization_option.organization_name'
                      )}
                      style={{ width: '100%' }}
                      defaultValue={text.text}
                      type="text"
                      className="structure-input"
                    />
                  </span>
                </div>
              </Row>
              <Row>
                <div style={{ color: '#121212', textAlign: 'left' }}>
                  {t('admin.organization_option.organization_email')}
                </div>

                <div style={{ textAlign: 'left' }}>
                  <span>
                    <input
                      style={{ width: '100%' }}
                      defaultValue={text.text}
                      autoComplete="off"
                      type="email"
                      name="email"
                      className="structure-input"
                      placeholder={t(
                        'admin.organization_option.organization_email_placeholder'
                      )}
                    />
                  </span>
                </div>
              </Row>
              <Row>
                <div style={{ color: '#121212', textAlign: 'left' }}>
                  {t('admin.organization_option.contract_type')}
                </div>
                <Select
                  type="select"
                  name="ptype"
                  value={contractType}
                  onChange={(e) => setContractType(e)}>
                  {companyContractType?.map(({ value, label }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <h1 className="form-admin-create-title">
                {t('admin.organization_option.project_settings')}
              </h1>
            </Col>
          </Row>
          <Row>
            <div style={{ color: '#121212', textAlign: 'left' }}>
              {t('admin.organization_option.project_name')}
            </div>

            <div style={{ textAlign: 'left' }}>
              <span>
                <input
                  style={{ width: '100%' }}
                  defaultValue={text.text}
                  type="text"
                  className="structure-input"
                  name="nameproject"
                  placeholder={t('admin.organization_option.project_name')}
                  autoComplete="off"
                  required
                />
              </span>
            </div>
          </Row>
          <Row>
            <Col span={12}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                name="country"
                placeholder={t('admin.organization_option.project_country')}
                value={projectPcountry}
                onChange={(e) => setProjectPcountry(e)}>
                {codeCountries.map(({ code, name }, index) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
              {/* {renderCountryValidationError} */}
            </Col>
            <Col span={12}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                name="country"
                placeholder={t('admin.organization_option.project_country')}
                value={projectPcountry}>
                <Option key={'En'} value={projectPcountry}>
                  {projectPcountry}
                </Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ textAlign: 'center' }}>
            <Button
              htmlType="submit"
              className="submit_button"
              loading={isLoading}>
              {t('admin.organization_option.create_organization')}
            </Button>
          </Row>
        </form>
        <ModalCropImage
          imgSrc={imgSrc}
          setImgSrc={setImgSrc}
          Visible={formCropVisible}
          setVisible={setFormCropVisible}
          setCompanyCurrent={setCompanyCurrent}
          CompanyCurrent={CompanyCurrent}
          projectSelectedId={stateProject.projectSelected}
          setStateUpload={setStateUpload}
          setStateNewImage={setStateNewImage}
          t={t}
        />
      </Fragment>
    </div>
  );
}

export default withTranslation()(CompanyCreate);

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ganttAPI } from '../../../../../utils/customGanttPlugin';

import styles from './Progress.module.scss';
import ProgressBar from '../../../../ProgressBar';

import {
  calculateExpected,
  calculateProgress
} from '../../../../../utils/lookahead-common';

import { socket } from '../../../../../services/socket.service';
import { activityService, taskService } from '../../../../../services';
import { historicalActivityProgressService } from '../../../../../services/historicalactivityprogress.service';

import EventEmitter from 'react-native-eventemitter';
import useWeeklyplanPermissions from '../../../../../hooks/useWeeklyplanPermissions';
import useSubtradeUser from '../../../../../hooks/useSubtradeUser';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../../../../analytics/implements/totango/index.js';
import * as Sentry from '@sentry/react';
import { addBreadcrumbUtil } from '../../../../../monitor/monitor.js';

const ProgressColumn = (props) => {
  const {
    activity,
    task,
    parentTask,
    isChangeProgress,
    setIsChangeProgress,
    onlyRead,
    subtradeRole
  } = props;

  const [progressColor, setProgresColor] = useState('#52c41a');

  /** Permissions */
  const weeklyPermisions = useWeeklyplanPermissions();
  const userSubtradeUser = useSubtradeUser();
  const [isOnlyReadElement, setOnlyReadElement] = useState();

  useEffect(() => {
    if (weeklyPermisions.plan == 'ACP') {
      const subtradeCasePermission =
        onlyRead || !(task.subcontractId == userSubtradeUser.subtrade?.id);
      setOnlyReadElement(subtradeCasePermission);
    }
  }, [weeklyPermisions, userSubtradeUser]);

  const disableProgress =
    isOnlyReadElement ||
    window.blockEditionProgress ||
    task.children.length != 0;

  const project = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);

  const { current: progressColorTypes } = useRef({
    complete: '#34AF00',
    advance: '#2C3421',
    ovewrdue: '#E50101'
  });

  useEffect(() => getColorProgress(), []);

  const getColorProgress = (_) => {
    let progressType = 'default';

    task.expected = calculateExpected(task, ganttAPI, activity.calendarId);

    task.progress.toFixed(2) == '100.00' && (progressType = 'complete');
    task.progress > task.expected && (progressType = 'advance');
    task.progress < task.expected && (progressType = 'overdue');

    setProgresColor(
      progressColorTypes[progressType]
        ? progressColorTypes[progressType]
        : '#52c41a'
    );
  };

  const calculateCommit = (task) => {
    if (task.total_quantity) {
      return (
        (100 * task.quantity_parcial) / task.total_quantity + task.progress
      );
    }

    if (parseFloat(task.quantity_parcial_percentaje) > 0) {
      return task.quantity_parcial_percentaje + task.progress;
    }

    return task.progress;
  };

  const updateTask = (task) => {
    let newCommit = calculateCommit(task);

    if (parseFloat(newCommit) > 100) {
      newCommit = 100;
      task.quantity_parcial = task.remaining_quantity;
    }

    task.commitment_percentaje = newCommit;

    const actualQuantity = (task.progress / 100) * task.total_quantity;
    task.actual_quantity = actualQuantity;
  };

  const updateAsyncTask = async (task) => {
    const taskClone = { ...task };
    delete taskClone.activityObject;
    delete taskClone.taskcommitments;
    delete taskClone.activity;

    await taskService.update(taskClone);
  };

  const updateAsyncActivity = async (task) => {
    try {
      activity.tasks.forEach((task) => (task.activity = undefined));
      addBreadcrumbUtil(
        'updateAsyncActivity',
        'Attempting to update activity',
        'info',
        {
          activityId: activity.id
        }
      );
      await activityService.update(activity);
      await activityService.updateTreeProgress(activity.id);

      const newActivityHistorical = {
        activityId: activity.id,
        progress: activity.progress
      };

      historicalActivityProgressService.create(newActivityHistorical);
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTags({
          progressColumn: 'updateAsyncActivity'
        });
        Sentry.captureException(error);
      });
    }
  };

  const updateRender = (_) => {
    setIsChangeProgress(!isChangeProgress);
  };

  const handleProgressTraker = (_) => {
    const loggedUser = JSON.parse(window.localStorage.getItem('user'));
    const currentCompany = stateCompany.currentCompany;
    const currentProject = project.allProjects.find(
      (p) => p.id == project.projectSelected
    );

    totangoSetAccountAttributes(
      loggedUser,
      project.projectSelected,
      currentCompany?.name,
      currentCompany?.id,
      currentProject?.name,
      currentProject?.stage,
      currentProject?.country
    );

    totangoEventTracking(
      `p_${project.projectSelected}`,
      loggedUser,
      'Progress Input',
      'Weekly Plan'
    );
  };
  return (
    <ProgressBar
      disable={disableProgress}
      activity={activity}
      calculateProgress={calculateProgress}
      column={{ name: 'progress' }}
      className={styles.progress}
      fillColor={progressColor}
      progress_traker={handleProgressTraker}
      projectState={project}
      task={task}
      parent={parentTask || activity}
      textClassName={styles.progress_label}
      hideBar
      updateAsyncActivity={updateAsyncActivity}
      updateAsyncTask={updateAsyncTask}
      updateRender={updateRender}
      updateTask={updateTask}
    />
  );
};

export default ProgressColumn;

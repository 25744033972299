import React from 'react';
import cn from 'classnames';

import { ToolbarItem } from './components/ToolbarItem';
import styles from './ToolbarGroup.module.scss';
import { trackingEvent } from '../../analytics';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';

export const ToolbarGroup = React.memo((props) => {
  const {
    items,
    noRequireResources,
    onCommandDispatched,
    className,
    t,
    refreshExcelExport,
    selectedActivities,
    type = null,
    toSelectResponsables,
    toSelectTags,
    subContracts,
    visualizationConfig
  } = props;

  return (
    <ul className={cn(styles['toolbar-group'], className)}>
      {items.map((itemConfig, i) => (
        <li
          className={
            type
              ? styles['toolbar-massive-group__division']
              : styles['toolbar-group__division']
          }
          key={i}>
          {Array.isArray(itemConfig) ? (
            itemConfig.map((subitemConfig, i) => (
              <ToolbarItem
                config={subitemConfig}
                onCommandDispatched={onCommandDispatched}
                t={t}
                selectedActivities={selectedActivities}
                type={type}
                key={i}
                ganttObject={props.ganttObject}
                toSelectResponsables={toSelectResponsables}
                toSelectTags={toSelectTags}
                subContracts={subContracts}
                visualizationConfig={visualizationConfig}
              />
            ))
          ) : (
            <ToolbarItem
              config={itemConfig}
              noRequireResources={noRequireResources}
              onCommandDispatched={onCommandDispatched}
              t={t}
              selectedActivities={selectedActivities}
              type={type}
              ganttObject={props.ganttObject}
              toSelectResponsables={toSelectResponsables}
              toSelectTags={toSelectTags}
              subContracts={subContracts}
              visualizationConfig={visualizationConfig}
            />
          )}
        </li>
      ))}
    </ul>
  );
});

import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { activityService } from '../../../services/activity.service';
import { calendarService } from '../../../services/calendar.service';
import { projectService } from '../../../services/project.service';
import { subContractService } from '../../../services/subcontract.service';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Page,
  Text,
  View,
  Document,
  Image,
  Font,
  PDFViewer
} from '@react-pdf/renderer';
import constraintIcon from '../../../assets/img/newlookahead/name/constraint.png';
import { ganttAPI } from '../../../utils/customGanttPlugin';
import { withTranslation } from 'react-i18next';
import { styles } from './style.js';
import { companyService, userService } from '../../../services';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import outbuild_logo from '../../../assets/img/outbuild_logo.png';
import { viewService } from '../../../services/views.service';
import { findDeepGetActivityOfTask } from '../../../views/lookahead/planification/index.helper';
import cloneDeep from 'lodash/cloneDeep';
import { calculateExpected } from '../../../utils/lookahead-common';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { leanStatusArray } from '../../../constants/leanStatusArray.js';
import {
  COMPANY_IDS_WITH_ROUTENAME_LIMITED,
  MAX_LENGTH_ACTIVITY_ROUTE,
  colorByStatus,
  lookaheadExportablePDFColumns
} from './constants.js';
import { arrowParentTask, formatText } from './utils.js';
import DownloadReportContainer from '../DownloadReportContainer/DownloadReportContainer.js';
import { getSessionTokenData } from '../../../utils/userUtils.js';
import { getCompanyTextColor } from '../../../utils';

export const NUMWEEKSPERPAGE = 4;
const DAYSPERWEEK = 7;

function LookAHeadReport(props) {
  const { t } = props;

  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  const week_state = props.week;
  const numWeeks = props.numWeeks;
  const timelineNumberOfDays = NUMWEEKSPERPAGE * DAYSPERWEEK;
  const numWeeksLastPage = numWeeks % NUMWEEKSPERPAGE;
  const numberHorizontalPages = Math.ceil(numWeeks / NUMWEEKSPERPAGE);
  const idsvalidate = props.idsvalidate || [];
  const projectState = useSelector((state) => state.projectState);
  const lookaheadState = useSelector(
    (state) => state.lookaheadState.configViews
  );
  const [activities, setActivities] = useState([]);
  const [pages, setPages] = useState([]);
  const [pagesButtom, setPagesButtom] = useState([]);
  const [resource, setResource] = useState([]);
  const [project, setProject] = useState(null);
  const [company, setCompany] = useState(null);
  const [sector, setSector] = useState(null);
  const [subContract, setSubContract] = useState([]);
  const [_, setCommitmentstate] = useState('Se Hará');
  const init_date = moment(week_state).lang('es');
  const end_date = init_date.clone().add(numWeeks * DAYSPERWEEK - 1, 'day');
  const [loading, setLoading] = useState(false);
  const [loadpdf, setLoadpdf] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const userLang = navigator.language || navigator.userLanguage;
  const defaultView = useRef({});
  const listView = useRef([]);
  const { current: dataToFilter } = useRef({ value: [] });
  const mapElements = {};
  let lenguaje = 'es';

  if (userLang.indexOf('es') !== -1) {
    lenguaje = 'es';
  } else {
    lenguaje = 'en';
  }

  const splitActivityRoute = (activityRoute) => {
    const maxLength = MAX_LENGTH_ACTIVITY_ROUTE;
    const pieces = activityRoute.split(' > ').reverse();

    if (shouldLimitRouteName()) {
      return pieces.length > 3
        ? `${pieces[0]} > ${pieces[1]} > ${pieces[2]}`
        : formatText(pieces.join(' > '), maxLength);
    }

    return formatText(pieces.join(' > '), maxLength);
  };

  const shouldLimitRouteName = () => {
    const project = projectState?.allProjects?.[0];
    return Boolean(
      project && COMPANY_IDS_WITH_ROUTENAME_LIMITED.includes(project.companyId)
    );
  };

  const loadCalendars = (calendars) => {
    /** This method load calendars to Gantt API and also creates a custom version to use custom calculate duration */
    ganttAPI.loadCalendars(calendars);
    setTimeout(() => {
      setLoading(true);
    }, 3000);
  };

  useEffect(() => {
    if (calendars.length) {
      loadCalendars(calendars);
    }
  }, [calendars]);

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf'
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf'
  });

  useEffect(() => {
    setTimeout(() => {
      buildViews();
    }, 1000);
  }, []);

  const buildViews = async () => {
    const views = await viewService.showByProyect(projectState.sectorSelected);

    if (!views.length) {
      history.push('/login');
      return;
    }
    let dateInit = new Date();
    let dateEnd = new Date();
    dateEnd.setDate(dateInit.getDate() + 30);
    dateInit = dateInit.toISOString().split('T')[0].split('-').join('/');
    dateEnd = dateEnd.toISOString().split('T')[0].split('-').join('/');

    const localUser = JSON.parse(localStorage.getItem('user'));
    const currentSector = projectState.sectorSelected;
    const updatedUser = await userService.show(localUser.id);

    const allUserDefaultViews = updatedUser?.user?.defaultLookaheadView;
    const userSectorDefaultView =
      allUserDefaultViews && allUserDefaultViews[currentSector];

    findDefaulViewSetDefaultView(views, userSectorDefaultView);
  };

  const newFindDefaultView =
    (userViewId) =>
    ({ view }) => {
      if (view.id == userViewId) return true;
    };

  const findDefaulViewSetDefaultView = (
    views,
    userSectorDefaultView = null
  ) => {
    const fechedViews = JSON.stringify(views);
    const statedViews = JSON.stringify(lookaheadState.configViews);

    if (fechedViews !== statedViews) {
      views.sort((a, b) => a.order - b.order);
      // dispatch(lookaheadActions.setLookaheadViewsConfig(views))
    }
    if (!views.find(newFindDefaultView(userSectorDefaultView))) {
      const defaultViewList = setTimelineAsDefault(views);
      if (!defaultViewList) views[0].view.default = true;
      else views = defaultViewList;
    }

    listView.current = views;
    defaultView.current = views.find(
      newFindDefaultView(userSectorDefaultView)
    ).view;
    getData();
  };

  const setTimelineAsDefault = (views, idx = 0) => {
    if (!views.length) return false;

    if (idx >= views.length) {
      return false;
    }

    if (views[idx].view.name == 'gantt') {
      views[idx].view.default = true;
      return views;
    }

    return setTimelineAsDefault(views, idx + 1);
  };

  const recursiveOrder = (query, array) => {
    // eslint-disable-next-line no-eval
    array.sort(eval(query));
    array.map((el) => {
      if (el.children) {
        recursiveOrder(query, el.children);
      }
    });
  };

  const calculateStatusAndSpected = (task) => {
    const findAct = findDeepGetActivityOfTask(activities[0], 'id', task.id);
    let activityReference;
    if (findAct) activityReference = findAct;

    const itemCopy = cloneDeep(task);
    task.expected = calculateExpected(
      itemCopy,
      ganttAPI,
      activityReference.calendarId
    );

    if (task.progress > 99.99) {
      task.status = 'Done';
    } else if (task.expected > task.progress) {
      task.status = 'Overdue';
    } else if (task.progress > task.expected) {
      task.status = 'Advancement';
    } else if (task.progress == 0 && task.expected == 0) {
      task.status = 'Waiting';
    } else if (task.progress > 0 && task.progress < task.expected) {
      task.status = 'Doing';
    } else {
      task.status = 'Waiting';
    }
  };

  const orderLookaheadData = (showedFilters, avoid_update_render = false) => {
    if (showedFilters?.value.length != 0) {
      let query = '';
      showedFilters.value.map((o, index) => {
        let fieldName = `'${o.name}'`;
        const orderBy = o.order_by;

        if (o.data_type.includes('array/')) {
          if (
            o.data_type.includes('/string') ||
            o.data_type.includes('/icon')
          ) {
            const optionsMap = {};
            o.from_values.map((option) => {
              optionsMap[option.value] = option.weigth;
            });
            mapElements[o.name] = optionsMap;
            fieldName = `
                        (v1, v2) => {
                            let weigth_one = mapElements.${o.name}[v1.${o.name}]
                            let weigth_two = mapElements.${o.name}[v2.${o.name}]
                            return (weigth_one || 0) - (weigth_two || 0)
                        }
                        `;
          }
        }

        if (o.name == 'user') {
          fieldName = '(v1, v2) => v1.user.id - v2.user.id';
        }

        if (index == 0) {
          query =
            query +
            `firstBy(${fieldName}, { direction: '${orderBy}', ignoreCase: true})`;
        } else {
          query =
            query +
            `.thenBy(${fieldName}, { direction: '${orderBy}', ignoreCase: true})`;
        }
      });

      dataToFilter.value.map((el) => {
        recursiveOrder(query, el.tasks);
      });
    } else {
      const query = 'firstBy("id", { direction: "asc", ignoreCase: true})';
      dataToFilter.value.map((el) => {
        recursiveOrder(query, el.tasks);
      });
    }

    if (!avoid_update_render) {
      // updateRender(dataToFilter.value)
      return dataToFilter.value;
    }
    return null;
  };

  const validateRangeWeekIncludeTasks = (init_date_v, task) => {
    const startDateMinusOneDay = moment(task.start_date).subtract(1, 'd');
    const endDatePlusOneDay = moment(task.end_date).add(1, 'd');

    for (
      let dayCounter = 0;
      dayCounter < numWeeks * DAYSPERWEEK;
      dayCounter++
    ) {
      const currentDate = init_date_v.clone().add('days', dayCounter);
      if (
        currentDate.isBetween(
          startDateMinusOneDay,
          endDatePlusOneDay,
          'days',
          false
        )
      ) {
        return true;
      }
    }
    return false;
  };

  async function getData() {
    const config = {
      end: end_date.format('YYYY/MM/DD'),
      sector_id: projectState.sectorSelected,
      start: init_date.format('YYYY/MM/DD')
    };
    const data_temp = await activityService.getLookaheadWeeklyPlan(config);

    dataToFilter.value = data_temp.activities;
    let data = [];
    if (defaultView.current?.order_query.value?.length > 0) {
      data =
        orderLookaheadData(defaultView.current.order_query) ||
        data_temp.activities;
    } else {
      data = data_temp.activities;
    }
    const calendars = await calendarService.showBySector(
      projectState.sectorSelected
    );
    const project = await projectService.show(projectState.projectSelected);
    const sectorFind = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    );
    const subcontractsGet = await subContractService.getByProject(
      projectState.projectSelected
    );

    setSubContract(subcontractsGet.subcontracts);
    setCalendars(calendars.calendar);
    setProject(project.project);
    setCompany(await getCompany());
    setSector(sectorFind);

    const activitys_array = [];
    const activitys = [];
    let task_total = 0;

    data &&
      data
        .sort((a, b) => parseInt(a.correlative_id) - parseInt(b.correlative_id))
        .map((atis) => {
          let validate_push = false;
          const temp_actis = { ...atis };
          // let activity = atis;
          const tasks = [];
          // let newArray = atis.tasks.filter(a => a.lean_status == "Will" || a.lean_status == "Committed");
          const calendar = ganttAPI.getTaskCalendar(atis.calendarId);
          atis.tasks.map((task) => {
            // inicio >= init day

            // if (task.lean_status == 'Will' || task.lean_status == 'Committed') {
            task_total++;
            // init_date = moment().lang("en").year("2020").week(week_state).startOf('isoweek').add('days', (planificationDay));
            // end_date =  init_date.clone().add(6, 'day')
            const init_date_v = moment(week_state).lang('es'); // .startOf('isoweek');//.add('days', (planificationDay));
            // let end_date_v =  init_date_v.clone().add(6, 'day')
            const start_validate = init_date.isBetween(
              moment(task.start_date),
              moment(task.end_date),
              'days',
              false
            );
            const end_validate = end_date.isBetween(
              moment(task.start_date),
              moment(task.end_date),
              'days',
              false
            );
            const validate_week_range = validateRangeWeekIncludeTasks(
              init_date_v,
              task
            );

            let validate_week_calendar_count = 1;
            let validate_week_calendar = false;
            while (validate_week_calendar_count < 8) {
              const copyOfDate = new Date(
                init_date
                  .clone()
                  .add('days', validate_week_calendar_count)
                  .format('YYYY/MM/DD H:mm')
              );

              // Funcion mal utilizada deberia ser {date, unit: 'day'}
              if (calendar?.isWorkTime({ date: copyOfDate, unit: 'day' })) {
                validate_week_calendar = true;
              }
              validate_week_calendar_count++;
            }

            validate_week_calendar = true;

            let validate_filter = false;
            if (idsvalidate.length) {
              if (
                idsvalidate.filter((e) => e.toString() === task.id.toString())
                  .length > 0
              ) {
                /* vendors contains the element we're looking for */
                validate_filter = true;
              }
            }
            if (
              validate_week_range &&
              validate_week_calendar &&
              validate_filter
            ) {
              validate_push = true;
              tasks.push(task);
            }
            // }
            if (task.lean_status == 'Committed') {
              setCommitmentstate('Comprometida');
            }
          });
          temp_actis.tasks = tasks;
          if (validate_push) {
            activitys.push(temp_actis);
          }
        });

    const array_paginator = [];
    const array_pageButtom = [];
    let paginator = 0;
    let count_activities = 0;
    let count_tasks = 0;
    let total = 0;
    let page_paginator = [];
    let activitiesAndTasksHeight = 0;
    let diff_buttom = 0;
    const NUM_ROWS = 37;
    const ROW_HEIGHT = 22;
    const EXPORTABLE_HEIGHT = NUM_ROWS * ROW_HEIGHT;

    function lastTaskAllActivities(a, b) {
      if (a.length != b.length) {
        return false;
      }
      let result = false;

      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false;
        }
        result = true;
      }
      return result;
    }

    function taskEvaluation(task, atis, i, j) {
      const nActivities = activitys.length - 1;
      const nTasks = atis.tasks.length - 1;
      if (task.children.length > 0) {
        paginator += task.children.length;
        const nChildrenTask = task.children.length - 1;
        task.children.map((subtask, k) => {
          count_tasks += 1;
          subtask.level_subtask = 1;
          subtask.activityParent = atis;
          page_paginator.push(subtask);
          activitiesAndTasksHeight =
            (count_tasks + count_activities) * ROW_HEIGHT;
          if (activitiesAndTasksHeight > EXPORTABLE_HEIGHT) {
            paginator = 0;
            activitiesAndTasksHeight = 0;
            count_activities = 0;
            count_tasks = 0;
            diff_buttom = activitiesAndTasksHeight - EXPORTABLE_HEIGHT;
            array_paginator.push(page_paginator);
            array_pageButtom.push(diff_buttom);
            page_paginator = [];
          }
          if (subtask.children.length > 0) {
            paginator += task.children.length;
            const nChildrenSubtask = subtask.children.length - 1;
            subtask.children.map((subtask, l) => {
              count_tasks += 1;
              subtask.level_subtask = 2;
              subtask.activityParent = atis;
              page_paginator.push(subtask);
              activitiesAndTasksHeight =
                (count_tasks + count_activities) * ROW_HEIGHT;
              if (activitiesAndTasksHeight > EXPORTABLE_HEIGHT) {
                paginator = 0;
                activitiesAndTasksHeight = 0;
                count_activities = 0;
                count_tasks = 0;
                diff_buttom = activitiesAndTasksHeight - EXPORTABLE_HEIGHT;
                array_paginator.push(page_paginator);
                array_pageButtom.push(diff_buttom);
                page_paginator = [];
              }
              if (subtask.children.length > 0) {
                paginator += task.children.length;
                const nChildrenSubtask2 = subtask.children.length - 1;
                subtask.children.map((subtask, m) => {
                  count_tasks += 1;
                  subtask.level_subtask = 3;
                  subtask.activityParent = atis;
                  page_paginator.push(subtask);
                  activitiesAndTasksHeight =
                    (count_tasks + count_activities) * ROW_HEIGHT;
                  if (activitiesAndTasksHeight > EXPORTABLE_HEIGHT) {
                    paginator = 0;
                    activitiesAndTasksHeight = 0;
                    count_activities = 0;
                    count_tasks = 0;
                    diff_buttom = activitiesAndTasksHeight - EXPORTABLE_HEIGHT;
                    array_paginator.push(page_paginator);
                    array_pageButtom.push(diff_buttom);
                    page_paginator = [];
                  }
                  if (subtask.children.length > 0) {
                    paginator += task.children.length;
                    const nChildrenSubtask3 = subtask.children.length - 1;
                    subtask.children.map((subtask, n) => {
                      count_tasks += 1;
                      subtask.level_subtask = 4;
                      subtask.activityParent = atis;
                      page_paginator.push(subtask);
                      activitiesAndTasksHeight =
                        (count_tasks + count_activities) * ROW_HEIGHT;
                      if (activitiesAndTasksHeight > EXPORTABLE_HEIGHT) {
                        paginator = 0;
                        activitiesAndTasksHeight = 0;
                        count_activities = 0;
                        count_tasks = 0;
                        diff_buttom =
                          activitiesAndTasksHeight - EXPORTABLE_HEIGHT;
                        array_paginator.push(page_paginator);
                        array_pageButtom.push(diff_buttom);
                        page_paginator = [];
                      }
                      if (subtask.children.length > 0) {
                        paginator += task.children.length;
                        const nChildrenSubtask4 = subtask.children.length - 1;
                        subtask.children.map((subtask, o) => {
                          count_tasks += 1;
                          subtask.level_subtask = 5;
                          subtask.activityParent = atis;
                          page_paginator.push(subtask);
                          activitiesAndTasksHeight =
                            (count_tasks + count_activities) * ROW_HEIGHT;
                          if (activitiesAndTasksHeight > EXPORTABLE_HEIGHT) {
                            paginator = 0;
                            activitiesAndTasksHeight = 0;
                            count_activities = 0;
                            count_tasks = 0;
                            diff_buttom =
                              activitiesAndTasksHeight - EXPORTABLE_HEIGHT;
                            array_paginator.push(page_paginator);
                            array_pageButtom.push(diff_buttom);
                            page_paginator = [];
                          } else if (
                            lastTaskAllActivities(
                              [i, j, k, l, m, n, o],
                              [
                                nActivities,
                                nTasks,
                                nChildrenTask,
                                nChildrenSubtask,
                                nChildrenSubtask2,
                                nChildrenSubtask3,
                                nChildrenSubtask4
                              ]
                            )
                          ) {
                            paginator = 0;
                            array_paginator.push(page_paginator);
                            page_paginator = [];
                          }
                        });
                      } else if (
                        lastTaskAllActivities(
                          [i, j, k, l, m, n],
                          [
                            nActivities,
                            nTasks,
                            nChildrenTask,
                            nChildrenSubtask,
                            nChildrenSubtask2,
                            nChildrenSubtask3
                          ]
                        )
                      ) {
                        paginator = 0;
                        array_paginator.push(page_paginator);
                        page_paginator = [];
                      }
                    });
                  } else if (
                    lastTaskAllActivities(
                      [i, j, k, l, m],
                      [
                        nActivities,
                        nTasks,
                        nChildrenTask,
                        nChildrenSubtask,
                        nChildrenSubtask2
                      ]
                    )
                  ) {
                    paginator = 0;
                    array_paginator.push(page_paginator);
                    page_paginator = [];
                  }
                });
              } else if (
                lastTaskAllActivities(
                  [i, j, k, l],
                  [nActivities, nTasks, nChildrenTask, nChildrenSubtask]
                )
              ) {
                paginator = 0;
                array_paginator.push(page_paginator);
                page_paginator = [];
              }
            });
          } else if (
            lastTaskAllActivities(
              [i, j, k],
              [nActivities, nTasks, nChildrenTask]
            )
          ) {
            paginator = 0;
            array_paginator.push(page_paginator);
            page_paginator = [];
          }
        });
      } else {
        if (activitiesAndTasksHeight > EXPORTABLE_HEIGHT) {
          diff_buttom = activitiesAndTasksHeight - EXPORTABLE_HEIGHT;
          paginator = 0;
          activitiesAndTasksHeight = 0;
          count_activities = 0;
          count_tasks = 0;
          array_paginator.push(page_paginator);
          array_pageButtom.push(diff_buttom);
          page_paginator = [];
        } else {
          if (lastTaskAllActivities([i, j], [nActivities, nTasks])) {
            paginator = 0;
            if (page_paginator.length > 0) {
              array_paginator.push(page_paginator);
            }
            page_paginator = [];
          }
        }
      }
    }

    activitys.map((atis, i) => {
      paginator += 1;
      count_activities += 1;
      const nActivities = activitys.length - 1;
      const nTasks = atis.tasks.length - 1;
      page_paginator.push(atis);
      activitiesAndTasksHeight = (count_tasks + count_activities) * ROW_HEIGHT;
      if (activitiesAndTasksHeight > EXPORTABLE_HEIGHT) {
        paginator = 0;
        activitiesAndTasksHeight = 0;
        count_activities = 0;
        count_tasks = 0;
        diff_buttom = activitiesAndTasksHeight - EXPORTABLE_HEIGHT;
        array_paginator.push(page_paginator);
        array_pageButtom.push(diff_buttom);
        page_paginator = [];
        atis.tasks.map((task, j) => {
          count_tasks += 1;
          paginator += 1;
          task.activityParent = atis;
          page_paginator.push(task);
          activitiesAndTasksHeight =
            (count_tasks + count_activities) * ROW_HEIGHT;
          if (activitiesAndTasksHeight > EXPORTABLE_HEIGHT) {
            paginator = 0;
            activitiesAndTasksHeight = 0;
            count_activities = 0;
            count_tasks = 0;
            diff_buttom = activitiesAndTasksHeight - EXPORTABLE_HEIGHT;
            array_paginator.push(page_paginator);
            array_pageButtom.push(diff_buttom);
            page_paginator = [];
          } else {
            if (lastTaskAllActivities([i, j], [nActivities, nTasks])) {
              paginator = 0;
              array_paginator.push(page_paginator);
              page_paginator = [];
            }
          }
          taskEvaluation(task, atis, i, j);
        });
        total += 1;
      } else {
        atis.tasks.map((task, j) => {
          count_tasks += 1;
          task.activityParent = atis;
          page_paginator.push(task);
          activitiesAndTasksHeight =
            (count_tasks + count_activities) * ROW_HEIGHT;
          taskEvaluation(task, atis, i, j);
        });
        total += 1;
      }
    });

    setPages(array_paginator);
    if (activitiesAndTasksHeight > EXPORTABLE_HEIGHT) {
      array_pageButtom.push(activitiesAndTasksHeight);
    }
    setPagesButtom(array_pageButtom);
    activitys_array.push(activitys);
    setActivities(activitys_array);
    setResource(data_temp.resource);
    setLoadpdf(true);
  }
  async function getCompany() {
    const sessionTokenData = getSessionTokenData();
    const resp = await companyService.show(sessionTokenData.companyId);
    return resp ? resp.company : false;
  }
  if (!loading) {
    return (
      <Spin className="loader-layout-style" indicator={<LoadingOutlined />} />
    );
  }

  function recursiveChild(
    task,
    itemsPerPageIndex,
    activity,
    padding,
    horPageIndex
  ) {
    if (task.children.length == 0) {
      return getTaskTable(
        task,
        itemsPerPageIndex,
        activity,
        padding,
        horPageIndex
      );
    }

    return getTaskTable(
      task,
      itemsPerPageIndex,
      activity,
      padding,
      horPageIndex,
      true
    );
  }

  function getHeaderTablePdf(horPageIndex) {
    const sectionHeaderDays = generateElementsCommon(
      horPageIndex,
      dayOfHeaderTable
    );

    return (
      <View style={{ ...styles.section_container_header }}>
        <View style={{ ...styles.section_header_table }}>
          <View style={{ ...styles.sub_section_columns_header_table }}>
            {Object.values(lookaheadExportablePDFColumns).map(
              (column, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.table_header,
                    flex: column.flexHeaderColumnValue
                  }}>
                  {column.headerColumnTexts ? (
                    <Text style={{ ...styles.table_heade_title_name }}>
                      {t(column.headerColumnTexts)}
                    </Text>
                  ) : (
                    <Image
                      style={{ ...styles.image_icon_roadblocks }}
                      src={constraintIcon}
                    />
                  )}
                </View>
              )
            )}
          </View>
          <View style={{ ...styles.sub_section_days_header_table }}>
            {sectionHeaderDays}
          </View>
        </View>
      </View>
    );
  }

  function getBackgroundColor(
    horPageIndex,
    task,
    { dayOf = false, activity = null, day = null } = {}
  ) {
    if (
      task &&
      activity &&
      day >= 0 &&
      !validateDay(task, activity, day, horPageIndex)
    ) {
      return '#FFFFFF';
    }
    let color = location.pathname.includes('reportlookahead')
      ? buildColorTypes(task)
      : defineColorByLeanStatus(task);
    dayOf &&
      params.colorSchema != 'tags' &&
      params.colorSchema != 'subcontract' &&
      (color = '#ffffff');
    if (color == '#C8C6C6') return color;
    return color;
  }

  function defineColorByStatus(task) {
    calculateStatusAndSpected(task);

    return colorByStatus[task.status] || '#C8C6C6';
  }

  function defineColorBySubcontract(task) {
    if (task.type == 'milestone') return;

    const subcontract = subContract.find((e) => e.id == task.subcontractId);

    return subcontract?.color || '#C8C6C6';
  }

  function defineColorByTags(task) {
    if (task.type == 'milestone') return;
    let color;

    const tags = task.tags;
    if (tags && tags.length) {
      const firstTag = tags[0];
      if (firstTag) {
        color = firstTag.description;
      }
    }

    !color && (color = '#C8C6C6');

    return color;
  }

  function defineColorByLeanStatus(task) {
    return leanStatusArray.find((lean) => lean.value == task.lean_status)
      ?.color;
  }

  function buildColorTypes(task) {
    const getColorSetter = (colorSchemeType) =>
      ({
        status: defineColorByStatus(task),
        subcontract: defineColorBySubcontract(task),
        tags: defineColorByTags(task),
        leanstatus: defineColorByLeanStatus(task)
      })[colorSchemeType] || defineColorByLeanStatus(task);

    return getColorSetter(params.colorSchema || 'subcontract');
  }

  function dayOf(activity, day) {
    const calendar = ganttAPI.getTaskCalendar(activity.calendarId);
    const validate_date = moment(week_state).lang('es').set('hour', '9');
    const copyOfDate = new Date(
      validate_date.add('days', day).format('YYYY/MM/DD H:mm')
    );
    // Funcion mal utilizada deberia ser {date, unit: 'day'}
    return !calendar.isWorkTime({ date: copyOfDate, unit: 'day' });
  }

  function dayOfHeaderTable(index, i) {
    return (
      <View key={i} style={{ ...styles.table_header_days }}>
        <Text style={styles.table_heade_title_name_date}>
          {init_date
            .clone()
            .lang(lenguaje)
            .add(i + index * DAYSPERWEEK * NUMWEEKSPERPAGE, 'days')
            .format('dd')
            .slice(0, -1) +
            init_date
              .clone()
              .add(i + index * DAYSPERWEEK * NUMWEEKSPERPAGE, 'days')
              .format('DD')}
        </Text>
      </View>
    );
  }

  function dayOfTaskTable(
    index,
    i,
    isParent,
    task,
    activity,
    itemsPerPageIndex
  ) {
    const firstTaskOfPage = itemsPerPageIndex === 0;
    const styleOfFirstTaskOfPage = firstTaskOfPage
      ? { borderTop: '1pt solid #EBEBEB' }
      : '';
    const isParentTask = (isParent) => ({
      backgroundColor: isParent ? '#F5F5F5' : ''
    });

    const getBackgroundColorView = ({ task, activity, i, index, isParent }) => {
      const isValidateDay = validateDay(task, activity, i, index);

      if (isParent) {
        return isParentTask(isParent).backgroundColor;
      }

      if (isValidateDay) {
        return getBackgroundColor(index, task);
      }

      const isDayOf = dayOf(activity, i);

      if (isDayOf) {
        return '#EFEFEF';
      }

      return getBackgroundColor(index, task, {
        dayOf: true,
        activity,
        day: i
      });
    };

    const backgroundColor = getBackgroundColorView({
      task,
      activity,
      i,
      index,
      isParent
    });
    return (
      <View
        key={i}
        style={[
          styles.table_task_days,
          styleOfFirstTaskOfPage,
          {
            backgroundColor: backgroundColor
          }
        ]}>
        <Text
          style={[
            styles.table_task_title_day,
            { color: getCompanyTextColor(backgroundColor) }
          ]}>
          {validateDay(task, activity, i, index) && !isParent
            ? task.plan_endowment === 0
              ? ''
              : task.plan_endowment
            : ''}
        </Text>
      </View>
    );
  }

  function dayOfActivityRow(index, i, itemsPerPageIndex) {
    const lastDayOfFourWeekPage = (i + 1) % timelineNumberOfDays === 0;
    const lastDayOfWeekPage =
      index + 1 === numberHorizontalPages &&
      (i + 1) % (numWeeksLastPage * DAYSPERWEEK) === 0;
    const styleLastDay =
      lastDayOfFourWeekPage || lastDayOfWeekPage
        ? { borderRight: '1pt solid #EBEBEB' }
        : '';
    const firstActivityOfPage = itemsPerPageIndex === 0;
    const styleOfFirstActivityOfPage = firstActivityOfPage
      ? { borderTop: '1pt solid #EBEBEB' }
      : '';
    return (
      <View
        key={i}
        style={[
          styles.section_timeline_activity_per_day,
          styleLastDay,
          styleOfFirstActivityOfPage
        ]}></View>
    );
  }

  function generateElementsCommon(horPageIndex, generateElement) {
    const elements = [];

    for (let i = 0; i < timelineNumberOfDays; i++) {
      if (
        horPageIndex + 1 === numberHorizontalPages &&
        numWeeksLastPage !== 0 &&
        numWeeksLastPage * DAYSPERWEEK - 1 < i
      ) {
        break;
      }
      const element = generateElement(horPageIndex, i);
      elements.push(element);
    }

    return elements;
  }

  function getActivityRow(page_content, index, itemsPerPageIndex) {
    const firstActivityOfPage = itemsPerPageIndex === 0;
    const styleOfFirstActivityOfPage = firstActivityOfPage
      ? { borderTop: '1pt solid #EBEBEB' }
      : '';

    const generatedElements3 = generateElementsCommon(index, (index, i) =>
      dayOfActivityRow(index, i, itemsPerPageIndex)
    );

    return (
      <View style={{ ...styles.section_row_activity }}>
        <View
          style={{
            ...styles.section_columns_activity,
            flex: 1.15,
            ...styleOfFirstActivityOfPage
          }}>
          <Text style={{ ...styles.section_activity_title_name }}>
            <Text style={{ fontWeight: 'bold' }}>
              {page_content.correlative_id}{' '}
            </Text>
            {splitActivityRoute(
              `${page_content.activityRoute + ' > ' + page_content.name}`
            )}
          </Text>
        </View>
        <View style={{ ...styles.section_timeline_activity, flex: 1 }}>
          {generatedElements3}
        </View>
      </View>
    );
  }

  function getTaskTable(
    task,
    itemsPerPageIndex,
    activity,
    parent,
    index,
    isParent
  ) {
    const subcontract = subContract.find((e) => e.id == task.subcontractId);
    let newArray = [];
    const vaslidate_constrints = task.constraints.filter(
      (c) => !c?.release_date
    );
    task.materialId &&
      (newArray = resource.filter((r) => r.id == task.materialId));

    const firstTaskOfPage = itemsPerPageIndex === 0;
    const styleOfFirstTaskOfPage = firstTaskOfPage
      ? { borderTop: '1pt solid #EBEBEB' }
      : '';
    const isParentTask = (isParent) => ({
      backgroundColor: isParent ? '#F5F5F5' : ''
    });
    const responsables = task.responsables.reduce(
      (responsable, nextResponsable, idx, array) => {
        responsable = `${responsable} ${nextResponsable?.name?.split(' ')[0][0]?.toUpperCase()}. ${nextResponsable?.lastname?.split(' ')[0]} ${idx < array.length - 1 ? ', ' : ' '}`;
        return responsable;
      },
      ''
    );

    const subcontractName = subcontract?.name || ' ';

    const sectionDayOfTaskTable = generateElementsCommon(index, (index, i) =>
      dayOfTaskTable(index, i, isParent, task, activity, itemsPerPageIndex)
    );

    const getTableColumnStyles = (index) => {
      if ([0, 1].includes(index))
        return { textAlign: 'left', marginLeft: '5px' };
      if (index === 2)
        return {
          textAlign: 'left',
          marginLeft: subcontract?.color ? '20px' : '5px'
        };
      if (index === 7) {
        if (!vaslidate_constrints?.length) {
          return { ...styles.removed_roadblocks };
        }
        return { ...styles.open_roadblocks };
      }
    };

    const getColumnText = (task, index) => {
      const taskProps = {
        task,
        responsables,
        subcontractName,
        sector,
        vaslidate_constrints
      };

      const columnConfig = lookaheadExportablePDFColumns[index];
      const text = columnConfig.getText(taskProps);

      return text || '';
    };

    const sectionColumnsOfTaskTable = (flex, index) => (
      <View
        key={index}
        style={{
          ...styles.table_task_column,
          flexDirection: 'row',
          flex: flex
        }}>
        {index === 0 && arrowParentTask(parent)}
        {index === 2 ? (
          <View style={{ flexDirection: 'row', flex: 1 }}>
            {subcontract?.color && (
              <View
                style={{
                  ...styles.table_task__subcontract_box,
                  backgroundColor: subcontract?.color
                }}></View>
            )}
            <Text
              style={{
                ...styles.table_task_title_name,
                ...getTableColumnStyles(index)
              }}>
              {getColumnText(task, index)}
            </Text>
          </View>
        ) : (
          <Text
            style={{
              ...styles.table_task_title_name,
              ...getTableColumnStyles(index)
            }}>
            {getColumnText(task, index)}
          </Text>
        )}
      </View>
    );

    return (
      <View style={{ ...styles.section_task_table }}>
        <View
          style={{
            ...styles.sub_section_columns_task_table,
            ...isParentTask(isParent),
            ...styleOfFirstTaskOfPage
          }}>
          {Object.values(lookaheadExportablePDFColumns).map((column, index) =>
            sectionColumnsOfTaskTable(column.flexHeaderColumnValue, index)
          )}
        </View>
        <View style={{ ...styles.sub_section_days_task_table }}>
          {sectionDayOfTaskTable}
        </View>
      </View>
    );
  }

  function validateDay(task, activity, day, index) {
    const calendar = ganttAPI.getTaskCalendar(activity.calendarId);
    const validate_date = moment(week_state)
      .lang('es')
      .add(index * DAYSPERWEEK * NUMWEEKSPERPAGE, 'day')
      .set('hour', '9');
    const copyOfDate = new Date(
      validate_date.add('days', day).format('YYYY/MM/DD H:mm')
    );
    const validate_day = validate_date.isBetween(
      moment(task.start_date).subtract(1, 'd'),
      moment(task.end_date).add(1, 'd'),
      'days',
      false
    );
    if (validate_day) {
      return calendar.isWorkTime({ date: copyOfDate, unit: 'day' });
    }
    return false;
  }

  const trackProperties = {
    start_date_selected: init_date,
    weeks_range_selected: numWeeks
  };

  if (loadpdf) {
    const numPages = pages.length * numberHorizontalPages;
    return (
      <DownloadReportContainer
        t={t}
        pdfDocument={
          <Document>
            {pages.flatMap((page, i) =>
              Array.from(
                { length: numberHorizontalPages },
                (_, horPageIndex) => (
                  <Page
                    size="A3"
                    style={styles.page}
                    orientation="landscape"
                    key={`${i}-${horPageIndex}`}>
                    <View style={{ ...styles.section_header }}>
                      <View style={{ flexDirection: 'row' }}>
                        {company?.image && (
                          <Image
                            style={{ ...styles.image_logo }}
                            src={{
                              uri: company.image,
                              method: 'GET',
                              headers: { 'Cache-Control': 'no-cache' },
                              body: ''
                            }}
                            allowDangerousPaths
                          />
                        )}
                        <View style={{ flexDirection: 'row' }}>
                          <Text
                            style={{
                              ...styles.title_name,
                              paddingLeft: '68px'
                            }}>
                            {t('lookahead_report.project') + ': '}
                            <Text style={{ ...styles.answer_name }}>
                              {' '}
                              {project?.name || t('week_interval_modal_title')}
                            </Text>
                          </Text>
                          <Text
                            style={{
                              ...styles.title_name,
                              paddingLeft: '20px'
                            }}>
                            {t('lookahead_report.stage') + ': '}
                            <Text style={{ ...styles.answer_name }}>
                              {sector?.name || ''}
                            </Text>
                          </Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={{ ...styles.title_name }}>
                          {t('lookahead_report.week_interval_report') + ': '}
                          <Text style={styles.answer_name}>
                            {init_date
                              .clone()
                              .add(horPageIndex * timelineNumberOfDays, 'day')
                              .lang(lenguaje)
                              .format('MMM DD') +
                              ' - ' +
                              init_date
                                .clone()
                                .add(
                                  numberHorizontalPages === horPageIndex + 1 &&
                                    numWeeksLastPage !== 0
                                    ? horPageIndex * timelineNumberOfDays +
                                        numWeeksLastPage * DAYSPERWEEK -
                                        1
                                    : (horPageIndex + 1) *
                                        timelineNumberOfDays -
                                        1,
                                  'day'
                                )
                                .lang(lenguaje)
                                .format('MMM DD')}
                          </Text>
                        </Text>
                        <Text
                          style={{
                            ...styles.title_name,
                            paddingLeft: '30px'
                          }}>
                          {t('lookahead_report.printed_date') + ': '}
                          <Text style={{ ...styles.answer_name }}>
                            {moment().format(sector.dateFormat)}
                          </Text>
                        </Text>
                        {project?.imageProjectOwner && (
                          <Image
                            style={{
                              ...styles.image_logo_proplanner,
                              paddingLeft: '20px'
                            }}
                            src={{
                              uri: project.imageProjectOwner,
                              method: 'GET',
                              headers: { 'Cache-Control': 'no-cache' },
                              body: ''
                            }}
                            allowDangerousPaths
                          />
                        )}
                      </View>
                    </View>
                    <View style={{ ...styles.table }}>
                      {getHeaderTablePdf(horPageIndex)}
                      <View style={{ ...styles.content_activities_tasks }}>
                        {page.map((page_content, itemsPerPageIndex) => (
                          <View key={itemsPerPageIndex}>
                            {page_content.activities ? (
                              <View>
                                {getActivityRow(
                                  page_content,
                                  horPageIndex,
                                  itemsPerPageIndex
                                )}
                              </View>
                            ) : page_content.children.length > 0 &&
                              page_content.parent_id === null ? (
                              recursiveChild(
                                page_content,
                                itemsPerPageIndex,
                                page_content.activityParent,
                                0,
                                horPageIndex
                              )
                            ) : page_content.parent_id === null &&
                              page_content.children.length == 0 ? (
                              getTaskTable(
                                page_content,
                                itemsPerPageIndex,
                                page_content.activityParent,
                                page_content.level_subtask * 4,
                                horPageIndex
                              )
                            ) : page_content.parent_id > 0 &&
                              page_content.children.length > 0 &&
                              page_content.level_subtask < 5 ? (
                              recursiveChild(
                                page_content,
                                itemsPerPageIndex,
                                page_content.activityParent,
                                page_content.level_subtask * 4,
                                horPageIndex
                              )
                            ) : page_content.parent_id > 0 &&
                              page_content.children.length > 0 &&
                              page_content.level_subtask == 5 ? (
                              getTaskTable(
                                page_content,
                                itemsPerPageIndex,
                                page_content.activityParent,
                                page_content.level_subtask * 4,
                                horPageIndex
                              )
                            ) : (
                              recursiveChild(
                                page_content,
                                itemsPerPageIndex,
                                page_content.activityParent,
                                page_content.level_subtask * 4,
                                horPageIndex
                              )
                            )}
                          </View>
                        ))}
                      </View>
                    </View>
                    <View
                      style={{
                        ...styles.section_footer,
                        marginBottom: `${pagesButtom[i] ? 60 + pagesButtom[i] : 20}px`,
                        borderTop: `${pagesButtom[i] ? 'none' : '1pt solid #EBEBEB'}`
                      }}>
                      <Image
                        style={{
                          ...styles.image_logo_proplanner,
                          paddingLeft: '20px'
                        }}
                        source={outbuild_logo}
                      />
                      <Text
                        style={{
                          ...styles.title_name,
                          paddingRight: '16px'
                        }}>
                        {t('lookahead_report.page') + ': '}
                        <Text style={{ ...styles.answer_name }}>
                          {i * numberHorizontalPages +
                            horPageIndex +
                            1 +
                            '/' +
                            numPages}
                        </Text>
                      </Text>
                    </View>
                  </Page>
                )
              )
            )}
          </Document>
        }
        info={{ module: 'lookahead', trackProps: trackProperties }}
      />
    );
  }
  return null;
}
export default withTranslation()(LookAHeadReport);

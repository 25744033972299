export const TemplateLean = ({ task }) => {
  const COLUMN = 'lean_status';
  const RESTRICTED = 'Restricted';

  const gantt = window?.ganttVisualization;
  const { isTask, is_milestone, is_restricted, restricted } = task;
  if (!isTask || is_milestone) return null;

  const { columns } = gantt.config;
  const { name, from_values } = columns.find(({ name }) => name === COLUMN);

  const isRestricted = is_restricted || restricted;

  const status = isRestricted ? RESTRICTED : task[name];
  const { color, label } = from_values.find(({ value }) => value === status);

  return `
      <div
      class="columnExportPDFHide"
        style="
          background-color: ${color}cf;
          color: white;
          height: 100%;
          width: 100%
        ">
        <span className="vertical-center col-lean-status">
          ${label}
        </span>
      </div>
      `;
};

/* eslint-disable max-lines-per-function */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'antd';
import { PDFDownloadLink } from '@react-pdf/renderer';
import './DownloadReportContainer.scss';
import pdfDownloadIcon from '../../../assets/img/icons/icon-pdf-download.svg';
import { trackingEvent } from '../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';

const DownloadReportContainer = ({ t, pdfDocument, info }) => {
  const history = useHistory();

  const handleDownloadClick = () => {
    if (info?.module === 'lookahead') {
      trackingEvent(
        `${info?.module}_pdf_exportable_download`,
        {
          ...getBasicAmplitudEventProperties()
        },
        AMPLITUDE_SERVICE
      );
    }
  };

  return (
    <div className="report_container">
      <div>
        <section className="report_header">
          <img src={pdfDownloadIcon} alt="pdf-download-icon" />
          <p>{t('download_report.title')}</p>
        </section>
        <section className="report_content">
          <PDFDownloadLink
            document={pdfDocument}
            fileName={`${info.module}_report.pdf`}>
            {({ loading }) =>
              loading ? (
                <Icon
                  info="loading"
                  style={{ fontSize: 20 }}
                  className="download__spinner"
                />
              ) : (
                <button
                  onClick={handleDownloadClick}
                  className="button_download">
                  {t('download_report.buttons.download')}
                </button>
              )
            }
          </PDFDownloadLink>
          <Button className="button_back" onClick={() => history.goBack()}>
            {t('download_report.buttons.back')}
          </Button>
        </section>
      </div>
    </div>
  );
};

export default DownloadReportContainer;

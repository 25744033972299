import {
  PROJECT_ACCESS_ERROR_MSG,
  SCHEDULE_ACCESS_ERROR_MSG
} from '../constants/access';
import { ProjectStatus } from '../constants/projectStatuses';

const ZERO = 0;

export const getProjectSector = ({ sectorSelected, sectors }) => {
  if (!sectors || sectors.length === ZERO) {
    return null;
  }

  if (sectorSelected) {
    const sector = sectors.find(({ id }) => id === sectorSelected);
    return sector ?? null;
  }

  return sectors[ZERO] ?? null;
};

export const getProjectCurrentSector = (sectors) =>
  sectors.find(({ set_current: isCurrent }) => isCurrent);

export const getModuleView = (ganttPermission) =>
  ganttPermission === 'SA' ? '/lookahead/planification' : '/masterplan';

export const filterByName =
  (filterName) =>
  ({ name }) =>
    name?.toLowerCase().includes(filterName?.toLowerCase());

export const isProjectListEmpty = (groupedProjects) => {
  if (!groupedProjects) {
    return true;
  }
  return Object.keys(groupedProjects).length === ZERO;
};

export const isFilteredProjectListEmpty = (groupedProjects, filterText) => {
  if (!groupedProjects) {
    return true;
  }

  return (
    Object.values(groupedProjects).flat().filter(filterByName(filterText))
      .length === ZERO
  );
};

export const filterActiveProjects = (projects) =>
  projects?.filter(
    ({ stage }) => stage !== ProjectStatus.PROJECT_STATUS_DELETED
  ) ?? [];

export const findProjectWithSchedule = (projects, projectId, scheduleId) => {
  const project = projects.find(({ id }) => id === parseInt(projectId, 10));

  if (!project) {
    throw new Error(PROJECT_ACCESS_ERROR_MSG);
  }

  const sector = project.sectors.find(
    ({ id }) => id === parseInt(scheduleId, 10)
  );

  if (!sector) {
    throw new Error(SCHEDULE_ACCESS_ERROR_MSG);
  }

  return { project, sector };
};

export const findItemById = (arr, itemId) =>
  arr.find(({ id }) => id === itemId);

export const findScheduleByIdAndProjectId = (projects, scheduleId, projectId) =>
  findItemById(projects, projectId)?.sectors.find(
    ({ id }) => id === scheduleId
  ) ?? null;

export const findProjectActiveSchedulesByProjectId = (projects, projectId) =>
  findItemById(projects, projectId)?.sectors.filter(
    ({ stage }) => stage !== ProjectStatus.PROJECT_STATUS_DELETED
  ) ?? [];

export const removeProjectAndSector = () => {
  sessionStorage.removeItem('currentSector');
  sessionStorage.removeItem('currentProject');
};

import TimeAgo from 'javascript-time-ago';
import moment from 'moment';

import { getCurrentLanguage } from '../i18n';

/**
 * Checks if a given instance is a valid date object.
 *
 * Note: The reason why this function checks the type description explicitly, instead of using instanceof,
 * is because instanceof doesn't work across different windows or frames.
 *
 * @param {Date} date - The date object to be tested
 * @return {boolean} - A boolean value indicating whether the date object is valid or not
 */
export const isValidDateObject = (date) =>
  Object.prototype.toString.call(date) === '[object Date]' &&
  !isNaN(date.getTime());

/**
 * Returns a localized string with the elapsed time since the date supplied as argument.
 *
 * @param {Date} date - The date object to be used as reference
 * @return {string} - A string with the localized elapsed time
 */
export const getElapsedTimeLabel = (date) => {
  const timeAgo = new TimeAgo(getCurrentLanguage());
  return timeAgo.format(date, 'round');
};

/**
 * Formats a given date into a human-readable text format ('MMM DD, YYYY') based on the specified language.
 * The first letter of the formatted date is capitalized.
 *
 * @param {Object} options - Options for formatting the date.
 * @param {string|Date} options.date - The date to format.
 * @param {string} options.language - The locale/language to use for formatting (e.g., 'en', 'es').
 * @returns {string} - The formatted date with the first letter capitalized.
 */
export const formatDateToText = ({ date, language = 'en' }) => {
  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const DATE_FORMATS = {
    es: 'DD MMM, YYYY',
    en: 'MMM DD, YYYY'
  };

  if (!isValidDateObject(new Date(date))) {
    return '';
  }
  const SPANISH_LANGUAGE = 'es';
  const FORMAT_DATE = DATE_FORMATS[language] || DATE_FORMATS.en;
  const formattedDate = moment(date).locale(language).format(FORMAT_DATE);

  if (language !== SPANISH_LANGUAGE) {
    return formattedDate;
  }

  const capitalizedFormattedDate = formattedDate.replace(
    /\b\w+\b/g,
    capitalizeFirstLetter
  );

  return capitalizedFormattedDate.replace('.', '');
};

import moment from 'moment';
import { trackingEvent } from '../../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { earlyAccessCriticalPath } from '../../../utils/earlyAccessCriticalPath';

const constraintTypesMap = {
  alap: 1,
  asap: 0,
  fnet: 6,
  fnlt: 7,
  mfo: 3,
  mso: 2,
  snet: 4,
  snlt: 5
};

const formatDate = 'DD-MM-YYYY H:mm';

export const MSProyectParseData = (gantt, isProcore = false) => {
  const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
  const currentProject = JSON.parse(sessionStorage.getItem('currentProject'));
  const currentCompany = JSON.parse(sessionStorage.getItem('company'));
  trackingEvent(
    'xml_exportable_generation',
    {
      company_name: currentCompany?.name,
      company_id: currentCompany?.id,
      project_name: currentProject?.name,
      project_id: currentProject?.id,
      stage_name: currentSector?.name,
      stage_id: currentSector?.id
    },
    AMPLITUDE_SERVICE
  );
  const data = isProcore ? ProcoreTasks(gantt) : MSProyectTasks(gantt);
  return data;
};

export const convertPredessesors = (gantt, link) =>
  extractNumber(formaterDays(link.lag, gantt), true);

const getBaseLine = (task) => {
  if (task.baseline_points && task.baseline_points.length) {
    return task.baseline_points.find(
      (base) => base.sectorbaselineversion && base.sectorbaselineversion.active
    );
  }

  return null;
};

const searchTaskCalendarInfo = (tasks, gantt, iterator = 0) => {
  if (tasks.length <= iterator) return null;

  if (tasks[iterator].calendar_id) {
    const calendar = gantt.calendarOptions.find(
      (calendar) => calendar.key == tasks[iterator].calendar_id
    );

    if (calendar) return calendar;
  }

  searchTaskCalendarInfo(tasks, gantt, iterator + 1);
};

const isNegative = (value) => value < 0;

const extractNumber = (value, int = true) => {
  const num = value.split(' ').shift();
  if (isNaN(num)) return 1;
  const res = int ? parseInt(num) : parseFloat(num);

  return num >= 1 ? res : parseFloat(num).toFixed(2);
};

const formaterDays = (value, gantt) =>
  value ? gantt.formatter.format(Math.abs(value)) : '0 days';

export const getCalendarName = (gantt, tasks) => {
  const calendar = searchTaskCalendarInfo(tasks, gantt);
  return calendar?.label || 'Custom';
};

const createTasks = (gantt) => ({
  FreeSlack: (task) => parseFreeSlackHoursToDays(gantt, task),
  TotalSlack: (task) => parseTotalSlackHoursToDays(gantt, task),
  Duration: (task) => parseDurationHoursToDays(gantt, task),
  ConstraintDate: (task) => parseDate(gantt, task.id),
  ConstraintType: (task) => parseConstraintType(gantt, task.id),
  Baseline: (task) => buildBaseLine(gantt, task),
  Work: (task) => task.hhWorkTime,
  RemainingWork: (task) => task.hhWorkTime,
  Cost: (task) => (gantt.hasChild(task.id) ? 0 : task.cost),
  FixedCost: (task) => (gantt.hasChild(task.id) ? 0 : task.cost),
  RemainingCost: (task) => (gantt.hasChild(task.id) ? 0 : task.cost),
  DurationFormat: (_) => 7,
  Milestone: (task) => validateMilestone(task),
  ActualStart: (task) =>
    parseActualStart(task, gantt.config.constraint_types.ASAP),
  ActualFinish: (task) => parseActualFinish(task),
  ExclusiveID: (task) => `${task.unique_correlative_id}`,
  Notes: (task) => task.proplannerId,
  SubprojectName: (task) => `${task.calendar_id}`
});

const MSProyectTasks = (gantt) => {
  const commonTasks = createTasks(gantt);
  return {
    ...commonTasks
  };
};

const ProcoreTasks = (gantt) => {
  const commonTasks = createTasks(gantt);
  return {
    ...commonTasks,
    Contact: (task) => task.unique_correlative_id
  };
};

const parseConstraintType = (gantt, taskId) => {
  if (!gantt || !taskId) return constraintTypesMap.asap;

  const task = gantt.getTask(taskId);
  const { progress, $target, constraint_type, type } = task;

  const PROJECT = 'project';
  const ASAP = 'asap';
  const asap = gantt.config.constraint_types.ASAP || ASAP;
  const SNET_CODE = 4;

  const isNotProject = type !== PROJECT;
  const isProgressNull = progress === 0;
  const hasPredecessors = $target && $target.length !== 0;
  const isASAP = constraint_type === asap;

  const isChangingConstraintType =
    isNotProject && isProgressNull && !hasPredecessors && isASAP;

  return isChangingConstraintType
    ? SNET_CODE
    : constraintTypesMap[constraint_type];
};

const parseDurationHoursToDays = (gantt, task) => {
  const duration =
    task.for_disable_milestone_duration !== 0
      ? gantt.formatter.format(task.for_disable_milestone_duration)
      : '0 days';
  return extractNumber(duration, false);
};

const buildBaseLine = (gantt, task) => {
  const baseline = getBaseLine(task);

  if (!baseline) {
    return {};
  }

  const startBase = baseline.start_date.replace(/\//g, '-').split(/[-|\s]/);
  const endBase = baseline.end_date.replace(/\//g, '-').split(/[-|\s]/);

  task.start_base = `${startBase[2]}-${startBase[1]}-${startBase[0]} ${startBase[3]}`;
  task.end_base = `${endBase[2]}-${endBase[1]}-${endBase[0]} ${endBase[3]}`;
  task.work_base = baseline.hh_work;
  task.cost_base = baseline.cost;

  return {
    Start: moment.utc(task.start_base, formatDate), // .utc().format()
    Finish: moment.utc(task.end_base, formatDate),
    Duration: baseline.duration,
    DurationFormat: 7,
    Work: task.work_base,
    Cost: baseline.cost
  };
};

const parseTotalSlackHoursToDays = (gantt, task) => {
  const TOTAL_SLACK_DEFAULT = 0;

  const calculateTotalSlack = earlyAccessCriticalPath()
    ? TOTAL_SLACK_DEFAULT
    : gantt.getTotalSlack(task);

  let totalSlack = task.totalSlack || calculateTotalSlack;
  const negative = isNegative(totalSlack);

  totalSlack = extractNumber(formaterDays(totalSlack, gantt), true);

  task.totalSlack = negative ? -Math.abs(totalSlack) : totalSlack;
  return task.totalSlack;
};

const parseFreeSlackHoursToDays = (gantt, task) => {
  let freeSlack = task.freeSlack || 0;
  const negative = isNegative(freeSlack);

  freeSlack = extractNumber(formaterDays(freeSlack, gantt), true);

  task.freeSlack = negative ? -Math.abs(freeSlack) : freeSlack;
  return task.freeSlack;
};

const parseDate = (gantt, taskId) => {
  if (!gantt || !taskId) return null;

  const getFormat = (date) => {
    const dateFormat = moment(date).format(formatDate);
    return moment.utc(dateFormat, formatDate);
  };

  const constraintTypes = gantt && gantt.config.constraint_types;
  if (!constraintTypes) return null;

  const task = gantt.getTask(taskId);
  const { progress, $target, constraint_date, constraint_type, start_date } =
    task;

  const hasPredecessors = $target && $target.length !== 0;

  const isProgressNull = progress === 0;
  const isASAP = constraint_type === constraintTypes.ASAP;
  const isALAP = constraint_type === constraintTypes.ALAP;

  if (isProgressNull && !hasPredecessors && isASAP) {
    return getFormat(start_date);
  }

  if (!isASAP && !isALAP) {
    return getFormat(constraint_date);
  }

  return null;
};

const validateMilestone = (task) =>
  task.duration === 0 || task.type === 'milestone' ? 1 : 0;

const parseActualStart = (task, asap) => {
  let actualStart = moment.utc(task.start_date, formatDate);
  // if (validateMilestone(task) === 1 && task.progress === 0) actualStart = '01-01-1000T00:00:00'
  if (task.progress === 0) actualStart = '01-01-1000T00:00:00';
  return moment.utc(actualStart, formatDate);
};

const parseActualFinish = (task) => {
  let actualFinish = moment.utc(task.end_date, formatDate);
  if (task.progress !== 100) actualFinish = '01-01-1000T00:00:00';
  return moment.utc(actualFinish, formatDate);
};

export const responsibleDataProcore = (gantt) => {
  const tasks = gantt.getTaskByTime();
  const responsibles = [];
  const assignments = [];

  tasks.forEach((task) => {
    const { responsables, proplannerId: task_id } = task;
    responsables &&
      responsables.forEach((responsible) => {
        const { id: responsible_id } = responsible;
        const isDuplicateResponsible = responsibles.some(
          (respo) => respo.id === responsible_id
        );

        if (!isDuplicateResponsible) responsibles.push(responsible);
        const assignment = {
          taskId: task_id,
          responsibleId: responsible_id
        };
        assignments.push(assignment);
      });
  });
  return { responsibles, assignments };
};

export const getTaskByGanttFormatProcore = (gantt) => {
  const getTask = gantt.getTaskByTime();
  const tasks = {};

  getTask.forEach((task) => {
    const { proplannerId, start_date, end_date } = task;
    if (!proplannerId) return;

    const start_task = moment(start_date)
      .format('YYYY-MM-DDTHH:mm:ss')
      .toString();
    const end_task = moment(end_date).format('YYYY-MM-DDTHH:mm:ss').toString();
    tasks[proplannerId] = { ...task, start_task, end_task };
  });
  return tasks;
};

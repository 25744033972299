import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { notification } from 'antd';

import * as icon from '../../../../../icons';
import {
  TOOLBAR_MIDDLE_GROUP_ITEMS,
  TOOLBAR_RIGTH_GROUP_ITEMS
} from './constants';
import { ToolbarGroup } from '../../../../ToolbarGroup';

import { CloseWeekModal } from '../CloseModal/CloseWeekModal';

import styles from './WeeklyplanToolbar.module.scss';
import { projectService } from '../../../../../services/project.service';
import useWeeklyplanPermissions from '../../../../../hooks/useWeeklyplanPermissions';
import { sectorService } from '../../../../../services';

import { getTodayWeekWithPdayAndCurrentDay } from '../../../../../utils';
import { saveAs } from 'file-saver';
import { useWeeklyPlanExcelExport } from '../../../../../hooks/useWeeklyPlanExcelExport';

export const WeeklyPlanCommitmentToolbar = (props) => {
  const {
    activities,
    currentWeek,
    changeCurrentWeek,
    dateRange,
    planificationDay,
    weekcommitment,
    t = (_) => {},
    sectorId,
    year,
    setWeekcommitment,
    setIsProgressColumns,
    setWeekcommitmentClosed,
    getLookahead,
    updateAllTaskCommitments,
    setShowModalDeletedTasks
  } = props;

  const [isCloseWeek, setCloseWeek] = useState(false);
  const [isVisibleModal, setVisibleModal] = useState(false);

  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  const noRequireResources = !stateCompany?.currentCompany?.require_resources;
  const history = useHistory();

  /** Permissions */
  const weeklyPlanPermissions = useWeeklyplanPermissions();
  const isOnlyReadElement = ['V', 'ACP'].includes(weeklyPlanPermissions.plan);
  const notAccessCloseWeekRoles = ['ACP'];

  useEffect(() => {
    TOOLBAR_MIDDLE_GROUP_ITEMS[0][1].props = { currentWeek, dateRange, t };
  }, [currentWeek, dateRange]);

  useEffect(() => {
    if (!weeklyPlanPermissions) return; // lookaheadPermissions replaced by weeklyPlanPermissions
    const { plan } = weeklyPlanPermissions; // lookaheadPermissions replaced by weeklyPlanPermissions

    if (weekcommitment) {
      /** Permission ACP is a custom permission behaviour so, must be stopped */
      if (notAccessCloseWeekRoles.includes(plan)) {
        TOOLBAR_RIGTH_GROUP_ITEMS.pop();
        setCloseWeek(weekcommitment.closed || false);
        window.blockEditionProgress = weekcommitment.closed;
        return;
      }

      const objectRange = getTodayWeekWithPdayAndCurrentDay(
        projectState,
        moment()
      );
      const loadedWeek = weekcommitment.week;
      const currentWeek = objectRange[0];

      setCloseWeek(weekcommitment.closed || false);

      if (!notAccessCloseWeekRoles.includes(plan)) {
        window.blockEditionProgress = weekcommitment?.closed;

        if (!weekcommitment?.closed && loadedWeek === currentWeek) {
          if (isOnlyReadElement)
            getTooltipBehaivor(true, t('not_permissions_actions'));

          getToolbarBehaivor(
            'weekcommitment.close_week',
            'CLOSE_WEEK',
            icon.CalendarIcon
          );
          TOOLBAR_RIGTH_GROUP_ITEMS[
            TOOLBAR_RIGTH_GROUP_ITEMS.length - 1
          ][0].invertTextColor = true;
        } else {
          if (isOnlyReadElement) getTooltipBehaivor(false, null);

          getToolbarBehaivor(
            'weekcommitment.lookahead',
            'GO_TO_LOOKAHED',
            icon.LookaheadRangeIcon
          );
        }
      }
    } else if (!notAccessCloseWeekRoles.includes(plan)) {
      window.blockEditionProgress = true;

      getTooltipBehaivor(false, null);
      getToolbarBehaivor(
        'weekcommitment.lookahead',
        'GO_TO_LOOKAHED',
        icon.LookaheadRangeIcon
      );
    }
  }, [weekcommitment]);

  const getToolbarBehaivor = (i18nKey, command, icon) => {
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].i18nKey =
      i18nKey;
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].command =
      command;
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].icon =
      icon;
  };

  const getTooltipBehaivor = (disabled, tooltip) => {
    TOOLBAR_RIGTH_GROUP_ITEMS[
      TOOLBAR_RIGTH_GROUP_ITEMS.length - 1
    ][0].disabled = disabled;
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].tooltip =
      tooltip;
  };

  const handleMiddleToolbarActions = (command) => {
    const val = command == 'CHANGE_PREV_WEEK' ? -1 : +1;
    changeCurrentWeek(val);
  };

  const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
  const defaultDateFormat = 'MM/DD/YY';
  const dateFormat = currentSector.dateFormat || defaultDateFormat;
  const { downloadExcel } = useWeeklyPlanExcelExport(
    activities,
    dateFormat,
    t,
    saveAs
  );

  const handleRightToolbarActions = (command) => {
    const commandActions = {
      GO_TO_LOOKAHED: (_) => history.push('/lookahead/planification'),
      DOWNLOAD_SCHEDULE: (_) => {
        if (!activities.length) {
          notification.error({
            message: t('weekcommitment.error_task'),
            description: t('weekcommitment.no_task')
          });
          return;
        }

        const dateStart = moment(dateRange.start)
          .format('YYYY-MM-DD')
          .toString();
        const dateEnd = moment(dateRange.end).format('YYYY-MM-DD').toString();
        history.push(
          `/report/week/all/${currentWeek}/${planificationDay}/${year}/${dateStart}/${dateEnd}`
        );
      },
      DOWNLOAD_SCHEDULE_EXCEL: downloadExcel,
      PROGRESS_COLS: (_) => {
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[0].active = true;
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[0].itemColor = '#FFFFFF';
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[1].active = false;
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[1].itemColor = '#121212';
        setIsProgressColumns({ progress: true, productivity: false });
      },
      PRODUCTIVITY_COLS: (_) => {
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[0].active = false;
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[0].itemColor = '#121212';
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[1].active = true;
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[1].itemColor = '#FFFFFF';
        setIsProgressColumns({ progress: false, productivity: true });
      },
      CLOSE_WEEK: (_) => {
        setVisibleModal(true);
      },
      SHOW_MODAL_DELETED_TASK: (_) => {
        setShowModalDeletedTasks(true);
      }
    };

    commandActions[command]();
  };

  const handleHiddeModal = (_) => setVisibleModal(false);

  const saveForwardWeeklyData = async (_) => {
    const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
    const finalData = { ...currentSector };
    if (currentWeek) {
      finalData.currentClosedWeek = currentWeek;
      finalData.didCloseWeek = true;
      await sectorService.update(finalData);
      sessionStorage.setItem('currentSector', JSON.stringify(finalData));
    }
  };

  const handleCloseWeek = async (_) => {
    handleHiddeModal();
    projectService.closecommitbysector(sectorId, currentWeek, year);
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].i18nKey =
      'weekcommitment.lookahead';
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].command =
      'GO_TO_LOOKAHED';
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].icon =
      icon.LookaheadRangeIcon;
    const cloneweekcommit = { ...weekcommitment };
    cloneweekcommit.closed = true;
    await getLookahead(currentWeek);
    setWeekcommitmentClosed(true);
    setWeekcommitment(cloneweekcommit);
    setCloseWeek(!isCloseWeek);
    updateAllTaskCommitments();
    await saveForwardWeeklyData();
  };

  return (
    <div className={styles.container}>
      <div></div>
      <ToolbarGroup
        items={TOOLBAR_MIDDLE_GROUP_ITEMS}
        onCommandDispatched={handleMiddleToolbarActions}
        t={t}
      />
      <ToolbarGroup
        items={TOOLBAR_RIGTH_GROUP_ITEMS}
        noRequireResources={noRequireResources}
        onCommandDispatched={handleRightToolbarActions}
        t={t}
      />
      <CloseWeekModal
        visible={isVisibleModal}
        hiddeModal={handleHiddeModal}
        closeWeek={handleCloseWeek}
        contentText={t('weekcommitment.content_text')}
        contentsubText={t('weekcommitment.content_subText')}
        cancelText={t('weekcommitment.cancel_text')}
        closeWeekText={t('weekcommitment.close_text')}
      />
    </div>
  );
};

import { useMemo } from 'react';
import {
  createExcelFile,
  getSheetData,
  processActivity
} from '../utils/excelUtils';
import * as Sentry from '@sentry/react';
import { Constants } from '../constants/components';

export const useWeeklyPlanExcelExport = (activities, dateFormat, t, saveAs) => {
  const finalData = useMemo(
    () =>
      activities.flatMap((activity) => processActivity(activity, dateFormat)),
    [activities, dateFormat]
  );

  const downloadExcel = async () => {
    try {
      if (activities.length === Constants.EMPTY_NUMBER) return;
      const sheetData = getSheetData(finalData, t);
      const excelBuffer = await createExcelFile(sheetData);
      saveAs(new Blob([excelBuffer]), 'file.xlsx');
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  return {
    finalData,
    downloadExcel
  };
};

import React from 'react';
import { findDeepGetActivityOfTask } from '../../../../views/lookahead/planification/index.helper';
import {
  findDeepGetTask,
  isGrouped,
  updateAsyncTaskGanttV2
} from '../../GanttVisualization.helper';
import cloneDeep from 'lodash/cloneDeep';
import {
  hasUnfitProblem2 as hasUnfitProblem,
  transformDateWithHour
} from '../../../../utils/lookahead-common';
import moment from 'moment';
import { trackingEvent } from '../../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';

const RenderEnd = ({
  task,
  node,
  column,
  activities,
  gantt,
  t,
  onlyRead,
  modalModification,
  setModalModification
}) => {
  const isOnlyReadElement = onlyRead || task?.readonly;
  if (task?.is_milestone) return '';
  /** No Render if parent is Abstract */
  if (isGrouped()) {
    if (task?.type === undefined) {
      return `<span></span>`;
    }
  }
  /** Check if data is defined. Activity don't have this data */
  /** Check if data is defined. Activity don't have this data */
  if (!task?.isTask) {
    return `<span class="vertical-center-dates">
            <span class="column-disabled">${moment(task[column.name]).format(gantt?.currentDateFormat?.replace('hh', 'HH'))}</span>
        </span>`;
  }

  /** get activity reference */
  const findAct = findDeepGetActivityOfTask(window.activities, 'id', task?.id);
  let activityReference;
  if (findAct) activityReference = findAct;
  if (!activityReference) return '';

  /** get reference task (taskFromLookahead) from activities */
  const taskFromLookahead = findDeepGetTask(activities, 'id', task?.id);
  if (!taskFromLookahead) return '';

  /** this function intercepts the save call, and adds data */
  const updateData = (taskCallback) => {
    /** fix bug on end date */
    taskCallback.end_date = new Date(taskCallback.end_date);
    updateAsyncTaskGanttV2(
      taskCallback,
      column.name,
      taskCallback.end_date,
      gantt
    );

    trackingEvent(
      'task_end_date_edition',
      {
        ...getBasicAmplitudEventProperties(),
        task_id: taskCallback?.id,
        task_name: taskCallback?.name,
        parent_activity_name: taskCallback?.activity,
        parent_activity_id: taskCallback?.activityId
      },
      AMPLITUDE_SERVICE
    );
  };

  /**
   * This function handles the request acitivity modification
   * @param {*} task task which has problems of unfit dates
   * @param {*} activity activity to request modification
   */
  const handleActivityModificationRequest = (task, activity) => {
    setModalModification({
      ...modalModification,
      visible: true,
      title: t('modals.lookahead.activity_modification_request.title'),
      data: {
        task,
        activity
      }
    });
  };

  const hasItUnfitYup = hasUnfitProblem(
    taskFromLookahead,
    activityReference,
    updateData,
    handleActivityModificationRequest,
    isOnlyReadElement,
    t
  );

  let dateObject;
  if (taskFromLookahead[column.name].split) {
    dateObject = new Date(taskFromLookahead[column.name]);
  } else {
    dateObject = cloneDeep(taskFromLookahead[column.name]);
  }

  let isOdd = false;
  if (gantt?.oddColsConfig && task?.type === 'activitytask') {
    isOdd = gantt.oddColsConfig[column.name];
  }

  let classOdd = isOdd ? 'odd-col' : 'non-odd-col';
  const momentDate = moment(task?.end_date);

  if (
    !window.ganttVisualization?.lookaheadTemplates_actionActivityModification
  ) {
    window.ganttVisualization.lookaheadTemplates_actionActivityModification = (
      taskId
    ) => {
      if (!onlyRead) {
        const currentTask = findDeepGetTask(window.activities, 'id', taskId);
        if (!currentTask) return;

        const currentActivity = findDeepGetActivityOfTask(
          window.activities,
          'id',
          taskId
        );
        if (!currentActivity) return;

        handleActivityModificationRequest(currentTask, currentActivity);
      }
    };
  }

  return `<div class="${classOdd}">
        <span>
            ${momentDate?.format(gantt?.currentDateFormat?.replace('hh', 'HH'))}
            ${hasItUnfitYup || ''}
        </span>
    </div>`;
};

export default RenderEnd;

import React, { useEffect, useState, Fragment } from 'react';

import ModalCropImage from '../../ModalCropImage';

import { Input, Select, Icon, message, Spin } from 'antd';
import styled from 'styled-components';

import './index.css';
import { useSelector } from 'react-redux';
import { companyService } from '../../../../services/company.service';
import uploadIcon from '../../../../assets/img/upload-icon.png';

import { LoadingOutlined } from '@ant-design/icons';

/** import common functions from utils */
import {
  companySizes,
  ObjectLogoSvg,
  companyContractType
} from '../../../../utils';

import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';
import { getSessionTokenData } from '../../../../utils/userUtils';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

function CardGeneral({ t }) {
  const permissions = useConfigurationPermissions();
  const [onlyRead, setOnlyRead] = useState(permissions.project == 'V');
  const sessionTokenData = getSessionTokenData();

  // GET ID COMPANY CURRENT FROM REDUX
  const stateCompany = useSelector((state) => state.companyState);
  const stateProject = useSelector((state) => state.projectState);
  const idCurrentCompany = stateCompany.currentCompany?.id;
  const [stateUpload, setStateUpload] = useState({ loading: false });

  const [CompanyCurrent, setCompanyCurrent] = useState([]);

  const [imgSrc, setImgSrc] = useState('');

  const [formCropVisible, setFormCropVisible] = useState(false);

  useEffect(() => {
    if (imgSrc) {
      setFormCropVisible(true);
    }
  }, [imgSrc]);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result.toString() || '');
        setFormCropVisible(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  // GET USERS BY COMPANY
  useEffect(() => {
    const abortController = new AbortController();
    companyService
      .show(idCurrentCompany)
      .then((response) => {
        setCompanyCurrent(response.company);
        if (response.company) {
          setStateUpload({
            ...stateUpload,
            imageUrl: response.company.image
          });
        }
        getLabelSize(response.company.size);
      })
      .catch((err) => console.log(err));

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  /** component Logo by default */
  const ProjectIcon = (props) => <Icon component={ObjectLogoSvg} {...props} />;

  /** component upload button */
  const uploadButton = (
    <div>
      {stateUpload && stateUpload.loading ? (
        <LoadingOutlined />
      ) : (
        <ProjectIcon className="FrmCompany" />
      )}
    </div>
  );

  const [stateNewImage, setStateNewImage] = useState({ isLoaded: false });

  useEffect(() => {
    /** preload */
    if (stateUpload.imageUrl) {
      const image = new Image();
      image.onload = () => setStateNewImage({ isLoaded: true });
      image.src = stateUpload.imageUrl;
    }
    if (CompanyCurrent.id) {
      updateCompany();
    }
  }, [stateUpload]);

  const changeCompany = (e) => {
    setCompanyCurrent({
      ...CompanyCurrent,
      [e.target.name]: e.target.value
    });
  };

  const [LabelSize, setLabelSize] = useState('');

  // This method update project in state
  const updateSelect = (name, value, label) => {
    setCompanyCurrent({
      ...CompanyCurrent,
      [`${name}`]: value
    });
    switch (name) {
      case 'size':
        setLabelSize(label);
        break;
    }
  };

  const updateCompany = async () => {
    await companyService.update(CompanyCurrent);
  };
  const { Option } = Select;

  const getLabelSize = (attribute) => {
    for (const iterator of companySizes) {
      if (iterator.value === attribute) {
        setLabelSize(iterator.label);
      }
    }
  };

  const { isLoaded } = stateNewImage;
  const imgToShow = !isLoaded ? (
    <Spin indicator={antIcon} />
  ) : (
    <img
      className="img-company-edit"
      width={147}
      alt="logo company"
      src={stateUpload.imageUrl}
    />
  );

  const userStorage = JSON.parse(localStorage.getItem('user'));
  const classNameExtraColumn =
    sessionTokenData.role === 'superadmin' &&
    userStorage.email.startsWith('super@')
      ? 'grid-general-company-extraColumns'
      : '';
  const renderContractTypeInput = () => {
    let ret = null;
    if (
      sessionTokenData.role === 'superadmin' &&
      userStorage.email.startsWith('super@')
    ) {
      const labelContractType = companyContractType.find(
        (element) => element.value == CompanyCurrent.contract_type
      );
      ret = (
        <div style={{ marginTop: '32px' }}>
          <p className="description-form">
            {t('settings.organization_form.contract_type')}
          </p>
          <SelectionStyle>
            <Fragment>
              <Select
                disabled={onlyRead}
                value={labelContractType ? labelContractType.label : ''}
                style={{
                  width: ' 100%',
                  fontSize: '12px',
                  border: '1px solid #121212',
                  borderRadius: '5px'
                }}
                name="contractType"
                onBlur={() => updateCompany()}>
                {companyContractType.map((element, index) => (
                  <Option
                    name="contractType"
                    key={index}
                    onClick={() =>
                      updateSelect(
                        'contract_type',
                        element.value,
                        element.label
                      )
                    }>
                    {element.label}
                  </Option>
                ))}
              </Select>
            </Fragment>
          </SelectionStyle>
        </div>
      );
    }
    return ret;
  };

  return (
    <div
      className={'grid-general-company image-upload ' + classNameExtraColumn}>
      <div className="cont-imag-edit">
        {['superadmin', 'admin'].includes(sessionTokenData.role) ? (
          <div className="Crop-Controls">
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              id="file-input-edit-image"
            />
          </div>
        ) : null}
        {stateUpload && stateUpload.imageUrl ? imgToShow : uploadButton}
      </div>

      <div>
        <h1 className="company-title">
          {t('settings.organization_form.info')}
        </h1>
        <p className="description-form">
          {t('settings.organization_form.name_organization')}
        </p>
        <Input
          disabled={onlyRead}
          name="name"
          placeholder={t('settings.organization_form.name_organization')}
          onBlur={() => updateCompany()}
          onChange={(e) => changeCompany(e)}
          value={CompanyCurrent ? CompanyCurrent.name : ''}
          className="input-text-company"
        />
      </div>

      <div style={{ marginTop: '32px' }}>
        <p className="description-form">
          {t('settings.organization_form.address')}
        </p>
        <Input
          disabled={onlyRead}
          name="address"
          className="input-text-company"
          placeholder={t('settings.organization_form.address')}
          onBlur={() => updateCompany()}
          onChange={(e) => changeCompany(e)}
          value={CompanyCurrent ? CompanyCurrent.address : ''}
        />
      </div>

      <div style={{ marginTop: '32px' }}>
        <p className="description-form">
          {t('settings.organization_form.size')}
        </p>
        <SelectionStyle>
          <Fragment>
            <Select
              disabled={onlyRead}
              value={LabelSize}
              style={{
                width: ' 100%',
                fontSize: '12px',
                border: '1px solid #121212',
                borderRadius: '5px'
              }}
              name="size"
              onBlur={() => updateCompany()}>
              {companySizes.map((size, index) => (
                <Option
                  name="size"
                  key={index}
                  onClick={() => updateSelect('size', size.value, size.label)}>
                  {size.label}
                </Option>
              ))}
            </Select>
          </Fragment>
        </SelectionStyle>
      </div>
      {renderContractTypeInput()}
      {['superadmin', 'admin'].includes(sessionTokenData.role) ? (
        <label htmlFor="file-input-edit-image">
          <img
            className="img-upload-logo"
            alt="logo company"
            src={uploadIcon}
          />
        </label>
      ) : null}
      {['superadmin', 'admin'].includes(sessionTokenData.role) ? (
        <ModalCropImage
          imgSrc={imgSrc}
          setImgSrc={setImgSrc}
          Visible={formCropVisible}
          setVisible={setFormCropVisible}
          setCompanyCurrent={setCompanyCurrent}
          CompanyCurrent={CompanyCurrent}
          projectSelectedId={stateProject.projectSelected}
          setStateUpload={setStateUpload}
          setStateNewImage={setStateNewImage}
          t={t}
        />
      ) : null}
    </div>
  );
}

export default CardGeneral;

const SelectionStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
    width: 90%;
  }
  .ant-select-selection {
    background-color: white !important;
    color: #5f5f5f;
  }
  .anticon svg {
    color: transparent;
  }

  .ant-select-selection,
  ant-select-selection--single {
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
  }

  .ant-select-selection:focus,
  ant-select-selection--single:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

import { capitalize } from 'lodash';

export const TemplateResponsible = (task) => {
  const BLANK = '';
  const SEPARATOR = ', ';
  const COLUMN = 'responsables';
  const gantt = window?.ganttVisualization;
  const { isTask } = task;

  if (!gantt || !isTask) return null;

  const { responsables: users } = task;

  let isOdd = false;
  const { oddColsConfig } = gantt;

  if (oddColsConfig) {
    isOdd = oddColsConfig.responsables;
  }

  const isMultiResponsible = users.length > 1;
  const insideHtml = users
    .map((user, index) => {
      const { name, lastname } = user;
      const userName = name ? capitalize(name) : BLANK;
      const userLastName = lastname ? capitalize(lastname) : BLANK;
      const userIcon =
        isMultiResponsible && parseInt(users.length) !== index + 1
          ? SEPARATOR
          : BLANK;
      return `<span className='name-responsable-pdf'>
            ${userName}
            ${userLastName}
            ${userIcon}  
    </span>`;
    })
    .join('');

  const { width } = gantt.config.columns.find(({ name }) => name === COLUMN);
  const columnWidth = parseInt(width);

  return `
        <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
            <span class="string-h" style="width: ${columnWidth}px;">
                ${insideHtml}
            </span>
        </div>
    `;
};

/* eslint-disable no-eval */
import React, { useEffect, useState } from 'react';
import Gantt from '../../../components/Gantt';
import { Row, Col, message, Alert, Spin } from 'antd';
import { notification } from 'antd';
import { table_grid_columns } from '../../../assets/js/field_config/gantt-columns';
import EventEmitter from 'react-native-eventemitter';
import './gantt.css';
import { historicalActivityProgressService } from '../../../services/historicalactivityprogress.service';
import {
  activityService,
  sectorService,
  activityRelationService,
  subContractService,
  userService,
  tagService,
  sectorBaselinePointService,
  baseworkingdayService,
  scheduleUpdatesService,
  productionunitService
} from '../../../services';
import {
  convertGanttTaskToActivity,
  convertActivityToGanttTask,
  convertLinkToActivityRelation,
  convertActivityRelationToLink,
  defaultData,
  calculatePonderators,
  transformHourToDays,
  createBaseCalendarFromCalendar,
  getBodyForNewBaseline,
  refreshCalendars,
  setCustomHourFn,
  MAX_ACTIVITIES,
  getNextUIDAtLoad,
  updateSubmittalsHelper,
  getAccumulatedGanttDuration,
  baselineOptions
} from './gantt.helper';
import { useParams, useRouteMatch } from 'react-router-dom';
import { getDrawerSelector } from '../../../redux/slices/hoveringStackSlice';
import { useSelector, useDispatch, connect } from 'react-redux';
import * as ganttActions from '../../../redux/slices/ganttSlice';
import {
  getSelectedActivitiesSelector,
  getGanttLoadingSelector
} from '../../../redux/slices/ganttSlice';
import GanttFilterHeader from '../../../components/GanttFilterHeader';
import { sectorBaselineVersionService } from '../../../services/index';
import { Gantt as GanttDhtmlx } from '../../../assets/gantt/dhtmlxgantt';
import cloneDeep from 'lodash/cloneDeep';
import ModalAddSubContract from '../../../components/Settings/ModalAddSubContract';
import ModalAddTags from '../../../components/Settings/ModalAddTags';
import ConstraintValidationModal from '../../../components/constraintValidationModal';
import moment from 'moment';
import { dynamicSort, grid } from '../../../utils';
import * as projectActions from '../../../redux/slices/projectSlice';
// import * as projectActions from '../../../redux/slices/projectSlice';
import masterplanPermissions from '../../../hooks/useMasterplanPermissions';

import Loader from '../../../components/Loadign';
/** import common functions from utils */
import { columns } from '../../../utils';
/** import IconComponent to SVG images */
import IconComponent from '../../../components/Projects/IconSvg';
import { check_progress } from '../../../assets/js/gantt_lifecycle/editing-flow';

import { withTranslation } from 'react-i18next';
import { trakerServiceAppCues } from '../../../utils/appcues-util';
import colors from '../../../stylesheets/variables.scss';
import modalStyles from './modals.module.scss';
import BaselineModal from '../../../components/BaselineModal';
import { ScheduleModal } from '../../../components/ScheduleModal/ScheduleModal';
import ActivityModification from '../../../components/ActivityModification';
import { descriptionEditor } from '../../../assets/js/field_config/editor-inline-gantt';
import { WarningIcon } from '../../../icons';

import { notifyMessage } from '../../../utils/lookahead-common';
import { trackingEvent } from '../../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import {
  getBasicAmplitudEventProperties,
  getSectorCompanyAndProject
} from '../../../analytics/utils';
import * as Sentry from '@sentry/react';
import { addBreadcrumbUtil, log } from '../../../monitor/monitor';
import ModalSystem from '../../../components/DesignSystem/ModalSystem';
import { activeSectorFlag } from '../../../utils/activeSectorFlag';
import { updateDefaultView } from '../../../services/views/viewSync';
import {
  getFilterOrder,
  getZoomLevel,
  getGeneralFilters,
  getDateFilter,
  getColumnsFilter,
  getVisualizationFilter,
  getColorsFilters,
  getCollapsedFilter,
  defaultView
} from '../../../redux/selectors/viewsSelector';
import {
  notifyGanttVisualizationConfigChange,
  setShowedFilters,
  setGanttLoading
} from '../../../redux/slices/ganttSlice';
import {
  setOrderFilter,
  setGeneralFilter,
  setRangeFilter,
  setColumnsFilter,
  setScaleVisualizaTionFilter,
  setVisualizationAndColorsFilter,
  setCollapsedFilter
} from './defaultView/filters';
import { checkForChanges } from '../../../hooks/useUnsavedElementsAlerter';
import ModalRemoveTaskLookahead from '../../../components/ModalRemoveTaskLookahead/ModalRemoveTaskLookahead';
import ModalMessage from '../../../components/ExportablePDFMessage/ModalMessage';

import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../../analytics/implements/totango';
import { getSignedUser } from '../../../utils/userUtils';
import { earlyAccessCriticalPath } from '../../../utils/earlyAccessCriticalPath';
import { cleanColumnsIssues } from './viewUtils';
const showModalWhenDeleteActivies = false; // just in case that we need to back to modal

message.config({
  maxCount: 1
});

const descriptionColumn = {
  name: 'description',
  label: 'Descripción',
  span: 3,
  editor: descriptionEditor,
  offset: 0,
  align: 'left',
  ignore_as_column: true,
  template: function (activity) {
    return `<div>${activity.description}</div>`;
  },
  data_type: 'string',
  width: 200,
  groupable: false,
  hide: false,
  orderable: true,
  orderable_switch: ['A → Z', 'Z → A'],
  filterable: true,
  resize: true,
  editable: true
};

let baselineSaveInProcess = false;
let linksToDeleteStack = [];
function GanttChartView(props) {
  const {
    permission,
    t,
    modalCircular,
    modalDelMass,
    modalDupMass,
    modalDupConfirmMass,
    modalDupMassAddActivities,
    selectedActivities,
    callbackModalDupConfirmMass,
    drawer,
    isGanttLoading
  } = props;
  const [isFirstView, setIsFirstView] = useState(true);
  const [heightGantt, setHeigthGantt] = useState('calc(100vh - 186px)');
  const [sector, setSector] =
    useState(
      null
    ); /** Allows to know at view which sector is necessary to load */
  const [ganttId, setGanttId] =
    useState(
      null
    ); /** Allows to know at view which gantt is necessary to reference */
  const { sectorId } =
    useParams(); /** Allows view to catch params at URL through react router dom lib */
  const [dataToLoad, setDataToLoad] = useState({
    data: [],
    links: []
  }); /** Allows view to give data to load for Gantt Component */
  const [loading, setLoading] =
    useState(true); /** Allows view to set loading screen */
  const [tasksToDelete, setTasksToDelete] = useState([]);
  const [linksToDelete, setLinksToDelete] = useState([]);
  const [activityModificationSelected, setActivityModificationSelected] =
    useState(false);
  const [gantt, setGantt] = useState(null);
  const [children, setChildren] = useState([]);
  const [modalMetadata, setModalMetadata] = useState({
    visible: false,
    onOkPayload: () => {
      console.log('On ok payload');
    },
    onCancelPayload: () => {
      console.log('On Cancel payload');
    },
    title: 'Modal',
    content: <div>Modal</div>,
    cancelText: 'Cancel',
    okText: 'Ok'
  });
  const [modalMetadataDelete, setModalMetadataDelete] = useState({
    visible: false,
    onOkPayload: () => {
      console.log('On ok payload');
    },
    onCancelPayload: () => {
      console.log('On Cancel payload');
    },
    title: 'Modal',
    isRunning: false,
    content: <div>Modal</div>,
    cancelText: 'Cancel',
    okText: 'Ok'
  });
  const [selectedOption, setSelectedOption] = useState([]);
  const [allCalendars, setAllCalendars] = useState([]);
  const [calendars, setCalendars] = useState(null);
  const [toDisable, setToDisable] = useState([]);
  const [deletedUnsavedActivities, setDeletedUnsavedActivities] = useState([]);
  const [deletedUnsavedLinks, setDeletedUnsavedLinks] = useState([]);
  const [deletedUnsavedActivitiesaAux, setDeletedUnsavedActivitiesAux] =
    useState([]);
  const [allBaselines, setAllBaselines] = useState([]);
  const [allScheduleUpdates, setAllScheduleUpdates] = useState([]);
  const [ganttLoaded, setGanttLoaded] = useState(false);
  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  const sectorObject = projectState.allSectors.find(
    (e) => e.id == projectState.sectorSelected
  );
  const ganttState = useSelector((state) => state.ganttState);
  const defaultZoomLevel = useSelector(getZoomLevel);
  const generalFilters = useSelector(getGeneralFilters);
  const filterOrder = useSelector(getFilterOrder);
  const defaultRange = useSelector(getDateFilter);
  const columnsFilter = useSelector(getColumnsFilter);
  const defaultVisualizationFilter = useSelector(getVisualizationFilter);
  const defaultColorsFilter = useSelector(getColorsFilters);
  const defaultCollapsedFilter = useSelector(getCollapsedFilter);
  const defaultViewTemplate = useSelector(defaultView);

  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [saveBaselineModal, setSaveBaselineModal] = useState({
    visible: false,
    title: '',
    name: '',
    description: '',
    toSaveOption: 1
  });
  const [saveBaselineModalAuto, setSaveBaselineModalAuto] = useState({
    visible: false,
    title: '',
    name: '',
    description: '',
    toSaveOption: 4
  });
  const [allBaselinesModal, setAllBaselinesModal] = useState({
    visible: false,
    title: ''
  });

  const [allScheduleUpdatesModal, setAllScheduleUpdatesModal] = useState({
    visible: false,
    title: ''
  });
  const [saveScheduleUpdatesModal, setSaveScheduleUpdatesModal] = useState({
    visible: false,
    update_name: '',
    comment: '',
    toSaveOption: 1
  });

  const [tableConfig, setTableConfig] = useState([]);
  const [tableConfigLightView, setTableConfigLightView] = useState([]);
  const [tableUpdated, setTableUpdated] = useState(false);
  const [tableUpdatedLigth, setTableUpdatedLigth] = useState(false);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  /** Array with users that belongs to the same sector that this master plan */
  const [toSelectResponsables, setToSelectResponsables] = useState([]);
  const [toSelectTags, setToSelectTags] = useState([]);
  const [subContracts, setSubcontracts] = useState([]);
  const [reloadSubsArray, setReloadSubsArray] = useState(true);
  const [visibleFormSubcontract, setVisibleFormSubcontract] = useState(false);
  const [visibleFormTags, setVisibleFormTags] = useState(false);
  const [reloadTagsArray, setReloadTagsArray] = useState(true);
  const [allpoinstbase, setAllpoinstbase] = useState([]);
  const [alltask, setAlltask] = useState([]);

  const [autoscheduling, setAutoscheduling] = useState(false);
  const [autoschedulingVisual, setAutoschedulingVisual] = useState(true);

  const [ganttShouldRender, setGanttShouldRender] = useState(false);

  const [zoomLevel, setZoomLevel] = useState(null);
  /** set hour min y max */
  const [customHour, setCustomHour] = useState({ startHour: '', endHour: '' });
  const [saveProcessActive, setSaveProcessActive] = useState(false);
  const [showBackUpModal, setShowBackUpModal] = useState(false);
  const { currentSector, currentProject, currentCompany } =
    getSectorCompanyAndProject();
  const [isShowModalPDF, setIsShowModalPDF] = useState(false);

  const [isShowModalRemoveTaskLookahead, setIsShowModalRemoveTaskLookahead] =
    useState(false);
  const [dataModalRemoveTaskLookahead, setDataModalRemoveTaskLookahead] =
    useState({
      t: null,
      task: null,
      parent: null,
      parentProplannerId: null
    });

  /** use effect to set hours according shifts */
  useEffect(() => {
    if (!calendars) return;
    if (Object.keys(calendars).length !== 0) {
      const calendarDefault =
        calendars && calendars.find((el) => el.is_default);
      if (calendarDefault) {
        const shifts = calendarDefault.shifts;
        const shiftOrdered = shifts.sort(dynamicSort('correlative_id', true));

        /** get shifts */
        const firstShift = shiftOrdered[0].shift_string;
        const lastShift = shiftOrdered[shiftOrdered.length - 1].shift_string;

        /** calculate ini */
        const shiftArr = firstShift.split('-');
        const firstHourArr = shiftArr[0].split(',');

        /** calculate end */
        const shiftArrEnd = lastShift.split('-');
        const lastHourArr = shiftArrEnd[1].split(',');

        /** calculate min hour of the last shift */
        let lastHourMin = 99;
        firstHourArr.map((el) => {
          if (parseInt(el) < parseInt(lastHourMin)) {
            lastHourMin = el;
          }
        });

        /** calculate max hour of the last shift */
        let lastHourMax = 0;
        lastHourArr.map((el) => {
          if (parseInt(el) > parseInt(lastHourMax)) {
            lastHourMax = el;
          }
        });

        if (lastHourMin && lastHourMax) {
          setCustomHour({
            ...customHour,
            startHour: lastHourMin,
            endHour: lastHourMax
          });
        }
      }
    }
    setCustomHourFn(calendars, customHour, setCustomHour);
  }, [calendars]);

  useEffect(() => {
    const showLoader = saveProcessActive || autoschedulingVisual || loading;
    if (showLoader) {
      window.loader.show();
      return;
    }
    window.loader.hide();
  }, [saveProcessActive, autoschedulingVisual, loading]);

  const validateTasksDelete = () => {
    if (deletedUnsavedActivities.length < 1) return;
    const updateTaskToDelete = deletedUnsavedActivities.filter((task) => {
      const { id } = task;
      const isExists = gantt.isTaskExists(id);
      return !isExists;
    });

    if (updateTaskToDelete.length !== deletedUnsavedActivities.length)
      setDeletedUnsavedActivities(updateTaskToDelete);
  };

  const validateLinksDelete = () => {
    if (deletedUnsavedLinks.length < 1) return;
    const updateLinkToDelete = deletedUnsavedLinks.filter((link) => {
      const { id } = link;
      const isExists = gantt.isLinkExists(id);
      return !isExists;
    });

    if (updateLinkToDelete.length !== deletedUnsavedLinks.length)
      setDeletedUnsavedLinks(updateLinkToDelete);
  };

  useEffect(() => {
    if (gantt) {
      validateTasksDelete();
      validateLinksDelete();
      gantt.deletedUnsavedActivities = deletedUnsavedActivities;
      gantt.deletedUnsavedLinks = deletedUnsavedLinks;
    }
  });

  /** This effect handles events for keep fitlering working fine, it first group, then order and at last filter */
  useEffect(() => {
    dispatch(ganttActions.notifyLookaheadUpdateGroup());

    setTimeout(() => {
      dispatch(ganttActions.notifyLookaheadUpdateOrder());
    }, 50);

    setTimeout(() => {
      dispatch(ganttActions.notifyLookaheadUpdateFilter());
    }, 100);
  }, [ganttState.notifyChange]);

  /** This effect handles date range filter */
  useEffect(() => {
    /** Set default dates as today with 2 weeks forward */
    let start = new Date();
    let end = new Date();
    start.setDate(start.getDate() - 100);
    end.setDate(end.getDate() + 30);
    start = start.toISOString().split('T')[0].split('-').join('/');
    end = end.toISOString().split('T')[0].split('-').join('/');

    /** Then using set state hook load this vars, to let virtual dom load datepickers with correct dates */
    setDateRange({
      start,
      end
    });
    /** Loads activities with their tasks */
  }, [projectState.sectorSelected]);

  /** This effect allow heavy gantt processes to finish without freezing the web browser */
  useEffect(() => {
    if (!gantt) {
      return;
    }
    /** shouldAutoscheduleSync is a flag that tells to react component when should autoschedule gantt without freezing memory */
    if (gantt.shouldAutoscheduleSync && autoschedulingVisual) {
      setTimeout(() => {
        if (!gantt.shouldAutoscheduleSync) return;
        gantt.autoSchedule();
        gantt.shouldAutoscheduleSync = false;
      }, 50);

      setTimeout(() => {
        setAutoschedulingVisual(false);
      }, 150);
    }

    updateFlagSector(gantt);
  }, [autoschedulingVisual]);

  /** This effect handle autoscheduling state, which is true once initial gantt data is loaded */
  useEffect(() => {
    if (autoscheduling) {
      if (!gantt.initAlreadyDid) {
        setAutoscheduling(true);
        window.to_use_react_gantt &&
          window.to_use_react_gantt.getTaskByIndex(0) &&
          window.to_use_react_gantt.updateTask(
            window.to_use_react_gantt.getTaskByIndex(0).id
          );
        setTimeout(() => {
          window.to_use_react_gantt &&
            window.to_use_react_gantt.getTaskByIndex(0) &&
            window.to_use_react_gantt.updateTask(
              window.to_use_react_gantt.getTaskByIndex(0).id
            );
          gantt.autoSchedule();
        }, 300);
        gantt.initAlreadyDid = true;
      }
    }
  }, [autoscheduling]);

  /** This effect handles the redux config with columns for gantt */
  useEffect(() => {
    if (gantt) {
      setTimeout(() => {
        const { gridTable } = ganttState.userPreferenceTableGantt;
        const copyOfTableData = [
          ...table_grid_columns(gantt, t, masterplanPermissions)
        ];

        copyOfTableData.map((column) => {
          if (column.name != 'buttons') {
            if (
              column.data_type.includes('/icon') ||
              column.data_type.includes('/string')
            ) {
              column.label = t('tables.gantt.' + column.name + '.label');
              if (!column.data_type.includes('array/string')) {
                column.from_values.map((option) => {
                  option.label = t(
                    'tables.gantt.' + column.name + '.options.' + option.value
                  );
                });
              }
            } else {
              column.label = t('tables.gantt.' + column.name);
            }
          }
        });
        setTableConfigLightView(copyOfTableData);
        setTableUpdatedLigth(true);

        if (!gridTable?.length) {
          dispatch(
            ganttActions.setUserPreferenceTableGantt({
              table: copyOfTableData,
              ganttChart: null
            })
          );
        }
      }, 500);
    }
  }, [gantt]);

  /** This effect just updates appcues instance */
  useEffect(() => {
    window.Appcues.page();
  });

  /** Effect to load translation to table declaration file */
  useEffect(() => {
    dispatch(setGanttLoading(true));
    setTimeout(() => {
      const { gridTable } = ganttState.userPreferenceTableGantt;
      const columnsByDefault = table_grid_columns(
        null,
        t,
        masterplanPermissions
      );

      let copyOfTableData =
        !ganttState.userPreferenceTableGantt || !gridTable || !gridTable.length
          ? [...columnsByDefault]
          : gridTable;

      const cleanedColumnsToLoadView = cleanColumnsIssues(copyOfTableData);
      let flag = false;
      /** CASE WHEN ANY COLUMNS WAS REMOVED. Check if filterable was changed */
      if (cleanedColumnsToLoadView) {
        flag = cleanedColumnsToLoadView.some((el, idx) => {
          if (columnsByDefault[idx]) {
            return el.filterable !== columnsByDefault[idx].filterable;
          }
          return false;
        });
      }

      // console.log('copy', copyOfTableData)
      copyOfTableData = cleanedColumnsToLoadView;

      copyOfTableData.map((column, idx) => {
        if (flag) {
          column.filterable = columnsByDefault[idx]?.filterable;
        }
        if (column.name != 'buttons') {
          if (
            column.data_type.includes('/icon') ||
            column.data_type.includes('/string')
          ) {
            column.label = t('tables.gantt.' + column.name + '.label');
            if (!column.data_type.includes('array/string')) {
              column.from_values.map((option) => {
                option.label = t(
                  'tables.gantt.' + column.name + '.options.' + option.value
                );
              });
            }
          } else {
            column.label = t('tables.gantt.' + column.name);
          }
        }
      });

      // clean redux ref with the cleaned columns
      dispatch(
        ganttActions.setUserPreferenceTableGantt({
          table: copyOfTableData,
          ganttChart: null
        })
      );
      setTableConfig(copyOfTableData);
      setTableUpdated(true);
      dispatch(
        projectActions.setProps({
          ...projectState.props,
          status_criteria: 'Baseline'
        })
      );
    }, 500);
  }, []);

  /** This function checks if clear stack is updated with the view status (undo/redo cleanup) */
  useEffect(() => {
    if (gantt) {
      gantt.clearStacks = !loading;
    }
  }, [loading]);

  /** This efect detects when a tag was added, and updates it state */
  useEffect(() => {
    if (tableConfig.length) {
      if (reloadTagsArray) {
        getTags();
      }
    }
  }, [reloadTagsArray]);

  /** This effect detects when a sub was added, and updates it state */
  useEffect(() => {
    if (tableConfig.length) {
      if (reloadSubsArray) {
        getSubs();
      }
    }
  }, [reloadSubsArray]);

  /** This effect loads initial gantt columns data for filters, and combines with redux logic */
  useEffect(() => {
    if (tableUpdated && tableConfig.length) {
      const callback = (data) => {
        data.route(props.history);
      };
      EventEmitter.on('changeMainRoute', callback);

      for (let i = 0; i < tableConfig.length; i++) {
        if (!tableConfig[i].ignore_as_column) {
          children.push({
            label: tableConfig[i].label,
            value: tableConfig[i].name
          });
        }
      }

      /** This section code deal when redux has a state, turning on those ones, and disabling which not */
      if (ganttState.activatedColumns.length) {
        const activated = ganttState.activatedColumns.map((op) => {
          const col = tableConfig.filter((c) => c.name == op);
          if (col.length != 0) {
            return { label: col[0].label, value: col[0].name };
          }
        });

        const all = [];

        tableConfig.map((el) => {
          if (!el.ignore_as_column) {
            all.push(el.name);
          }
        });

        /** difference between arrays (all/active) to disable */
        const colsToDisable = all.filter(
          (x) => !ganttState.activatedColumns.includes(x)
        );
        const colsToEnable = all.filter((x) =>
          ganttState.activatedColumns.includes(x)
        );
        setToDisable(colsToDisable);

        setSelectedOption(activated);

        /** This code deals with no redux state, and use default config from gantt-columns.js */
      } else {
        const names = [];
        const activated = tableConfig.map((col) => {
          if (!col.hide) {
            names.push(col.name);
            return { label: col.label, value: col.name };
          }
        });

        dispatch(ganttActions.setActivatedColumns(names));
        setSelectedOption(activated);
      }

      return () => {
        EventEmitter.removeListener('changeMainRoute', callback);
      };
    }
  }, [tableUpdated]);

  /** This effect allows re load sector data when it changes at selector */
  useEffect(() => {
    if (tableUpdated && tableConfig.length) {
      loadSectorActivities();
    }
  }, [projectState.sectorSelected, tableUpdated]);

  /** This function checks when initial data is already at view state, and execute load calendars */
  useEffect(() => {
    if (calendars && gantt && ganttLoaded) {
      loadCalendars();
    }
  }, [calendars, gantt, ganttLoaded]);

  useEffect(() => {
    if (!loading) {
      const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
      const currentProject = JSON.parse(
        sessionStorage.getItem('currentProject')
      );
      const currentCompany = JSON.parse(sessionStorage.getItem('company'));
      const currentScale = JSON.parse(localStorage.getItem('scale'));

      const viewOptionsSelected = [];
      if (ganttState?.visualizationConfig?.areLinksVisible)
        viewOptionsSelected.push('Show Links');
      if (ganttState?.visualizationConfig?.areSlackVisible)
        viewOptionsSelected.push('Show Float');
      if (ganttState?.visualizationConfig?.areNumtasksVisible)
        viewOptionsSelected.push('Show Number of task');
      if (ganttState?.visualizationConfig?.areBaselineVisible)
        viewOptionsSelected.push('Show Baselines');
      if (ganttState?.visualizationConfig?.isTodaylineVisible)
        viewOptionsSelected.push('Show Todayline');
      if (ganttState?.visualizationConfig?.areSubmittalsVisible)
        viewOptionsSelected.push('Show Submittals');

      setTimeout(() => {
        window.addEventListener('beforeunload', unsavedChangesListener);

        trackingEvent(
          'schedule_visualization',
          {
            visible_columns_activated: ganttState?.activatedColumns,
            zoom_scale_selected: currentScale || 'years',
            view_options_selected: viewOptionsSelected,
            bar_color_option_active:
              ganttState?.visualizationConfig?.colorSchemeType,
            date_format_selected: currentSector?.dateFormat,
            hours_per_day_displayed: currentSector?.hoursPerDay,
            hours_per_week_displayed: currentSector?.hoursPerWeek,
            number_of_activities: gantt?.getTaskCount(),
            company_name: currentCompany?.name,
            company_id: currentCompany?.id,
            project_name: currentProject?.name,
            project_id: currentProject?.id,
            stage_name: currentSector?.name,
            stage_id: currentSector?.id
          },
          AMPLITUDE_SERVICE
        );
      }, 1000);
    }
  }, [loading]);

  useEffect(() => {
    window.stackActivitiesMotherSave = new Set();

    return () => (window.stackActivitiesMotherSave = new Set());
  }, []);

  useEffect(() => {
    if (gantt) {
      gantt.showModalRemoveTaskLookahead = openModalRemoveTaskLookahead;
      gantt.showModalPDF = openModalPDF;
    }
  }, [gantt]);

  /**
   * Functions
   */

  const refreshBaselinesStates = async (shouldSaveGanttData = true) => {
    baselineSaveInProcess = true;
    await refreshCalendars(projectState.sectorSelected, setCalendars);
    const sectorResActivitybaseVersion =
      await sectorService.showActivityVersions(projectState.sectorSelected);
    const sectorResActivity = await sectorService.showActivityPoints(
      projectState.sectorSelected
    );

    /** filter activities added when one or more activities were deleted  */
    const newSectorResActivity = sectorResActivity.baselines.filter(
      (el) => el.activityId
    );

    gantt &&
      newSectorResActivity.forEach((newAc) => {
        const oldRef = gantt.getTask(newAc.activityDhtmlxId);
        if (oldRef instanceof Object) {
          oldRef.baseline_points = [newAc];
        }
      });

    /** remove deleted activities  */
    updateTasksDeletedRender(gantt.deletedUnsavedActivitiesAux);

    await goCalculatePonderators(true);
    gantt.updateExpectedGlobal && gantt.updateExpectedGlobal(gantt);

    setAllBaselines(sectorResActivitybaseVersion.baselines);
    setTimeout(() => {
      baselineSaveInProcess = false;
      if (shouldSaveGanttData) {
        saveHandler();
      }
      gantt.render();
      if (window.savingBaselineOnProcess) {
        window.savingBaselineOnProcess = false;
        setAutoschedulingVisual(false);
      }
    }, 2000);
  };

  /** Main state loading function for all view behaviours */
  async function loadSectorActivities() {
    setGantt(null);
    /** Active an loading screen */

    if (sectorId != projectState.sectorSelected) {
      props.history.push('/masterplan');
    }

    setLoading(true);

    // Load Schedule Updates by sector
    const scheduleUpdates = await scheduleUpdatesService.showBySector(
      projectState.sectorSelected
    );
    const updateorder = scheduleUpdates.updates?.sort((a, b) => b.id - a.id); // b - a for reverse sort
    setAllScheduleUpdates(updateorder);

    /** Fetch to get sector from URL param */
    const sectorResActivity = await sectorService.showActivity(
      projectState.sectorSelected
    );
    const sectorResActivityRelation = await sectorService.showActivityRelation(
      projectState.sectorSelected
    );

    const responsables = await userService.getBySector(
      projectState.sectorSelected
    );
    const tags = await tagService.showByProject(projectState.projectSelected);
    await getSubs();
    if (responsables && tags) {
      setToSelectResponsables(responsables.users);
      setToSelectTags(tags.tag);
    }
    const sectorRes = await refreshCalendars(
      projectState.sectorSelected,
      setCalendars
    );
    const { calendars } = sectorRes.sector;

    const sectorGantts = sectorRes.sector.gantts;
    setSector(sectorRes.sector);

    /** Check if sector has a gantt, and if it is, it will select last one */
    if (sectorGantts.length != 0) {
      setGanttId(sectorGantts[sectorGantts.length - 1].id);
    } else {
      props.history.push('/masterplan');
      return;
    }

    /** If there is no calendar, it will be sent to create one */
    if (!calendars.length) {
      trackingEvent(
        'calendar_page_visualization',
        {
          ...getBasicAmplitudEventProperties(),
          event_source: 'scratch_button'
        },
        AMPLITUDE_SERVICE
      );
      props.history.push(`${match.url.split('ganttchart')[0]}calendars`);
      message.warning(t('need_calendar_gantt'), 5);
    }

    /** Adapter from ProPlanner Data Structure to Gantt DHTMLX Task Data Structure */
    let data = sectorResActivity.sector.map((activity) =>
      convertActivityToGanttTask(activity)
    );
    window.backup_data_saving_use = cloneDeep(data);
    window.backup_data_saving_use = window.backup_data_saving_use.map((ac) => {
      ac.end_date = new Date(ac.non_parsed_original_end_date);
      return ac;
    });

    /** Same with Gantt Links */
    let links = sectorResActivityRelation.sector.map((activityrelation) =>
      convertActivityRelationToLink(activityrelation)
    );

    /** Default data for new gantts */
    if (!data.length && !links.length) {
      const calendarId = calendars.length ? calendars[0].id : null;
      const tasks = cloneDeep(defaultData).data.map((item) => {
        item.calendarId = calendarId;
        item.non_parsed_original_start_date = item.start_date;
        item.non_parsed_original_duration = item.duration;
        item.duration_milestone_bugged = item.duration;
        return item;
      });

      data = tasks;
      links = cloneDeep(defaultData).links;

      if (calendars.length) {
        message.info(t('default_activities_gantt'), 5);
      }
    }

    /** Set states to load Gantt Component on View */
    setDataToLoad({ data: data, links: links });

    /** get last correlative id after the gantt is loaded */
    const findLastUnique = ganttState?.lastUniqueCorrelativeIds.find(
      (el) => parseInt(el.sectorId) === parseInt(sectorId)
    );

    if (findLastUnique === undefined) {
      const nextUIDAtLoad = getNextUIDAtLoad(data);
      const newData = [
        ...ganttState.lastUniqueCorrelativeIds,
        {
          sectorId: sectorId,
          lastUniqueCorrelativeId: parseInt(nextUIDAtLoad)
        }
      ];
      dispatch(ganttActions.setLastUniqueCorrelativeIds(newData));
    }

    const newGantt = getNewGanttLibInstance(sectorRes.sector);
    setGantt(newGantt);
    props.setParentGantt(newGantt);
    setLoading(false);
    const sectorResActivitybasePoinst = await sectorService.showActivityPoints(
      projectState.sectorSelected
    );
    setAllpoinstbase(sectorResActivitybasePoinst.baselines);
    const sectorResActivityTaskCount =
      await sectorService.showActivityTaskCount(projectState.sectorSelected);
    // setAllBaselines(sectorResActivitybaseVersion.baselines)
    setAlltask(
      sectorResActivityTaskCount.activity_tasks.map((act) => ({
        ...act,
        custom_sucessors: '',
        custom_precedessors: ''
      }))
    );
    const sectorResActivitybaseVersion =
      await sectorService.showActivityVersions(projectState.sectorSelected);
    setAllBaselines(sectorResActivitybaseVersion.baselines);
  }

  /**
   * This function get new instance from dhtmlx lib, and updates it sector date format config, as also set options for checkbox
   * @param {*} sector Sector object which is actually used
   * @returns Returns an DHTMLX gantt lib object
   */
  const getNewGanttLibInstance = (sector) => {
    const newGantt = GanttDhtmlx.getGanttInstance();
    newGantt.criteriaStatusOptions = ['Baseline', 'Follow'];
    newGantt.status_criteria = 'Baseline';
    newGantt.dateFormatOptions = [
      'DD/MM/YY',
      'MM/DD/YY',
      'DD/MM/YYYY',
      'MM/DD/YYYY',
      'DD/MM/YY hh:mm',
      'MM/DD/YY hh:mm'
    ];
    newGantt.hashMoment2Date = {
      'DD/MM/YY': '%d/%m/%y',
      'MM/DD/YY': '%m/%d/%y',
      'DD/MM/YYYY': '%d/%m/%Y',
      'MM/DD/YYYY': '%m/%d/%Y',
      'DD/MM/YY hh:mm': '%d/%m/%y %H:%i',
      'MM/DD/YY hh:mm': '%m/%d/%y %H:%i'
    };
    newGantt.currentDateFormat =
      sector.dateFormat || (t('lang') === 'en' ? 'MM/DD/YY' : 'DD/MM/YY');
    return newGantt;
  };
  useEffect(() => {
    const fetchData = async () => {
      if (allpoinstbase.length > 0) {
        dataToLoad.data.map((data) => {
          const baselinePoints = allpoinstbase.filter(
            ({ activityId }) => activityId === data.proplannerId
          );
          data.baseline_points = baselinePoints;
        });

        if (sector.update_ponderators_masterplan) {
          message.info(t('change_criteria_alert'), 3);
          await goCalculatePonderators(true);
          updateFlagSector(gantt);
        }
        // requested goSaveHangle because of work hours change
        if (sector.update_activities_work_hours) {
          requestGoSaveHandler();
        }
      }
    };

    fetchData();
  }, [allpoinstbase]);

  const requestGoSaveHandler = async () => {
    setSaveProcessActive(true);
    await goSaveHandler();
    setSaveProcessActive(false);
    activeSectorFlag('update_activities_work_hours', false);
  };

  useEffect(() => {
    if (alltask?.length && alltask.length > 0) {
      dataToLoad.data.map((data) => {
        const ats = alltask.find((p) => p.id === data.proplannerId);
        if (ats) {
          data.tasks = ats.tasks;
        }
      });
    }
  }, [alltask]);

  useEffect(() => {
    if (calendars && gantt && ganttLoaded) {
      loadCalendars();
    }
    dispatch(ganttActions.setSelectedActivities([]));
  }, [calendars, gantt, ganttLoaded]);

  /** Updates subs state */
  const getSubs = async () => {
    const subcontractsGet = await subContractService.getByProject(
      projectState.projectSelected
    );
    if (subcontractsGet) {
      const typeFrom = tableConfig.find((col) => col.name == 'subcontractId');
      if (!typeFrom) {
        dispatch(
          ganttActions.setUserPreferenceTableGantt({
            table: table_grid_columns(gantt, t, masterplanPermissions),
            ganttChart: gantt
          })
        );
        window.location.reload();
      }
      typeFrom.from_values = [];
      const subContractsOrdered = subcontractsGet.subcontracts.sort(
        dynamicSort('name')
      );
      subContractsOrdered.map((type, index) => {
        typeFrom.from_values.push({
          value: type.id,
          label: type.name,
          weigth: index + 1
        });
      });
      setTableConfig([...tableConfig]);
      setSubcontracts(subContractsOrdered);
      if (gantt) {
        gantt.subContracts = subContractsOrdered;
        const idToAssignLastSubcontract = gantt.toHardAssignSubcontract;
        if (!idToAssignLastSubcontract) return;

        if (gantt.isTaskExists(idToAssignLastSubcontract)) {
          const objectTask = gantt.getTask(idToAssignLastSubcontract);
          let lastId = 0;
          gantt.subContracts.forEach((s) => {
            if (s.id > lastId) lastId = s.id;
          });
          objectTask.subcontractId = lastId;
          trackingEvent(
            'activity_company_entry',
            {
              ...getBasicAmplitudEventProperties(),
              activity_name: objectTask?.text,
              activity_id: objectTask?.id,
              activity_index: objectTask?.correlative_id
            },
            AMPLITUDE_SERVICE
          );
        }

        delete gantt.toHardAssignSubcontract;
        gantt.changeVisualizationOption();
        gantt.render();
      }
    }
  };

  /** Updates tags state */
  const getTags = async () => {
    const tags = await tagService.showByProject(projectState.projectSelected);
    if (tags) {
      setToSelectTags(tags.tag);
      if (gantt) {
        gantt.toSelectTags = tags.tag;
        const idToAssignLastTag = gantt.toHardAssignTag;
        if (!idToAssignLastTag) return;
        const objectTask = gantt.getTask(idToAssignLastTag);
        let lastId = 0;
        let lastTagObject;
        gantt.toSelectTags.forEach((tag) => {
          if (tag.id > lastId) {
            lastId = tag.id;
            lastTagObject = tag;
          }
        });
        objectTask.tags.push(lastTagObject);
        trackingEvent(
          'activity_tag_entry',
          {
            ...getBasicAmplitudEventProperties(),
            activity_name: objectTask?.text,
            activity_id: objectTask?.id,
            activity_index: objectTask?.correlative_id
          },
          AMPLITUDE_SERVICE
        );
        delete gantt.toHardAssignTag;
        gantt.changeVisualizationOption();
        gantt.render();
      }
    }
  };

  /**
   * This function hanldes legacy mixed nomenclature from v1 and transforms into a new version cal
   * @param {*} legacy_working_time Legacy array of numbers or boolean elements
   * @returns normalized array with working days calendar info
   */
  const getWorkingDaysFromCalendar = (legacy_working_time) => {
    const ganttDhtmlxWorktime = legacy_working_time.map((workingtime) => {
      switch (workingtime) {
        case true:
          return 1;
        case false:
          return 0;
        case 1:
          return 1;
        case 0:
          return 0;
      }
    });

    return ganttDhtmlxWorktime;
  };

  /**
   * This functions takes string DB pure shifts, and transforms it into an array
   * @param {*} shift String to be transformed into array of shifts
   * @returns Array with shifts and their worktime
   */
  const transformShiftToArray = (shift) => {
    const initArray = shift.split('-')[0].split(',');
    const endArray = shift.split('-')[1].split(',');
    const newArrayShift = [];

    initArray.map((el, index) => {
      const newShift = initArray[index] + '-' + endArray[index];
      newArrayShift.push(newShift);
    });

    return newArrayShift;
  };

  /**
   * This function goes to each holiday for a calendar and loads it, into a gantt
   * calendar object (lib one not pp db pure data)
   * @param {*} holidays Array with holidays and their exceptions (if it has)
   * @param {*} calendar calendar object loaded at gantt dhtmlx lib
   */
  const setHolidaysForCalendar = (holidays, calendar) => {
    const task_master = cloneDeep(gantt.getTaskByIndex(0));
    let start_master = null;
    let end_master = null;
    if (task_master) {
      task_master.start_date.setFullYear(
        task_master.start_date.getFullYear() - 1
      );
      task_master.end_date.setFullYear(task_master.end_date.getFullYear() + 1);
      start_master = task_master.start_date;
      end_master = task_master.end_date;
    }

    holidays.map((holiday) => {
      // We cut the ISO format to YY-MM-DD

      const holidayInitString = holiday.from_date.split('T')[0];
      const holidayEndString = holiday.to_date.split('T')[0];
      //  Parse string dates to JS Date object
      const holidayInit = gantt.date.parseDate(holidayInitString, 'xml_date');
      const holidayEnd = gantt.date.parseDate(holidayEndString, 'xml_date');
      // holidayEnd.setDate(holidayEnd.getDate() + 1)

      let currentHoliday = holidayInit;
      if (calendar) {
        if (holidayEnd >= holidayInit) {
          while (true) {
            if (holiday.every_type) {
              // Define shift by flag workable
              let hoursShift = [];
              if (holiday.workable) {
                holiday.shifts
                  .sort((a, b) =>
                    parseInt(a.correlative_id - parseInt(b.correlative_id))
                  )
                  .map((shift) => {
                    if (shift.shift_string != 'false-false') {
                      hoursShift.push(shift.shift_string);
                    }
                  });
              } else {
                hoursShift = false;
              }

              if (start_master && end_master) {
                if (
                  currentHoliday >= start_master ||
                  currentHoliday <= end_master
                ) {
                  calendar.setWorkTime({
                    date: currentHoliday,
                    hours: hoursShift
                  });
                }
              } else {
                calendar.setWorkTime({
                  date: currentHoliday,
                  hours: hoursShift
                });
              }

              if (holiday.every_type == 'YEARLY') {
                currentHoliday = new Date(
                  currentHoliday.setFullYear(currentHoliday.getFullYear() + 1)
                );
              }
              if (holiday.every_type == 'DAILY') {
                currentHoliday = new Date(
                  currentHoliday.setDate(currentHoliday.getDate() + 1)
                );
              }
              if (holiday.every_type == 'WEEKLY') {
                currentHoliday = new Date(
                  currentHoliday.setDate(currentHoliday.getDate() + 7)
                );
              }
              if (holiday.every_type == 'MONTHLY') {
                currentHoliday = new Date(
                  currentHoliday.setMonth(currentHoliday.getMonth() + 1)
                );
              }
              if (currentHoliday > holidayEnd) break;
            } else {
              // Define shift by flag workable
              let hoursShift = [];
              if (holiday.workable) {
                holiday.shifts
                  .sort((a, b) =>
                    parseInt(a.correlative_id - parseInt(b.correlative_id))
                  )
                  .map((shift) => {
                    if (shift.shift_string != 'false-false') {
                      hoursShift.push(shift.shift_string);
                    }
                  });
              } else {
                hoursShift = false;
              }

              if (start_master && end_master) {
                if (
                  currentHoliday >= start_master ||
                  currentHoliday <= end_master
                ) {
                  calendar.setWorkTime({
                    date: currentHoliday,
                    hours: hoursShift
                  });
                }
              } else {
                calendar.setWorkTime({
                  date: currentHoliday,
                  hours: hoursShift
                });
              }

              const years = diff_years(holidayEnd, holidayInit);
              if (years < 1) {
                currentHoliday = new Date(
                  currentHoliday.setDate(currentHoliday.getDate() + 1)
                );
              } else {
                currentHoliday = new Date(
                  currentHoliday.setFullYear(currentHoliday.getFullYear() + 1)
                );
              }

              if (currentHoliday > holidayEnd) break;
            }
          }
        }
      }
    });
  };

  /**
   * This functions compares two dates to check if it is at least one year
   * @param {*} dt2 date 2
   * @param {*} dt1 date 1
   * @returns Years amount
   */
  function diff_years(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(Math.round(diff / 365.25));
  }

  /**
   * This function is executed, once API finished giving data,
   * it load calendars to gantt API and correct whole dates about loaded calendars
   */
  const loadDataForCalendar = async () => {
    let hasDefaultCalendar = false;

    const calendarSelectorOptions = [];
    const calendarForFilters = [];
    gantt.getCalendars().map((calendar) => {
      gantt.deleteCalendar(calendar.id);
    });

    calendars.map((calendar, index) => {
      const workingDays = calendar.working_days
        .split(',')
        .map((el) => eval(el));
      const shiftsArray = [];
      /** N workingdays integration */
      calendar.shifts
        .sort((a, b) => parseInt(a.correlative_id - parseInt(b.correlative_id)))
        .map((shift) => {
          shiftsArray.push(transformShiftToArray(shift.shift_string));
        });

      /** Load general Gantt worktime (default calendar) */
      const workTime = getWorkingDaysFromCalendar(workingDays);

      /** This code section set the default behaviour for TASK without calendar_id */
      if (calendar.is_default && typeof calendar.id !== 'string') {
        hasDefaultCalendar = true;
        gantt.defaultCalendar = calendar.id;
      }

      /** This code section set the default behaviour for TASK without calendar_id */
      if (calendar.baseDefault) {
        gantt.defaultBaseCalendar = calendar.id;
      }

      const singleShiftPerDay = workTime.map((singleWorktime, index) => {
        if (singleWorktime) {
          const toReturnArray = [];
          shiftsArray.map((nShift) => {
            if (nShift[index] != 'false-false') {
              toReturnArray.push(nShift[index]);
            }
          });
          return toReturnArray;
        }
        return 0;
      });

      /** This code adds the calendar to DHTMLX API */
      const calendarToAdd = {
        id: calendar.id,
        worktime: {
          days: singleShiftPerDay
        }
      };
      if (typeof calendar.id !== 'string') {
        calendarSelectorOptions.push({
          key: calendarToAdd.id,
          label: calendar.name
        });
        calendarForFilters.push({
          value: calendarToAdd.id,
          label: calendar.name
        });
      }
      gantt.addCalendar(calendarToAdd);
      const calendarDhtmlxObject = gantt.getCalendar(calendarToAdd.id);

      /** Load all holidays from calendar */
      const holidays = calendar.exceptiondays;
      setHolidaysForCalendar(holidays, calendarDhtmlxObject);
      /** This function is KEY to keep performance in order (First render initial activities for user, then load calendars, and FINALLY) */
    });

    if (!hasDefaultCalendar) {
      gantt.defaultCalendar = 'global';
    }

    gantt.calendarOptions = calendarSelectorOptions;
    gantt.config.columns.find(
      (col) => col.name == 'calendar_id'
    ).editor.options = calendarSelectorOptions;

    const columnObject = tableConfig.find((col) => col.name == 'calendar_id');
    columnObject.from_values = calendarForFilters;
    setTimeout(() => {
      try {
        !baselineSaveInProcess && gantt.correctWrongDates();
      } catch (e) {
        console.log(e);
      }
    }, 100);
    setTimeout(() => {
      if (!gantt.initAlreadyDid) {
        setAutoscheduling(true);
      }
    }, 200);
    if (permission == 'V') {
      gantt.config.readonly = true;
    }
  };

  /** This effect detects when gantt should
   * be rended, which normally must be when API fetching has finished
   */
  useEffect(() => {
    if (ganttShouldRender) {
      // gantt.adjustGridWidth()
      loadDataForCalendar();
      if (sector.update_duration_for_primavera_for_end_date) {
        dataToLoad.data.map((data) => {
          const calendar = gantt.getCalendar(data.calendar_id);
          if (calendar) {
            const duration = calendar.calculateDuration(
              new Date(data.non_parsed_original_start_date),
              new Date(data.non_parsed_original_end_date)
            );
            data.duration = duration;
            data.duration_milestone_bugged = duration;
            data.for_disable_milestone_duration = duration;
            data.non_parsed_original_duration = duration;
            data.old_duration = duration;
            data.old_duration_registered = duration;
          }
        });
      }
    }
  }, [ganttShouldRender]);

  useEffect(() => {
    function setDefaultView() {
      if (gantt.getGridColumns().length === 0) return;
      if (!gantt.getGridColumns()) return;
      setGeneralFilter(generalFilters, gantt, () => {
        dispatch(setShowedFilters(generalFilters));
      });
      setRangeFilter(defaultRange, gantt, (dateStrings) => {
        dispatch(
          ganttActions.setIsRangeFiltered({ boolean: true, range: dateStrings })
        );
      });
      setColumnsFilter(columnsFilter, handleChange);
      setScaleVisualizaTionFilter(defaultZoomLevel, gantt);
      setVisualizationAndColorsFilter(
        defaultVisualizationFilter,
        defaultColorsFilter,
        gantt
      );
      setOrderFilter(filterOrder, gantt);
      setCollapsedFilter(defaultCollapsedFilter, gantt);
      gantt.refreshData();
      gantt.render();
    }

    function isDefaultViewTemplateEmpty(defaultViewTemplate) {
      if (Object.keys(defaultViewTemplate).length === 0) return true;
    }

    if (isGanttLoading) return;
    if (isDefaultViewTemplateEmpty(defaultViewTemplate)) return;
    if (!gantt) return;

    setDefaultView();
  }, [isGanttLoading, gantt]);

  /**
   * This function is executed when API data fetching has finished,
   * it allows to first parse initial gantt data, then through setGanttShouldRender
   * state function run loadDataForCalendar which is going to load data calendars to the dhtmlx API
   * and will correct whole dates at gantt
   * @param {*} goParse Flag to avoid normal function behaviour
   */
  const loadCalendars = async (goParse = true) => {
    if (goParse) {
      try {
        /** exclude deleted activities if deletedUnsavedActivities exists */
        if (
          deletedUnsavedActivities &&
          Array.isArray(deletedUnsavedActivities) &&
          deletedUnsavedActivities.length
        ) {
          const filterData = dataToLoad.data.filter((act) => {
            const existsActivity = deletedUnsavedActivities.some(
              (actDel) => actDel.id === act.id
            );
            return !existsActivity;
          });
          dataToLoad.data = filterData;
        }
        gantt.legacy_data = dataToLoad;
        dataToLoad.data.forEach((act) => {
          act.custom_sucessors = '';
          act.old_custom_sucessor = '';
          act.custom_predecessors = '';
          act.old_custom_predecessor = '';
        });
        console.log(dataToLoad);
        !baselineSaveInProcess && gantt.parse(dataToLoad);
        /**
         * BUG FIX: Sorting tasks were wrong
         */
        gantt.sort('correlative_id', false);
        if (gantt.getTaskByTime().length != 0) {
          gantt.getGridColumn('buttons').label = t('buttons_label_only');
        }
        gantt.render();
        setGanttShouldRender(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  /**
   * This functions, allow to delete duplicates objects from an array
   * @param {*} arr Array with objects to be cleaned
   * @param {*} comp Element that must be unique in our objects, as example ID
   */
  function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])
      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);
    return unique;
  }

  /**
   * This function deals with the change of multi select.
   * Deals with  deleting, and clicking news cols.
   * @param {*} value Array of values for multi select of Ant Design Framework
   */
  function handleChange(value) {
    if (!value) return;
    setSelectedOption(value);
    value = value.map((v) => v.value);
    /** This if statement allows to check if there is some fields to disable */
    if (value.length < ganttState.activatedColumns.length) {
      /** link for docs https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript */
      const colsToDisable = ganttState.activatedColumns.filter(
        (x) => !value.includes(x)
      );
      colsToDisable.map((colToDisable) => {
        if (gantt.getGridColumn(colToDisable)) {
          gantt.getGridColumn(colToDisable).hide = true;
        }
      });
    }
    /** Then by mapping array, can active the columns indeed and render methods will take effect visually */
    dispatch(ganttActions.setActivatedColumns(value));
    value.map((columnName) => {
      if (gantt.getGridColumn(columnName)) {
        gantt.getGridColumn(columnName).hide = false;
      }
    });
    gantt.render();
    gantt.adjustGridWidth();
    const hasSlackCritical = checkSlackCritical(value);
    if (hasSlackCritical) {
      gantt.autoSchedule();
    }
    updateDefaultView();
  }

  function checkSlackCritical(array) {
    const columnsSet = new Set(
      columnsWhoNeedToReloadCritical().map((column) => column.name)
    );
    return array.some((property) => columnsSet.has(property));
  }

  function columnsWhoNeedToReloadCritical() {
    const columns = [
      { name: 'totalSlack' },
      { name: 'freeSlack' },
      { name: 'is_critical' }
    ];

    if (earlyAccessCriticalPath()) {
      columns.push(
        { name: 'early_start' },
        { name: 'late_start' },
        { name: 'early_finish' },
        { name: 'late_finish' }
      );
    }
    return columns;
  }

  /**
   * This function runs when is deleted, and checks all relations that it may have
   * with other tasks or links, and send them to deleteHandler function flow
   * @param {*} task a task which must be checked if it has childs or links to be deleted with it
   */
  async function detectChildAndLinks(task) {
    const links = [...task.$source, ...task.$target];
    links.map((link) => {
      deleteHandler(gantt.getLink(link), 'link');
    });

    if (task.type == 'project') {
      const tasks = gantt.getTaskByTime().filter((t) => t.parent == task.id);
      tasks.map((t) => {
        deleteHandler(t, 'task');
        detectChildAndLinks(t);
      });
    } else if (task.type === 'task') {
      /** last level */
      deleteHandler(task, 'task');
    }
  }

  /**
   * This functions is executed on events onBeforeLinkDelete and onBeforeTaskDelete
   * @param {*} item Object from DHTMLX Gantt (link or task)
   * @param {*} type 'task' | 'link'
   */
  async function deleteHandler(item, type) {
    const loggedUser = getSignedUser();
    const currentCompany = stateCompany.currentCompany;
    const project = projectState.allProjects.find(
      (p) => p.id == projectState.projectSelected
    );

    switch (type) {
      case 'task':
        if (!item.proplannerId) break;
        if (deletedUnsavedActivities.filter((el) => el.id === item.id).length) {
          break;
        }
        setDeletedUnsavedActivities((prev) => {
          prev.push(item);
          return prev;
        });
        setDeletedUnsavedActivitiesAux((prev) => {
          prev.push(item);
          return prev;
        });
        await setTasksToDelete((prev) => {
          prev.push(item);
          return prev;
        });

        totangoSetAccountAttributes(
          loggedUser,
          projectState.projectSelected,
          currentCompany?.name,
          currentCompany?.id,
          project?.name,
          project?.stage,
          project?.country
        );

        totangoEventTracking(
          `p_${projectState.projectSelected}`,
          loggedUser,
          'Activity deleted',
          'Master Plan'
        );
        break;
      case 'link':
        if (!item.proplannerId) break;
        linksToDelete.push(item);
        linksToDeleteStack.push(item);
        setLinksToDelete(linksToDelete);
        setDeletedUnsavedLinks(linksToDelete);
        break;
    }
  }

  const updateWidgetAndSectorData = async () => {
    // message.loading(t('saving_alert'), 2);
    const updatedSector = await sectorService.showByLooaheadWeekly(
      projectState.sectorSelected
    );
    const currentSector = updatedSector.sector;

    /** NOTE: This code section were added to calculate widget data easely */
    try {
      if (currentSector) {
        if (currentSector != null || currentSector != undefined) {
          const accumulatedGanttDuration = gantt
            .getTaskBy((t) => !gantt.hasChild(t.id))
            .reduce((ac, t) => ac + t.duration, 0);
          currentSector.accumulatedDuration = accumulatedGanttDuration;
          await sectorService.update(currentSector);
          const copy_sector = { ...currentSector };
          copy_sector.cncs = [];
          copy_sector.constraints = [];
          copy_sector.weekcommitments = [];
          sessionStorage.setItem('currentSector', JSON.stringify(copy_sector));
        } else {
          Sentry.captureException('currentSector.accumulatedDuration null');
        }
      } else {
        Sentry.captureException('currentSector.accumulatedDuration null');
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }

    /** Widget retro handler for accum for active baseline */
    const activeBaseline = allBaselines.find((base) => base.active);
    if (activeBaseline) {
      const baseline = activeBaseline;
      if (!baseline.accumulatedDuration) {
        const accumDuration = baseline.points
          .filter((t) => {
            const tRef = gantt.getTask(t.activityDhtmlxId);
            if (tRef) {
              if (!gantt.hasChild(tRef.id)) {
                return true;
              }
            }
          })
          .reduce((ac, t) => ac + t.duration, 0);
        baseline.accumulatedDuration = accumDuration;
        sectorBaselineVersionService.update(baseline);
      }
    }
  };

  const defineModalDeleteMessage = (paragraph) => {
    let msg;
    if (paragraph === 'first') {
      msg = t('modals.gantt.non_saved_activities.deleted_text');
    } else if (paragraph === 'second') {
      msg = t('modals.gantt.non_saved_activities.deleted_text2');
    }
    return msg;
  };

  async function goSaveBaseline(
    saveBaselineModalautoParam,
    shouldSaveGanttData = true
  ) {
    if (permission === 'V') {
      message.error(t('without_permission_to_save'), 2);
      return;
    }

    /** Create a new baseline version object */
    const currentUser = getSignedUser();
    const accumulatedGanttDuration = getAccumulatedGanttDuration(gantt);
    const newBaselineVersion = {
      create_date: moment().format('YYYY/MM/DD H:mm'),
      name: saveBaselineModalautoParam.name,
      description: saveBaselineModalautoParam.description,
      creatorId: currentUser.id,
      creator: currentUser,
      sectorId: sector.id,
      visible: true,
      active: true,
      accumulatedDuration: accumulatedGanttDuration,
      saveOption: saveBaselineModalautoParam.toSaveOption
    };

    /** Find the last active baseline version */
    /** Set all old baselines active and visible to false */
    let lastActiveBaselineVersion;
    const asyncAllBaselines = allBaselines.map(async (baselineVersion) => {
      if (baselineVersion.active) {
        lastActiveBaselineVersion = baselineVersion;
      }

      baselineVersion.active = false;
      baselineVersion.visible = false;
      await sectorBaselineVersionService.update(baselineVersion);
    });
    await Promise.all(asyncAllBaselines);

    /** Create the new baseline version, and update allbaselines state */
    const resVersion =
      await sectorBaselineVersionService.create(newBaselineVersion);
    newBaselineVersion.id = resVersion.id;
    setAllBaselines([...allBaselines, newBaselineVersion]);

    /** Clone calendars to base calendars */
    const hashTable = await createBaseCalendarFromCalendar(
      resVersion,
      lastActiveBaselineVersion,
      calendars
    );

    /** Create baseline points for new baseline version */
    const bodyForNewBaselinePoints = getBodyForNewBaseline(
      hashTable,
      newBaselineVersion,
      gantt,
      sector
    );
    const resPoints = await sectorBaselinePointService.createAllBaselines(
      bodyForNewBaselinePoints
    );

    /** Set flag to update ponderators,executed by location reload */
    if (resPoints) {
      await refreshBaselinesStates(shouldSaveGanttData);
      notifyMessage({
        title: t('saved'),
        message: t('saved_baseline_succesfully'),
        type: 'success'
      });
      setSaveBaselineModal({ ...saveBaselineModal, visible: false });
    }

    /** Tracking event */
    const eventResult = resPoints ? 'yes' : 'no';
    const baselineType =
      baselineOptions[saveBaselineModalautoParam.toSaveOption];
    trackingEvent(
      'baseline_creation',
      {
        ...getBasicAmplitudEventProperties(),
        baseline_type: baselineType,
        event_result: eventResult
      },
      AMPLITUDE_SERVICE
    );
  }

  /**
   * This function check parents activities with new activities added as child, then updates recursive values to allow baseline component check which parent needs an update
   * @returns Null when checking is not possible
   */
  const validateParentsWithNewActivitiesAdded = (_) => {
    const parentsWithNewActivities = gantt.getTaskBy(
      (task) => task.newActivitiesArray && task.newActivitiesArray.length
    );
    if (!parentsWithNewActivities || !parentsWithNewActivities.length) return;
    for (let i = 0; i < parentsWithNewActivities.length; i++) {
      const currentParent = parentsWithNewActivities[i];
      if (!currentParent || !gantt.checkRecursiveFlagParents) continue;
      gantt.checkRecursiveFlagParents(currentParent);
    }
  };

  /**
   * This function check parents acitiviteis with new activities deleted, then updates recursive values to allow basleine component check which parent needs an update
   * @returns Null when checking is not possible
   */
  const validateParentsWithNewActivitiesDeleted = (_) => {
    const parentsWithNewActivitiesDeleted = gantt.getTaskBy(
      (task) => task.newActivitiesToDelete && task.newActivitiesToDelete.length
    );
    if (
      parentsWithNewActivitiesDeleted ||
      !parentsWithNewActivitiesDeleted.length
    )
      return;
    for (let i = 0; i < parentsWithNewActivitiesDeleted.length; i++) {
      const currentParent = parentsWithNewActivitiesDeleted[i];
      if (!currentParent) continue;
      const deletedIds = currentParent.newActivitiesToDelete;
      if (!deletedIds || !deletedIds.length) continue;
      for (let j = 0; j < deletedIds.length; j++) {
        const currentDeletedId = deletedIds[j];
        if (
          !currentParent ||
          !gantt.checkRecursiveFlagParents ||
          !currentDeletedId
        )
          continue;
        gantt.checkRecursiveFlagParents(currentParent, false, currentDeletedId);
      }
    }
  };

  const validateFlagsForBaselineOption2 = (_) => {
    if (gantt) {
      validateParentsWithNewActivitiesAdded();
      validateParentsWithNewActivitiesDeleted();
    }
  };

  const goSaveHandler = async (clickSaveButton = false) => {
    let faildInformation = '';
    const newActivitiesRelationsArray = [];
    const newActivitiesArray = [];
    let tasktDeleted = [];
    const massiveHistoricalArray = [];
    try {
      // trackingEvent('Save Gantt changes', { projectId: projectState.projectSelected, sectorId: projectState.sectorSelected })
      validateFlagsForBaselineOption2();
      await updateWidgetAndSectorData();

      const loggedUser = getSignedUser();
      const currentCompany = stateCompany.currentCompany;
      const project = projectState.allProjects.find(
        (p) => p.id == projectState.projectSelected
      );

      totangoSetAccountAttributes(
        loggedUser,
        projectState.projectSelected,
        currentCompany?.name,
        currentCompany?.id,
        project?.name,
        project?.stage,
        project?.country
      );

      totangoEventTracking(
        `p_${projectState.projectSelected}`,
        loggedUser,
        'Save changes Masterplan',
        'Master Plan'
      );

      trakerServiceAppCues('Save Masterplan');

      /** Serialize from Gantt let get object of data { data, links} */

      const currentGanttData = gantt.serialize();
      const nonUpdatedTasksCounter =
        gantt &&
        gantt.checkNoUpdatedActivities &&
        gantt.checkNoUpdatedActivities();

      const nonSavedTasksCounter =
        gantt && gantt.checkNoSavedActivities && gantt.checkNoSavedActivities();

      const planeModifiedActivities = [
        ...nonUpdatedTasksCounter,
        ...nonSavedTasksCounter
      ];

      /** Same with links */
      const nonUpdatedLinksCounter =
        gantt && gantt.checkNoUpdatedLinks && gantt.checkNoUpdatedLinks();
      const nonSavedLinksCounter =
        gantt && gantt.checkNoSavedLinks && gantt.checkNoSavedLinks();
      const planeModifiedLinks = [
        ...nonUpdatedLinksCounter,
        ...nonSavedLinksCounter
      ];

      if (!planeModifiedActivities || !planeModifiedLinks)
        throw 'Error with act and links to save';

      /** This line replaces whole data, by the only edited and created elements */
      currentGanttData.data = planeModifiedActivities.filter((act) =>
        Boolean(act)
      );
      currentGanttData.links = planeModifiedLinks.filter((link) =>
        Boolean(link)
      );

      /** Mapping Gantt Task objects we can transform each of them to ProPlanner Data Structure */
      const activities = currentGanttData.data.map((activity, index) => {
        const convertedObject = convertGanttTaskToActivity(activity);
        convertedObject.sectorId = sector.id;
        convertedObject.ganttId = ganttId;
        convertedObject.has_childs = Boolean(gantt.hasChild(activity.id));
        if (activity.proplannerId) {
          convertedObject.id = activity.proplannerId;
        }
        return convertedObject;
      });

      /** Same with Gantt Links objects */
      const activityrelations = currentGanttData.links.map(
        (activityrelation) => {
          if (!activityrelation.lag) {
            activityrelation.lag = 0;
          }
          activityrelation.sectorId = sector.id;
          activityrelation.ganttId = ganttId;
          const convertedObject =
            convertLinkToActivityRelation(activityrelation);
          if (activityrelation.proplannerId) {
            convertedObject.id = activityrelation.proplannerId;
          }
          return convertedObject;
        }
      );

      /** Fetch to save current Gantt Data */
      const hashMap = {};
      activities.forEach((elem) => {
        if (!hashMap[elem.unique_id]) {
          newActivitiesArray.push(elem);
          hashMap[elem.unique_id] = true;
        }
      });

      const hashMapRelations = {};
      activityrelations.forEach((elem) => {
        if (!hashMapRelations[elem.unique_id]) {
          newActivitiesRelationsArray.push(elem);
          hashMapRelations[elem.unique_id] = true;
        }
      });

      const breadcrumbMessage = `user ${loggedUser.email} is saving sectorId ${projectState.sectorSelected}`;
      log('saving_sector', breadcrumbMessage);
      let res = await activityService.createAll(
        newActivitiesArray,
        projectState.sectorSelected
      );
      const back_up_data = window.backup_data_saving_use;
      if (res) {
        /** We update the news activities with the new id created */
        res.map((activity) => {
          if (activity.unique_id) {
            const ganttDhtmlxTask = gantt.getTask(activity.unique_id);
            ganttDhtmlxTask.proplannerId = activity.id;
            // INSANE USAGE OF MEMORY gantt.updateTask(ganttDhtmlxTask.id)

            const activity_backup = newActivitiesArray.find(
              (a) => a.unique_id == activity.unique_id
            );
            if (activity_backup) {
              activity_backup.id = activity.id;
            }
          }
        });
        await activityService.updateResponsiblesAndTags(
          newActivitiesArray,
          projectState.sectorSelected
        );

        await activityService.createAllChangeTaskDate(
          newActivitiesArray,
          projectState.sectorSelected,
          back_up_data
        );

        const currentGanttData = gantt.serialize();
        currentGanttData.data.map((ac) => {
          const newActivityHistorical = {
            activityId: ac.proplannerId,
            progress: ac.progress,
            created: moment(new Date()).format('YYYY/MM/DD H:mm:ss')
          };

          massiveHistoricalArray.push(newActivityHistorical);
        });
      } else {
        faildInformation = 'Activities save fails';
        throw new Error('Cant save activities');
      }

      window.backup_data_saving_use = cloneDeep(gantt.getTaskByTime());

      res = await activityRelationService.createAll(
        newActivitiesRelationsArray
      );
      if (res) {
        res.map((activityrelation) => {
          const ganttDhtmlxLink = gantt.getLink(activityrelation.unique_id);
          try {
            ganttDhtmlxLink.proplannerId = activityrelation.id;
            // INSANE USAGE OF MEMORY gantt.updateLink(ganttDhtmlxLink.id)
          } catch (e) {
            Sentry.captureException(e);
          }
        });
      } else {
        faildInformation = 'Links save fails';
        throw new Error('Cant save relations');
      }

      /** Delete Stuff */
      await fetchSaveHistorical(massiveHistoricalArray);
      const updateLinkToDelete = linksToDelete.filter((link) => {
        const { id } = link;
        const isExists = gantt.isLinkExists(id);
        return !isExists;
      });
      getUnique(updateLinkToDelete, 'proplannerId').map((linkToDelete) => {
        const activityrelation = convertLinkToActivityRelation(linkToDelete);
        if (linkToDelete.proplannerId) {
          activityrelation.id = linkToDelete.proplannerId;
        }
        activityRelationService.destroy(activityrelation.id);
      });

      const updateTasksToDelete = tasksToDelete.filter((task) => {
        const { id } = task;
        const isExists = gantt.isTaskExists(id);
        return !isExists;
      });
      tasktDeleted = updateTasksToDelete.map(async (taskToDelete) => {
        const activity = convertGanttTaskToActivity(taskToDelete);
        if (taskToDelete.proplannerId) {
          activity.id = taskToDelete.proplannerId;
        }
        const res = await activityService.destroy(activity.id);
        if (!res) {
          log('error_delete_activity', activity.id);
          faildInformation = 'Cant delete activities';
        }
      });
      await Promise.all(tasktDeleted);

      const tasks = gantt.getTaskByTime();
      gantt.legacy_corrected_activities = cloneDeep(tasks);

      setTasksToDelete((prev) => []);
      setLinksToDelete((prev) => []);
      linksToDeleteStack = [];
      setDeletedUnsavedActivities((prev) => []);
      setDeletedUnsavedLinks((prev) => []);

      const activitiesDeletedTaskLookahead = [];
      const activitiesRemoveIsOnLookahead = [];

      window.stackActivitiesMotherSave.forEach((activity) => {
        const currentTask = tasks.find(
          (task) => task.proplannerId === activity
        );

        if (currentTask.type === 'project') {
          activitiesDeletedTaskLookahead.push(currentTask.proplannerId);
          activitiesRemoveIsOnLookahead.push(currentTask);
        }
      });

      if (activitiesDeletedTaskLookahead.length > 0) {
        await activityService.deleteTaskByActivityId(
          activitiesDeletedTaskLookahead
        );
      }

      if (activitiesRemoveIsOnLookahead.length > 0) {
        for (const currentActivity of activitiesRemoveIsOnLookahead) {
          const current = {
            ...convertGanttTaskToActivity(currentActivity),
            id: currentActivity.proplannerId,
            has_childs: Boolean(gantt.hasChild(currentActivity.id)),
            isOnLookahead: false
          };

          addBreadcrumbUtil(
            'goSaveHandler',
            'activitiesRemoveIsOnLookahead',
            'Attempting to update activity',
            'info',
            {
              activityId: current.id
            }
          );

          await activityService.update(current);
        }
      }
      setTimeout(() => {
        gantt.checkGhostActivities && gantt.checkGhostActivities();
        gantt.deleteGhostActivities && gantt.deleteGhostActivities();
        gantt.initNonSavedTasksCounter = gantt.checkNoSavedActivities().length;
        gantt.initNonSavedDeletedTasksCounter =
          gantt.deletedUnsavedActivities.length;
        gantt.initNonUpdatedTasksCounter =
          gantt.checkNoUpdatedActivities().length;
        gantt.initNonSavedLinksCounter = gantt.checkNoSavedLinks().length;
        gantt.initNonSavedDeletedLinksCounter =
          gantt.deletedUnsavedLinks.length;
        gantt.initNonUpdatedLinksCounter = gantt.checkNoUpdatedLinks().length;
      }, 500);
      updateSubmittalsHelper(currentCompany, currentProject, currentSector);
      if (!window.savingBaselineOnProcess) {
        message.success(t('saved_succesfully'), 4);
      }
      if (clickSaveButton) {
        trackingEvent(
          'saving_schedule_changes',
          {
            ...getBasicAmplitudEventProperties(),
            event_source: 'save_button_selection',
            event_result: 'Successfull',
            error_information: faildInformation
          },
          AMPLITUDE_SERVICE
        );
      }
    } catch (error) {
      setShowBackUpModal(true);
      message.error(
        t('lang') === 'es'
          ? 'No hemos podido guardar la informacion.'
          : 'We were unable to save the information.'
      );

      trackingEvent(
        'save_schedule_failed_dev',
        {
          ...getBasicAmplitudEventProperties(),
          error_information: faildInformation
        },
        AMPLITUDE_SERVICE
      );
      trackingEvent(
        'saving_schedule_changes',
        {
          ...getBasicAmplitudEventProperties(),
          event_source: 'save_button_selection',
          event_result: 'Failed',
          error_information: faildInformation
        },
        AMPLITUDE_SERVICE
      );
      Sentry.withScope((scope) => {
        scope.setTags({
          ganttChartView: 'goSaveHandler'
        });
        Sentry.captureException(error);
      });
      const deleteActivitiesBackup = tasksToDelete.map((t) => ({
        id: t.proplannerId
      }));
      const linksDeleteBackup = linksToDeleteStack.map((l) => ({
        id: l.proplannerId
      }));
      activityService.createAllBackUp(
        newActivitiesArray,
        projectState.sectorSelected,
        newActivitiesRelationsArray,
        deleteActivitiesBackup,
        linksDeleteBackup
      );
    }
  };

  /**
   * this function updates the gantt eliminating the deleted tasks that are displayed after saving the baseline
   */
  const updateTasksDeletedRender = async (deletedUnsavedActivities) => {
    if (deletedUnsavedActivities) {
      deletedUnsavedActivities.map((el) => {
        const getTaskDeleted = gantt.getTask(el.id);
        if (getTaskDeleted && gantt.isTaskExists(getTaskDeleted.id)) {
          gantt.deleteTask(getTaskDeleted.id);
        }
      });
    }
  };

  const saveBaseLineWhenDeletedActivities = async () => {
    const shouldSaveGanttData = false;
    const sectorResActivitybaseVersion =
      await sectorService.showActivityVersions(projectState.sectorSelected);
    const { baselines } = sectorResActivitybaseVersion;
    const newBaseline = baselines.filter((b) => b.active === true);

    const deletedUnsavedActivitiesIds = new Set(
      gantt?.deletedUnsavedActivities.map((activity) => activity.id)
    );
    if (newBaseline.length > 0) {
      const saveBaselineDeletedActivides = {
        ...saveBaselineModal,
        name: `${newBaseline[0].name} - ${t('master_plan.updated')} `,
        description: `${t('master_plan.updated')}: ${deletedUnsavedActivitiesIds.size} ${t('master_plan.activities_deleted')}`,
        toSaveOption: 4
      };
      message.info(
        t('lang') === 'es' ? 'Guardando línea base' : 'Saving baseline',
        2
      );
      await goSaveBaseline(saveBaselineDeletedActivides, shouldSaveGanttData);
      deletedUnsavedActivitiesIds.clear();
    } else {
      openNotificationBaseline();
      trackingEvent(
        'no_baseline_active_dev',
        {
          ...getBasicAmplitudEventProperties(),
          event_source: 'save_button_selection'
        },
        AMPLITUDE_SERVICE
      );
    }
  };

  const openNotificationBaseline = () => {
    notification.config({
      duration: 6
    });
    notification.error({
      message:
        t('lang') === 'es' ? 'Activar línea de base ' : 'Activate Baseline ',
      description:
        t('lang') === 'es'
          ? 'El programa no tiene una línea base activa. Verifica la configuración para un seguimiento adecuado'
          : 'The program does not have an active baseline. Verify the settings for proper follow-up.',
      style: { zIndex: 999999 },
      key: 'base'
    });
  };

  /** This function handle saving data (links and activities) */
  async function saveHandler(clickSaveButton = false) {
    if (permission == 'V') {
      message.error(t('without_permission_to_save'), 2);
      return;
    }
    if (saveProcessActive) {
      message.open({
        type: 'loading',
        content:
          t('lang') === 'es'
            ? 'Hay un guardado en proceso.'
            : 'There is a save in process.',
        duration: 0
      });
      return;
    }
    setSaveProcessActive(true);
    try {
      /** get versions */
      const sectorResActivitybaseVersion =
        await sectorService.showActivityVersions(projectState.sectorSelected);
      if (
        gantt?.deletedUnsavedActivities.length &&
        !modalMetadataDelete.isRunning &&
        sectorResActivitybaseVersion.baselines.length
      ) {
        if (showModalWhenDeleteActivies) {
          if (
            gantt?.deletedUnsavedActivities.length &&
            !modalMetadataDelete.isRunning &&
            sectorResActivitybaseVersion.baselines.length
          ) {
            modalMetadataDelete.title = ' &nbsp;';
            modalMetadataDelete.isRunning = true;
            modalMetadataDelete.okText = t('modals.gantt.new_baseline.save');
            modalMetadataDelete.cancelText = t(
              'modals.gantt.non_saved_activities.cancel'
            );
            modalMetadataDelete.content = (
              <div>
                <div className="icon-center">
                  <WarningIcon color={colors.brandOrange40} />
                </div>
                <div className="delete-text-gantt">
                  <div>{defineModalDeleteMessage('first')}</div>
                  <div>{defineModalDeleteMessage('second')}</div>
                </div>
              </div>
            );
            modalMetadataDelete.visible = true;
            modalMetadataDelete.onOkPayload = async (
              saveBaselineModalAutoParam
            ) => {
              await goSaveHandler(clickSaveButton);
              await goSaveBaseline(saveBaselineModalAutoParam);
              setSaveProcessActive(false);
            };
            modalMetadataDelete.onCancelPayload = () => {
              setSaveProcessActive(false);
            };
            updateModalRenderDelete();
          }
        } else {
          if (atLeastOneActivityHasBaseline(gantt.deletedUnsavedActivities)) {
            await saveBaseLineWhenDeletedActivities();
          }
          await goSaveHandler(clickSaveButton);
        }
      } else {
        await goSaveHandler(clickSaveButton);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setSaveProcessActive(false);
    }
    return false;
  }

  function atLeastOneActivityHasBaseline(activities) {
    return activities.some((activity) => activity.baselineObject);
  }

  const fetchSaveHistorical = async (historicals) => {
    const res =
      await historicalActivityProgressService.massiveCreate(historicals);
  };

  /**
   * This function calcualtes whole gantt ponderators
   * @param {*} avoid_save Flag to avoid saving changes
   */
  const goCalculatePonderators = async (avoid_save = false) => {
    const allActivities = gantt.getTaskByTime();
    allActivities.forEach((ac) => {
      ac.ponderator = 0;
    });

    const withChildsTasks = allActivities.filter((ac) => {
      if (gantt.hasChild(ac.id)) {
        return true;
      }
    });

    const getFirstChildTask = (parent, gantt) => {
      const childIds = gantt.getChildren(parent.id);
      if (!childIds || childIds.length === 0) return null;
      return gantt.getTask(childIds[0]);
    };

    for (const parent of withChildsTasks) {
      calculatePonderators(parent, gantt, projectState);
      const firstChild = getFirstChildTask(parent, gantt);
      if (firstChild) {
        await check_progress(gantt, firstChild);
      }
    }

    gantt.render();
    if (!avoid_save) {
      saveHandler();
    }
  };

  function updateModalRender() {
    setModalMetadata((prev) => {
      prev = cloneDeep(modalMetadata);
      return prev;
    });
  }

  function updateModalRenderDelete() {
    setModalMetadataDelete((prev) => {
      prev = cloneDeep(modalMetadataDelete);
      return prev;
    });
  }

  /** This effect handle loosing focus when modals are open */
  useEffect(() => {
    if (
      (saveScheduleUpdatesModal.visible ||
        allScheduleUpdatesModal.visible ||
        saveBaselineModal.visible ||
        allBaselinesModal.visible) &&
      gantt
    ) {
      gantt.disconnectKeyboardNavigation = true;
    } else if (gantt) {
      gantt.disconnectKeyboardNavigation = false;
    }
  }, [
    saveBaselineModal,
    allBaselinesModal,
    allScheduleUpdatesModal,
    saveScheduleUpdatesModal,
    allScheduleUpdatesModal
  ]);

  const updateScheduleUpdates = async () => {
    const scheduleUpdates = await scheduleUpdatesService.showBySector(
      projectState.sectorSelected
    );
    setAllScheduleUpdates(scheduleUpdates.updates);
    setTimeout(() => {
      saveHandler();
    }, 2000);
  };

  const handleConfigClick = (opt) => {
    switch (opt) {
      case 'view_calendars':
        props.history.push('calendars');
        break;
      case 'save_baseline':
        setSaveBaselineModal({
          ...saveBaselineModal,
          visible: true,
          title: t('modals.gantt.all_baselines.newbase_label')
        });
        break;
      case 'base_lines':
        setAllBaselinesModal({
          ...allBaselinesModal,
          visible: true,
          title: t('baselines')
        });
        break;
      case 'show_schedule_updates':
        setAllScheduleUpdatesModal({
          ...allScheduleUpdatesModal,
          visible: true,
          title: t('modals.gantt.schedule_updates.schedule_updates')
        });
        break;
      case 'save_schedule_updates':
        setSaveScheduleUpdatesModal({
          ...saveScheduleUpdatesModal,
          visible: true,
          title: t('modals.gantt.schedule_updates.new_schedule_update')
        });
        break;
      case 'update_schedule_updates':
        setSaveScheduleUpdatesModal({
          ...saveScheduleUpdatesModal,
          visible: false,
          comment: '',
          update_name: ''
        });
        updateScheduleUpdates();
        break;
    }
  };

  const updateFlagSector = async (gantt) => {
    if (!autoschedulingVisual) {
      if (sector.update_ponderators_masterplan) {
        await sectorService.updatePonderatorMaster(
          projectState.sectorSelected,
          false
        );
        await sectorService.updateprimaveradurationmaster(
          projectState.sectorSelected,
          false
        );
        gantt.clearStacks = true;
        saveHandler();
      }
      if (sector.update_save_handle_for_calendar_update) {
        await sectorService.updatecalendarsavemaster(
          projectState.sectorSelected,
          false
        );
      }
      const isSomeAutoAssignedCalendar =
        dataToLoad &&
        dataToLoad.data &&
        dataToLoad.data.some((el) => el.assignedDefaultCalendar);
      if (isSomeAutoAssignedCalendar) {
        gantt.clearStacks = true;
        saveHandler();
      }
    }
  };

  /**
   * This function returns the baseline, workhour, activity modification, adding subs/tags modals
   * @returns A div with all components modal elements
   */
  const renderViewModals = (saveHandler) => (
    <div>
      <ScheduleModal
        handleConfigClick={handleConfigClick}
        allScheduleUpdatesModal={allScheduleUpdatesModal}
        setAllScheduleUpdatesModal={setAllScheduleUpdatesModal}
        allScheduleUpdates={allScheduleUpdates}
        setAllScheduleUpdates={setAllScheduleUpdates}
        saveScheduleUpdatesModal={saveScheduleUpdatesModal}
        setSaveScheduleUpdatesModal={setSaveScheduleUpdatesModal}
        colors={colors}
        modalStyles={modalStyles}
        gantt={gantt}
        sector={sector}
        t={t}
      />
      <BaselineModal
        saveHandler={saveHandler}
        setAutoschedulingVisual={setAutoschedulingVisual}
        refreshBaselinesStates={refreshBaselinesStates}
        deletedUnsavedActivities={deletedUnsavedActivities}
        handleConfigClick={handleConfigClick}
        calendars={calendars}
        sector={sector}
        allBaselinesModal={allBaselinesModal}
        setAllBaselinesModal={setAllBaselinesModal}
        colors={colors}
        updateModalRender={updateModalRender}
        updateModalRenderDelete={updateModalRenderDelete}
        modalMetadataDelete={modalMetadataDelete}
        modalMetadata={modalMetadata}
        t={t}
        setAllBaselines={setAllBaselines}
        allBaselines={allBaselines}
        setSaveBaselineModal={setSaveBaselineModal}
        saveBaselineModal={saveBaselineModal}
        setSaveBaselineModalAuto={setSaveBaselineModalAuto}
        saveBaselineModalAuto={saveBaselineModalAuto}
        permission={permission}
        gantt={gantt}
        modalStyles={modalStyles}
      />
      <ActivityModification
        updateModalRender={updateModalRender}
        permission={permission}
        saveHandler={saveHandler}
        gantt={gantt}
        t={t}
        activityModificationSelected={activityModificationSelected}
      />
      <ModalAddSubContract
        t={t}
        Visible={visibleFormSubcontract}
        setVisible={setVisibleFormSubcontract}
        setReloadTableUsers={setReloadSubsArray}
        ReloadTableUsers={reloadSubsArray}
      />
      <ModalAddTags
        t={t}
        Visible={visibleFormTags}
        setVisible={setVisibleFormTags}
        setReloadTableUsers={setReloadTagsArray}
        ReloadTableUsers={reloadTagsArray}
      />
      <ConstraintValidationModal
        isVisible={false}
        setShowModal={() => {}}
        t={t}
      />
    </div>
  );

  const calendarObject = {
    calendars,
    customHour
  };

  /** build multiselect on GanttFilterHeader */
  const dataForFilterColumns = {
    className: 'filter-lookahead-multi-check',
    placeholder: t('master_plan.search'),
    value: selectedOption,
    onChange: handleChange,
    options: children,
    getDropdownButtonLabel: (_) => null
  };

  const getHeightGant = () => {
    const isBABOpen = Boolean(selectedActivities?.length);
    const isActivityCardOpen =
      drawer.status === 'OPEN' && drawer.origin === 'bottom';
    let heightGantt = 'calc(100vh - 186px)';
    if (isBABOpen && isActivityCardOpen) {
      heightGantt = 'calc(100vh - 527px)';
    } else if (isBABOpen) {
      heightGantt = 'calc(100vh - 215px)';
    } else if (isActivityCardOpen) {
      heightGantt = 'calc(100vh - 497px)';
    }

    if (!isFirstView && (!isBABOpen || isActivityCardOpen)) {
      const drawerNode = document.querySelector('[data-testid=drawer_root]');

      if (drawerNode) {
        drawerNode.addEventListener(
          'webkitTransitionEnd',
          (event) => {
            if (event.propertyName !== 'transform') return;

            drawerNode.removeEventListener('webkitTransitionEnd');
            window.to_use_react_gantt.render();
          },
          false
        );
      }
    }

    setIsFirstView(false);

    return heightGantt;
  };

  useEffect(() => {
    setHeigthGantt(getHeightGant());
  }, [selectedActivities?.length, drawer.status]);

  /**
   * This function check if the view is totally loaded if dont, it returns an loader to preload data
   * @returns If loading initial gantt data, a loader, if it's done, the filter header and gantt
   */
  function renderLoadedContent() {
    if (loading) {
      window.loader.show();
      return;
    }
    return (
      <Col>
        <GanttFilterHeader
          t={t}
          permission={permission}
          handleConfigClick={handleConfigClick}
          optionsForFilter={tableConfig}
          ganttObject={gantt}
          saveHandler={saveHandler}
          calendars={calendars}
          sectorSelected={sectorObject}
          goUpload={() => props.history.push('/importgantt')}
          dataForColumns={dataForFilterColumns}
          columns={children}
          zoomLevel={zoomLevel}
          toSelectResponsables={toSelectResponsables}
          toSelectTags={toSelectTags}
          subContracts={subContracts}
          modalDelMass={modalDelMass}
          modalDupMass={modalDupMass}
          modalDupMassAddActivities={modalDupMassAddActivities}
          callbackModalDupConfirmMass={callbackModalDupConfirmMass}
        />
        <Row>
          <Col>
            <div className="gantt-container" style={{ height: heightGantt }}>
              <Gantt
                setVisibleFormTags={setVisibleFormTags}
                setVisibleFormSubcontract={setVisibleFormSubcontract}
                subContracts={subContracts}
                toSelectResponsables={toSelectResponsables}
                toSelectTags={toSelectTags}
                setAutoschedulingVisual={setAutoschedulingVisual}
                setAutoscheduling={setAutoscheduling}
                setLoading={setGanttShouldRender}
                t={t}
                toDisable={toDisable}
                setActivityModificationSelected={
                  setActivityModificationSelected
                }
                data={dataToLoad}
                deleteHandler={deleteHandler}
                allCalendars={allCalendars}
                gantt={gantt}
                setGanttLoaded={setGanttLoaded}
                detectChildAndLinks={detectChildAndLinks}
                setZoomLevel={setZoomLevel}
                calendarObject={calendarObject}
                modalCircular={modalCircular}
              />
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
  const contentChildren = () => (
    <div className="delete-stage__content">
      <div className="content__form">
        <div className="form__icon">
          <WarningIcon color="#ED8536" />
        </div>
        <div className="form__title">
          <h5>{t('not_save_mp_error.text_1')}</h5>
        </div>
        <div className="form__input">
          <label style={{ textAlign: 'center' }}>
            {t('not_save_mp_error.text_2')}
          </label>
        </div>
      </div>
      <div className="content__buttons">
        {/* <button className='buttons__cancel' onClick={() => handleCloseModal()}>{t('delete_stage.button_cancel_text')}</button> */}
        <button className="buttons__delete" onClick={() => XMLBackupAcepted()}>
          {t('not_save_mp_error.button')}
        </button>
      </div>
    </div>
  );

  const XMLBackupAcepted = () => {
    setShowBackUpModal(false);
    gantt.recoveryErrorSaveProcessXml();
  };

  const openModalRemoveTaskLookahead = (
    t,
    task,
    parent,
    parentProplannerId
  ) => {
    setIsShowModalRemoveTaskLookahead(true);
    setDataModalRemoveTaskLookahead({
      t,
      task,
      parent,
      parentProplannerId
    });
  };
  const openModalPDF = () => {
    setIsShowModalPDF(true);
  };

  /**
   * Render
   */

  return (
    <Row>
      {renderLoadedContent()}
      {renderViewModals(saveHandler)}
      <ModalRemoveTaskLookahead
        isShow={isShowModalRemoveTaskLookahead}
        setIsShow={setIsShowModalRemoveTaskLookahead}
        {...dataModalRemoveTaskLookahead}
      />
      <ModalMessage
        t={t}
        isShow={isShowModalPDF}
        setIsShow={setIsShowModalPDF}
      />
      {ModalSystem({
        closable: false,
        visible: showBackUpModal,
        theme: 'dark',
        setVisible: setShowBackUpModal,
        showTitle: false,
        centered: true,
        width: 520,
        children: contentChildren()
      })}
    </Row>
  );
}

const mapStateToProps = (state) => ({
  selectedActivities: getSelectedActivitiesSelector(state),
  drawer: getDrawerSelector(state),
  isGanttLoading: getGanttLoadingSelector(state)
});

export const unsavedChangesListener = function (event) {
  const gantt = window.to_use_react_gantt;
  const { haveChanges } = checkForChanges(gantt);

  const user = getSignedUser();
  if (!user || !user.id) return;

  trackingEvent(
    'unsaved_changes_alert_triggered',
    {
      ...getBasicAmplitudEventProperties(),
      haveChanges: haveChanges
    },
    AMPLITUDE_SERVICE
  );

  if (haveChanges) {
    const confirmationMessage =
      'Tienes cambios sin guardar. ¿Seguro que quieres salir?';

    event.returnValue = confirmationMessage;
    return confirmationMessage;
  }
};

export default connect(mapStateToProps, {})(withTranslation()(GanttChartView));

import { NEW_TAB } from '../constants';
import { getHeaderPdf } from '../Templates/header';
import { getFooterPDF } from '../Templates/footer';
import { getCSSPdf } from '../style/index';
import { formatIsAllContent, getTotalPages } from './configPages';
import { getZoomLevel } from './updateGantt';
import { getFormatDate } from './formateDate';
import { base } from '../../../../../../services/base';

const exportableServerURL = base.exportableUrl;

/**
 * Creates an array of PDF files for export, each representing a page of the Gantt chart.
 *
 * @param {Object} gantt - The Gantt chart instance.
 * @param {Function} t - Translation function.
 * @param {string} lang - Language code.
 * @param {Object} pageDimensions - Dimensions of the pages for export.
 * @param {boolean} isLookahead - Flag indicating if the export is for lookahead.
 * @param {Object} config - Configuration settings for the export.
 * @param {boolean} config.isFitOnePage - Flag indicating if the content should fit one page.
 * @param {boolean} config.isTrimParent - Flag indicating if parent tasks should be trimmed.
 * @param {Date} [config.newDate=new Date()] - The date for the export.
 * @param {Date} [config.newDateToday=new Date()] - The current date for the export.
 * @param {string} config.formatToExport - Format to export.
 * @returns {Promise[]} - An array of promises, each resolving to a PDF file for the Gantt chart.
 */
export const createArrayOfFilesPDF = ({
  gantt,
  t,
  lang,
  pageDimensions,
  isLookahead,
  config
}) => {
  const {
    isFitOnePage,
    isTrimParent,
    newDate = new Date(),
    newDateToday = new Date(),
    formatToExport
  } = config;

  const totalPages = getTotalPages({ gantt, isFitOnePage, pageDimensions });
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const isAllContent = formatIsAllContent(formatToExport);
  const zoomLevel = getZoomLevel(gantt);

  const headerPDF = getHeaderPdf();
  const footerPDF = getFooterPDF({
    gantt,
    t,
    isSchedule: !isLookahead,
    date: newDate
  });

  const arrayOfFiles = pages.map((pageNumber) => {
    const currentPageDimensions = {
      ...pageDimensions,
      customProplanner: {
        totalPages,
        format_text: formatToExport,
        level: zoomLevel,
        lang,
        isFitOnePage,
        isTrimParent,
        pageCounter: pageNumber
      }
    };

    const cssPDF = getCSSPdf({
      gantt,
      isLookahead,
      isAllContent,
      pageDimensions,
      pageNumber
    });

    return gantt.exportToPDF({
      raw: false,
      skip_circular_links: true,
      header: headerPDF + cssPDF,
      footer: footerPDF,
      server: exportableServerURL,
      additional_settings: currentPageDimensions,
      newTodayLine: newDate,
      newDateToday
    });
  });

  return arrayOfFiles;
};

/**
 * Opens a PDF in a new browser tab.
 *
 * @param {Object} exportResult - The result object containing the export details.
 * @param {string} exportResult.url - The URL of the exported PDF.
 */
export const openPDFInNewTab = ({ exportResult }) => {
  const { url } = exportResult;

  if (!url) {
    return;
  }

  window.open(url, NEW_TAB);
};

/**
 * Generates the name for the exported PDF file.
 *
 * @param {Object} params - The parameters for generating the PDF name.
 * @param {Object} gantt - The Gantt chart instance.
 * @param {boolean} isSchedule - Indicates if the export is for a schedule or lookahead.
 * @param {string} projectName - The name of the project.
 * @param {string} sectorName - The name of the sector.
 * @param {string} formatToExport - The format of the export.
 * @returns {string} - The generated PDF file name.
 */
export const getExportPDFName = ({
  gantt,
  isSchedule,
  projectName,
  sectorName,
  formatToExport
}) => {
  const date = new Date();
  const formatDate = getFormatDate({ gantt, date }).replaceAll('/', '-');
  const ganttType = isSchedule ? 'schedule' : 'lookahead';
  const exportPDFName = `${ganttType}_exportable_${projectName}_${sectorName}_${formatDate}_${formatToExport}.pdf`;
  return exportPDFName.replaceAll(' ', '-');
};

/** React stuff */
import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
/** utils */
import {
  calculateExpectedCost,
  calculateExpectedForWeek,
  getRecursiveFromParentTask,
  getTask,
  statusArray,
  formatMoney,
  calculatePonderators
} from '../../../../utils/lookahead-common';

import RenderName from '../RenderName';
import RenderActions from '../RenderActions';
import RenderRoadblocks from '../RenderRoadblocks';
import RenderMaterial from '../RenderMaterial';
import RenderMachinery from '../RenderMachinery';
import RenderSpecialty from '../RenderSpecialty';
import RenderSub from '../RenderSub';
import RenderTag from '../RenderTag';
import RenderResponsible from '../RenderResponsible';
import RenderPonderator from '../RenderPonderator';
import RenderPriority from '../RenderPriority';
import RenderDescription from '../RenderDescription';
import RenderNumeric from '../RenderNumeric';
import renderStart from '../RenderStart';
import RenderEnd from '../RenderEnd';
import RenderProgress from '../RenderProgress';
import RenderDuration from '../RenderDuration';
import RenderRoute from '../RenderRoute';
import RenderLean from '../RenderLean';
import RenderWeeklyCommit from '../RenderWeeklyCommit';
import RenderWeeklyMaterial from '../RenderWeeklyMaterial';
import RenderStatus from '../RenderStatus/RenderStatus';
import {
  recursiveTasksFilterWp,
  recursiveActivityFilter,
  getFlattenActivities
} from '../../../../utils';
import {
  defineRestrictedTaskByChilds,
  findDeepGetTask,
  updateAsyncTaskGanttV2
} from '../../GanttVisualization.helper';
import { calculateCommitment } from '../../../../views/weeklyPlan/weeklyPlan.helper';
import { TemplateSubcontractId } from '../RenderSub/TemplateSubcontractId';
import { TemplateTag } from '../RenderTag/TemplateTag';
import { TemplateResponsible } from '../RenderResponsible/TemplateResponsible';
import { TemplateRoadblocks } from '../RenderRoadblocks/TemplateRoadblocks';
import { TemplateLean } from '../RenderLean/TemplateLean';

const buildReactRender = ({
  activities,
  gantt,
  t,
  onlyRead,
  materials,
  lastLevelActivities,
  dropdownRemoveCallback,
  setActivityResource,
  setTypeResource,
  setVisibleFormResource,
  machineries,
  tableMetadata,
  volatileTaskData,
  specialties,
  toSelectSubs,
  setVisibleFormSubcontract,
  toSelectTags,
  setVisibleFormTags,
  toSelectResponsables,
  setEditedInput,
  projectState,
  updateAsyncActivity,
  updateGanttVisualizationState,
  data,
  showDrawer,
  updateRender,
  assignValueFromGanttToLookaheadRef,
  calculateProgressForTree,
  updateAsyncTask,
  editedInput,
  resources,
  weeklyStatusObject,
  showModal,
  setShowModal,
  selectedParent,
  setSelectedParent,
  popsVisibility,
  setPopsVisibility,
  ganttAPI,
  modalModification,
  setModalModification,
  groupBy,
  tasksWeeklyPlan,
  setTasksWeeklyPlan,
  localActivitiesMap,
  setLocalActivitiesMap,
  weeklyRangeObject,
  updateTaskDrag
}) => {
  /** Global vars */
  const haveWeeklyPlan = weeklyStatusObject.isCommited;
  const dateRange = {
    start: weeklyStatusObject.start,
    end: weeklyStatusObject.end
  };

  /** Functions */

  /**
   * This function use common ref of weekly to re assign values
   * @param {*} task current task reference
   * @param {*} value new value to update
   */
  const updateCommitment = async (task, value) => {
    const tasks = tasksWeeklyPlan.value;
    const findTaskIndex = tasks.findIndex((e) => e.id === task.id);
    tasks[findTaskIndex] = task;
    tasksWeeklyPlan.value = tasks;
  };

  /**
   * This function updates following resource fields:
   * remaining_quantity
   * tagResource
   * quantity_parcial
   * commitment_percentaje
   * current_commitment_partial
   * @param {*} resourceMaterial_taks Array of already filtered resources
   * @param {*} taskRef Task lookahead memory ref
   * @param {*} ganttRef Gantt dhtmlx memory ref
   */
  const updateWeeklyMaterialValues = (
    resourceMaterial_taks,
    taskRef,
    ganttRef
  ) => {
    /** update remaining quantity  */
    const remainQuant = taskRef.total_quantity - taskRef.actual_quantity;
    if (taskRef.remaining_quantity != remainQuant) {
      taskRef.remaining_quantity = remainQuant;
      ganttRef.remaining_quantity = taskRef.remaining_quantity;
    }

    let tagResource = '';
    if (resourceMaterial_taks) {
      const materialObject = resourceMaterial_taks.filter(
        (el) => el.id == taskRef.materialId
      );
      tagResource = materialObject[0] ? materialObject[0].material_label : null;
    }

    let quantity_parcial = 0;
    if (haveWeeklyPlan) {
      const find = haveWeeklyPlan.taskcommitments.find(
        (e) => e.taskId === taskRef.id
      );
      if (find && !isNaN(find.commitment_percentaje)) {
        quantity_parcial = find.current_commitment_partial;
        taskRef.quantity_parcial = quantity_parcial;
        ganttRef.quantity_parcial = taskRef.quantity_parcial;
      }
    } else if (taskRef.commitment_percentaje < taskRef.progress) {
      let newCompromise = taskRef.progress;
      if (taskRef.total_quantity) {
        newCompromise =
          taskRef.progress + (quantity_parcial * 100) / taskRef.total_quantity;
      }
      taskRef.commitment_percentaje = newCompromise;
      ganttRef.commitment_percentaje = taskRef.commitment_percentaje;
      quantity_parcial = 0;
    } else {
      if (!isNaN(taskRef.commitment_percentaje)) {
        if (gantt.isWeeklyCommited && !gantt.isWeeklyCommited.closed) {
          return false;
        } else {
          quantity_parcial =
            ((taskRef.commitment_percentaje - taskRef.progress) *
              taskRef.total_quantity) /
            100;
          if (taskRef.commitment_percentaje < taskRef.progress) {
            quantity_parcial = 0;
            taskRef.quantity_parcial = quantity_parcial;
            ganttRef.quantity_parcial = taskRef.quantity_parcial;
          }
        }
      }
    }

    taskRef.current_commitment_partial =
      parseFloat(quantity_parcial).toFixed(2);
    ganttRef.current_commitment_partial = taskRef.current_commitment_partial;
    taskRef.quantity_parcial = parseFloat(quantity_parcial).toFixed(2);
    ganttRef.quantity_parcial = taskRef.quantity_parcial;

    ganttRef.tagResource = tagResource;
  };

  /**
   * This function updates following weekly commit fields:
   * -commitment_percentaje
   * -recalcCommitment
   * -showPureSpanCommitment
   * @param {*} taskRef Task lookahead memory ref
   * @param {*} ganttRef Gantt dhtmlx memory ref
   * @param {*} activityRef Activity ref from lookahead
   */
  const updateWeeklyCommitValues = (taskRef, ganttRef, activityRef) => {
    const parentTask = getParentLookaheadRef(taskRef);

    if (
      parseFloat(taskRef.commitment_percentaje) > 100 ||
      parseFloat(ganttRef.commitment_percentaje) > 100
    ) {
      taskRef.commitment_percentaje = 100;
      ganttRef.commitment_percentaje = 100;
    }

    /** Defines if already have a weekly, and this task is included in it */
    const localActivityRef = localActivitiesMap[activityRef.id];
    const findCommitedTask = haveWeeklyPlan?.taskcommitments?.find(
      (e) => e.taskId === taskRef.id
    );

    if (
      haveWeeklyPlan &&
      localActivityRef &&
      localActivityRef.recalcCommitment &&
      findCommitedTask &&
      !isNaN(findCommitedTask.commitment_percentaje)
    ) {
      activityRef.recalcCommitment = localActivityRef.recalcCommitment;
      calculateCommitment(
        taskRef,
        parentTask || activityRef,
        updateCommitment,
        haveWeeklyPlan,
        activityRef
      );
      const valueColumn = findCommitedTask.commitment_percentaje || 0;
      let percentVal = valueColumn.toFixed
        ? valueColumn.toFixed(2)
        : valueColumn;
      /** Bug fix: 843 */
      volatileTaskData[taskRef.id] = {
        ...volatileTaskData[taskRef.id],
        commitment_percentaje: findCommitedTask.commitment_percentaje
      };
      if (parseFloat(percentVal) > 100) {
        percentVal = 100;
      }
      taskRef.commitment_percentaje = findCommitedTask.commitment_percentaje;
      ganttRef.commitment_percentaje = findCommitedTask.commitment_percentaje;
      taskRef.showPureSpanCommitment = true;
      ganttRef.showPureSpanCommitment = true;
    } else if (!ganttRef.hasCustomCommitmentPercentaje) {
      const task = taskRef;
      const findActivity = activityRef;
      task.expected_cost = calculateExpectedCost(
        task,
        ganttAPI,
        findActivity.calendarId
      );
      ganttRef.expected_cost = task.expected_cost;
      try {
        const expWeek = calculateExpectedForWeek(
          task,
          ganttAPI,
          findActivity.calendarId,
          null,
          dateRange
        );
        task.commitment_percentaje = parseFloat(expWeek).toFixed(2);
        ganttRef.commitment_percentaje = task.commitment_percentaje;
        task.expectedweek = parseFloat(expWeek).toFixed(2);
        ganttRef.expectedweek = task.expectedweek;

        if (task.commitment_percentaje < task.progress) {
          let quantity_parcial = 0;
          let newCompromise = task.progress;
          if (task.total_quantity) {
            newCompromise =
              task.progress + (quantity_parcial * 100) / task.total_quantity;
          }
          task.commitment_percentaje = newCompromise;
          ganttRef.commitment_percentaje = task.commitment_percentaje;
          quantity_parcial = 0;
        }
        const quantity_parcial_percentaje = parseFloat(
          parseFloat(task.commitment_percentaje || 0) -
            parseFloat(task.progress)
        );
        task.quantity_parcial_percentaje = quantity_parcial_percentaje;
        ganttRef.quantity_parcial_percentaje = task.quantity_parcial_percentaje;
      } catch (e) {}
    }
  };

  /**
   * This function search paren task if there exist
   * @param {*} taskRef Child ref object to check his parent
   * @returns Parent lookahead ref for given task ref
   */
  const getParentLookaheadRef = (taskRef) => {
    let parentTask = null;
    if (taskRef) {
      if (taskRef.parent_id) {
        const doesExistAtReferenceParent = findDeepGetTask(
          activities,
          'id',
          taskRef.parent_id
        );
        parentTask = doesExistAtReferenceParent || null;
      }
    }

    return parentTask;
  };

  /**
   * This function update lean status stuff (about restricted behaviour)
   * @param {*} taskRef Task ref to check his lean status
   * @param {*} ganttRef Gantt dhtmlx object ref
   */
  const updateLeanValues = (taskRef, ganttRef) => {
    const parentTask = getParentLookaheadRef(taskRef);

    /** this function intercepts the save call, and adds data */
    const updateData = (taskCallback) => {
      updateAsyncTaskGanttV2(taskCallback, 'lean_status', null, gantt);
    };
    const isTaskRestricted = defineRestrictedTaskByChilds(
      taskRef,
      parentTask,
      updateData
    );

    if (isTaskRestricted || ganttRef?.is_restricted) {
      ganttRef.restricted = true;
    }
  };

  /** This functions updates the current hashmap to handle memory ref,  */
  const updateHashMapMemoryRef = () => {
    if (!gantt) return;
    let resourceMaterial_taks;
    if (resources) {
      resourceMaterial_taks =
        resources &&
        resources.filter(function (rs) {
          return rs.type == 'material';
        });
    }

    const ganttTreeRef = gantt.getTaskByTime();
    ganttTreeRef &&
      ganttTreeRef.forEach((node) => {
        const { taskRef, activityRef } =
          getReferenceFromLookaheadWithActivityRef(node) || {
            taskRef: null,
            activityRef: null
          };
        /** This map includes lookahead view memory ref, acvtivity lookahead memory ref, and finally task at gantt dhtmlx memory ref */
        if (!taskRef) return;
        setTimeout(() => {
          updateWeeklyCommitValues(taskRef, node, activityRef);
        }, 500);
        setTimeout(() => {
          updateWeeklyMaterialValues(resourceMaterial_taks, taskRef, node);
        }, 1500);
        setTimeout(() => {
          updateLeanValues(taskRef, node);
        }, 2500);
      });
    // gantt.lookaheadRefToGanttRef = lookaheadRefToGanttRef
    setTimeout(() => {
      if (gantt.getTaskByTime()) {
        gantt.optimizedRender();
      }
    }, 500);
  };

  /** This function updates the material units of the current hashmap to handle memory ref */
  const updateMemoryRefMaterial = (task, node) => {
    if (!gantt) return;
    let resourceMaterial_taks;
    if (resources)
      resourceMaterial_taks =
        resources && resources.filter((rs) => rs.type == 'material');

    const ganttTreeRef = gantt.getTaskByTime();
    ganttTreeRef &&
      ganttTreeRef.forEach((node) => {
        const { taskRef, activityRef } =
          getReferenceFromLookaheadWithActivityRef(node) || {
            taskRef: null,
            activityRef: null
          };
        /** This map includes lookahead view memory ref, acvtivity lookahead memory ref, and finally task at gantt dhtmlx memory ref */
        if (!taskRef) return;
        if (node.id === task.id) {
          let tagResource = '';
          if (resourceMaterial_taks) {
            const materialObject = resourceMaterial_taks.filter(
              (el) => el.id == taskRef.materialId
            );
            tagResource = materialObject[0]
              ? materialObject[0].material_label
              : null;
          }
          node.tagResource = tagResource;
          return tagResource;
        }
      });
    gantt.optimizedRender();
  };

  /** updates values after each weekly plan update at react, or after autoschedule at gantt */
  const refreshView = () => {
    /** map activities and set hidden, according to filters */
    const lastLevelActivitiesFiltered = lastLevelActivities.activities;
    const filterWeek = ['Will', 'Committed'];

    /** sort by default */
    const data = recursiveTasksFilterWp(
      lastLevelActivitiesFiltered,
      filterWeek,
      dateRange
    );
    const dataFilterActivities = recursiveActivityFilter(data);
    const dataFilterActivitiesShow = dataFilterActivities.filter(
      (el) => el.showWeeklyPlan === true
    );
    const cloneActivities = dataFilterActivitiesShow.filter(
      (el) => el.tasks.length
    );
    const flattenActivities = getFlattenActivities(cloneActivities).filter(
      (el) => el.showWeeklyPlan
    );
    setTasksWeeklyPlan({
      value: flattenActivities
    });
    if (haveWeeklyPlan) {
      /** recalc activity on component activiy */
      cloneActivities.map((act) => {
        act.recalcCommitment = true;
        localActivitiesMap[act.id] = act;
        return act;
      });
    }
    setTimeout(() => {
      updateHashMapMemoryRef();
    }, 1000);
    return cloneActivities;
  };
  gantt && (gantt.refreshView = refreshView);

  /** get reference task (taskFromLookahead) from activities */
  const getReferenceFromLookaheadWithActivityRef = (ganttRefTask) => {
    const task = ganttRefTask;
    const activityReferenceLvl = activities.find(
      (ac) => ac.id == task?.activityReference?.proplannerId
    );
    if (!activityReferenceLvl) return;
    const newLookaheadTaskRef = getTask(task.id, null, activityReferenceLvl);
    if (newLookaheadTaskRef?.length) {
      return {
        taskRef: newLookaheadTaskRef[0],
        activityRef: activityReferenceLvl
      };
    } else {
      return { taskRef: null, activityRef: activityReferenceLvl };
    }
  };

  /** Onrender assign functions */

  /**
   * This function handles common dropdown behaviour at onrender React custom components
   * @param {*} column Current column to define onrender method
   * @param {*} newCommonObject Common props to use any render component
   */
  const defineRenderDropdown = (column, newCommonObject) => {
    if (column.name === 'materialId') {
      column.onrender = (task, node) => {
        return (
          <RenderMaterial
            {...newCommonObject}
            options={materials}
            lastLevelActivities={lastLevelActivities}
            task={task}
            callbackOnClickRemove={dropdownRemoveCallback(task, column)}
            callbackOnClickCreate={() => {
              setActivityResource(task.activityReference);
              setTypeResource('material');
              setVisibleFormResource(true);
              gantt.toHardAssignMaterial = task.id;
            }}
            updateMemoryRefMaterial={() => updateMemoryRefMaterial(task, node)}
          />
        );
      };
    }

    if (column.name === 'machineId') {
      column.onrender = (task, node) => {
        return (
          <RenderMachinery
            {...newCommonObject}
            options={machineries}
            lastLevelActivities={lastLevelActivities}
            task={task}
            callbackOnClickRemove={dropdownRemoveCallback(task, column)}
            callbackOnClickCreate={() => {
              setActivityResource(task.activityReference);
              setTypeResource('machinery');
              setVisibleFormResource(true);
              gantt.toHardAssignMachine = task.id;
            }}
          />
        );
      };
    }

    if (column.name === 'specialtyId') {
      column.onrender = (task, node) => {
        return (
          <RenderSpecialty
            {...newCommonObject}
            options={specialties}
            lastLevelActivities={lastLevelActivities}
            task={task}
            callbackOnClickRemove={dropdownRemoveCallback(task, column)}
            callbackOnClickCreate={() => {
              setActivityResource(task.activityReference);
              setTypeResource('rrhh');
              setVisibleFormResource(true);
              gantt.toHardAssignSpecialty = task.id;
            }}
          />
        );
      };
    }

    if (column.name === 'subcontractId') {
      column.onrender = (task, node) => {
        return (
          <RenderSub
            {...newCommonObject}
            lastLevelActivities={lastLevelActivities}
            task={task}
            subs={toSelectSubs}
            callbackOnClickRemove={dropdownRemoveCallback(task, column)}
            callbackOnClickCreate={() => {
              setVisibleFormSubcontract(true);
              gantt.toHardAssignSubcontract = task.id;
            }}
          />
        );
      };

      column.template = (task) => {
        return TemplateSubcontractId(task);
      };
    }

    if (column.name === 'tags') {
      column.onrender = (task, node) => {
        return (
          <RenderTag
            {...newCommonObject}
            lastLevelActivities={lastLevelActivities}
            task={task}
            tags={toSelectTags}
            callbackOnClickRemove={dropdownRemoveCallback(task, column)}
            callbackOnClickCreate={() => {
              setVisibleFormTags(true);
              gantt.toHardAssignTag = task.id;
            }}
          />
        );
      };

      column.template = (task) => {
        return TemplateTag(task);
      };
    }
    if (column.name === 'responsables') {
      column.onrender = (task, node) => {
        return (
          <RenderResponsible
            {...newCommonObject}
            lastLevelActivities={lastLevelActivities}
            task={task}
            responsables={toSelectResponsables}
            callbackOnClickRemove={dropdownRemoveCallback(task, column)}
            callbackOnClickCreate={() => {
              console.log('create');
            }}
          />
        );
      };

      column.template = (task) => {
        return TemplateResponsible(task);
      };
    }
  };

  const defineRenderName = (column, newCommonObject, index) => {
    if (column.name === 'name') {
      const renderNameProps = {
        ...newCommonObject,
        setEditedInput,
        index,
        tableConfig: tableMetadata,
        projectState,
        updateAsyncActivity,
        updateGanttVisualizationState,
        data,
        showDrawer,
        updateRender
      };
      column.template = (task, node) => {
        return RenderName({
          ...renderNameProps,
          task,
          node,
          gantt,
          updateMemoryRefMaterialValues: () => updateHashMapMemoryRef()
        });
      };
    }
  };

  const defineRenderActions = (column, newCommonObject, index) => {
    if (column.name === 'actions') {
      const renderActionsProps = {
        ...newCommonObject,
        setEditedInput,
        index,
        tableConfig: tableMetadata,
        projectState,
        updateAsyncActivity,
        updateGanttVisualizationState,
        data,
        showDrawer,
        updateRender,
        ganttAPI,
        onlyRead,
        updateTaskDrag
      };
      const updateMemoryRefMaterialValues = updateHashMapMemoryRef;
      column.template = (task, node) => {
        return (
          // <RenderActions {...renderActionsProps} task={task} node={node} gantt={gantt} updateMemoryRefMaterialValues={() => updateHashMapMemoryRef()}/>
          RenderActions({
            ...renderActionsProps,
            task,
            node,
            gantt,
            updateMemoryRefMaterialValues
          })
        );
      };
    }
  };

  const defineRenderRoadblocks = (column, newCommonObject, index) => {
    if (column.name === 'roadblocks') {
      const renderRoadblocksProps = {
        ...newCommonObject,
        setEditedInput,
        index,
        tableConfig: tableMetadata,
        projectState,
        updateAsyncActivity,
        updateGanttVisualizationState,
        data,
        showDrawer,
        updateRender
      };
      column.onrender = (task, node) => {
        return (
          // <RenderRoadblocks {...renderRoadblocksProps} task={task} node={node} gantt={gantt} updateMemoryRefMaterialValues={() => updateHashMapMemoryRef()}/>
          RenderRoadblocks({ ...renderRoadblocksProps, task, node, gantt })
        );
      };

      column.template = (task) => {
        return TemplateRoadblocks(task);
      };
    }
  };
  const defineRenderPriority = (column, newCommonObject) => {
    if (column.name === 'priority') {
      column.onrender = (task, node) => {
        return <RenderPriority {...newCommonObject} task={task} node={node} />;
      };
    }
  };

  const defineRenderNumeric = (column, newCommonObject, index) => {
    if (column.name === 'spend_hm') {
      column.template = (task) => {
        if (!task.isTask) return null;
        let isOdd = false;
        if (gantt.oddColsConfig && task.type === 'activitytask') {
          isOdd = gantt.oddColsConfig[column.name];
        }
        task.spend_hm = (task.progress / 100) * (task.total_hm || 0);
        return t('lang') === 'en'
          ? formatMoney(
              task.spend_hm,
              '',
              Number.isInteger(task.spend_hm) ? 0 : 2,
              '.',
              ','
            )
          : formatMoney(
              task.spend_hm,
              '',
              Number.isInteger(task.spend_hm) ? 0 : 2,
              ',',
              '.'
            );
        /* (
                    <div className={(isOdd ? 'odd-col' : 'non-odd-col')}>
                        <span className="vertical-center">{t('lang') === 'en' ? formatMoney(task.spend_hm.toFixed(2), '', 2, '.', ',') : formatMoney(task.spend_hm.toFixed(2), '', 2, ',', '.')}</span>
                    </div>
                ) */
      };
    }

    if (column.name === 'won_hh') {
      column.template = (task) => {
        if (!task.isTask) return null;
        let isOdd = false;
        if (gantt.oddColsConfig && task.type === 'activitytask') {
          isOdd = gantt.oddColsConfig[column.name];
        }
        const wonHH = (task.progress / 100) * (task.hhWorkTime || 0);
        if (task.won_hh != wonHH) {
          assignValueFromGanttToLookaheadRef(task, wonHH, 'won_hh');
        }
        return t('lang') === 'en'
          ? formatMoney(wonHH, '', Number.isInteger(wonHH) ? 0 : 2, '.', ',')
          : formatMoney(wonHH, '', Number.isInteger(wonHH) ? 0 : 2, ',', '.');
        /*   (
                    <div className={(isOdd ? 'odd-col' : 'non-odd-col')}>
                        <span className="vertical-center">{t('lang') === 'en' ? formatMoney(wonHH.toFixed(2), '', 2, '.', ',') : formatMoney(wonHH.toFixed(2), '', 2, ',', '.')}</span>
                    </div>
                ) */
      };
    }

    if (column.name === 'remaining_quantity') {
      column.template = (task) => {
        if (!task.isTask) return null;
        let isOdd = false;
        if (gantt.oddColsConfig && task.type === 'activitytask') {
          isOdd = gantt.oddColsConfig[column.name];
        }

        const remainQuant =
          (task.total_quantity || 0) - (task.actual_quantity || 0);
        if (task.remaining_quantity != remainQuant) {
          assignValueFromGanttToLookaheadRef(
            task,
            remainQuant,
            'remaining_quantity'
          );
        }
        return t('lang') === 'en'
          ? formatMoney(
              remainQuant,
              '',
              Number.isInteger(remainQuant) ? 0 : 2,
              '.',
              ','
            )
          : formatMoney(
              remainQuant,
              '',
              Number.isInteger(remainQuant) ? 0 : 2,
              ',',
              '.'
            );
        /* (
                    <div className={(isOdd ? 'odd-col' : 'non-odd-col')}>
                        <span className="vertical-center">{t('lang') === 'en' ? formatMoney(remainQuant.toFixed(2), '', 2, '.', ',') : formatMoney(remainQuant.toFixed(2), '', 2, ',', '.')} </span>
                    </div>
                ) */
      };
    }

    if (column.name === 'actual_quantity') {
      column.onrender = (task, node) => {
        if (task.type !== 'activitytask') return null;
        const actualQuanti = (task.progress / 100) * task.total_quantity;
        let newProgress = 0;
        let sw_progress_changed = false;
        if (task.actual_quantity != actualQuanti) {
          const newActualQuanti = task.actual_quantity;
          assignValueFromGanttToLookaheadRef(
            task,
            newActualQuanti,
            'actual_quantity'
          );

          if (task.total_quantity) {
            newProgress = (100 * newActualQuanti) / task.total_quantity;
            sw_progress_changed = true;
          }
          if (newProgress > 100) {
            newProgress = 100;
            task.actual_quantity = task.total_quantity;
            sw_progress_changed = true;
          }
          assignValueFromGanttToLookaheadRef(task, newProgress, 'progress');
        }
        const renderNumericProps = {
          ...newCommonObject,
          task,
          node,
          setEditedInput,
          index,
          onChange: (t, parent, activity) => {
            const e = t.actual_quantity;
            if (sw_progress_changed) {
              calculateProgressForTree(
                task,
                parent,
                activity,
                column,
                newProgress
              );
            }
          }
        };

        return (
          // <RenderNumeric {...renderNumericProps} />
          RenderNumeric({ ...renderNumericProps, task, node })
        );
      };
    }

    if (column.name === 'expected_cost') {
      column.template = (task) => {
        if (task.type !== 'activitytask') return null;
        const newExpectedCost = task.cost * (task.expected / 100);
        if (task.expected_cost != newExpectedCost) {
          assignValueFromGanttToLookaheadRef(
            task,
            newExpectedCost,
            'expected_cost'
          );
        }

        let isOdd = false;
        if (gantt.oddColsConfig && task.type === 'activitytask') {
          isOdd = gantt.oddColsConfig[column.name];
        }
        return t('lang') === 'en'
          ? formatMoney(
              newExpectedCost,
              '$',
              Number.isInteger(newExpectedCost) ? 0 : 2,
              '.',
              ','
            )
          : formatMoney(
              newExpectedCost,
              '$',
              Number.isInteger(newExpectedCost) ? 0 : 2,
              ',',
              '.'
            );
        /* (
                    <div className={(isOdd ? 'odd-col' : 'non-odd-col')}>
                        <span className="vertical-center">{t('lang') === 'en' ? formatMoney(newExpectedCost.toFixed(2), '$', 2, '.', ',') : formatMoney(newExpectedCost.toFixed(2), '$', 2, ',', '.')} </span>
                    </div>
                ) */
      };
    }

    if (
      [
        'hhWorkTime',
        'spend_hh',
        'total_hm',
        'real_endowment',
        'plan_endowment',
        'plan_endowment',
        'cost',
        'total_quantity'
      ].includes(column.name)
    ) {
      const renderNumericProps = {
        ...newCommonObject,
        setEditedInput,
        index,
        onChange: (t, parent, activity) => {
          let projectSelected, taskCriteria;
          if (projectState) {
            projectSelected = projectState?.allProjects?.find(
              (el) => el.id == projectState.projectSelected
            );
            taskCriteria = projectSelected?.task_creter;
          }
          if (column.name === 'cost' || column.name === 'hhWorkTime') {
            if (
              taskCriteria.toUpperCase() === 'COST' ||
              taskCriteria.toUpperCase() === 'HH'
            ) {
              calculatePonderators(
                parent || activity,
                activity,
                (taskCallback) => {
                  updateAsyncTaskGanttV2(
                    taskCallback,
                    'ponderator',
                    parseFloat(taskCallback.ponderator),
                    gantt
                  );
                },
                projectState
              );
            }
          }
        }
      };

      column.template = (task, node) => {
        if (task?.children?.length) {
          getRecursiveFromParentTask(
            task,
            column?.name,
            (updateState) => {},
            updateAsyncTask
          );
        }
        return RenderNumeric({ ...renderNumericProps, task, node });
      };
    }
  };

  const defineWeeklyFields = (column, newCommonObject, index) => {
    const commonWeeklyFieldProps = {
      ...newCommonObject,
      volatileTaskData,
      setEditedInput,
      editedInput,
      resources,
      haveWeeklyPlan,
      lastLevelActivities,
      dateRange,
      getReferenceFromLookahead: getReferenceFromLookaheadWithActivityRef,
      refreshView,
      tasksWeeklyPlan,
      localActivitiesMap,
      updateCommitment
    };

    if (column.name === 'commitment_percentaje') {
      column.template = (task, node) => {
        let isOdd = false;
        if (gantt.oddColsConfig && task.type === 'activitytask') {
          isOdd = gantt.oddColsConfig[column.name];
        }
        const renderWeeklyCommitProps = {
          ...commonWeeklyFieldProps,
          task
        };
        return RenderWeeklyCommit(renderWeeklyCommitProps);
      };
    }

    if (column.name === 'quantity_parcial') {
      column.template = (task, node) => {
        let isOdd = false;
        if (gantt.oddColsConfig && task.type === 'activitytask') {
          isOdd = gantt.oddColsConfig[column.name];
        }
        const renderWeeklyMaterialProps = {
          ...commonWeeklyFieldProps,
          task
        };
        return task ? RenderWeeklyMaterial(renderWeeklyMaterialProps) : '';
      };
    }
  };

  /** Main return */
  /** this function sets the onrender property for each column */
  const renderActivityColums = () => {
    tableMetadata.map((column, index) => {
      /** common params */
      const newCommonObject = { column, activities, gantt, t, onlyRead, index };

      defineRenderDropdown(column, newCommonObject);
      defineRenderName(column, newCommonObject, index);
      defineRenderActions(column, newCommonObject, index);
      defineRenderRoadblocks(column, newCommonObject, index);
      defineRenderPriority(column, newCommonObject);

      if (column.name === 'description') {
        column.template = (task) => {
          return RenderDescription({ ...newCommonObject, task });
        };
      }
      if (column.name === 'start_date') {
        column.template = (task) => {
          const renderStartProps = {
            ...newCommonObject,
            task,
            ganttAPI,
            projectState,
            onlyRead,
            updateTaskDrag
          };
          return renderStart(renderStartProps);
        };
      }
      if (column.name === 'end_date') {
        column.template = (task, node) => {
          const renderEndProps = {
            ...newCommonObject,
            task,
            modalModification,
            setModalModification
          };
          return RenderEnd(renderEndProps);
        };
      }

      if (column.name === 'status') {
        column.template = (task, node) => {
          const renderStatusProps = {
            task,
            gantt
          };
          return RenderStatus(renderStatusProps);
        };
      }

      if (column.name === 'progress') {
        column.template = (task, node) => {
          const renderProgressProps = {
            node,
            task,
            ...newCommonObject,
            projectState,
            updateAsyncActivity,
            tasksWeeklyPlan
          };
          return RenderProgress(renderProgressProps);
        };
      }

      if (column.name === 'duration') {
        const renderDurationProps = {
          ...newCommonObject,
          setEditedInput,
          index,
          ganttAPI,
          projectState
        };
        column.template = (task, node) => {
          return RenderDuration({ ...renderDurationProps, task, node });
        };
      }
      if (column.name === 'taskRoute') {
        column.template = (task, node) => {
          const renderRouteProps = {
            ...newCommonObject,
            task,
            node
          };
          return RenderRoute(renderRouteProps);
        };
      }

      /** Fields only available on this groupping by */
      if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
        defineRenderNumeric(column, newCommonObject, index);
        defineWeeklyFields(column, newCommonObject, index);

        if (['ponderator'].includes(column.name)) {
          column.template = (task) => {
            return RenderPonderator({ ...newCommonObject, task });
          };
        }

        /** Legacy onrender */
        if (column.name === 'lean_status') {
          column.onrender = (task, node) => {
            const renderLeanProps = {
              ...newCommonObject,
              task,
              node
            };
            return <RenderLean {...renderLeanProps} />;
          };

          column.template = (task) => {
            return TemplateLean({ task });
          };
        }
      }
      return column;
    });
  };

  renderActivityColums();
};

export default buildReactRender;

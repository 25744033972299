/* eslint-disable prefer-const */

/** React components  */
import React, { useEffect, useState, Fragment, useRef } from 'react';

/** Import elements from library Antd */
import { Row, Col, Button, Select, Checkbox } from 'antd';

/** import common functions from utils */
import { openNotification } from '../../../utils';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { calendarActions } from '../../../redux/actions/calendarActions';

/** Services */
import {
  calendarExceptionDayService,
  calendarService,
  ganttService,
  workingdayService
} from '../../../services';

//
import cloneDeep from 'lodash/cloneDeep';

/** import components */
import FormDays from '../CalendarForm/FormDays';
import FormExceptionsDays from '../CalendarForm/FormExceptionsDays';

/** component for edit cell */
import Editable from '../CalendarForm/FormExceptionsDays/Editable';
import { withTranslation } from 'react-i18next';

import { trackingEvent } from '../../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';

import shiftIsValid from '../utils/shiftIsValid';
import { activeSectorFlag } from '../../../utils/activeSectorFlag';
import CheckboxSystem from '../../DesignSystem/CheckboxSystem/CheckboxSystem';
import notificationSystemV2 from '../../DesignSystem/NotificationSystemV2';
import TooltipSystemV2 from '../../DesignSystem/TooltipSystemV2';

function CalendarEdit(props) {
  /** traduction */
  const { t, permission } = props;
  /** redux */
  const calendarState = useSelector((state) => state.calendarState);
  const projectState = useSelector((state) => state.projectState);
  const calendarsSelected = calendarState.calendarSelected;
  const dispatch = useDispatch();

  /** use props */
  const { handleCancelEdit, formatShift, add1Hour, loadCalendars } = props;
  const [loading, setLoading] = useState(false); /** handle load */

  const alertCalendarNothingDay = {
    /** notification error some day */ title: t('calendars_form.title'),
    description: t('calendars_form.select_day'),
    type: 'error'
  };
  const alertCalendarNothingWorkingDay = {
    title: t('lang') === 'es' ? 'Ninguna jornada creada' : 'No shift created',
    description:
      t('lang') === 'es'
        ? 'Al menos debes tener una jornada laboral'
        : 'You must have at least one working day',
    type: 'error'
  };
  const alertCalendarAddError = {
    /** notification error */ title: t('calendars_form.title'),
    description: t('calendars_form.review_config'),
    type: 'error'
  };
  const alertCalendarExceptionError = {
    /** notification error exceptions */ title: t('calendars_form.title'),
    description: t('calendars_form.review_excep'),
    type: 'error'
  };

  /** object by defualt for Calendar Form */
  const templatecalendar = {
    name: null,
    days: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftStart_ini: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftEnd_ini: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftStart_end: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftEnd_end: cloneDeep([...Array(7).keys()].map((i) => false)),
    exceptions: [],
    is_default: true
  };

  /** hooks */
  const [currentGantt, setCurrentGantt] = useState();
  const [currentCalendar, setCurrentCalendar] =
    useState(templatecalendar); /** handle calendar state */
  const [toDropWorkingDays, setToDropWorkingDays] = useState([]);

  /** refs */
  const inputRef = useRef();

  /** load data */
  useEffect(() => {
    loadGantt();
    if (calendarsSelected) {
      trackingEvent(
        'calendar_selection',
        {
          ...getBasicAmplitudEventProperties(),
          calendar_name: calendarsSelected?.name,
          calendar_id: calendarsSelected?.id
        },
        AMPLITUDE_SERVICE
      );
    }
  }, []);

  /** services */
  const getGantt = async () => {
    const gannt = await ganttService.showBySector(projectState.sectorSelected);
    return gannt;
  };

  const loadGantt = async () => {
    const gantt = await getGantt();
    if (gantt.gantt?.id) {
      setCurrentGantt(gantt.gantt.id);
    }
  };

  const createExceptionDay = async (data) => {
    const excCreated = await calendarExceptionDayService.create(data);
    return excCreated;
  };

  /** Logic component */
  const deleteException = async (id) => {
    await calendarExceptionDayService.destroy(id);
  };

  const saveExceptions = async (calendarSelected) => {
    /** clean exceptions */
    calendarState.calendarSelected.exceptions.map(async (e, index) => {
      await deleteException(e.id);
    });
    calendarState.calendarForm.exceptions.map(async (exc, index) => {
      const data = {
        name: exc.name ? exc.name : 'name',
        unique_id: new Date().getTime(),
        to_date: exc.to_date,
        from_date: exc.from_date,
        calendarId: calendarSelected.id,
        workable: exc.workable,
        iterable: exc.iterable
      };
      const start = `${isNaN(exc.hourini) ? null : formatShift(exc.hourini)}-${isNaN(exc.hourend) ? null : formatShift(exc.hourend)}`;
      data.shift_start =
        isNaN(exc.hourini) && isNaN(exc.hourini) ? exc.shift_start : start;
      const resException = await createExceptionDay(data);
      if (resException) {
        const asyncExceptionWorkingday = exc.workingDays.map(async (shift) => {
          const toAssignExceptionId = resException.id;
          const formattedIni = formatShift(shift.shift_ini);
          const formattedEnd = formatShift(shift.shift_end);
          const newWorkingDay = {
            shift_string: `${formattedIni}-${formattedEnd}`,
            correlative_id: shift.correlative_id,
            calendarexceptiondayId: toAssignExceptionId
          };
          if (shift.id) {
            newWorkingDay.id = shift.id;
            await workingdayService.update(newWorkingDay);
          } else {
            await workingdayService.create(newWorkingDay);
          }
        });

        await Promise.all(asyncExceptionWorkingday);
      }
    });
  };

  const handleWorkingDays = async (
    toDropWorkingDays,
    toUpdateWorkingDays,
    toCreateWorkingDays
  ) => {
    try {
      // Delete working days
      const asyncDropping = toDropWorkingDays.map(async (workingday) => {
        if (workingday.id) {
          await workingdayService.destroy(workingday.id);
        }
      });
      await Promise.all(asyncDropping);

      // Save new and updated working days
      const asyncMapWorkingDayUpdate = toUpdateWorkingDays.map(
        async (newWorkingDay) => {
          await workingdayService.update(newWorkingDay);
        }
      );
      await Promise.all(asyncMapWorkingDayUpdate);
      const asyncMapWorkingDayCreate = toCreateWorkingDays.map(
        async (newWorkingDay) => {
          await workingdayService.create(newWorkingDay);
        }
      );
      await Promise.all(asyncMapWorkingDayCreate);
    } catch (error) {
      throw error('Error handling working days:', error);
    }
  };

  const separateWorkingDays = (workingDays, calendarId) => {
    return workingDays.reduce(
      (acc, shift) => {
        const formattedIni = formatShift(shift.shift_ini);
        const formattedEnd = formatShift(shift.shift_end);
        const newWorkingDay = {
          shift_string: `${formattedIni}-${formattedEnd}`,
          correlative_id: shift.correlative_id,
          calendarId: calendarId
        };

        if (shift.id) {
          newWorkingDay.id = shift.id;
          acc.toUpdateWorkingDays.push(newWorkingDay);
        } else {
          acc.toCreateWorkingDays.push(newWorkingDay);
        }

        return acc;
      },
      { toUpdateWorkingDays: [], toCreateWorkingDays: [] }
    );
  };

  const handleSubmitEdit = async () => {
    const working_days = calendarState.calendarForm.days
      .map((eld) => eld | 0)
      .join();
    /** working days validation */
    const nothingDaySelect = calendarState.calendarForm.days.every(
      (el) => el === false
    );
    if (nothingDaySelect) {
      openNotification(alertCalendarNothingDay);
      return false;
    }

    /** shift validation */
    let validate = true;
    calendarState.calendarForm.days.map((el, index) => {
      if (el) {
        calendarState.calendarForm.workingDays.map((shift) => {
          if (!shiftIsValid(shift, index)) {
            validate = false;
          }
        });
      }
    });
    if (!validate) {
      openNotification(alertCalendarAddError);
      return false;
    }

    /** Working days presence validation */
    const nothingWorkingDays = calendarState.calendarForm.workingDays;
    if (!nothingWorkingDays.length) {
      openNotification(alertCalendarNothingWorkingDay);
      return false;
    }

    /** validate shift of exceptions */
    const someShiftNotDefined = calendarState.calendarForm.exceptions.some(
      (el) => {
        let isNew =
          el.workable && el.hourend !== undefined && Number.isNaN(el.hourend);
        let isOld =
          el.shift_start === 'null-null' &&
          el.workable &&
          Number.isNaN(el.hourend);
        return isNew || isOld;
      }
    );
    if (someShiftNotDefined) {
      openNotification(alertCalendarExceptionError);
      return false;
    }

    try {
      const data = {
        id: calendarState.calendarSelected.id,
        name: calendarState.calendarForm.name,
        unique_id: calendarState.calendarSelected.unique_id,
        working_days: working_days,
        sectorId: projectState.sectorSelected,
        ganttId: currentGantt,
        is_default: calendarState.calendarForm.is_default
      };
      setLoading(true);
      const calendarId = data.id;
      const { toUpdateWorkingDays, toCreateWorkingDays } = separateWorkingDays(
        calendarState.calendarForm.workingDays,
        calendarId
      );

      await calendarService.update(data);

      await handleWorkingDays(
        toDropWorkingDays,
        toUpdateWorkingDays,
        toCreateWorkingDays
      );

      if (calendarState.calendarForm.exceptions) {
        await saveExceptions(calendarState.calendarSelected);
      }

      notificationSystemV2({
        type: 'success',
        key: 'handle-calendar-update-success',
        message: t('calendars_form.title'),
        description: t('calendars_form.update.success')
      });
      setLoading(false);

      const isPrevDefaultCalendar = calendarState.calendarSelected.is_default;
      const isNewDefaultCalendar = calendarState.calendarForm.is_default;

      if (!isPrevDefaultCalendar && isNewDefaultCalendar) {
        trackingEvent(
          'select_calendar_as_default',
          {
            ...getBasicAmplitudEventProperties(),
            calendar_name: calendarState.calendarSelected?.name,
            calendar_id: calendarState.calendarSelected?.id
          },
          AMPLITUDE_SERVICE
        );
      }
      trackingEvent(
        'calendar_changes_saving',
        {
          ...getBasicAmplitudEventProperties(),
          calendar_name: calendarState.calendarSelected?.name,
          calendar_id: calendarState.calendarSelected?.id
        },
        AMPLITUDE_SERVICE
      );
      activeSectorFlag('update_ponderators_masterplan', true);

      setToDropWorkingDays([]);
      loadCalendars();
      handleCancelEdit();
    } catch (error) {
      notificationSystemV2({
        type: 'error',
        key: 'handle-calendar-update-error',
        message: t('calendars_form.title'),
        description: t('calendars_form.update.error')
      });
      console.error('Error editing calendar:', error);
    }
  };

  const handleWrite = (e) => {
    dispatch(
      calendarActions.setCalendarForm({
        ...calendarState.calendarForm,
        name: e.target.value
      })
    );
  };

  const handleChangeCheckbox = (e) => {
    dispatch(
      calendarActions.setCalendarForm({
        ...calendarState.calendarForm,
        is_default: e.target.checked
      })
    );
  };
  const prevCheckedValue = calendarState?.calendarSelected?.is_default;
  const actualCheckedFormValue = calendarState?.calendarForm?.is_default;

  const lockCheckForm = prevCheckedValue && actualCheckedFormValue;
  /** render */
  return calendarsSelected ? (
    <Fragment>
      {/* days, schedule, calendar */}
      <Row className="calendarsEdit div-title">
        <Col span={12}>
          <div className="title">
            <Editable
              text={calendarState.calendarForm.name}
              placeholder="Name"
              childRef={inputRef}
              type="input"
              className="input-table-edit">
              <input
                ref={inputRef}
                type="text"
                name="task"
                className="inputInline-edit"
                placeholder="Name"
                value={calendarState.calendarForm.name}
                onFocus={() => {
                  trackingEvent(
                    'set_calendar_name',
                    {
                      ...getBasicAmplitudEventProperties(),
                      event_source: 'calendar_edition'
                    },
                    AMPLITUDE_SERVICE
                  );
                }}
                onChange={(e) => handleWrite(e)}
                autoComplete="off"
              />
            </Editable>
          </div>
          <div className="subtitle">
            {t('master_plan_calendars.confirure_text')}
          </div>
        </Col>
        <Col span={12}>
          <div className="title">
            {t('master_plan_calendars.confirure_text_default')}
          </div>
          <div className="subtitle">
            {lockCheckForm ? (
              TooltipSystemV2({
                title: t('calendars_form.tooltips.default_calendar_lock'),
                placement: 'right',
                children: (
                  <div>
                    <CheckboxSystem
                      checked={actualCheckedFormValue}
                      disabled={true}
                    />
                  </div>
                )
              })
            ) : (
              <div>
                <CheckboxSystem
                  checked={actualCheckedFormValue}
                  onChange={(e) => handleChangeCheckbox(e)}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row className="calendarsEdit">
        <Col span={24}>
          <div className="title">
            {t('master_plan_calendars.confirure_text_workin_days')}
          </div>
        </Col>
        <FormDays
          toDropWorkingDays={toDropWorkingDays}
          currentCalendar={currentCalendar}
          calendarsSelected={calendarsSelected}
          setCurrentCalendar={setCurrentCalendar}
          add1Hour={add1Hour}
        />
      </Row>

      {/* exceptions */}
      <Row className="calendarsEdit">
        <Col span={24}>
          <div className="title">
            {t('master_plan_calendars.confirure_text_exception')}
          </div>
        </Col>
      </Row>
      <Row className="calendarsEdit">
        <Col span={24}>
          <FormExceptionsDays
            toDropWorkingDays={toDropWorkingDays}
            currentCalendar={currentCalendar}
            setCurrentCalendar={setCurrentCalendar}
            add1Hour={add1Hour}
          />
        </Col>
      </Row>

      {/* buttons */}
      <Row className="calendarsEdit" style={{ marginBottom: 20 }}>
        <Col span={24}>
          <div className="footerBtns">
            <a
              href="#/"
              onClick={(e) => handleCancelEdit(e)}
              className="refLink skipLink">
              {t('master_plan_calendars.cancel')}
            </a>
            <Button
              disabled={permission === 'V'}
              loading={loading}
              onClick={() => handleSubmitEdit()}
              className={
                permission === 'V'
                  ? 'editSubmit disabled'
                  : 'btnSubmit editSubmit'
              }>
              {t('master_plan_calendars.save')}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  ) : (
    <div className="empty">
      {t('master_plan_calendars.calendar_not_selected')}
    </div>
  );
}

export default withTranslation()(CalendarEdit);

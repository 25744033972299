import moment from 'moment';
import { debounce } from 'lodash';
import { lookaheadActions } from '../../../redux/actions/lookaheadActions';
import {
  avoidOptimizationTimelineBlinking,
  updateTaskMassive,
  addToQueueToSave,
  validateMinMax,
  getReferences,
  updateReferences,
  updateParentDuration,
  assignCorrelativeNumbers,
  disabledDragDrop,
  assignCorrelativeNumbersLookahead,
  sortByCorrelativeNumber,
  updateAsyncParentRecursive,
  checkIfTaskHasChildren
} from '../GanttVisualization.helper';
import EventEmitter from 'react-native-eventemitter';
import { ganttAPI } from '../../../utils/customGanttPlugin';
import {
  calculatePonderators,
  getEndDateByGantt
} from '../../../utils/lookahead-common';
import { transformHourToDays } from '../../../views/ganttContainer/gantt/gantt.helper';
import { notifyMessage } from '../../../utils/lookahead-common';
import { openNotification } from '../../../utils';
import { findDeepGetActivityOfTask } from '../../../views/lookahead/planification/index.helper';
import { findDeepGetTask } from '../GanttVisualization.helper';
import onAfterTaskDrag from './onAfterTaskDrag';
import cloneDeep from 'lodash/cloneDeep';
import { onBeforeTaskChanged } from './onBeforeTaskChanged';
import { keepHoursWhileDrag } from './onTaskDrag/helpers';
import { getStartAndEndHours } from './utils';
import { getSessionTokenData } from '../../../utils/userUtils';
import { TaskType } from '../../../constants/taskType.constants';
import { trackingEvent } from '../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';

/**
 * This function handles creating the events array to unplug when component dies.
 * Also has the definition of each event that are actually documented by dhtmlx
 * @param {*} gantt Gantt dhtmlx instance object
 * @param {*} userPreferenceTableGantt State comming from redux about view preferences
 * @param {*} dispatch Dispatch redux function to call lookahead actions defined at redux folder
 * @param {*} markersDates markersdates comming from view which marks start and end for lookahead
 * @param {*} updateTaskDrag Callback function handle drag gantt API dates changes
 * @param {*} t Translation object
 * @returns Array with attached events from gantt API
 */
export const buildEvents = (
  gantt,
  userPreferenceTableGantt,
  dispatch,
  markersDates,
  updateTaskDrag,
  t,
  saveColumnOrder,
  resizeColumnsTimeLine,
  colorSchema,
  projectState
) => {
  if (gantt.allEventsVar) {
    while (gantt.allEventsVar?.length) {
      gantt.detachEvent(gantt.allEventsVar.pop());
    }
  }
  const events = [];
  /** Check .utils.js extension file to check function */
  // events.push(gantt.attachEvent('onMultiSelect', gantt.handleMultiselectionScheduler))

  events.push(
    gantt.ext.inlineEditors.attachEvent('onBeforeSave', (state) => {
      const { columnName, newValue, oldValue, id } = state;
      const datesInputs = ['start_date', 'end_date', 'constraint_date'];
      if (datesInputs.includes(columnName)) {
        if (
          gantt.validateFormatDay &&
          !gantt.validateFormatDay(gantt.stringDateInput)
        ) {
          return false;
        }
      }

      const fieldsToValidateDate = ['start_date', 'end_date'];
      const task = gantt.getTask(id);

      if (columnName === 'description') {
        setTimeout(() => {
          try {
            task.onSaveDescription && task.onSaveDescription(newValue);
          } catch (e) {
            console.log(e);
          }
        }, 50);
      }

      if (
        fieldsToValidateDate.includes(columnName) &&
        gantt.checkLongTaskDate &&
        !gantt.checkLongTaskDate(newValue)
      ) {
        return false;
      }
      if (columnName === 'start_date') {
        if (
          gantt.currentDateFormat.split(' ').length === 1 &&
          task.start_date.getTimezoneOffset() <= 0
        ) {
          newValue.setDate(newValue.getDate() - 1);
        }
        setTimeout(() => {
          try {
            task.onChangeStart && task.onChangeStart(newValue);
          } catch (e) {
            console.log(e);
          }
        }, 50);
        return false;
      }

      if (columnName === 'progress') {
        console.log(parseFloat(state.newValue));
        const n = parseFloat(state.newValue);
        if (n > 100 || n < 0 || isNaN(n)) {
          return false;
        }
        setTimeout(() => {
          try {
            task.onChangeProgress && task.onChangeProgress(n);

            trackingEvent(
              'task_progress_edition',
              {
                ...getBasicAmplitudEventProperties(),
                task_id: task?.id,
                task_name: task?.name,
                parent_activity_name: task?.activityReference.name,
                parent_activity_id: task?.activityReference.proplannerId
              },
              AMPLITUDE_SERVICE
            );
          } catch (e) {
            console.log(e);
          }
        }, 50);
      }

      if (columnName === 'commitment_percentaje') {
        const n = parseFloat(state.newValue);
        if (n <= 0 || isNaN(n)) {
          notifyMessage({
            title: t('not_valid_committed_title'),
            message: t('not_upper_committed_min'),
            type: 'error'
          });
          return false;
        } else if (n > 100) {
          notifyMessage({
            title: t('not_valid_committed_title'),
            message: t('not_upper_committed_max'),
            type: 'error'
          });
          return false;
        } else if (parseFloat(n) < parseFloat(task.progress)) {
          notifyMessage({
            title: t('not_valid_committed_title'),
            message: t('not_upper_committed'),
            type: 'error'
          });
          return false;
        }
        setTimeout(() => {
          try {
            task.onChangeWeeklyCommitment &&
              task.onChangeWeeklyCommitment(newValue);
          } catch (e) {
            console.log(e);
          }
        }, 50);

        return false;
      }

      if (columnName === 'name') {
        if (newValue.replaceAll(' ', '') === '') return false;
        setTimeout(() => {
          try {
            task.name = newValue;
            task.onTextChange && task.onTextChange(newValue);
          } catch (e) {
            console.log(e);
          }
        }, 50);
      }

      if (columnName === 'quantity_parcial') {
        const n = parseFloat(state.newValue);
        if (parseFloat(n) > parseFloat(task.remaining_quantity)) {
          notifyMessage({
            title: t('not_valid_committed_title'),
            message: t('not_upper_remaining'),
            type: 'error'
          });
          return false;
        } else if (parseFloat(n) < 0) {
          return false;
        }
        setTimeout(() => {
          try {
            task.onChangeWeekMaterial && task.onChangeWeekMaterial(newValue);
          } catch (e) {
            console.log(e);
          }
        }, 50);
      }

      if (columnName === 'duration') {
        const e = parseFloat(state.newValue);
        if (parseFloat(e) <= 0) {
          openNotification({
            title: t('master_plan.duration'),
            description: t('lookahead.duration_min_alert'),
            type: 'warning',
            key: 'duration'
          });
          return false;
        } else if (parseFloat(e) > 200) {
          openNotification({
            title: t('master_plan.duration'),
            description: t('lookahead.duration_max_alert'),
            type: 'warning',
            key: 'duration'
          });
          return false;
        }
        setTimeout(() => {
          try {
            if (newValue && newValue !== oldValue) {
              task.durationDays = newValue;
              trackingEvent(
                'task_duration edition',
                {
                  ...getBasicAmplitudEventProperties(),
                  task_id: task?.id,
                  task_name: task?.name,
                  parent_activity_name: task?.activityReference.name,
                  parent_activity_id: task?.activityReference.proplannerId
                },
                AMPLITUDE_SERVICE
              );
            }
            task.onDurationColum && task.onDurationColum(e);
          } catch (e) {
            console.log(e);
          }
        }, 50);
      }

      if (
        [
          'hhWorkTime',
          'spend_hh',
          'total_hm',
          'real_endowment',
          'plan_endowment',
          'cost',
          'total_quantity',
          'actual_quantity'
        ].includes(columnName)
      ) {
        if (newValue.replaceAll(' ', '') === '') return false;
        let value = parseFloat(state.newValue);
        if (isNaN(value)) return false;
        if (value < 0) return false;
        if (newValue === 0 || newValue.length >= 16) return false;

        if (['real_endowment', 'plan_endowment'].includes(columnName)) {
          value = Math.round(parseFloat(value));
        }

        value = Number(
          parseFloat(value).toFixed(Number.isInteger(value) ? 0 : 2)
        );

        task['onChangeNumeric_' + columnName] &&
          task['onChangeNumeric_' + columnName](value);
      }
    })
  );

  events.push(
    gantt.ext.zoom.attachEvent('onAfterZoom', (level, config) => {
      /** Disabled auto scroll */
      gantt.config.scroll_on_click = false;
      let current_zoom = 'MONTH';

      switch (level) {
        case 0:
          current_zoom = 'YEARS';
          break;
        case 4:
          current_zoom = 'DAYS';
          break;
        case 3:
          current_zoom = 'WEEKS';
          break;
        case 2:
          current_zoom = 'MONTH';
          break;
        case 1:
          current_zoom = 'QUARTERS';
          break;
      }

      if (!gantt.ext) return;
      if (!gantt.ext.zoom) return;
      if (!gantt.ext.zoom.getCurrentLevel) return;
      const currentZoomLevel = gantt.ext.zoom.getCurrentLevel();
      const stringValues = ['YEARS', 'QUARTERS', 'MONTH', 'WEEKS', 'DAYS'];
      const stringCurrentValue = stringValues[currentZoomLevel];
      if (!stringCurrentValue) return;
      gantt.stringCurrentValue = stringCurrentValue;
      EventEmitter.emit('changeScaleVisualization', {
        value: gantt.stringCurrentValue
      });
    })
  );

  events.push(
    gantt.ext.inlineEditors.attachEvent('onBeforeEditStart', (state) => {
      /** get duration in days in an auxiliary variable */
      if (state.columnName === 'duration') {
        const isOnlyReadElement = gantt?.config?.readonly;
        if (isOnlyReadElement) return false;

        const taskGantt = gantt.getTask(state.id);
        taskGantt.durationDays = parseFloat(
          transformHourToDays(taskGantt.duration)
        );
      }

      /** do not allow editing of activities */
      const getItem = gantt.getTask(state.id);
      let taskKeyBoardNavigation = null;
      if (gantt.config.keyboard_navigation)
        taskKeyBoardNavigation =
          gantt?.$keyboardNavigation?.dispatcher?.activeNode?.taskId;
      if (
        state.columnName === 'name' &&
        !gantt.isSelectedTask(state.id) &&
        taskKeyBoardNavigation === null
      ) {
        gantt.selectTask(state.id);
        return false;
      } else if (
        getItem.isParentTask &&
        state.columnName !== 'name' &&
        state.columnName !== 'description'
      ) {
        return false;
      }
      if (!getItem.isTask) return false;

      if (
        state.columnName === 'commitment_percentaje' ||
        state.columnName === 'quantity_parcial'
      ) {
        if (gantt.isWeeklyCommited && !gantt.isWeeklyCommited.closed) {
          return false;
        }
      }
      const inputName = state.columnName;
      gantt.currentActiveInputName = inputName;

      if (getItem['disabledNumeric_' + state.columnName]) return false;

      return true;
    })
  );

  /**
   * This function is responsible for executing all events after changing the duration
   * @param {*} state is the task whose duration was edited
   */
  const eventsDuration = (state) => {
    gantt.batchUpdate(() => {
      const arrToUpdate = [];

      /** get task from gantt */
      const taskGantt = gantt.getTask(state.id);

      /** validations */
      validateMinMax(taskGantt, t);

      /** get activity, task, and parent references */
      const { activityReference, taskFromLookahead, parentTask } =
        getReferences(taskGantt);

      /** commitment percentaje */
      gantt.updateCustomCommitmentPercentaje(taskGantt, false);

      /** calculate End date with new duration in Days */
      taskGantt.duration = parseFloat(taskGantt.durationDays);
      getEndDateByGantt(taskGantt, activityReference);

      /** calculate new Duration with calendar */
      const durationToSave = ganttAPI.calculateDuration(
        taskGantt.start_date,
        taskGantt.end_date,
        taskGantt.calendar_id
      );

      /** update references with new duration and end date */
      updateReferences(taskGantt, taskFromLookahead, durationToSave);

      /** Calculates ponderators feature */
      calculatePonderators(
        parentTask || activityReference,
        activityReference,
        (taskCallback) => {
          gantt.updateTask(taskCallback.id);
          addToQueueToSave(arrToUpdate, taskCallback);
        },
        projectState
      );

      /** refres view */
      gantt.updateTask(taskFromLookahead.id);

      addToQueueToSave(arrToUpdate, taskFromLookahead);

      /** update parent */
      if (taskFromLookahead.parent_id) {
        updateParentDuration(
          taskFromLookahead.parent_id,
          activityReference,
          arrToUpdate,
          gantt,
          ganttAPI,
          projectState
        );
      }

      /** save data */
      updateTaskMassive(arrToUpdate);

      setTimeout(() => {
        try {
          taskGantt.onChangeProgress &&
            taskGantt.onChangeProgress(taskGantt.progress);
        } catch (e) {
          console.log(e);
        }
      }, 50);

      /** check inside weekly */
      setTimeout(() => {
        try {
          gantt.checkIfTaskIsInsideOfWeekly(taskGantt);
        } catch (e) {
          console.log(e);
        }
      }, 600);
    });
  };

  events.push(
    gantt.ext.inlineEditors.attachEvent('onEditEnd', (state) => {
      if (state.columnName === 'duration') {
        eventsDuration(state);
      }
      const getItem = gantt.getTask(state.id);
      const isRunning = gantt?.attachToStack(
        state.id,
        'refreshVisualizations',
        600
      );
      if (!isRunning) return;
      gantt.refreshVisualizations &&
        gantt.refreshVisualizations({ id: state.id });
    })
  );

  events.push(
    gantt.attachEvent('onAfterAutoSchedule', (taskId, updatedTasks) => {
      gantt.refreshView && gantt.refreshView();
      setTimeout(() => {
        try {
          if (gantt.getTaskByTime()) {
            gantt.render();
          }
        } catch (e) {
          console.log(e);
        }
      }, 7000);
    })
  );

  // console.log(gantt.allEventsVar)
  events.push(
    gantt.attachEvent('onTaskClick', (id, e) => {
      if (e.ctrlKey || e.metaKey) {
        const elRef = gantt.getTask(id);
        const activityRefId = elRef?.activityReference?.proplannerId || null;
        gantt.updateDOMCheckbox && gantt.updateDOMCheckbox(id, activityRefId);
        return false;
      }

      return true;
    })
  );

  events.push(
    gantt.attachEvent('onEmptyClick', (e) => {
      gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
    })
  );

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      try {
        gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
      } catch (e) {}
    }
  });

  events.push(
    gantt.attachEvent('onGanttRender', () => {
      setTimeout(() => {
        try {
          if (gantt.currentActiveInputName) {
            const nodes = document.getElementsByName(
              gantt.currentActiveInputName
            );
            const node = nodes[0];
            if (node && node.focus) {
              node.focus();
            }
          }
        } catch (e) {
          console.log(e);
          if (gantt.Sentry) {
            gantt.Sentry.captureMessage(e.message, 'warning');
          }
        }
      }, 500);
    })
  );

  events.push(
    gantt.attachEvent('onGanttReady', () => {
      const grid = window.ganttVisualization.$ui.getView('grid');
      window.ganttVisualization.changeVisualizationOption(colorSchema);
      grid.attachEvent('onAfterColumnReorder', (reorderData) => {
        avoidOptimizationTimelineBlinking();
        saveColumnOrder(reorderData);
        return true;
      });

      grid.attachEvent('onBeforeColumnDragStart', (column, index) => {
        /** the actions column can't be moved */
        if (column?.draggedColumn?.name === 'actions') {
          return false;
        }
        return true;
      });
    })
  );

  events.push(
    gantt.attachEvent('onGridResizeEnd', (_old_width, new_width) => {
      avoidOptimizationTimelineBlinking();
      resizeColumnsTimeLine({ newWidth: new_width });
      setTimeout(() => {
        if (gantt.getTaskByTime()) {
          try {
            gantt.adjustGridWidth();
            gantt.optimizedRender();
          } catch (e) {
            console.log(e);
          }
        }
      }, 1000);
      return true;
    })
  );

  const isOdd = (num) => num % 2;
  gantt.oddColsConfig = {};
  events.push(
    gantt.attachEvent('onBeforeGanttRender', () => {
      gantt
        .getGridColumns()
        .filter((el) => !el.hide)
        .forEach((el, index) => {
          const columnName = el.name;
          if (isOdd(index)) {
            gantt.oddColsConfig[columnName] = false;
          } else {
            gantt.oddColsConfig[columnName] = true;
          }
        });
    })
  );

  events.push(
    gantt.attachEvent('onGridResizeEnd', (_old_width, new_width) => {
      try {
        const { lookaheadState, currentIdxView } = userPreferenceTableGantt;
        lookaheadState.configViews[currentIdxView].ganttChart = new_width;
        dispatch(
          lookaheadActions.setLookaheadViewsConfig(lookaheadState.configViews)
        );
      } catch (e) {
        console.log(e);
        if (gantt.Sentry) {
          gantt.Sentry.captureMessage(
            'Error when using view in lookahead',
            'warning'
          );
        }
      }
      return true;
    })
  );

  const updateCollapsedViewData = (id, newValue) => {
    const collapsedMap = window.defaultView.current.collapsed;
    let response = collapsedMap;
    if (!collapsedMap) {
      response = {
        [id]: newValue
      };
    } else {
      response = {
        ...collapsedMap,
        [id]: newValue
      };
    }

    if (window.updateUserView) {
      window.updateUserView('collapsed', response);
    }
  };

  events.push(
    gantt.attachEvent('onTaskOpened', (id) => {
      updateCollapsedViewData(id, false);
    })
  );

  events.push(
    gantt.attachEvent('onTaskClosed', (id) => {
      updateCollapsedViewData(id, true);
    })
  );

  events.push(
    gantt.attachEvent(
      'onColumnResizeEnd',
      (_index, configColumn, new_width) => {
        try {
          const { lookaheadState, currentIdxView } = userPreferenceTableGantt;
          const columnsSettings =
            lookaheadState.configViews[currentIdxView].view.columns_json
              .settings;
          const columnIdx = columnsSettings.findIndex(
            (column) => configColumn.name === column.name
          );
          lookaheadState.configViews[currentIdxView].view.columns_json.settings[
            columnIdx
          ].width = new_width;
          dispatch(
            lookaheadActions.setLookaheadViewsConfig(lookaheadState.configViews)
          );

          avoidOptimizationTimelineBlinking();
          resizeColumnsTimeLine({
            columnRizised: configColumn,
            newWidth: new_width
          });
          setTimeout(() => {
            if (gantt.getTaskByTime()) {
              try {
                gantt.adjustGridWidth();
              } catch (e) {
                console.log(e);
              }
            }
          }, 1000);
        } catch (e) {
          console.log(e);
          if (gantt.Sentry) {
            gantt.Sentry.captureMessage(
              'Error when using view in lookahead',
              'warning'
            );
          }
        }
        return true;
      }
    )
  );

  events.push(gantt.attachEvent('onBeforeLightbox', (id) => false));
  events.push(
    gantt.attachEvent('onParse', () => {
      window.loader.show();

      gantt.batchUpdate(() => {
        gantt.eachTask((task) => {
          task.duration = task.duration_milestone_bugged;

          if (gantt.defaultCalendar) {
            if (!task.calendar_id) {
              task.calendar_id = gantt.defaultCalendar;
              task.assignedDefaultCalendar = true;
            }
          }

          if (task.should_correct_start_date) {
            gantt.updateTaskTiming(task, task.non_parsed_original_start_date);
          }

          /** Weekly plan new feature */
          gantt.checkIfTaskIsInsideOfWeekly &&
            gantt.checkIfTaskIsInsideOfWeekly(task);
        });
      });
      gantt.sort('correlative_id');
      const today = new Date();
      gantt.addMarker({
        start_date: today,
        css: 'today-custom-timeline',
        text: t('today_label_only'),
        title:
          t('today_label_only') +
          ': ' +
          gantt.date.date_to_str(gantt.config.task_date)(today)
      });

      markersDates.map((mark) => {
        const today = new Date(mark.date);
        gantt.addMarker({
          start_date: today,
          css: 'today lookahead-date-marker',
          /* text: mark.label, */
          title: gantt.date.date_to_str(gantt.config.task_date)(today)
        });
      });

      gantt.updateExpectedGlobal();
      setTimeout(() => {
        if (gantt.getTaskByTime()) {
          try {
            gantt.adjustGridWidth();
            window.loader.hide();
          } catch (e) {
            console.log(e);
          }
        }
      }, 100);
      if (window.defaultView && window.defaultView.current) {
        const defaultView = window.defaultView.current;
        const { collapsed } = defaultView;
        if (collapsed) {
          Object.keys(collapsed).forEach((taskId) => {
            const isCollapsed = collapsed[taskId];
            const doesExistTask = gantt.isTaskExists(taskId);
            if (isCollapsed && doesExistTask) {
              const task = gantt.getTask(taskId);
              task.$open = false;
            }
          });
        }
        gantt.render();
      }
    })
  );

  events.push(
    gantt.attachEvent('onGanttScroll', (left, top) => {
      /** This flag gets when an scroll is coming from a editiong instead scrolling, so then, we just let it work as it should at gantt.options.js file */
      if (!gantt.optimizeReactRender && gantt.comesFromV2Updating) {
        setTimeout(() => {
          gantt.comesFromV2Updating = false;
        }, 500);
        return;
      }

      /** This flag detects first load, which includes an scroll into today date position, this must be avoided by the optimized render at gantt.option.js */
      if (gantt.scrollingInitialToToday) {
        gantt.optimizeReactRender = false;
        return;
      }

      /** Normal flow goes with a normal scroll user process, which obviously must be optimized */
      gantt.optimizeReactRender = true;
      gantt.ext.inlineEditors.hide();
    })
  );

  events.push(
    gantt.attachEvent('onTaskUnselected', (id) => {
      if (!gantt.isTaskExists(id)) return;
      const ganttRef = gantt.getTask(id);
      if (ganttRef.checked && ganttRef.isTask) {
        gantt.selectTask(id);
      }
    })
  );

  events.push(
    gantt.attachEvent('onBeforeTaskDisplay', (id, activity) => {
      const { hide, type, proplannerId } = activity;
      if (hide) return false;
      const isDataFiltered = gantt.isDataFiltered;
      if (type === TaskType.MILESTONE && isDataFiltered) return false;

      if (type == TaskType.MAIN && isDataFiltered) {
        const taskActivity = gantt.getTaskBy((task) => {
          const { type, activityReference, hide } = task;
          if (hide) return false;

          const isActivityTask = type === TaskType.ACTIVITY_TASK;
          const isSubTaskActivity =
            activityReference &&
            activityReference.proplannerId === proplannerId;
          if (isActivityTask && isSubTaskActivity) return true;
        });

        if (!taskActivity) return false;
      }

      return true;
    })
  );

  /** IMPORTANT: This event include all selected task in timeline */
  events.push(
    gantt.attachEvent('onTaskDrag', (id, mode, task, original) => {
      const state = gantt.getState();
      const minDate = state.min_date;
      const maxDate = state.max_date;

      /** Bug fix: This fix, resize drag behaviour */
      if (task.is_open_lightbox) {
        task.for_disable_milestone_duration = task.duration;
      }

      const scaleStep =
        gantt.date.add(new Date(), state.scale_step, state.scale_unit) -
        new Date();

      /**  Bug fix: PP-4483 - PP-4645 */
      if (mode === 'move') {
        task.start_date.setHours(0, 0, 0, 0);
        task.end_date.setHours(0, 0, 0, 0);
      }
      /**  Bug fix: PP-4483 - PP-4645 */

      let showDate;
      let repaint = false;

      if (mode === 'resize') {
        keepHoursWhileDrag(task);
      }

      if (mode == 'resize' || mode == 'move') {
        if (Math.abs(task.start_date - minDate) < scaleStep) {
          showDate = task.start_date;
          repaint = true;
        } else if (Math.abs(task.end_date - maxDate) < scaleStep) {
          showDate = task.end_date;
          repaint = true;
        }

        if (repaint) {
          gantt.optimizedRender();
          gantt.showDate(showDate);
        }
      }
    })
  );

  function getAllCheckedTasksByTaskOnly(gantt) {
    let currentGantt = gantt || window.to_use_react_gantt;
    return currentGantt
      .getTaskBy(
        (t) =>
          (t.visibleChecked || t.checked || t.mustApplyVisibleChecked) &&
          t.type == 'activitytask' &&
          !t?.isParentTask
      )
      .map((t) => t.id);
  }

  /** IMPORTANT: This event on multi select movments dont handle all selected task. only clicked and dragged will be called inside of this event */
  events.push(
    gantt.attachEvent('onBeforeTaskDrag', function (id, mode, e) {
      const checkIfMultipleTasksAreSelected = () => {
        gantt.getAllCheckedTasks = getAllCheckedTasksByTaskOnly(gantt);

        if (getAllCheckedTasksByTaskOnly(gantt).length > 1) {
          gantt.dragWithMultiple = true;
          gantt.avoidAutoScheduleBeforeDrag = true;
        }
      };
      let tasksToDrag;
      checkIfMultipleTasksAreSelected();
      if (gantt.dragWithMultiple) {
        tasksToDrag = getAllCheckedTasksByTaskOnly(gantt);
      } else {
        tasksToDrag = [id];
      }

      let fist_task_in_move_back = false;
      let currentSector = JSON.parse(sessionStorage.getItem('currentSector'));

      tasksToDrag.forEach((id) => {
        const currentDragTask = gantt.getTask(id);
        if (!currentDragTask) return;

        if (fist_task_in_move_back === false) {
          fist_task_in_move_back = currentDragTask;
        }

        if (currentDragTask.start_date < fist_task_in_move_back.start_date) {
          fist_task_in_move_back = currentDragTask;
        }
      });
      tasksToDrag.forEach((id) => {
        const currentDragTask = gantt.getTask(id);
        if (!currentDragTask) return;
        const { start_date, duration, end_date } = currentDragTask;
        const calendar = gantt.getCalendar(currentDragTask.calendar_id);
        let difference =
          calendar.calculateDuration(
            fist_task_in_move_back.start_date,
            start_date,
            currentDragTask.calendar_id
          ) / currentSector.hoursPerDay;
        const backupDates = {
          start_date,
          duration,
          end_date,
          difference
        };
        currentDragTask.backupDates = cloneDeep(backupDates);
        currentDragTask.calendarWorkingHours =
          getStartAndEndHours(currentDragTask);
      });

      gantt.fist_task_in_move_back = fist_task_in_move_back;

      const isGrouped =
        window.groupBy.criteria != 'activity' &&
        window.groupBy.criteria != 'activityId';
      if (isGrouped) return;

      const a = gantt.getTask(id);
      if (a.proplannerId) return;
      if (a.is_parent) return;
      // if (a.restricted) return

      gantt.maxPerformance = true;
      const modes = gantt.config.drag_mode;
      const temp_task_var = gantt.getTask(id);
      temp_task_var.auto_scheduling = false;
      switch (mode) {
        case modes.move:
          temp_task_var.modeDragAux = 'move';
          /** Verify if task has progress to not allow moving */
          if (temp_task_var.progress > 99.999) {
            setTimeout(() => {
              gantt.maxPerformance = false;
            }, 1000);
            // end_warning_message(gantt)('Movimiento no permitido: Intentas mover una tarea con avance.')
            return false;
          }

          /** Verifiy if task is at lookahead to not allow moving
            if (temp_task_var.is_lookahead) {
            send_warning_message(gantt)('Movimiento no permitido: Intentas modificar la fecha de inicio de una tarea que esta en el lookahead.')
            return false
            } */

          gantt.is_task_moved = true;
          temp_task_var.correct_baseline_worktime_bug = true;
          /* temp_task_var.auto_scheduling = false */
          if (temp_task_var.task_added) {
            temp_task_var.duration = temp_task_var.aux_duration;
          }
          /**
           * Fix: Task with progress shouldnt be allowed to move
           */
          break;
        case modes.resize:
          /**
           * Double Fix BUG: Ops said to change this to task with advancement, then change it to just 100%
           * In case to new change check this line which blocks to edit drag resize duration
           * Then to inline duration edition go to line 212 :)
           * NOTE: Intervals go from 0 to 1 where 0 means 0% and 1 means 100%
           */
          if (temp_task_var.progress == 100) {
            // send_warning_message(gantt)('Movimiento no permitido: Intentas modificar la fecha de inicio de una tarea completada.')
            setTimeout(() => {
              gantt.maxPerformance = false;
            }, 1000);
            return false;
          }

          temp_task_var.modeDragAux = 'resize';
          gantt.copyBehaviourFromInline = true;
          temp_task_var.last_start_date = temp_task_var.start_date;
          /** We use this flag to copy the behaviour of lightbox editing with resize trough drag */
          temp_task_var.is_open_lightbox = true;
          /** This flag allows to event aftertaskupdate from Gantt DHTMLX to fix bug resize drag */
          temp_task_var.bug_duration_from_resize_drag = true;
          break;
      }
      gantt.isEditingDrag = true;
      // gantt.render()
      return true;
    })
  );

  let tasksToProcess = [];
  const debouncedAfterDrag = debounce(() => {
    onAfterTaskDrag(tasksToProcess, gantt);
    tasksToProcess = [];
  }, 100);
  events.push(
    gantt.attachEvent('onAfterTaskDrag', (id, mode, tindex) => {
      if (
        gantt.dragWithMultiple &&
        gantt.getAllCheckedTasks &&
        mode == 'move'
      ) {
        let currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
        const fist_task_in_move_back_new_posittion = gantt.getTask(
          gantt.fist_task_in_move_back.id
        );

        if (fist_task_in_move_back_new_posittion) {
          const task = fist_task_in_move_back_new_posittion;
          const calendar = gantt.getCalendar(task.calendar_id);

          const new_date_init = ganttAPI.getEndByDuration(
            task.start_date,
            task.backupDates.difference,
            task.id,
            task.calendar_id,
            currentSector
          );

          const new_date = '';

          task.start_date = new_date_init.start_date;
          task.end_date = new_date_init.end_date;

          task.start_date.setHours(0, 0, 0, 0);
          task.end_date.setHours(0, 0, 0, 0);
        }

        gantt.getAllCheckedTasks.forEach((selectedTask) => {
          const task = gantt.getTask(selectedTask);
          const calendar = gantt.getCalendar(task.calendar_id);
          const new_date_init = calendar.calculateEndDate({
            start_date: fist_task_in_move_back_new_posittion.start_date,
            duration:
              task.backupDates.difference * currentSector.hoursPerDay + 1
          });
          const new_date = calendar.calculateEndDate({
            start_date: new_date_init,
            duration: task.duration * currentSector.hoursPerDay
          });

          task.start_date = new_date_init;
          task.end_date = new_date;
        });
        gantt.dragWithMultiple = null;
        gantt.getAllCheckedTasks = null;
      }

      tasksToProcess.push({ id, mode, tindex });
      debouncedAfterDrag();
    })
  );

  events.push(
    gantt.attachEvent('onAfterTaskAdd', (id, task) => {
      setTimeout(() => {
        try {
          task.onChangeProgress && task.onChangeProgress(task.progress);
        } catch (e) {
          console.log(e);
        }
      }, 1000);
    })
  );

  events.push(
    gantt.attachEvent('onBeforeTaskUpdate', (id, task) => {
      task.constraint_type = 'asap';
      gantt.refreshVisualizations && gantt.refreshVisualizations(task);
    })
  );

  events.push(
    gantt.attachEvent('onAfterTaskUpdate', (id, task) => {
      gantt.refreshVisualizations && gantt.refreshVisualizations(task);
      gantt.checkIfTaskIsInsideOfWeekly(task);
    })
  );

  events.push(
    gantt.attachEvent('onBeforeTaskChanged', function (id, mode, task) {
      onBeforeTaskChanged(id, mode, task);
      return true;
    })
  );

  /**
   * This function renders 6 more months to the gantt time scale
   */
  gantt.attachEvent('onBeforeGanttRender', () => {
    const range = gantt.getSubtaskDates();
    const addTime = {
      duration_start: -1,
      duration_end: 6,
      scaleUnit: 'month'
    };
    if (range.start_date && range.end_date) {
      gantt.config.start_date = gantt.calculateEndDate(
        range.start_date,
        addTime.duration_start,
        addTime.scaleUnit
      );
      gantt.config.end_date = gantt.calculateEndDate(
        range.end_date,
        addTime.duration_end,
        addTime.scaleUnit
      );
    }
  });

  gantt.attachEvent('onTaskSelected', (id) => {
    if (!gantt.isTaskExists(id)) return '';
    const object = gantt.getTask(id);

    if (object.type !== 'main') {
      return;
    }

    const task = gantt.getTaskPosition(object);

    if (task) {
      const offset = '7';
      let unit = 'd';
      if (gantt.ext.zoom.getCurrentLevel() === 3) {
        // scale is in hours
        unit = 'h';
      }
      const scrollX = gantt.posFromDate(
        moment(object.start_date).subtract(offset, unit).toDate()
      );
      gantt.scrollTo(scrollX);
    }
  });

  gantt.attachEvent('onBeforeTaskMove', (id) => {
    const sessionTokenData = getSessionTokenData();
    if (!sessionTokenData) return false;
    if (
      ![
        'superadmin',
        'admin',
        'projectleader',
        'superintendent',
        'subtrade',
        'planner'
      ].includes(sessionTokenData?.role)
    )
      return false;

    const task = gantt.getTask(id);
    if (!task) return false;
    if (task.type === 'main' || task.type === 'milestone') return false;

    return true;
  });

  gantt.attachEvent('onBeforeRowDragEnd', (id, parent) => {
    if (gantt.isDataOrdered || gantt.isDataFiltered) {
      disabledDragDrop(t, 'order');
      return false;
    }

    const task = gantt.getTask(id);

    if (task.type === 'main' || task.type === 'milestone') return false;

    const taskParent = gantt.getTask(task.parent);
    const currentParent = gantt.getTask(parent);
    gantt.current_parent_id_drag = currentParent.id;

    if (
      parseInt(task.parent) !== parseInt(parent) &&
      !taskParent.parent &&
      !currentParent.parent
    ) {
      disabledDragDrop(t);
      return false;
    }

    if (
      taskParent.id !== currentParent.id &&
      taskParent.parent !== currentParent.parent
    ) {
      disabledDragDrop(t);
      return false;
    }

    if (task.progress === 100 && task.parent !== task.activityReference?.id) {
      disabledDragDrop(t, 'childrens');
      return false;
    }
    taskParent.is_parent = true;

    return true;
  });

  gantt.attachEvent('onRowDragEnd', (id) => {
    const currentTask = gantt.getTask(id);
    const currentActivity = currentTask.activityReference;

    const tasks = gantt.getTaskBy(
      (task) => task.activityReference?.id === currentActivity.id
    );
    assignCorrelativeNumbers(tasks);

    const saveTasksMassive = [];
    const currentActivityLookahead = findDeepGetActivityOfTask(
      window.activities,
      'id',
      currentTask.id
    );

    for (const task of tasks) {
      saveTasksMassive.push({
        id: task.id,
        parent_id:
          task.parent !== task.activityReference?.id ? task.parent : null
      });

      assignCorrelativeNumbersLookahead(currentActivityLookahead.tasks, task);
    }

    const taskFromLookahead = findDeepGetTask(
      window.activities,
      'id',
      currentTask.id
    );
    const findParentTask = findDeepGetTask(
      window.activities,
      'id',
      currentTask.parent
    );
    const currentParentDrag = findDeepGetTask(
      window.activities,
      'id',
      gantt.current_parent_id_drag
    );

    if (findParentTask) taskFromLookahead.parent_id = findParentTask.id;

    if (
      taskFromLookahead &&
      findParentTask &&
      findParentTask.id !== currentParentDrag.id
    )
      findParentTask.children.push(taskFromLookahead);
    if (
      taskFromLookahead &&
      currentParentDrag &&
      findParentTask.id !== currentParentDrag.id
    )
      currentParentDrag.children = currentParentDrag.children.filter(
        (element) => element.id !== currentTask.id
      );

    sortByCorrelativeNumber(
      findParentTask ? findParentTask.children : currentActivityLookahead.tasks
    );

    if (findParentTask || currentParentDrag) {
      if (findParentTask) {
        const taskUpdatesParent = [];
        calculatePonderators(
          findParentTask,
          currentActivityLookahead,
          (taskCallback) => addToQueueToSave(taskUpdatesParent, taskCallback),
          projectState
        );
        if (findParentTask.children.length > 0)
          updateParentDuration(
            findParentTask.id,
            currentActivityLookahead,
            taskUpdatesParent,
            gantt,
            ganttAPI,
            projectState
          );
        updateAsyncParentRecursive(gantt, findParentTask.id);

        saveTasksMassive.push({
          id: findParentTask.id,
          duration: findParentTask.duration
        });
      }

      if (currentParentDrag) {
        const taskUpdatesCurrentParent = [];
        calculatePonderators(
          currentParentDrag,
          currentActivityLookahead,
          (taskCallback) =>
            addToQueueToSave(taskUpdatesCurrentParent, taskCallback),
          projectState
        );
        if (currentParentDrag.children.length > 0) {
          updateParentDuration(
            currentParentDrag.id,
            currentActivityLookahead,
            taskUpdatesCurrentParent,
            gantt,
            ganttAPI,
            projectState
          );
          updateAsyncParentRecursive(gantt, currentParentDrag.id);
        }

        saveTasksMassive.push({
          id: currentParentDrag.id,
          duration: currentParentDrag.duration,
          progress: currentTask.progress
        });
      }
    }

    if (!checkIfTaskHasChildren(gantt.current_parent_id_drag)) {
      const oldParent = gantt.getTask(gantt.current_parent_id_drag);
      oldParent.is_parent = false;
    }
    updateTaskMassive(saveTasksMassive);
  });

  gantt.allEventsVar = events;
  return events;
};

import moment from 'moment';

import { from_links_to_string_predecessor } from '../custom_gantt_fields/custom_predecessor';
import { from_links_to_string_sucessor } from '../custom_gantt_fields/custom_sucessor';
import { Constants } from '../../../constants/components';
import { earlyAccessCriticalPath } from '../../../utils/earlyAccessCriticalPath';

export const parseColumns = (columns) =>
  columns.map((col) => {
    if (col.id == 'calendar_id') {
      delete col.type;
    }

    return col;
  });

export const parseExportToExcel = (tasks, gantt, t) =>
  tasks.map((task) => {
    task.duration = fixedDecimal(parseHoursToDays(gantt, task));
    task.freeSlack = fixedDecimal(parseFreeSlackHoursToDays(gantt, task));
    task.totalSlack = fixedDecimal(parseTotalSlackHoursToDays(gantt, task));
    task.expected_progress = fixedDecimal(task.expected_progress);
    task.progress = fixedDecimal(task.progress);
    task.expected_progress_base = fixedDecimal(task.expected_progress_base);
    task.ponderator = fixedDecimal(task.ponderator);
    task.used_cost = fixedDecimal(task.used_cost);

    task.calendar_id = getCalendarName(task, gantt);
    task.is_critical = isCriticalTask(task, gantt);
    task.responsables = responsables(task);

    task.start_base = dateToString(task, gantt)?.start;
    task.end_base = dateToString(task, gantt)?.end;

    task.tags = tags(task);

    task.constraint_date =
      task.constraint_type == 'asap' ? '' : task.constraint_date;
    if (task.constraint_type === undefined) task.constraint_type = 'asap';
    task.constraint_type = translate(
      `tables.gantt.constraint_type.options.${task.constraint_type}`,
      t
    );
    task.status = translate(`exportable.${task.status}`, t);
    task.subcontractId = subcontracts(gantt, task);

    return task;
  });

const isNegative = (value) => value < 0;

const fixedDecimal = (value, decimals = 2) =>
  !value || value % 1 == 0 ? value : parseFloat(value).toFixed(decimals);

const formaterDays = (value, gantt) =>
  value ? gantt.formatter.format(Math.abs(value)) : '0 days';

const translate = (key, t) => t(key);

const getBaseLine = (task) =>
  task.baseline_points && task.baseline_points.length
    ? task.baseline_points.find(
        (base) =>
          base.sectorbaselineversion && base.sectorbaselineversion.active
      )
    : null;

const extractNumber = (value) => {
  const num = value.split(' ').shift();

  if (isNaN(num)) {
    return 1;
  }

  return parseFloat(num);
};

const dateToString = (task, gantt) => {
  const baseline = getBaseLine(task);

  if (!baseline) {
    return {};
  }

  task.work_base = fixedDecimal(baseline.hh_work);
  task.cost_base = fixedDecimal(baseline.cost);
  task.duration_base = baseline.duration;

  return {
    start: moment(baseline.start_date).format(gantt.currentDateFormat),
    end: moment(baseline.end_date).format(gantt.currentDateFormat)
  };
};

const parseHoursToDays = (gantt, task) => {
  const duration = task.duration
    ? gantt.formatter.format(task.duration)
    : '0 days';
  return extractNumber(duration);
};

export const getActivityLinkColumns = (activityList, gantt) => {
  if (
    !Array.isArray(activityList) ||
    activityList.length === Constants.EMPTY_NUMBER
  )
    return [];

  const activityLinks = activityList.map((activity) => {
    const currentActivity = gantt.getTask(activity.id);
    if (!currentActivity) return null;

    activity.$source = currentActivity.$source || [];
    activity.$target = currentActivity.$target || [];

    const [predecessors, sucessors] = [
      from_links_to_string_predecessor(gantt)(activity),
      from_links_to_string_sucessor(gantt)(activity)
    ];

    activity.custom_predecessors = predecessors;
    activity.custom_sucessors = sucessors;

    activity.$source = undefined;
    activity.$target = undefined;

    return activity;
  });

  return activityLinks.filter(Boolean);
};

const parseTotalSlackHoursToDays = (gantt, task) => {
  const TOTAL_SLACK_DEFAULT = 0;

  const calculateTotalSlack = earlyAccessCriticalPath()
    ? TOTAL_SLACK_DEFAULT
    : gantt.getTotalSlack(task);

  let totalSlack = task.totalSlack || calculateTotalSlack;
  const negative = isNegative(totalSlack);

  totalSlack = extractNumber(formaterDays(totalSlack, gantt));

  task.totalSlack = negative ? -Math.abs(totalSlack) : totalSlack;
  return task.totalSlack;
};

const parseFreeSlackHoursToDays = (gantt, task) => {
  let freeSlack = task.freeSlack || 0;
  const negative = isNegative(freeSlack);

  freeSlack = extractNumber(formaterDays(freeSlack, gantt));

  task.freeSlack = negative ? -Math.abs(freeSlack) : freeSlack;
  return task.freeSlack;
};

const responsables = (task) =>
  task.responsables.reduce((acc, responsable, idx, arr) => {
    acc += `${responsable.name} ${responsable.lastname}`;
    acc += arr.length - 1 != idx ? ', ' : '';
    return acc;
  }, '');

const tags = (task) =>
  task.tags.reduce((acc, tag, idx, arr) => {
    acc += tag.name;
    acc += arr.length - 1 != idx ? ', ' : '';
    return acc;
  }, '');

export const getCalendarName = (task, gantt) => {
  const calendar = gantt.calendarOptions.find(
    (calendar) => calendar.key == task.calendar_id
  );
  return calendar?.label || 'Custom';
};

const subcontracts = (gantt, task) => {
  const statusObject = gantt.subContracts?.find(
    (e) => e.id === task.subcontractId
  );
  return statusObject?.name || '';
};

const isCriticalTask = (task, gantt) => {
  if (earlyAccessCriticalPath()) {
    return task.is_critical === 'Si' ? 'si' : 'no';
  }

  return gantt.isCriticalTask(task) ? 'si' : 'no';
};

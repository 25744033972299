/** React stuff */
import React, { useState, useEffect, useRef, useCallback } from 'react';

/** Components from ant design css framework */
import { Row, Col, Dropdown, Input, Tooltip, Menu } from 'antd';

/** Component that handle filtering on lookahead data */
import LookaheadFilter from '../LookaheadFilter';

/** Component that handle ordering on lookahead data */
import LookaheadOrder from '../LookaheadOrder';

/** Component that handle grouping on lookahead data */
import LookaheadGroup from '../LookaheadGroup';

/** Component that handle visualization options */
import LookaheadVisualization from '../LookaheadVisualizationOptions';

/** Component for multiselect elements on checkbox display */
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';

/** To clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';

/** Styles on pure css */
import './index.css';
import { RoadblockIcon } from '../../icons';

/** import common functions from utils */
import {
  group,
  date,
  filter,
  order,
  columns,
  export_file,
  reversPathTasks,
  getTodayWeekWithPday
} from '../../utils';

import LookAheadPlanExport from '../exportable/LookAheadPlan';
/** import IconComponent to SVG images */
import IconComponent from '../Projects/IconSvg';
import { withTranslation } from 'react-i18next';

import LookaheadFilterHeaderStyles from './index.module.css';
import { LookaheadToolbar } from '../LookaheadToolbar';
import { LookaheadDateRangePicker } from '../LookaheadDateRangePicker';
import { executeNewToolbarCommand } from './index.interface';
import moment from 'moment';
import { avoidOptimizationTimelineBlinking } from '../GanttVisualization/GanttVisualization.helper';
import useLookaheadPermissions from '../../hooks/useLookaheadPermissions';
import { trackingEvent } from '../../analytics';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';

const mapElements = {};
const { Search } = Input;

/** Permissions */
const userLookaheadPermissions = useLookaheadPermissions();
const onlyRead = userLookaheadPermissions?.constraints === 'V';
/**
 * This component handles the filtering options at lookahead view
 * @param {*} props { changeDateState, dateRange, updateTableMetadata, tableMetadata, width }
 */
function LookaheadFilterHeader(props) {
  const {
    t,
    listViews,
    lookaheadActions,
    disableLookahead,
    defaultOrderOptions,
    renderExtraFilterCenter,
    renderExtraFilterRigth,
    visualizationOp,
    ganttDhtmlx,
    lastLevelActivities,
    viewType,
    isPlanification,
    disableColumnsSelector,
    defaultView,
    viewService,
    setIsFilter,
    updateDefaultViewFilters,
    isWeeklyCommited,
    isRoadblock,
    handleAddConstraint = (_) => {}
  } = props;

  const { current: dateRangePickerOptions } = useRef([
    'free',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8'
  ]);
  const { current: dataToFilter } = useRef({ value: [] });

  const [avoidUpdateRender, setIsUpdateRender] = useState(false);
  const [toSearchOnPage, setToSearchOnPage] = useState('');
  const [counterSearch, setCounterSearch] = useState({
    current: null,
    total: null
  });
  const [queryFilter, setQueryFilter] = useState(false);
  const [orderQuery, setOrderQuery] = useState(false);
  const [isSubDropActive, setIsSubDropActive] = useState({ value: false });

  const [refresh, setRefresh] = useState(false);

  const [currentFilter, setCurrentFilter] = useState({
    queryFilter: [],
    conditionalFilter: false
  });
  const [currentOrderFilter, setCurrentOrderFilter] = useState([]);

  /** Saves the hidden of dropdown for dates */
  const [datesVisibility, setDatesVisibility] = useState(false);

  /** Saves the hidden of dropdown for filters */
  const [filterVisibility, setFilterVisibility] = useState(false);

  /** Saves the hidden of dropdown for order */
  const [orderVisibility, setOrderVisibility] = useState(false);

  /** Saves the hidden of dropdown for grouping */
  const [groupVisibility, setGroupVisibility] = useState(false);

  /** Array with elements to show at hide/show cols */
  const [children, setChildren] = useState([]);

  /** Array with selected options at hide/show cols */
  const [selectedOption, setSelectedOption] = useState([]);

  const dateRange = useRef({
    start: props.dateRange.start,
    end: props.dateRange.end
  });

  /** lookahead state from redux */
  const lookaheadState = useSelector((state) => state[props.lookaheadState]);
  const projectState = useSelector((state) => state.projectState);
  const exportable = props.exportable;

  /** function to emit events with actions at redux */
  const dispatch = useDispatch();

  const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));

  const findView = ({ view }) => {
    const defaultObject = props.defaultView || defaultView;
    if (!defaultObject) return;
    if (defaultObject.id === view.id) return true;
  };

  /** show filter drodown on change props.reApplyFilters */
  useEffect(() => {
    setFilterVisibility(props.reApplyFilters);
  }, [props.reApplyFilters]);

  useEffect(() => {
    props.data.forEach((activity) =>
      activity.tasks.forEach((task) => {
        task.taskRoute = reversPathTasks(
          task.taskRoute?.replace(/\s\s+/g, ' ')
        );
      })
    );
    dataToFilter.value = props.data;

    // If exist data, get the default view, then apply filters
    if (isPlanification && visualizationOp) {
      dateRange.current = {
        start: visualizationOp.lookahead_init,
        end: visualizationOp.lookahead_end
      };
      checkBackgrondFilter(visualizationOp.filter_query);
      checkBackgrondOrder(visualizationOp.order_query);
      checkBackgrondGroup(visualizationOp.group_query);
      applyFilters(visualizationOp);
      setIsUpdateRender(true);
    }
  }, [props.groupBy, props.data]);

  /** This effect loads the elements for hide/show cols */
  useEffect(() => {
    /** This function build the option for multi select format { label, value } */
    props.tableMetadata.forEach((column) => {
      if (!column.ignore_as_column) {
        children.push({ label: column.label, value: column.name });
      }
    });

    /** This section code deal when redux has a state, turning on those ones, and disabling which not */
    if (lookaheadState.activatedColumns.length) {
      const [activated, activatedColumns] = activateColumnsPlanification();
      const all = [];

      props.tableMetadata.forEach(
        (el) => !el.ignore_as_column && all.push(el.name)
      );

      /** difference between arrays (all/active) to disable */
      const colsToDisable = all.filter((x) => !activatedColumns.includes(x));
      const colsToEnable = all.filter((x) => activatedColumns.includes(x));

      updateVisibilityCols(colsToEnable, colsToDisable);

      setSelectedOption(activated);

      /** This code deals with no redux state, and use default config from table.layout.js */
    } else {
      let names = [];
      let activated = [];
      const defaultColumns = visualizationOp?.columns_json?.columnsActive;
      if (defaultColumns?.length) {
        activated = findActiveColumns(defaultColumns);
        names = activated;
      } else {
        activated = props.tableMetadata.map((col) => {
          if (col.visible) {
            names.push(col.name);
            return { label: col.label, value: col.name };
          }
        });
      }
      dispatch(lookaheadActions.setActivatedColumns(names));
      setSelectedOption(activated);
    }
  }, [visualizationOp]);

  const findActiveColumns = (activatedColumns) =>
    activatedColumns.map((op) => {
      const col = props.tableMetadata.filter((c) => c.name == op);
      if (col.length != 0) {
        return { label: col[0].label, value: col[0].name };
      }
    });

  const activateColumnsPlanification = () => {
    if (isPlanification && listViews.current.length) {
      const configView = listViews.current.find(findView);
      // eslint-disable-next-line no-prototype-builtins
      if (configView?.view.columns_json.hasOwnProperty('columnsActive')) {
        return [
          findActiveColumns(configView.view.columns_json.columnsActive),
          configView.view.columns_json.columnsActive
        ];
      }
    }

    return [
      findActiveColumns(lookaheadState.activatedColumns),
      lookaheadState.activatedColumns
    ];
  };

  const recursiveFilter = (query, array) => {
    let isTaskHidden = true;
    array.map((el) => {
      const { start_date, end_date, children } = el;
      const STRING = 'string';
      const FORMAT = 'YYYY/MM/DD';
      if (typeof end_date !== STRING) {
        el.end_date = moment(end_date).format(FORMAT);
      }
      if (typeof start_date !== STRING) {
        el.start_date = moment(start_date).format(FORMAT);
      }
      if (eval(query)) {
        el.hide = false;
        isTaskHidden = false;
      } else {
        el.hide = true;
      }

      const hasChildren = children && children.length !== 0;
      if (hasChildren) {
        const allChildrenHidden = recursiveFilter(query, children);
        if (!allChildrenHidden) {
          isTaskHidden = false;
          el.hide = false;
        }
      }
    });

    return isTaskHidden;
  };

  /**
   * This function executes filtering all data at lookahead
   * @param {*} showedFilters Filters from header
   * @param {*} andFilter And/Or from header dropdown
   * @param {*} avoid_update_render flag to avoid hard update activities
   */
  const filterLookaheadData = (
    showedFilters,
    andFilter,
    avoid_update_render = false
  ) => {
    if (showedFilters.value.length != 0) {
      let query = '';
      const connector = andFilter.value ? ' || ' : ' && ';
      let filterBy = false;
      showedFilters.value.map((filter, index) => {
        const isLast = index == showedFilters.value.length - 1;
        if (filter.filter_by && filter.filter_by != '') filterBy = true;
        const value = "'" + filter.filter_by + "'";
        const valueToFilter = 'el.' + filter.name;

        if (filter.data_type == 'date') {
          if (value) {
            switch (filter.equals) {
              case 'afterthan':
                /** Despues de la fecha indicada */
                query =
                  query +
                  '(new Date(' +
                  valueToFilter +
                  ')).getTime() > (new Date(' +
                  value +
                  ')).getTime()';
                break;
              case 'beforethan':
                query =
                  query +
                  '(new Date(' +
                  valueToFilter +
                  ')).getTime() < (new Date(' +
                  value +
                  ')).getTime()';
                break;
            }
          }
        } else if (filter.data_type == 'string') {
          switch (filter.equals) {
            case 'includes':
              query = !filter.el_to_extract_from
                ? `${query} ${valueToFilter}.toLocaleLowerCase().includes(${value.toLocaleLowerCase()})`
                : `${query} ${valueToFilter}.filter(e => e.${
                    filter.el_to_extract_from
                  }.toLocaleLowerCase().includes(${value.toLocaleLowerCase()})).length`;
              break;
            case 'notincludes':
              query = filter.el_to_extract_from
                ? `${query} ${valueToFilter}.toLocaleLowerCase().includes(${value.toLocaleLowerCase()})`
                : `${query} !${valueToFilter}.filter(e => e.${
                    filter.el_to_extract_from
                  }.toLocaleLowerCase().includes(${value.toLocaleLowerCase()})).length`;
              break;
            case 'is':
              query = !filter.el_to_extract_from
                ? `${query} ${valueToFilter} == ${value}`
                : `${query} ${valueToFilter}.filter(e => e.${filter.el_to_extract_from} == ${value})`;
              break;
            case 'isnot':
              query = !filter.el_to_extract_from
                ? `${query} ${valueToFilter} == ${value}`
                : `${query} ${valueToFilter}.filter(e => e.${filter.el_to_extract_from} != ${value})`;
              break;
          }
        } else if (filter.data_type == 'number') {
          switch (filter.equals) {
            case 'equals':
              query =
                String(query) + valueToFilter + ' == parseFloat(' + value + ')';
              break;
            case 'notequals':
              query =
                String(query) + valueToFilter + ' != parseFloat(' + value + ')';
              break;
            case 'morethan':
              query =
                String(query) + valueToFilter + ' > parseFloat(' + value + ')';
              break;
            case 'lessthan':
              query =
                String(query) + valueToFilter + ' < parseFloat(' + value + ')';
              break;
            case 'moreequalsthan':
              query =
                String(query) + valueToFilter + ' >= parseFloat(' + value + ')';
              break;
            case 'lessequalsthan':
              query =
                String(query) + valueToFilter + ' <= parseFloat(' + value + ')';
              break;
          }
        } else if (filter.data_type == 'array/images') {
          const value =
            valueToFilter == 'el.user' ? `el.${filter.ref}` : valueToFilter;
          switch (filter.equals) {
            case 'issomeof':
              query =
                query +
                `(${value}.filter( u =>
                            ${JSON.stringify(filter.filter_by)}.includes(u.${
                              filter.el_to_extract_from
                            })).length)`;
              break;
            case 'isnotsomeof':
              query =
                query +
                `!(${value}.filter( u =>
                            ${JSON.stringify(filter.filter_by)}.includes(u.${
                              filter.el_to_extract_from
                            })).length)`;
              break;
          }
        } else if (filter.data_type != 'array/images') {
          /** Mixed cols logic */
          let toAddQueryMixed = [];
          let mixQueries = true;
          if (
            filter.mixed &&
            (!filter.filter_by.includes('Overdue') ||
              !filter.filter_by.includes('Advancement'))
          ) {
            let howManyMixDoesInclude = 0;
            if (filter.filter_by.map) {
              filter.filter_by.map((by) => {
                if (filter.mixed_from.includes(by)) {
                  howManyMixDoesInclude++;
                  if (filter.equals == 'issomeof') {
                    toAddQueryMixed.push(' el.' + by);
                  } else if (filter.equals == 'isnotsomeof') {
                    toAddQueryMixed.push(' !el.' + by);
                  }
                }
              });
              toAddQueryMixed = toAddQueryMixed.join(' && ');

              if (filter.filter_by.length == howManyMixDoesInclude) {
                mixQueries = false;
              }
            }
          }

          switch (filter.equals) {
            case 'issomeof':
              if (mixQueries) {
                if (filter.name == 'status') {
                  query =
                    query +
                    buildOverdueAdvancementStatusFilter(
                      value,
                      query,
                      valueToFilter,
                      true
                    );
                  break;
                }
                query =
                  query +
                  value +
                  '.split(",").map(elem => isNaN(elem)? elem : parseInt(elem)).includes(' +
                  valueToFilter +
                  ')';
              }
              if (toAddQueryMixed.length) {
                query = query + (mixQueries ? ' && ' : '') + toAddQueryMixed;
              }
              break;
            case 'isnotsomeof':
              if (mixQueries) {
                if (filter.name == 'status') {
                  query =
                    query +
                    buildOverdueAdvancementStatusFilter(
                      value,
                      query,
                      valueToFilter
                    );
                  break;
                }
                query =
                  query +
                  '!' +
                  value +
                  '.split(",").map(elem => isNaN(elem)? elem : parseInt(elem)).includes(' +
                  valueToFilter +
                  ')';
              }

              if (toAddQueryMixed.length) {
                query = query + (mixQueries ? ' &&' : '') + toAddQueryMixed;
              }
              break;
          }
        }

        if (!isLast) {
          query = query + connector;
        }
      });

      // eslint-disable-next-line no-useless-escape
      query = query
        .replace(/\&\&\s+$/, '')
        .replace(/\&\&\s{3}/g, '')
        .replace(') (', ') && (');
      if (!filterBy) query = 'true';
      dataToFilter.value.forEach((el) => {
        const taskHidden = recursiveFilter(query, el.tasks);
        el.hide = taskHidden;
      });
    } else {
      const query = 'true';
      dataToFilter.value.forEach((el) => {
        recursiveFilter(query, el.tasks);
        el.hide = false;
      });
    }

    const queryParams = {
      value: showedFilters.value,
      conditionFilter: andFilter
    };

    visualizationOp && (visualizationOp.filter_query = { ...queryParams });

    setQueryFilter({ ...queryParams });
    setCurrentFilter({
      queryFilter: showedFilters.value,
      conditionalFilter: andFilter.value
    });

    if (showedFilters.value.length > 0) {
      const { ganttVisualization } = window;
      if (ganttVisualization) ganttVisualization.isDataFiltered = true;
    }

    !avoid_update_render && props.updateRender(dataToFilter.value);
  };

  const recursiveOrder = (query, array) => {
    // eslint-disable-next-line no-eval
    array.sort(eval(query));
    array.map((el) => {
      if (el.children) {
        recursiveOrder(query, el.children);
      }
    });
  };

  const showTaskInWeeklyRange = (filterCurrentWeekTasks) => {
    // const {start: weekStart, end: weekEnd} = weekRange(getTodayWeekWithPday(projectState))
    const gantt = window.ganttVisualization;
    if (!gantt) return;
    const { weeklyRangeObject } = gantt;
    if (!weeklyRangeObject) return;
    const weekStart = weeklyRangeObject?.range?.start;
    const weekEnd = weeklyRangeObject?.range?.end;
    const conditional = `(el && typeof el.start_date == 'string' && typeof el.end_date == 'string') && ( (el.start_date.split(' ')[0] >= '${weekStart}' && el.end_date.split(' ')[0] <= '${weekEnd}') || 
             (el.start_date.split(' ')[0] <= '${weekStart}' && el.end_date.split(' ')[0] >= '${weekEnd}') ||
             (el.start_date.split(' ')[0] <= '${weekStart}' && el.end_date.split(' ')[0] >= '${weekStart}') ||
             (el.start_date.split(' ')[0] <= '${weekEnd}' && el.end_date.split(' ')[0] >= '${weekEnd}'))`;

    const query = filterCurrentWeekTasks ? conditional : 'true';

    dataToFilter.value.forEach((el) => recursiveFilter(query, el.tasks));
    props.updateRender(dataToFilter.value);

    const tableSettings = filterCurrentWeekTasks
      ? props.tableMetadata.map((column) => {
          const visibility =
            column.name == 'name' ||
            column.name == 'responsables' ||
            column.name == 'subcontractId' ||
            column.name == 'progress' ||
            column.name == 'commitment_percentaje' ||
            column.name == 'plan_endowment';

          return { ...column, visible: visibility };
        })
      : props.tableMetadata;

    props.updateTableMetadata(tableSettings, false, false, { isPreview: true });
  };

  const weekRange = (week) => {
    const currentYear = moment().format('YYYY');
    const year = moment(new Date()).format('YYYY');
    const project = props.currentProject;

    const day3ofWeek =
      parseInt(year) !== parseInt(currentYear)
        ? moment(`${currentYear}/01/07`, 'YYYY/MM/DD').week(week).day(3)
        : moment(`${currentYear}/01/01`, 'YYYY/MM/DD').week(week).day(3);

    const month = day3ofWeek.format('MM');
    const day = day3ofWeek.format('DD');

    const fixDay3ofWeek = moment(
      `${currentYear}/${month}/${day}`,
      'YYYY/MM/DD'
    );

    const planificationDay =
      project.planification_day == 0 ? -1 : project.planification_day - 1;

    const from_date = fixDay3ofWeek
      .clone()
      .startOf('isoWeek')
      .add('days', planificationDay);
    const to_date = from_date.clone().add(6, 'day');

    return {
      start: from_date.toISOString().split('T')[0].split('-').join('/'),
      end: to_date.toISOString().split('T')[0].split('-').join('/')
    };
  };

  const buildOverdueAdvancementStatusFilter = (
    value,
    query,
    valueToFilter,
    isSomeOf = false
  ) => {
    const overdue = isSomeOf
      ? 'el.progress < el.expected'
      : 'el.progress > el.expected';
    const advancement = isSomeOf
      ? 'el.progress > el.expected'
      : 'el.progress < el.expected';
    const isNot = isSomeOf ? '' : '!';
    value = value.replace(/'/g, '');

    const overdueStatus = value.includes('Overdue') ? overdue : '';
    const advanceStatus = value.includes('Advancement')
      ? `${overdueStatus != '' ? `${overdueStatus} ||` : ''} ${advancement}`
      : overdueStatus;
    const queryStatus = advanceStatus || '';
    const status = value
      .split(',')
      .filter((val) => val != 'Overdue' && val != 'Advancement')
      .join(',');

    return status.length
      ? `(${
          queryStatus != '' ? `${queryStatus} ||` : ''
        } ${isNot}'${status}'.split(",").map(elem => isNaN(elem) ? elem : parseInt(elem)).includes(${valueToFilter}))`
      : `${query} ${queryStatus != '' ? `(${queryStatus})` : ''}`;
  };

  /**
   * This function order lookahead data
   * @param {*} showedFilters Filter string from header
   * @param {*} avoid_update_render Flag to avoid updating render (which actually updates whole data)
   */
  const orderLookaheadData = (showedFilters, avoid_update_render = false) => {
    if (showedFilters.value.length != 0) {
      let query = '';
      showedFilters.value.map((o, index) => {
        let fieldName = `'${o.name}'`;
        const orderBy = o.order_by;

        if (o.data_type.includes('array/')) {
          if (
            o.data_type.includes('/string') ||
            o.data_type.includes('/icon')
          ) {
            const optionsMap = {};
            o.from_values.map((option) => {
              optionsMap[option.value] = option.weigth;
            });
            mapElements[o.name] = optionsMap;
            fieldName = `
                        (v1, v2) => {
                            let weigth_one = mapElements.${o.name}[v1.${o.name}]
                            let weigth_two = mapElements.${o.name}[v2.${o.name}]
                            return (weigth_one || 0) - (weigth_two || 0)
                        }
                        `;
          }
        }

        if (o.name == 'user') {
          fieldName = '(v1, v2) => v1.user.id - v2.user.id';
        }

        if (index == 0) {
          query =
            query +
            `firstBy(${fieldName}, { direction: '${orderBy}', ignoreCase: true})`;
        } else {
          query =
            query +
            `.thenBy(${fieldName}, { direction: '${orderBy}', ignoreCase: true})`;
        }
      });

      dataToFilter.value.map((el) => {
        recursiveOrder(query, el.tasks);
      });
    } else {
      const query = 'firstBy("id", { direction: "asc", ignoreCase: true})';
      dataToFilter.value.map((el) => {
        recursiveOrder(query, el.tasks);
      });
    }

    const orderParams = { value: showedFilters.value };
    visualizationOp && (visualizationOp.order_query = { ...orderParams });
    setOrderQuery({ ...orderParams });
    setCurrentOrderFilter(showedFilters.value);

    if (showedFilters.value.length > 0) {
      const { ganttVisualization } = window;
      if (ganttVisualization) {
        ganttVisualization.isDataOrdered = true;
      }
    }

    if (!avoid_update_render) {
      updateRender(dataToFilter.value);
    }
  };

  const updateVisibilityCols = (toEnable, toDisable) => {
    const tableData = cloneDeep(props.tableMetadata);
    toEnable.map((columnName) => {
      const column = tableData.find((c) => c.name == columnName);
      if (column) {
        column.visible = true;
      }
    });
    toDisable.map((columnName) => {
      const column = tableData.find((c) => c.name == columnName);
      if (column) {
        column.visible = false;
      }
    });

    props.updateTableMetadata(tableData, false, true);
  };

  /**
   * This function changes the visibility for an array of cols
   * @param {*} cols Array with cols to change visible status
   * @param {*} enabled Boolean, true for show col, and false for hide col
   */
  const changeColsVisibility = (cols, enabled) => {
    const tableData = cloneDeep(props.tableMetadata);
    cols.map((columnName) => {
      const column = tableData.filter((c) => c.name == columnName);
      if (column[0]) {
        column[0].visible = enabled;
      }
    });

    props.updateTableMetadata(tableData, false, true);
  };

  const updateActiveCols = useCallback(
    (cols) => {
      dispatch(lookaheadActions.setActivatedColumns(cols));
    },
    [dispatch]
  );

  /**
   * This function deals with the change of multi select.
   * Deals with  deleting, and clicking news cols.
   * @param {*} value Array of values for multi select of Ant Design Framework
   */
  function handleChange(value) {
    avoidOptimizationTimelineBlinking();
    setSelectedOption(JSON.parse(JSON.stringify(value)));
    updateActiveCols(value);
    value = value.map((v) => v.value);
    props.lookaheadState == 'lookaheadState'
      ? columnsActivatedView(value)
      : columnsActivated(value);
    if (window.ganttVisualization) {
      // window.ganttVisualization.optimizedRender && window.ganttVisualization.optimizedRender()
      setTimeout(() => {
        window.ganttVisualization.adjustGridWidth();
      }, 1000);
    }
  }

  const columnsActivatedView = (value) => {
    /** For gantt */
    const viewDefault = visualizationOp;
    if (ganttDhtmlx && viewDefault.name == 'gantt') {
      /** This if statement allows to check if there is some fields to disable */
      const viewConfig = listViews.current.find(findView);
      const columnsActived =
        props.lookaheadState === 'lookaheadState'
          ? viewConfig.view.columns_json.columnsActive
          : lookaheadState.activatedColumns;

      if (value.length < columnsActived.length) {
        /** link for docs https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript */
        const colsToDisable = columnsActived.filter((x) => !value.includes(x));
        colsToDisable.map((colToDisable) => {
          if (ganttDhtmlx.getGridColumn(colToDisable))
            ganttDhtmlx.getGridColumn(colToDisable).hide = true;
        });
        const colToDisable = colsToDisable[0];
        trackingEvent(
          'hide_lookahead_column',
          {
            ...getBasicAmplitudEventProperties(),
            column_selected: colToDisable
          },
          AMPLITUDE_SERVICE
        );
      } else {
        const colToEnable = value[value.length - 1];
        trackingEvent(
          'activate_lookahead_column',
          {
            ...getBasicAmplitudEventProperties(),
            column_selected: colToEnable
          },
          AMPLITUDE_SERVICE
        );
      }

      value.map((columnName) => {
        ganttDhtmlx.getGridColumn(columnName).hide = false;
      });

      /** Then by mapping array, can active the columns indeed and render methods will take effect visually */
      window.ganttVisualization.optimizedRender &&
        window.ganttVisualization.optimizedRender();

      if (window.ganttVisualization) {
        const gantt = window.ganttVisualization;
        setTimeout(() => {
          window.ganttVisualization.optimizedRender &&
            window.ganttVisualization.optimizedRender();
        }, 300);
        /** fix bug that doesn't show tasks when you change column visibility  */
        const sPos = gantt.getScrollState();
        const posY = sPos.y;
        gantt.scrollTo(null, posY);
      }
    }

    changeColsVisibility(value, true);

    /** This if statement allows to check if there is some fields to disable */
    if (value.length < lookaheadState.activatedColumns.length) {
      const colsToDisable = lookaheadState.activatedColumns.filter(
        (x) => !value.includes(x)
      );
      changeColsVisibility(colsToDisable, false);
    }

    /** For table */
    /** Then by mapping array, can active the columns indeed and render methods will take effect visually */
    if (listViews.current.length) {
      const findIdx = listViews.current.findIndex(findView);

      if (
        value.length <
        listViews.current[findIdx].view.columns_json.columnsActive.length
      ) {
        const colsToDisable = listViews.current[
          findIdx
        ].view.columns_json.columnsActive.filter((x) => !value.includes(x));
        changeColsVisibility(colsToDisable, false);
      } else {
        changeColsVisibility(value, true);
      }

      listViews.current[findIdx].view.columns_json = {
        ...listViews.current[findIdx].view.columns_json,
        columnsActive: value
      };

      viewService.update({
        projectId: listViews.current[findIdx].project,
        sectorId: listViews.current[findIdx].sector,
        ganttChart: listViews.current[findIdx].ganttChart,
        orderView: listViews.current[findIdx].order,
        ...listViews.current[findIdx].view
      });

      dispatch(lookaheadActions.setLookaheadViewsConfig(listViews.current));
    }
  };

  const columnsActivated = (value) => {
    /** For gantt */

    if (ganttDhtmlx && visualizationOp.name == 'gantt') {
      /** This if statement allows to check if there is some fields to disable */
      if (value.length < lookaheadState.activatedColumns.length) {
        /** link for docs https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript */
        const colsToDisable = lookaheadState.activatedColumns.filter(
          (x) => !value.includes(x)
        );
        colsToDisable.map((colToDisable) => {
          if (ganttDhtmlx.getGridColumn(colToDisable))
            ganttDhtmlx.getGridColumn(colToDisable).hide = true;
        });
      }
      value.map((columnName) => {
        ganttDhtmlx.getGridColumn(columnName).hide = false;
      });

      /** Then by mapping array, can active the columns indeed and render methods will take effect visually */
      ganttDhtmlx.optimizedRender && ganttDhtmlx.optimizedRender();
    }

    /** For table */
    /** Then by mapping array, can active the columns indeed and render methods will take effect visually */
    dispatch(lookaheadActions.setActivatedColumns(value));
    changeColsVisibility(value, true);

    /** This if statement allows to check if there is some fields to disable */
    if (value.length < lookaheadState.activatedColumns.length) {
      const colsToDisable = lookaheadState.activatedColumns.filter(
        (x) => !value.includes(x)
      );
      changeColsVisibility(colsToDisable, false);
    }

    if (window.ganttVisualization) {
      setTimeout(() => {
        window.ganttVisualization.optimizedRender &&
          window.ganttVisualization.optimizedRender();
      }, 100);
    }
  };

  /**
   * This functions changes the visibility of dropdown for dates
   * @param {*} val Boolean, true to show dropdown, and false to hide dropdown
   */
  const handleDatesDropdown = (val) => {
    setDatesVisibility(val);
  };

  const updateRender = (data) => {
    props.updateRender(data);
  };

  /**
   * This function hides the date dropdown selector before executing the query to bring activities
   * @param {*} a start_date
   * @param {*} b end_date
   */
  const middleDatesChange = (a, b) => {
    setDatesVisibility(false);
    props.changeDateState(a, b, null);
  };

  const presetDateChange = (weeks) => {
    /** Set default dates as today with 2 weeks forward */
    let start = new Date();
    let end = new Date();
    start = start.toISOString().split('T')[0].split('-').join('/');

    switch (weeks) {
      case '1':
        end.setDate(end.getDate() + 7);
        break;
      case '2':
        end.setDate(end.getDate() + 14);
        break;
      case '3':
        end.setDate(end.getDate() + 21);
        break;
      case '4':
        end.setDate(end.getDate() + 28);
        break;
      case '5':
        end.setDate(end.getDate() + 35);
        break;
      case '6':
        end.setDate(end.getDate() + 42);
        break;
      case '7':
        end.setDate(end.getDate() + 49);
        break;
      case '8':
        end.setDate(end.getDate() + 56);
        break;
    }
    end = end.toISOString().split('T')[0].split('-').join('/');
    setDatesVisibility(false);
    props.changeDateState(null, [start, end], weeks);
  };

  /**
   * This functions changes the visibility of dropdown for filters
   * @param {*} val Boolean, true to show dropdown, and false to hide dropdown
   */
  const handleFilterDropdown = (val) => {
    if (!isSubDropActive.value) {
      setFilterVisibility(val);
    }
    if (isSubDropActive.value && val) {
      setFilterVisibility(val);
    }
  };

  /**
   * This functions changes the visibility of dropdown for ordering
   * @param {*} val Boolean, true to show dropdown, and false to hide dropdown
   */
  const handleOrderDropdown = (val) => {
    setOrderVisibility(val);
  };

  /**
   * This functions changes the visibility of dropdown for grouping
   * @param {*} val Boolean, true to show dropdown, and false to hide dropdown
   */
  const handleGroupDropdown = (val) => {
    setGroupVisibility(val);
  };

  /**
   * @description Applay all setting`s filters saved
   * @param {Object} configView current view configuration
   */
  const applyFilters = (configView, groupFilter = false) => {
    if (groupFilter) {
      configView.filter_query.value &&
        filterLookaheadData(
          configView.filter_query,
          configView.filter_query.conditionFilter,
          avoidUpdateRender
        );
      configView.order_query.value &&
        orderLookaheadData(configView.order_query);
      return;
    }

    // applay queryFilter
    if (configView.filter_query.value) {
      if (
        currentFilter.queryFilter.length !=
          configView.filter_query.value.length ||
        !findHidePropTask()
      ) {
        filterLookaheadData(
          configView.filter_query,
          configView.filter_query.conditionFilter,
          avoidUpdateRender
        );
      }
    }

    // apply order query
    if (
      configView.order_query.value &&
      currentOrderFilter.length != configView.order_query.value.length
    ) {
      orderLookaheadData(configView.order_query);
    }
  };

  const findHidePropTask = () => {
    for (const activity of props.data) {
      if (!activity.tasks.length) {
        continue;
      }
      for (const task of activity.tasks) {
        // eslint-disable-next-line no-prototype-builtins
        if (task.hasOwnProperty('hide')) {
          return true;
        }
      }
    }

    return false;
  };

  const onSearchChange = (search) => {
    search = search.target.value;
    if (toSearchOnPage != search) {
      window.finder.findTerm(search);
    } else {
      window.finder.nextResult();
    }
    const newCounterState = {
      current: window.finder.currentResult,
      total: window.finder.resultsCount
    };
    const query = `el.name.toLocaleLowerCase().includes('${search.toLocaleLowerCase()}')`;

    dataToFilter.value.map((el) => {
      recursiveFilter(query, el.tasks);
    });

    setCounterSearch(newCounterState);
    setToSearchOnPage(search);
    props.updateRender(dataToFilter.value);
  };

  const getCounterForSearch = () => {
    if (counterSearch.current && counterSearch.total) {
      return (
        <span className="counter-search-on-page">
          {counterSearch.current}/{counterSearch.total}
        </span>
      );
    }
    return null;
  };

  const [backgroundColorFilter, setBackgroundColorFilter] = useState({
    show: false,
    style: {},
    counter: 0
  });

  const checkBackgrondFilter = (showedfilters) => {
    if (showedfilters.value) {
      if (
        showedfilters.value.length &&
        showedfilters.value.length != backgroundColorFilter.counter
      ) {
        setBackgroundColorFilter({
          show: true,
          style: {
            border: '1px solid #2C3421',
            borderRadius: 4,
            width: '90%',
            height: '128%',
            top: '-25%',
            left: '8%',
            position: 'absolute'
          },
          counter: showedfilters.value.length
        });
      } else if (showedfilters.value.length != backgroundColorFilter.counter) {
        setBackgroundColorFilter({
          show: false,
          style: {},
          counter: showedfilters.value.length
        });
      }
    }
  };

  const [backgroundColorGroup, setBackgroundColorGroup] = useState({
    show: false,
    style: {}
  });
  const checkBackgrondGroup = (showGroups) => {
    const groups = JSON.parse(showGroups);
    if (groups.criteria !== 'activityId') {
      setBackgroundColorGroup({
        show: true,
        style: {
          border: '1px solid #2C3421',
          borderRadius: 4,
          width: '90%',
          height: '128%',
          top: '-25%',
          left: '8%',
          position: 'absolute'
        }
      });
    } else {
      setBackgroundColorGroup({ show: false, style: {} });
    }
  };

  const [backgroundColorOrder, setBackgroundColorOrder] = useState({
    show: false,
    style: {},
    counter: 0
  });

  const checkBackgrondOrder = (showedfilters) => {
    if (showedfilters.value) {
      if (
        showedfilters.value.length &&
        showedfilters.value.length != backgroundColorOrder.counter
      ) {
        setBackgroundColorOrder({
          show: true,
          style: {
            border: '1px solid #2C3421',
            borderRadius: 4,
            width: '90%',
            height: '128%',
            top: '-25%',
            left: '8%',
            position: 'absolute'
          },
          counter: showedfilters.value.length
        });
      } else if (showedfilters.value.length != backgroundColorOrder.counter) {
        setBackgroundColorOrder({
          show: false,
          style: {},
          counter: showedfilters.value.length
        });
      }
    }
  };

  const [downloadVisibility, setDownloadVisibility] = useState(false);
  const handleDownloadDropdown = (val) => {
    setDownloadVisibility(val);
    setRefresh(val);
  };

  const handleToolbarCommandDispatched = (command, value) =>
    executeNewToolbarCommand(command, value);

  const changeVisualization = (view) => props.changeVisualization(view);

  /** Render */
  return (
    selectedOption.length > 0 && (
      <Row
        onClick={() => {
          avoidOptimizationTimelineBlinking();
        }}
        className={
          isPlanification
            ? LookaheadFilterHeaderStyles['lookahead-filter-header']
            : 'lookahead-filter-header'
        }>
        {isPlanification && (
          <LookaheadToolbar
            isWeeklyCommited={isWeeklyCommited}
            t={t}
            toogleActivityTreePane={props.setActiveActivityTreePane}
            activitiTreePane={props.activitiTreePane}
            onCommandDispatched={handleToolbarCommandDispatched}
            disableColumnsSelector={disableColumnsSelector}
            filterCounter={visualizationOp?.filter_query?.value?.length || 0}
            orderCounter={visualizationOp?.order_query?.value?.length || 0}
            renderIndicator={props.renderIndicator}
            data={props.data}
            toolbarLeftSide={{
              lookaheadVisualizationProps: {
                changeVisualization: changeVisualization,
                dateRange: props.dateRange,
                getLookahead: props.getLookahead,
                groupBy: props.groupBy,
                setDateRange: props.setDateRange,
                isPlanification: props.isPlanification,
                lookaheadState: lookaheadState,
                lookaheadActions: lookaheadActions,
                orderQuery: orderQuery,
                queryFilter: queryFilter,
                viewType: viewType,
                visualizationOp: visualizationOp,
                listViews: listViews,
                defaultView: defaultView,
                removeFiltersInCurrentView: updateDefaultViewFilters
              },
              lookaheadRange: {
                LookaheadFilterHeaderStyles,
                dateRangePickerOptions,
                middleDatesChange,
                presetDateChange,
                dateRange,
                visualizationOp
              },
              lookaheadFilter: {
                checkBackgrondFilter: checkBackgrondFilter,
                currentFilter: currentFilter,
                dataToFilter: dataToFilter,
                reApplyFilters: props.reApplyFilters,
                filterLookaheadData: filterLookaheadData,
                groupCriteria: props.groupBy.criteria,
                isSubDropActive: isSubDropActive,
                isPlanification: isPlanification,
                setIsSubDropActive: setIsSubDropActive,
                lookaheadState: props.lookaheadState,
                options: props.tableMetadata,
                setIsFilter: setIsFilter,
                toSelectResponsables: props.toSelectResponsables,
                toSelectTags: props.toSelectTags,
                updateRender: updateRender,
                viewType: visualizationOp,
                isFilterByActivityId: props.filterByActivityId
              },
              lookaheadOrder: {
                checkBackgrondOrder: checkBackgrondOrder,
                currentOrderFilter: currentOrderFilter,
                dataToFilter: dataToFilter,
                lookaheadState: props.lookaheadState,
                options: props.tableMetadata,
                orderLookaheadData: orderLookaheadData,
                updateRender: updateRender
              },
              lookaheadGroup: {
                visualizationOp: visualizationOp,
                data: props.data,
                defaultOrderOptions: defaultOrderOptions,
                groupBy: props.groupBy,
                lookaheadState: props.lookaheadState,
                modifyGroupBy: props.modifyGroupBy,
                options: props.tableMetadata,
                updateDefaultViewFilters: updateDefaultViewFilters
              },
              lookaheadColumns: {
                placeholder: t('filters_label.search_bar_label'),
                className: 'filter-lookahead-multi-check',
                placeholderButtonLabel: 'Columns',
                value: selectedOption,
                onChange: handleChange,
                options: children,
                getDropdownButtonLabel: (_) => null
              }
            }}
            toolbarRigthSide={{
              setIsShowPreview: props.setIsShowPreview,
              downloadOption: {
                excel: {
                  tableColumns: props.tableMetadata,
                  activateColumns: selectedOption,
                  dataToExport: props.data,
                  dateRange: props.dateRange,
                  resources: props.resources,
                  subContracts: props.subContracts,
                  cncTypes: props.cncTypes,
                  constraintTypes: props.constraintTypes,
                  projectSelected: props.projectSelected,
                  toSelectResponsables: props.toSelectResponsables,
                  toSelectTags: props.toSelectTags,
                  taskList: props.taskList,
                  lastLevelActivities: lastLevelActivities,
                  lookaheadState: props.lookaheadState,
                  haveWeeklyPlan: props.haveWeeklyPlan,
                  place: props.place
                },
                pdfReport: props.dateReportActive,
                ganttReport: {
                  skip_circular_links: false,
                  raw: false,
                  name: `${props.currentProject.name} - ${currentSector.name} lookahead from ${props.dateRange.start} to ${props.dateRange.end}.pdf`
                }
              },
              filterWeeklyTasks: showTaskInWeeklyRange,
              search: {
                prefix: getCounterForSearch(),
                placeholder: t('filters_label.search_bar_label'),
                onChange: (value) => onSearchChange(value),
                onPressEnter: (value) => onSearchChange(value)
              }
            }}
          />
        )}
        {!isPlanification && (
          <>
            <Col span={renderExtraFilterCenter ? 10 : 12}>
              <LookaheadVisualization
                defaultUserView={defaultView}
                changeVisualization={changeVisualization}
                dateRange={props.dateRange}
                getLookahead={props.getLookahead}
                groupBy={props.groupBy}
                setDateRange={props.setDateRange}
                isPlanification={props.isPlanification}
                lookaheadState={lookaheadState}
                lookaheadActions={lookaheadActions}
                orderQuery={orderQuery}
                queryFilter={queryFilter}
                viewType={viewType}
                visualizationOp={visualizationOp}
                listViews={listViews}
                removeFiltersInCurrentView={updateDefaultViewFilters}
              />
              {!disableLookahead && (
                <Col style={{ height: '100%', textAlign: 'center' }} span={4}>
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <LookaheadDateRangePicker
                        isPlanification={isPlanification}
                        LookaheadFilterHeaderStyles={
                          LookaheadFilterHeaderStyles
                        }
                        dateRangePickerOptions={dateRangePickerOptions}
                        middleDatesChange={middleDatesChange}
                        presetDateChange={presetDateChange}
                        dateRange={dateRange}
                        visualizationOp={visualizationOp}
                        t={t}
                      />
                    }
                    visible={datesVisibility}
                    onVisibleChange={handleDatesDropdown}>
                    <div style={{ cursor: 'pointer', fontSize: '12px' }}>
                      <IconComponent
                        data={date}
                        fill="#53C255"
                        className="svg-icon-date"
                      />
                      {t('filters_label.lookahead_label')}
                    </div>
                  </Dropdown>
                </Col>
              )}
              <Col style={{ height: '100%', textAlign: 'center' }} span={3}>
                <Dropdown
                  overlay={
                    <LookaheadFilter
                      checkBackgrondFilter={checkBackgrondFilter}
                      currentFilter={currentFilter}
                      dataToFilter={dataToFilter}
                      reApplyFilters={props.reApplyFilters}
                      filterLookaheadData={filterLookaheadData}
                      groupCriteria={props.groupBy.criteria}
                      isSubDropActive={isSubDropActive}
                      isPlanification={isPlanification}
                      setIsSubDropActive={setIsSubDropActive}
                      lookaheadState={props.lookaheadState}
                      options={props.tableMetadata}
                      setIsFilter={setIsFilter}
                      t={t}
                      toSelectResponsables={props.toSelectResponsables}
                      toSelectTags={props.toSelectTags}
                      updateRender={updateRender}
                      viewType={visualizationOp}
                      isFilterByActivityId={props.filterByActivityId}
                    />
                  }
                  trigger={['click']}
                  visible={filterVisibility}
                  onVisibleChange={handleFilterDropdown}>
                  <div style={{ cursor: 'pointer', fontSize: '12px' }}>
                    {backgroundColorFilter.show ? (
                      <span style={backgroundColorFilter.style}></span>
                    ) : null}
                    {backgroundColorFilter.show ? (
                      <span className="counter-selected-filters">
                        {backgroundColorFilter.counter}
                      </span>
                    ) : null}
                    <IconComponent
                      data={filter}
                      fill="#53C255"
                      className="svg-icon-filter"
                    />
                    {t('filters_label.filters_label')}
                  </div>
                </Dropdown>
              </Col>
              <Col style={{ height: '100%', textAlign: 'center' }} span={3}>
                <Dropdown
                  overlay={
                    <LookaheadOrder
                      checkBackgrondOrder={checkBackgrondOrder}
                      currentOrderFilter={currentOrderFilter}
                      dataToFilter={dataToFilter}
                      lookaheadState={props.lookaheadState}
                      options={props.tableMetadata}
                      orderLookaheadData={orderLookaheadData}
                      t={t}
                      updateRender={updateRender}
                    />
                  }
                  trigger={['click']}
                  visible={orderVisibility}
                  onVisibleChange={handleOrderDropdown}>
                  <div style={{ cursor: 'pointer', fontSize: '12px' }}>
                    {backgroundColorOrder.show ? (
                      <span style={backgroundColorOrder.style}></span>
                    ) : null}
                    {backgroundColorOrder.show ? (
                      <span className="counter-selected-filters">
                        {backgroundColorOrder.counter}
                      </span>
                    ) : null}
                    <IconComponent
                      data={order}
                      fill="#53C255"
                      className="svg-icon-order"
                    />
                    {t('filters_label.order_label')}
                  </div>
                </Dropdown>
              </Col>
              <Col style={{ height: '100%', textAlign: 'center' }} span={3}>
                <Dropdown
                  overlay={
                    <LookaheadGroup
                      visualizationOp={visualizationOp}
                      data={props.data}
                      defaultOrderOptions={defaultOrderOptions}
                      groupBy={props.groupBy}
                      lookaheadState={props.lookaheadState}
                      modifyGroupBy={props.modifyGroupBy}
                      options={props.tableMetadata}
                      updateDefaultViewFilters={updateDefaultViewFilters}
                    />
                  }
                  trigger={['click']}
                  visible={groupVisibility}
                  onVisibleChange={handleGroupDropdown}>
                  <div style={{ cursor: 'pointer', fontSize: '12px' }}>
                    {backgroundColorGroup.show ? (
                      <span style={backgroundColorGroup.style}></span>
                    ) : null}
                    <IconComponent
                      data={group}
                      fill="#53C255"
                      className="svg-icon-group"
                    />
                    {t('filters_label.group_label')}
                  </div>
                </Dropdown>
              </Col>
              <Col style={{ height: '100%', textAlign: 'center' }} span={3}>
                {!disableColumnsSelector && (
                  <ReactMultiSelectCheckboxes
                    placeholder={t('filters_label.search_bar_label')}
                    className="filter-lookahead-multi-check"
                    getDropdownButtonLabel={() => (
                      <div
                        style={{ color: '#121212', fontSize: '12px' }}
                        onClick={() => {
                          setTimeout(() => {
                            const a = document.getElementsByClassName(
                              'css-1pcexqc-container'
                            );
                            if (a[0]) {
                              a[0].parentNode.style.marginTop = '0px';
                            }
                          }, 20);
                        }}>
                        <IconComponent
                          data={columns}
                          fill="#53C255"
                          className="svg-icon-columns"
                        />
                        {t('filters_label.columns_label')}
                      </div>
                    )}
                    placeholderButtonLabel="Columns"
                    value={selectedOption}
                    onChange={handleChange}
                    options={children}
                  />
                )}
              </Col>
            </Col>
            <Col span={renderExtraFilterCenter ? 4 : 2}>
              {renderExtraFilterCenter ? renderExtraFilterCenter() : null}
            </Col>

            <Col span={renderExtraFilterCenter ? 10 : 12}>
              <Col
                span={12}
                offset={12}
                style={{
                  textAlign: 'right',
                  width: '100%',
                  marginLeft: 0
                }}>
                {exportable || null}
                {renderExtraFilterRigth ? renderExtraFilterRigth() : null}
                <span
                  style={{
                    cursor: 'pointer',
                    fontSize: '19px',
                    display: 'inline-block',
                    marginLeft: 8
                  }}>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <LookAheadPlanExport
                            activateColumns={selectedOption}
                            tableColumns={props.tableMetadata}
                            dataToExport={props.data}
                            dateRange={props.dateRange}
                            resources={props.resources}
                            subContracts={props.subContracts}
                            cncTypes={props.cncTypes}
                            constraintTypes={props.constraintTypes}
                            projectSelected={props.projectSelected}
                            toSelectResponsables={props.toSelectResponsables}
                            taskList={props.taskList}
                            lastLevelActivities={lastLevelActivities}
                            lookaheadState={props.lookaheadState}
                            haveWeeklyPlan={props.haveWeeklyPlan}
                          />
                        </Menu.Item>
                        {visualizationOp?.name == 'gantt' && (
                          <Menu.Item>
                            <div
                              onClick={() => {
                                window.ganttVisualization.customExportToPDF({
                                  skip_circular_links: false,
                                  raw: false,
                                  name:
                                    props.currentProject.name +
                                    ' - ' +
                                    currentSector.name +
                                    ' lookahead from ' +
                                    props.dateRange.start +
                                    ' to ' +
                                    props.dateRange.end +
                                    '.pdf'
                                });
                              }}>
                              {t('week_interval_modal_view_pdf')}
                            </div>
                          </Menu.Item>
                        )}
                        {visualizationOp && (
                          <Menu.Item>
                            <div
                              onClick={() => {
                                props.dateReportActive();
                              }}>
                              {t('week_interval_modal_view')}
                            </div>
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                    trigger={['click']}
                    visible={downloadVisibility}
                    onVisibleChange={handleDownloadDropdown}
                    forceRender>
                    <Tooltip title={t('filters_label.download_label')}>
                      <span
                        style={{
                          cursor: 'pointer',
                          fontSize: '16px',
                          height: '20px'
                        }}>
                        <IconComponent
                          data={export_file}
                          width={14}
                          fill="#53C255"
                          className="svg-icon-export-file"
                        />
                      </span>
                    </Tooltip>
                  </Dropdown>
                </span>
                {isRoadblock && (
                  <button
                    style={{
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: 32,
                      marginRight: 20,
                      cursor: onlyRead ? 'not-allowed' : 'pointer',
                      background: '#7DFF8A',
                      color: '#121212',
                      borderRadius: 5,
                      padding: '3px 20px',
                      border: 'none'
                    }}
                    onClick={handleAddConstraint}
                    disabled={onlyRead}>
                    <RoadblockIcon color="#121212" />
                    <span style={{ marginLeft: 3 }}>
                      {t('add_constraint_label')}
                    </span>
                  </button>
                )}
                <div className="lookahead-search-on-page-bar">
                  <Search
                    prefix={getCounterForSearch()}
                    placeholder={t('filters_label.search_bar_label')}
                    onChange={(value) => onSearchChange(value)}
                    onPressEnter={(value) => onSearchChange(value)}
                    style={{ width: 200 }}
                  />
                </div>
              </Col>
            </Col>
          </>
        )}
      </Row>
    )
  );
}

export default withTranslation()(LookaheadFilterHeader);

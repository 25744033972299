import { getCompanyTextColor } from '../../../../utils';

export const TemplateSubcontractId = (task) => {
  const gantt = window?.ganttVisualization;
  const COLUMN = 'subcontractId';
  const SUBCONTRACT_DEFAULT = null;
  const { isTask } = task;

  if (!gantt || !isTask) return SUBCONTRACT_DEFAULT;

  const { subContracts } = gantt;
  const subcontractId = task[COLUMN];

  if (!subContracts.length) return SUBCONTRACT_DEFAULT;

  const statusObject = subContracts.find(({ id }) => id == subcontractId);

  if (!statusObject) return SUBCONTRACT_DEFAULT;

  const { name, color } = statusObject;
  const textColor = getCompanyTextColor(color);

  return `
        <div
            class="subcontract-border cut-text-and-dot columnExportPDFHide"
            style="
                height: 100%;
                padding: 0;
                padding-left: 3px;
                position: relative;
                text-align: center;
                margin-left: 3px;
                color: ${textColor} !important;
                background: ${color} !important;
            "
        >
            ${name}
        </div>
    `;
};

import React, { memo } from 'react';
import { InformationCircleOutlineIcon } from '../../../../../../icons';
import TooltipSystemV2 from '../../../../../DesignSystem/TooltipSystemV2';
import { DARK_GRAY } from '../../../../../../assets/styles/variables.scss';

const LastActive = memo(({ user, t }) => {
  const { lastActive, last_active } = user;

  const renderIcon = () => {
    const title = t(
      'settings.organization_settings.users_tab.table_last_active_tooltip'
    );
    return (
      <div className="last-active__icon">
        {TooltipSystemV2({
          title,
          placement: 'top',
          children: (
            <div className="content__icon">
              <InformationCircleOutlineIcon color={DARK_GRAY} />
            </div>
          )
        })}
      </div>
    );
  };

  return (
    <div className="last-active-column ">
      <span>{lastActive}</span>
      {!last_active && renderIcon()}
    </div>
  );
});

export default LastActive;

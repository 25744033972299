/* eslint no-label-var: "off" */
import React, { useState, useEffect, useRef } from 'react';

/**
 * High order components (HOC)
 */
import wrappedComponent from '../hocs/wrappedComponent.hoc';

/** Components */
import AppRouter from '../router';
import Toolbar from '../components/Toolbar';
import Loader from './loader';

/** Redux */
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * JSON with menu
 */
import { menu } from './layout.menu';
import EventEmitter from 'react-native-eventemitter';

import modalStyles from '../views/ganttContainer/gantt/modals.module.scss';

/** import elements from library Antd Framework */
import { Spin, Layout, Menu, Tooltip, Modal } from 'antd';

/** import icons from antd framework */
import { WarningOutlined, LoadingOutlined } from '@ant-design/icons';

import './layout.css';
import useAnalyticsPermissions from '../hooks/useAnalyticsPermissions';
import useMasterplanPermissions from '../hooks/useMasterplanPermissions';
import useLookaheadPermissions from '../hooks/useLookaheadPermissions';
import useWeeklyplanPermissions from '../hooks/useWeeklyplanPermissions';
import { useCheckUserAndLocation } from '../hooks/useCheckUserAndLocation';
import { withTranslation } from 'react-i18next';
import { HoveringPortal } from '../components/HoveringPortal';
import moment from 'moment';
import useSubtradeUser from '../hooks/useSubtradeUser';
import useUnsavedElementsAlerter from '../hooks/useUnsavedElementsAlerter';
import { log } from '../monitor/monitor';

export const TestIds = {
  MENU_OPTION_: 'MENU_OPTION_'
};

const { Content, Sider } = Layout;

const companyToExclude = 48;

function LayoutProPlanner(props) {
  useCheckUserAndLocation();
  const [FinishPage, setFinishPage] = useState(
    <Spin className="loader-layout-style" indicator={<LoadingOutlined />} />
  );
  const [selectedMenu, setSelectedMenu] = useState(null);
  const sideNavRef = useRef(null);
  window.sideNavRef = sideNavRef;
  const { t } = props;
  const analyticsPer = useAnalyticsPermissions();
  const masterplanPer = useMasterplanPermissions();
  const lookaheadPer = useLookaheadPermissions();
  const weeklyplanPer = useWeeklyplanPermissions();
  const routerState = useSelector((state) => state.routerState);
  const userLang = navigator.language || navigator.userLanguage;
  const { jsx, callback } = useUnsavedElementsAlerter(t);
  const withoutLayout = props.withoutLayout || false;
  const subtradeRole = useSubtradeUser();
  const [availableProducts, setAvailableProducts] = useState([
    analyticsPer,
    masterplanPer,
    lookaheadPer,
    weeklyplanPer
  ]);

  const [loadingPermission, setLoadingPermission] = useState(true);
  const [localMenu, setLocalMenu] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setSelectedMenu(null);
    setAvailableProducts([...availableProducts]);
  }, [routerState]);

  useEffect(() => {
    setTimeout(() => {
      const userLang = navigator.language || navigator.userLanguage;
      const copyMenu = [...menu(t, companyState.currentCompany, userLang)];

      availableProducts.map((product) => {
        let block = true;
        /** PERMISSIONS CODE (DO NOT REMOVE THIS COMMENT) */
        Object.keys(product).map((module) => {
          if (module != 'product') {
            if (
              product[module] == 'ACP' ||
              product[module] == 'AC' ||
              product[module] == 'V'
            ) {
              block = false;
            }
          }
        });
        product.hideSidenav = block;

        const ref = copyMenu.find((el) => el.routeName == product.product);
        ref.block = block;
      });
      /** NOTE: This code must be replaced by checking permission table for takt planning module */
      if (subtradeRole.isSubtradeRole) {
        const ref = copyMenu.find((el) => el.routeName === 'taktplanning');
        if (ref) {
          ref.block = true;
        }
      }

      copyMenu.filter((m) => {
        if (m.routeName == 'cloud') {
          if (userLang.includes('en')) {
            return false;
          }
          return true;
        }
      });
      setLocalMenu([...copyMenu]);
    }, 500);
  }, [availableProducts, subtradeRole.isSubtradeRole]);

  const selects = {
    projects: true,
    stages: true
  };

  const companyState = useSelector((state) => state.companyState);

  useEffect(() => {
    const url = window.location.href;
    let selected = '0';
    for (const option of localMenu) {
      if (url.includes(option.routeName)) {
        selected = option.id;
        break;
      }
    }
    const Final = wrappedComponent({ ...props, selects })(AppRouter);
    setSelectedMenu(selected);
    setFinishPage(<Final />);
    setTimeout(() => {
      setLoadingPermission(false);
    }, 1000);
  }, [localMenu, history, location]);

  useEffect(() => {
    setCurrentActiveItem(selectedMenu);
    setSelectedKeys([selectedMenu]);
  }, [selectedMenu]);

  const [currentActiveItem, setCurrentActiveItem] = useState(null);

  const updateLocalState = (item) => {
    EventEmitter.emit('changeMainRoute', { route: item.go });
    setSelectedKeys([item.id]);
    setCurrentActiveItem(item.id);
  };

  const onClickItemSidenav = (item) => {
    /** Means masterplan active */
    if (currentActiveItem == 0) {
      const forceReturn =
        callback &&
        callback(() => {
          updateLocalState(item);
        });
      if (forceReturn) return;
    }
    updateLocalState(item);
    if (!item) return;
    log('module_change', item.routeName);
  };

  const [selectedKeys, setSelectedKeys] = useState([]);

  const noLayout = (
    <Layout>
      <Content>
        {FinishPage}
        {jsx}
      </Content>
    </Layout>
  );

  const layout = (
    <span>
      {loadingPermission ? null : (
        <span>
          <Toolbar selects={selects} fixed={props.position !== undefined} />
          <Layout>
            <Sider
              id="asideId"
              trigger={null}
              style={{ backgroundColor: '#101010', width: '74px!important' }}>
              {selectedMenu ? (
                <Menu
                  ref={sideNavRef}
                  id="menu"
                  style={{
                    width: 74,
                    backgroundColor: '#101010',
                    color: 'white'
                  }}
                  // defaultSelectedKeys={[selectedMenu]}
                  selectedKeys={selectedKeys}
                  mode="inline">
                  {localMenu.map((item, i) => {
                    if (
                      i > 0 &&
                      companyState?.currentCompany?.id === companyToExclude
                    )
                      return false;
                    if (item.routeName == 'cloud' && userLang.includes('en'))
                      return false;
                    return (
                      !item.block && (
                        <Menu.Item
                          disabled={!item.available}
                          className="layout-sidebar-icon"
                          key={i}
                          data-testid={TestIds.MENU_OPTION_ + i}
                          onClick={() => onClickItemSidenav(item)}>
                          <span className="layout-sidebar-title">
                            <Tooltip
                              placement="right"
                              title={
                                item.available
                                  ? item.title
                                  : item.title +
                                    ' (' +
                                    t('modules.next_release') +
                                    ')'
                              }>
                              <div>{item.svgIcon(item.routeName)}</div>
                            </Tooltip>
                          </span>
                        </Menu.Item>
                      )
                    );
                  })}
                </Menu>
              ) : null}
            </Sider>
            <Layout>
              <Content
                style={{
                  margin: '0px 0px',
                  padding: 0,
                  background: '#fff'
                }}>
                {props.children}
                {FinishPage}
                {jsx}
              </Content>
            </Layout>
          </Layout>
        </span>
      )}
    </span>
  );

  return withoutLayout ? (
    <>{noLayout}</>
  ) : (
    <>
      {layout}
      <HoveringPortal />
      <Loader />
    </>
  );
}

export default withTranslation()(LayoutProPlanner);

import { getFormatDate } from '../helpers/formateDate';

const MARGIN_ELEMENT = '<div style="height:5px; width:30px"></div>';

const IMAGE_CRITICAL =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146869923-logo.png';
const IMAGE_NON_CRITICAL =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146893853-logo.png';
const IMAGE_COMPLETED =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662521987746-logo.png';
const IMAGE_OVERDUE =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146869923-logo.png';
const IMAGE_AHEAD =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662521930394-logo.png';
const IMAGE_UNINITIATED =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146893853-logo.png';
const IMAGE_PARENT_ACTIVITY =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146539442-logo.png';
const IMAGE_CHILD_ACTIVITY =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146694166-logo.png';
const IMAGE_BASELINE =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146724169-logo.png';
const IMAGE_MILESTONE =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146751776-logo.png';
const IMAGE_LINKS =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146790862-logo.png';
const IMAGE_FLOAT =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146843189-logo.png';

/**
 * Generates the HTML for the status or critical path indicators in the PDF footer based on the Gantt chart configuration.
 *
 * @param {Object} gantt - The Gantt chart instance, containing configuration and visualization settings.
 * @param {Object} gantt.config - The configuration settings of the Gantt chart.
 * @param {boolean} gantt.config.highlight_critical_path - Indicates if the critical path should be highlighted.
 * @param {string} gantt.visualizationColorActive - The active visualization color setting, e.g., 'status'.
 *
 * @returns {string} The HTML string for the status or critical path indicators, or an empty string if no indicators are active.
 */
const getOptionStatus = (gantt) => {
  const { config, visualizationColorActive } = gantt;
  const { highlight_critical_path: highlightCriticalPath } = config;

  if (highlightCriticalPath) {
    return `${MARGIN_ELEMENT}${MARGIN_ELEMENT}
                <div class="footer_container_icons_column">
                    <span class="footer_container_icons_column_title">Critical Path</span>${MARGIN_ELEMENT}${MARGIN_ELEMENT}
                    <div class="footer_container_items">
                        <div class="footer_container_icons_column_item">
                            <span><img class="footer-icon" src="${IMAGE_CRITICAL}"/> Critical</span>
                            ${MARGIN_ELEMENT}
                            <span><img class="footer-icon" src="${IMAGE_NON_CRITICAL}"/> Non Critical</span>
                        </div>
                    </div>
                </div>`;
  }

  if (visualizationColorActive === 'status') {
    return `${MARGIN_ELEMENT}${MARGIN_ELEMENT}
                <div class="footer_container_icons_column">
                    <span class="footer_container_icons_column_title">Status</span>${MARGIN_ELEMENT}${MARGIN_ELEMENT}
                    <div class="footer_container_items">
                        <div class="footer_container_icons_column_item">
                        <span>
                            <div>
                                <img class="footer-icon" src="${IMAGE_COMPLETED}"/> 
                            </div>
                            <span>Completed</span>
                            ${MARGIN_ELEMENT}
                        </span>
                        ${MARGIN_ELEMENT}
                        <span>
                            <div>
                                <img class="footer-icon" src="${IMAGE_OVERDUE}"/> 
                            </div>
                            <span>Overdue</span>
                            ${MARGIN_ELEMENT}
                        </span>
                    </div>
                    <div class="footer_container_icons_column_item">
                        <span>
                            <div>
                                <img class="footer-icon" src="${IMAGE_AHEAD}"/> 
                            </div>
                            <span>Ahead</span>
                            ${MARGIN_ELEMENT}
                        </span>
                        ${MARGIN_ELEMENT}
                        <span>
                            <div>
                                <img class="footer-icon" src="${IMAGE_UNINITIATED}"/> 
                            </div>
                            <span>Uninitiated</span>
                            ${MARGIN_ELEMENT}
                        </span>
                    </div>
                </div>
        </div>`;
  }

  return '';
};

/**
 * Generates the HTML for the schedule indicators in the PDF footer if the schedule mode is active.
 *
 * @param {boolean} isSchedule - Indicates whether the schedule mode is active.
 *
 * @returns {string} The HTML string for the schedule indicators, or an empty string if the schedule mode is inactive.
 */
const getOptionSchedule = (isSchedule) => {
  if (!isSchedule) {
    return '';
  }

  return ` 
    <div class="footer_container_icons_column_item">
        <span>
            <div>
                <img class="footer-icon" src="${IMAGE_BASELINE}"/> 
            </div>
            <span>Baseline</span>
            ${MARGIN_ELEMENT}
        </span>
        ${MARGIN_ELEMENT}
        <span>
            <div>
                <span style="text-align: center; font-size: 13px;margin-left:-3px"><p>(+# days)</p> 
            </div>
            <span>Delay</span>
            ${MARGIN_ELEMENT}
        </span>
    </div>
`;
};

/**
 * Generates the HTML for the child activity or child task indicator in the PDF footer.
 *
 * @param {boolean} isSchedule - Determines whether the name should be 'Child Activity' (if true) or 'Child Task' (if false).
 * @returns {string} The HTML string for the child activity or child task indicator.
 */
const getOptionChild = (isSchedule) => {
  const name = isSchedule ? 'Child Activity' : 'Child Task';
  return `
        <span>
            <div>
                <img class="footer-icon" src="${IMAGE_CHILD_ACTIVITY}"/> 
            </div>
            <span>${name}</span>
            ${MARGIN_ELEMENT}
        </span>
    `;
};

/**
 * Generates the HTML for the footer section of the PDF export.
 *
 * @param {Object} gantt - The Gantt chart instance, used for configuration and data.
 * @param {Function} t - Translation function for localization.
 * @param {Date} date - The date to format and include in the footer.
 * @param {boolean} isSchedule - Determines if the schedule mode is active.
 *
 * @returns {string} The HTML string for the footer section of the PDF.
 */
export const getFooterPDF = ({ gantt, t, date, isSchedule }) => {
  const OUTBUILD_LOGO =
    'https://proplannerv2.s3.us-east-2.amazonaws.com/pdf-gantt/outbuild-logo.png';
  const imageOutbuildHTML = `<img src="${OUTBUILD_LOGO}" class="img-pp" alt="logo-proplanner" />`;
  const printedOn = t('master_plan.printed_on');
  const page = t('master_plan.page');
  const today = getFormatDate({ gantt, date });

  const optionStatus = getOptionStatus(gantt);
  const optionSchedule = getOptionSchedule(isSchedule);
  const optionChild = getOptionChild(isSchedule);

  return `<div class="footer_container">
                <div style="display: flex;flex-direction: row">${MARGIN_ELEMENT}${imageOutbuildHTML}</div>   
                <div class="footer_container_icons">
                    <div class="footer_container_icons_column">
                        <span class="footer_container_icons_column_title">General</span>${MARGIN_ELEMENT}${MARGIN_ELEMENT}
                        <div class="footer_container_items">
                            <div class="footer_container_icons_column_item">
                                <span>
                                    <div>
                                        <img class="footer-icon" src="${IMAGE_PARENT_ACTIVITY}"/> 
                                    </div>
                                    <span>Parent Activity</span>
                                    ${MARGIN_ELEMENT}
                                </span>
                                ${MARGIN_ELEMENT}
                                ${optionChild}
                            </div>
                            ${optionSchedule}
                            <div class="footer_container_icons_column_item">
                                <span>
                                    <div style="width:30px !important">
                                        <img class="footer-icon" src="${IMAGE_MILESTONE}" style="height:15px !important"/> 
                                    </div>
                                    <span>Milestone</span>
                                    ${MARGIN_ELEMENT}
                                </span>
                            </div>
                            <div class="footer_container_icons_column_item">
                                <span class="footer_container_icons_column_item__links">
                                    <img class="footer-icon" src="${IMAGE_LINKS}"/>
                                    Links
                                </span>
                                ${MARGIN_ELEMENT}
                                <span class="footer_container_icons_column_item__float" style="display:none">
                                    <img class="footer-icon" src="${IMAGE_FLOAT}"/>
                                    Float
                                </span>
                            </div>
                        </div>
                    </div>
                    ${optionStatus}
                    
                </div>
                <div class="footer_container_info">
                    <span class="gantt-footer-info">
                        <p class="gantt-footer-title">${printedOn}:  </p>
                        <p class="gantt-footer-data">${today} </p>
                        ${MARGIN_ELEMENT}
                    </span>
                    <span id="gantt-footer-page" class="gantt-footer-info" style="visibility:hidden">
                        <p class="gantt-footer-title">${page}:  </p>
                        <p class="gantt-footer-data">{{p}}/{{pt}} </p>
                    </span>
                </div>
            </div>`;
};

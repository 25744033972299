import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import colors from '../../stylesheets/variables.scss';

import { Input } from 'antd';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';
import * as icons from '../../icons';
import ReactMultiselectCheckboxes from 'react-multiselect-checkboxes';
import { LookaheadDateRangePicker } from '../LookaheadDateRangePicker';
import LookaheadFilter from '../LookaheadFilter';
import LookaheadGroup from '../LookaheadGroup';
import LookaheadOrder from '../LookaheadOrder';
import { ToolbarGroup } from '../ToolbarGroup';

import {
  TOOLBAR_LEFT_GROUP_ITEMS,
  TOOLBAR_RIGTH_GROUP_ITEMS,
  TOOLBAR_TIMELINE_OPTIONS,
  COLOR_SCHEME_TYPE_SUBMENU_ITEMS,
  TOOLBAR_ITEMS_TRACK
} from './constants';

import styles from './LookaheadToolbar.module.scss';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import { isFeatureOn } from '../../utils/featureUtils';
import { viewService } from '../../services/views.service';
import useLookaheadPermissions from '../../hooks/useLookaheadPermissions';
import { useSelector, useDispatch } from 'react-redux';
import { timelineActions } from '../../redux/actions/lookaheadTimelineActions';
import * as Sentry from '@sentry/react';
import { log } from '../../monitor/monitor';
import { userService } from '../../services';
import { trackingEvent } from '../../analytics';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { exportPDFGantt } from '../../assets/gantt/ext/Export/ExportPDFGantt';

const { Search } = Input;
export const TestIds = {
  LOOKAHEAD_TOOLBAR_CONTAINER: 'LOOKAHEAD_TOOLBAR_CONTAINER'
};

export const LookaheadToolbar = ({
  toogleActivityTreePane = (_) => {},
  onCommandDispatched = (_) => {},
  renderIndicator = (_) => {},
  t = (_) => {},
  disableColumnsSelector = false,
  filterCounter = 0,
  orderCounter = 0,
  activitiTreePane = false,
  toolbarLeftSide: {
    lookaheadVisualizationProps = {},
    lookaheadRange = {},
    lookaheadFilter = {},
    lookaheadOrder = {},
    lookaheadGroup = {},
    lookaheadColumns = {}
  },
  toolbarRigthSide: {
    setIsShowPreview = (_) => {},
    filterWeeklyTasks = (_) => {},
    downloadOption: { excel = {}, pdfReport = (_) => {}, ganttReport = {} },
    search: { prefix, placeholder, onChange, onPressEnter }
  },
  isWeeklyCommited = false,
  data = []
}) => {
  const timelinePDFOptions = useRef({
    icon: null,
    iconColor: colors.brandBlue40,
    i18nKey: '',
    command: ''
  });

  const lookaheadRef = useRef(null);
  const filterRef = useRef(null);
  const orderRef = useRef(null);
  const groupRef = useRef(null);
  const columnsRef = useRef(null);
  const projectState = useSelector((state) => state.projectState);
  const dispatch = useDispatch();
  const [renderForced, setRenderForced] = useState(true);
  const [rightToolbar, setToolbarRightSide] = useState(false);
  const [isPreviewBtn, setIsPreviewBtn] = useState(false);
  const [refreshExcelExport, setrefreshExcelExport] = useState(true);
  const [actions, setActions] = useState({
    LOOKAHEAD: false,
    FILTER: false,
    ORDER: false,
    GROUP: false,
    COLUMNS: false
  });

  const trackMultipleEvents = (eventName, additionalProps = {}) => {
    try {
      trackingEvent(
        eventName,
        {
          ...getBasicAmplitudEventProperties(),
          ...additionalProps
        },
        AMPLITUDE_SERVICE
      );
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  };

  /** Permission */
  const lookaheadPermissions = useLookaheadPermissions();
  const notAccessCloseWeek = ['V', 'ACP'].includes(lookaheadPermissions?.plan);

  /** Similar to did mount */
  useEffect(() => {
    const correctZoomLVL = (data) => {
      const zoomLevel = data.value;
      if (!zoomLevel) {
        return;
      }
      if (TOOLBAR_RIGTH_GROUP_ITEMS && TOOLBAR_RIGTH_GROUP_ITEMS.length) {
        const zoomRef = TOOLBAR_RIGTH_GROUP_ITEMS.findIndex(
          (node) => node.command === 'SET_ZOOM_LEVEL'
        );
        if (zoomRef !== null && zoomRef !== undefined && zoomRef !== -1) {
          TOOLBAR_RIGTH_GROUP_ITEMS[zoomRef].defaultValue = zoomLevel;
          updateUserView('zoomLevel', zoomLevel);
        }
      }
      setRenderForced(false);
      setRenderForced(true);
    };

    correctZoomLVL({ value: window?.ganttVisualization?.stringCurrentValue });
    EventEmitter.on('changeScaleVisualization', correctZoomLVL);

    return () => {
      EventEmitter.removeListener('changeScaleVisualization', correctZoomLVL);
    };
  }, []);

  useEffect(() => {
    const idx = TOOLBAR_RIGTH_GROUP_ITEMS.findIndex(
      (item) => item.id == 'download-options'
    );
    const lookaheadBtn = TOOLBAR_LEFT_GROUP_ITEMS[1].findIndex(
      (item) => item.command == 'LOOKAHEAD'
    );

    isFeatureOn('enable-LWP-103') &&
      (TOOLBAR_LEFT_GROUP_ITEMS[1][lookaheadBtn].tooltipI18nKey =
        'filters_label.lookahead_activities_label');

    TOOLBAR_RIGTH_GROUP_ITEMS[idx].subitems[0].props = { ...excel };
  }, []);

  useEffect(() => {
    setrefreshExcelExport(false);
    const idx = TOOLBAR_RIGTH_GROUP_ITEMS.findIndex(
      (item) => item.id == 'download-options'
    );
    // const lookaheadBtn = TOOLBAR_LEFT_GROUP_ITEMS[1].findIndex(item => item.command == 'LOOKAHEAD')
    // isFeatureOn('enable-LWP-103') && (TOOLBAR_LEFT_GROUP_ITEMS[1][lookaheadBtn].tooltipI18nKey = 'filters_label.lookahead_activities_label')
    TOOLBAR_RIGTH_GROUP_ITEMS[idx].subitems[0].props = { ...excel };
    // setrefreshExcelExport(true )
  }, [data]);

  useEffect(() => {
    if (!refreshExcelExport) {
      setrefreshExcelExport(true);
    }
  }, [refreshExcelExport]);

  useEffect(
    (_) => {
      const filterIdx = TOOLBAR_LEFT_GROUP_ITEMS[1].findIndex(
        (item) => item.command == 'FILTER'
      );
      const orderIdx = TOOLBAR_LEFT_GROUP_ITEMS[1].findIndex(
        (item) => item.command == 'ORDER'
      );

      TOOLBAR_LEFT_GROUP_ITEMS[1][filterIdx].counterSelected = filterCounter;
      TOOLBAR_LEFT_GROUP_ITEMS[1][orderIdx].counterSelected = orderCounter;
    },
    [filterCounter, orderCounter]
  );

  useEffect(() => {
    TOOLBAR_LEFT_GROUP_ITEMS[0].props = {
      ...lookaheadVisualizationProps
    };
  }, [lookaheadVisualizationProps]);

  useEffect(
    (_) => {
      const idx = TOOLBAR_RIGTH_GROUP_ITEMS.findIndex(
        (item) => item.id == 'download-options'
      );

      const timelineOption = TOOLBAR_RIGTH_GROUP_ITEMS[idx].subitems?.find(
        (item) => item.i18nKey == timelinePDFOptions.current.i18nKey
      );

      if (
        !timelineOption &&
        lookaheadVisualizationProps?.visualizationOp?.module !=
          'Masterplan_gantt'
      ) {
        return;
      }

      if (
        timelineOption &&
        lookaheadVisualizationProps?.visualizationOp?.module ==
          'Masterplan_gantt'
      ) {
        return;
      }

      if (
        timelineOption &&
        lookaheadVisualizationProps.visualizationOp.module != 'Masterplan_gantt'
      ) {
        TOOLBAR_RIGTH_GROUP_ITEMS[idx].subitems.splice(1, 1);
        TOOLBAR_RIGTH_GROUP_ITEMS.shift();
        TOOLBAR_RIGTH_GROUP_ITEMS.shift();
        TOOLBAR_RIGTH_GROUP_ITEMS.shift();
        TOOLBAR_RIGTH_GROUP_ITEMS.shift();
        TOOLBAR_RIGTH_GROUP_ITEMS.shift();
        return;
      }

      /** What does mean Masterplan_gantt, if this condition only exist on lookahead TIMELINE view ¿ */
      updateNewWeeklyLookaheadIntegrationToolbarBtn('initial_data_effect');
    },
    [lookaheadVisualizationProps]
  );

  useEffect(() => {
    if (
      lookaheadVisualizationProps?.visualizationOp?.module !==
      'Masterplan_gantt'
    ) {
      return;
    }

    updateNewWeeklyLookaheadIntegrationToolbarBtn('update_data_effect');
  }, [
    isWeeklyCommited,
    projectState.projectSelected,
    projectState.sectorSelected
  ]);

  useEffect(() => {
    const previewBtn = TOOLBAR_RIGTH_GROUP_ITEMS.findIndex(
      (item) => item.id == 'weekly-lookahead-preview'
    );

    if (previewBtn < 0) return;

    TOOLBAR_RIGTH_GROUP_ITEMS[previewBtn].activeBtn = isPreviewBtn;
    TOOLBAR_RIGTH_GROUP_ITEMS[previewBtn].iconColor = isPreviewBtn
      ? '#FFFFFF'
      : '#2C3421';
    setToolbarRightSide(!rightToolbar);
  }, [isPreviewBtn]);

  const findView = (view, userViewId) => {
    if (view.id == userViewId) return true;
  };

  /**
   * This function handle new button from weekly/lookahead integration, which one must be called on updated component
   * Or being called as initial load effect when props comes to the current component
   * @param {*} flow String which declarative way say what case use we are working with
   * @returns None to avoid some effects flows
   */
  const updateNewWeeklyLookaheadIntegrationToolbarBtn = (flow) => {
    if (!lookaheadPermissions) return;
    const { plan } = lookaheadPermissions;

    /** Permission ACP is a custom permission behaviour so, must be stopped */
    // if (notAccessCloseWeek.includes(plan)) return

    /** Flow which is executed when update states effect launch it */
    if (flow === 'update_data_effect') {
      const idCommitBtn = TOOLBAR_TIMELINE_OPTIONS.findIndex(
        (item) =>
          item.command === 'COMMIT_WEEKLY_PLAN' ||
          item.command === 'REVIEW_WEEKLY_PLAN'
      );
      if (isWeeklyCommited.commited) {
        TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].command = 'REVIEW_WEEKLY_PLAN';
        TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].iconColor = '#2C3421';
        TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].i18nKey =
          'new_weekly_lookahead.review_btn';
        TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].mainColor = false;
        setToolbarRightSide(!rightToolbar);
        return;
      }

      if (!isWeeklyCommited.commited) {
        const idCommitBtn = TOOLBAR_TIMELINE_OPTIONS.findIndex(
          (item) =>
            item.command === 'COMMIT_WEEKLY_PLAN' ||
            item.command === 'REVIEW_WEEKLY_PLAN'
        );

        TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].command = 'COMMIT_WEEKLY_PLAN';
        TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].i18nKey =
          'new_weekly_lookahead.commit_btn';
        TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].mainColor = true;
        if (notAccessCloseWeek) {
          TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].disabled = true;
          TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].tooltip = t(
            'not_permissions_actions'
          );
        }

        setToolbarRightSide(!rightToolbar);
      }
      /** This one, does launch when the initial props effect is called */
    } else if (flow === 'initial_data_effect') {
      const idx = TOOLBAR_RIGTH_GROUP_ITEMS.findIndex(
        (item) => item.id == 'download-options'
      );
      const timelineOption = TOOLBAR_RIGTH_GROUP_ITEMS[idx].subitems?.find(
        (item) => item.i18nKey == timelinePDFOptions.current.i18nKey
      );

      if (
        !timelineOption &&
        lookaheadVisualizationProps.visualizationOp.module == 'Masterplan_gantt'
      ) {
        /** This modification allows to put always to right the commit btn, without changing the order of older btns */
        TOOLBAR_RIGTH_GROUP_ITEMS[idx].subitems.splice(
          1,
          0,
          timelinePDFOptions.current
        );
        const idCommitBtn = TOOLBAR_RIGTH_GROUP_ITEMS.findIndex(
          (item) => item.command === 'COMMIT_WEEKLY_PLAN'
        );

        TOOLBAR_RIGTH_GROUP_ITEMS.unshift(...TOOLBAR_TIMELINE_OPTIONS);
        setToolbarRightSide(!rightToolbar);

        if (isWeeklyCommited.commited) {
          if (TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn]) {
            TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].command =
              'REVIEW_WEEKLY_PLAN';
            TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].iconColor = '#2C3421';
            TOOLBAR_RIGTH_GROUP_ITEMS[idCommitBtn].i18nKey =
              'new_weekly_lookahead.review_btn';
          }
        }
      }
    }
  };

  const handleHideFilters = (target) => {
    const classesToIgnore = [
      'css-1hwfws3',
      'option',
      'placeholder',
      'control',
      'ant-calendar-range-picker-input',
      'ant-calendar-date',
      'optionForFilter',
      'ant-select-dropdown-menu-item'
    ];

    const shouldIgnoreTarget = (target) => {
      if (!target || !target.className) return false;
      return classesToIgnore.some((className) => {
        return target.className.includes(className);
      });
    };

    if (target && target.nodeName === 'DIV' && !shouldIgnoreTarget(target)) {
      handleHideLeftActionOptions();
    }
  };

  useOutsideAlerter(lookaheadRef, handleHideFilters);
  useOutsideAlerter(filterRef, handleHideFilters);
  useOutsideAlerter(orderRef, handleHideFilters);
  useOutsideAlerter(groupRef, handleHideFilters);
  useOutsideAlerter(columnsRef, handleHideFilters);

  const changeColorSchema = async (color) => {
    COLOR_SCHEME_TYPE_SUBMENU_ITEMS.forEach((subitem) => {
      subitem.isChecked =
        subitem.command === `CHANGE_COLOR_FOR_${color.toUpperCase()}`;
    });
    try {
      const defaultViewId = await getDefaultViewIndex();
      window.listView.current[defaultViewId].view.colorSchema = color;
    } catch (e) {
      Sentry.captureMessage(e, 'warning');
    }

    lookaheadVisualizationProps.defaultView.colorSchema = color;
  };

  const handleHideLeftActionOptions = (action = '') => {
    const commands = Object.keys(actions).reduce(
      (actionsCommand, nextActionCommand) => {
        actionsCommand[nextActionCommand] =
          action == actionsCommand ? !actions[action] : false;
        return actionsCommand;
      },
      {}
    );

    setActions(commands);
  };

  const handleLetToolbarActionDispatch = (action = '') => {
    const commands = Object.keys(actions).reduce(
      (actionsCommand, nextActionCommand) => {
        actionsCommand[nextActionCommand] =
          nextActionCommand == action ? !actions[action] : false;
        return actionsCommand;
      },
      {}
    );

    if (isFeatureOn('enable-LWP-103') && action == 'LOOKAHEAD') {
      toogleActivityTreePane(!activitiTreePane);
    }

    if (TOOLBAR_ITEMS_TRACK[action]) {
      const { eventName, getParams } = TOOLBAR_ITEMS_TRACK[action];
      const params = getParams ? getParams(action) : {};
      trackMultipleEvents(eventName, params);
    }

    setActions(commands);
  };

  const getUserDefaultView = async () => {
    const localUser = JSON.parse(localStorage.getItem('user'));
    const currentSector = projectState.sectorSelected;
    const updatedUser = await userService.show(localUser.id);
    const allUserDefaultViews = updatedUser?.user?.defaultLookaheadView;
    const userSectorDefaultView =
      allUserDefaultViews && allUserDefaultViews[currentSector];
    const messageForSentryLog = `The user id ${localUser.id} for sector ${currentSector} have default view with id ${userSectorDefaultView}`;
    log('LOOKAHEAD_VIEW_LOG', messageForSentryLog);
    return userSectorDefaultView;
  };

  const getDefaultViewIndex = async () => {
    const userDefaultView = await getUserDefaultView();
    const defaultViewId = window.listView.current.findIndex((view) => {
      if (findView(view.view, userDefaultView)) return true;
    });
    const messageForSentryLog = `The defaultView index in the listviewArray is ${defaultViewId}`;
    log('LOOKAHEAD_VIEW_LOG', messageForSentryLog);
    return defaultViewId;
  };

  const updateUserView = async (propName, newValue) => {
    if (!propName) return;
    try {
      const defaultViewId = await getDefaultViewIndex();
      window.listView.current[defaultViewId].view[propName] = newValue;
    } catch (e) {
      Sentry.captureMessage(e, 'warning');
    }
    lookaheadVisualizationProps.defaultView[propName] = newValue;
    viewService.update({
      projectId: lookaheadVisualizationProps.visualizationOp.project,
      sectorId: lookaheadVisualizationProps.visualizationOp.sector,
      ganttChart: lookaheadVisualizationProps.visualizationOp.ganttChart,
      orderView: lookaheadVisualizationProps.visualizationOp.order,
      ...lookaheadVisualizationProps.visualizationOp,
      [propName]: newValue
    });
  };
  window.updateUserView = updateUserView;

  useEffect(() => {
    const callbackView = () => {
      showPreviewHandle();
    };

    EventEmitter.on('loadViewPreview', callbackView);
    return () => {
      EventEmitter.removeListener('loadViewPreview', callbackView);
    };
  }, []);

  const showPreviewHandle = () => {
    dispatch(timelineActions.updateParsedValue(false));
    setIsPreviewBtn(!isPreviewBtn);
    setIsShowPreview(!isPreviewBtn);
    filterWeeklyTasks(!isPreviewBtn);
  };

  const changeBarColor = (type) => {
    changeColorSchema(type);
    onCommandDispatched('CHANGE_COLOR', type);
  };

  window.changeBarColor = changeBarColor;

  const handleRightToolbarActionDispatch = (command, value) => {
    const commands = {
      DOWNLOAD_SCHEDULE_TO_PDF: (_) =>
        window.ganttVisualization.customExportToPDF(ganttReport),
      DOWNLOAD_SCHEDULE: (_) => pdfReport(),
      DOWNLOAD_PDF_GANTT: (_) => exportPDFGantt({ isSchedule: false, t }),
      SHOW_PREVIEW: (_) => {
        showPreviewHandle();
        updateUserView('ispreview', !isPreviewBtn);
      },
      CHANGE_COLOR_FOR_SUBCONTRACT: (_) => {
        changeBarColor('subcontract');
        updateUserView('barColor', 'subcontract');
      },
      CHANGE_COLOR_FOR_LEANSTATUS: (_) => {
        changeBarColor('leanstatus');
        updateUserView('barColor', 'leanstatus');
      },
      CHANGE_COLOR_FOR_TAGS: (_) => {
        changeBarColor('tags');
        updateUserView('barColor', 'tags');
      },
      CHANGE_COLOR_FOR_STATUS: (_) => {
        changeBarColor('status');
        updateUserView('barColor', 'status');
      }
    };

    const matchedItem = Object.entries(TOOLBAR_ITEMS_TRACK).find(([key]) =>
      command.startsWith(key)
    )?.[1];

    if (matchedItem) {
      const { eventName, getParams } = matchedItem;
      const params = getParams ? getParams(value ?? command) : {};
      trackMultipleEvents(eventName, params);
    }
    if (commands[command]) {
      return commands[command]();
    }
    onCommandDispatched(command, value);
  };

  return (
    <div
      className={styles['lookahead-toolbar']}
      data-testid={TestIds.LOOKAHEAD_TOOLBAR_CONTAINER}>
      <ToolbarGroup
        items={TOOLBAR_LEFT_GROUP_ITEMS}
        onCommandDispatched={handleLetToolbarActionDispatch}
        t={t}
      />
      <Options
        lookaheadRef={lookaheadRef}
        filterRef={filterRef}
        orderRef={orderRef}
        groupRef={groupRef}
        columnsRef={columnsRef}
        actions={actions}
        lookaheadRange={lookaheadRange}
        lookaheadFilter={lookaheadFilter}
        lookaheadOrder={lookaheadOrder}
        lookaheadGroup={lookaheadGroup}
        lookaheadColumns={lookaheadColumns}
        disableColumnsSelector={disableColumnsSelector}
        t={t}
      />
      <div className={styles['lookahead-right-side']}>
        {renderIndicator}
        {renderForced ? (
          refreshExcelExport ? (
            <ToolbarGroup
              items={TOOLBAR_RIGTH_GROUP_ITEMS}
              onCommandDispatched={handleRightToolbarActionDispatch}
              refreshExcelExport={refreshExcelExport}
              t={t}
            />
          ) : (
            <div></div>
          )
        ) : null}

        <Search
          prefix={prefix}
          placeholder={placeholder}
          onChange={onChange}
          onPressEnter={onPressEnter}
          style={{ marginLeft: 5, height: 32 }}
        />
      </div>
    </div>
  );
};

const Options = (props) => {
  const {
    lookaheadRef,
    filterRef,
    orderRef,
    groupRef,
    columnsRef,
    actions,
    lookaheadRange,
    lookaheadFilter,
    lookaheadOrder,
    lookaheadGroup,
    lookaheadColumns,
    disableColumnsSelector,
    t
  } = props;

  return (
    <>
      {actions.LOOKAHEAD && !isFeatureOn('enable-LWP-103') && (
        <div
          ref={lookaheadRef}
          className={cn(
            styles['lookahead-popover'],
            styles['lookahead-range-btn']
          )}>
          <LookaheadDateRangePicker visible={true} t={t} {...lookaheadRange} />
        </div>
      )}
      {actions.FILTER && (
        <div
          ref={filterRef}
          className={cn(
            styles['lookahead-popover'],
            styles['lookahead-filter-btn']
          )}>
          <LookaheadFilter t={t} {...lookaheadFilter} />
        </div>
      )}
      {actions.ORDER && (
        <div
          ref={orderRef}
          className={cn(
            styles['lookahead-popover'],
            styles['lookahead-order-btn']
          )}>
          <LookaheadOrder t={t} {...lookaheadOrder} />
        </div>
      )}
      {actions.GROUP && (
        <div
          ref={groupRef}
          className={cn(
            styles['lookahead-popover'],
            styles['lookahead-group-btn']
          )}>
          <LookaheadGroup {...lookaheadGroup} />
        </div>
      )}
      {!disableColumnsSelector && actions.COLUMNS && (
        <div
          ref={columnsRef}
          className={cn(
            styles['lookahead-popover'],
            styles['lookahead-columns-btn']
          )}>
          <ReactMultiselectCheckboxes
            {...lookaheadColumns}
            className={cn(
              lookaheadColumns.className,
              styles['filter-lookahead-multi-check']
            )}
            menuIsOpen={actions.COLUMNS}
          />
        </div>
      )}
    </>
  );
};

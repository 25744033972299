import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { log } from '../monitor/monitor';
import { experiment } from '../analytics/implements/amplitude';

export const useLoadFeatureFlags = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    experiment
      .start()
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);

        log(
          'GET_FEATURE_FLAGS_EXPERIMENT',
          `Failed to start experiment: ${err}`
        );

        Sentry.captureException(err);
      });
  }, []);

  return { loading };
};

/**
 * An enumeration that maps role types to their corresponding names.
 * This object is frozen to make it immutable, ensuring that the mappings
 * cannot be accidentally modified elsewhere in the code.
 *
 * @readonly
 * @enum {string}
 */
export const roleTypes = Object.freeze({
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  PROJECTLEADER: 'projectleader',
  PLANNER: 'planner', // Scheduler
  SUPERINTENDENT: 'superintendent',
  LASTPLANNER: 'lastplanner',
  CLIENT: 'client', // Owner
  MANAGER: 'manager', // Read Only
  SUBTRADE: 'subtrade' // Project Partner
});

export const userType = (t, loggedUser) =>
  Object.entries(roleTypes).reduce((acc, [key, value]) => {
    if (loggedUser.role !== 'superadmin' && value === 'superadmin') {
      return acc;
    }
    acc.push({ value, label: t(`positions.${value}`) });
    return acc;
  }, []);

export const USER_ROLES = (t) => [
  {
    value: 'DO_NOT_SYNC',
    label: t('positions.do_not_sync'),
    level: 10
  },
  {
    value: 'superadmin',
    label: t('positions.superadmin'),
    level: 1
  },
  {
    value: 'admin',
    label: t('positions.admin'),
    level: 2
  },
  {
    value: 'projectleader',
    label: t('positions.projectleader'),
    level: 3
  },
  {
    value: 'planner',
    label: t('positions.planner'),
    level: 4
  },
  {
    value: 'superintendent',
    label: t('positions.fieldprofessional'),
    level: 5
  },
  {
    value: 'subtrade',
    label: t('positions.subtrade'),
    level: 6
  },
  {
    value: 'lastplanner',
    label: t('positions.lastplanner'),
    level: 7
  },
  {
    value: 'client',
    label: t('positions.client'),
    level: 8
  },
  {
    value: 'manager',
    label: t('positions.manager'),
    level: 9
  }
];

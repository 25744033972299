import moment from 'moment';
import { addDurationToDate, getNextWorkingHour } from '../../helpers/index';
import { CONSTRAINT_TYPES, LINK_TYPES } from '../../constants/index';

class CalculationsGenericMethods {
  constructor(calculationObject) {
    this.activity = calculationObject.activity;
    this.links = calculationObject.links;
    this.calculatedActivities = calculationObject.calculatedActivitiesMap;
    this.forwardPathCalculations =
      calculationObject.calculatedForwardActivitiesMap;
    this.gantt = calculationObject.ganttInstance;
  }

  /**
   * Retrieves the late start (LS) and late finish (lf) dates for a given activity from previously calculated activities.
   *
   * @param {string|number} activity - The ID of the activity for which to retrieve LS and lf dates.
   * @param {string} [constraint=null] - The constraint type to use for the calculation (e.g., 'alap').
   * @returns {Object|null} The LS and lf dates for the activity, or null if not found.
   * @throws {Error} If an error occurs during the retrieval process.
   */
  getForwardOrBackwardCalculations(activity, constraint = null) {
    if (constraint === CONSTRAINT_TYPES.ALAP) {
      return this.forwardPathCalculations.get(activity);
    }

    return this.calculatedActivities.get(activity);
  }
  /**
   * Calculates the LS (Latest Start) and LF (Latest Finish) times for the successor activity.
   * @param {Date} successorTime - The time of the successor activity.
   * @param {number} lsDataCalculation - The duration for LS calculation.
   * @param {number} [lfDataCalculation=0] - The duration for LF calculation, default is 0.
   * @param {number} lag - The lag time.
   * @param {string} [type='FS'] - The type of link ('FS', 'FF', 'SS', 'SF'), default is 'FS'.
   * @returns {Object} Returns an object containing the LS and LF times for the successor activity.
   * @throws Will throw an error if there's an issue with the calculation.
   */
  calculateSucessorTimes(
    sucessorTime,
    lsDataCalculation,
    lfDataCalculation = 0,
    lag,
    type = LINK_TYPES.FS
  ) {
    try {
      let activityCalendar = this.gantt.getCalendar(this.activity.calendar_id);
      if (!activityCalendar) {
        throw new Error(
          `Error trying to get calendar with id: ${this.activity.calendar_id}`
        );
      }
      const workTime = sucessorTime;
      let isMilestone = this.activity.type === 'milestone';

      if (isMilestone && lag === 0) {
        let lsPred = addDurationToDate(
          activityCalendar,
          workTime,
          lsDataCalculation,
          this.activity
        );

        let lfPred = addDurationToDate(
          activityCalendar,
          lsPred,
          lfDataCalculation,
          this.activity
        );

        return { ls: lsPred, lf: lfPred };
      }
      const valuesToDoCalculation = {
        lag,
        workTime,
        activityCalendar,
        lsDataCalculation,
        lfDataCalculation,
        isMilestone
      };

      if (type === LINK_TYPES.FS) {
        let { ls, lf } = this.calculateFsLink(valuesToDoCalculation);
        return { ls, lf };
      }
      if (type === LINK_TYPES.FF) {
        let { ls, lf } = this.calculateFFLink(valuesToDoCalculation);
        return { ls, lf };
      }

      if (type === LINK_TYPES.SS) {
        let { ls, lf } = this.calculateSSlink(valuesToDoCalculation);
        return { ls, lf };
      }

      if (type === LINK_TYPES.SF) {
        let { ls, lf } = this.calculateSFLink(valuesToDoCalculation);
        return { ls, lf };
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  /**
   * Calculates the LS (Latest Start) and LF (Latest Finish) times for the successor activity in a SS (Start-to-Start) link.
   * @param {Object} link - The link object containing information about the relationship.
   * @param {boolean} [alap=false] - Indicates whether to calculate based on ALAP (As Late As Possible) constraint, default is false.
   * @returns {Object} Returns an object containing the calculated LS and LF times for the successor activity.
   * @throws Will throw an error if there's an issue with the calculation.
   */
  calculateSS(link, alap = false) {
    let lsSuc = this.getValueForSS(link, alap);
    let lsDataForCalculation = -link.lag || 0;
    let lfDataForCalculation = this.activity.duration;
    let lag = link.lag;

    return this.calculateSucessorTimes(
      lsSuc,
      lsDataForCalculation,
      lfDataForCalculation,
      lag,
      LINK_TYPES.SS
    );
  }

  /**
   * Calculates the LS (Latest Start) and LF (Latest Finish) times for the successor activity in a FF (Finish-to-Finish) link.
   * @param {Object} link - The link object containing information about the relationship.
   * @returns {Object} Returns an object containing the calculated LS and LF times for the successor activity.
   * @throws Will throw an error if there's an issue with the calculation.
   */
  calculateFF(link, constraint) {
    const succesorDate = this.getForwardOrBackwardCalculations(
      Number(link.target)
    );
    const propertToAcess = constraint === CONSTRAINT_TYPES.ALAP ? 'ef' : 'lf';
    let sucessorDate = succesorDate[propertToAcess];

    let lsDataForCalculation = -this.activity.duration;
    let lfDataForCalculation = -link.lag || 0;
    let lag = link.lag;

    return this.calculateSucessorTimes(
      sucessorDate,
      lsDataForCalculation,
      lfDataForCalculation,
      lag,
      LINK_TYPES.FF
    );
  }
  /**
   * Calculates the LS (Latest Start) and LF (Latest Finish) times for the successor activity in a FS (Finish-to-Start) link.
   * @param {Object} link - The link object containing information about the relationship.
   * @param {string} constraint - The constraint type.
   * @returns {Object} Returns an object containing the calculated LS and LF times for the successor activity.
   * @throws Will throw an error if there's an issue with the calculation.
   */
  calculateFS(link, constraint) {
    let propertyToCalculate = 'ls';
    propertyToCalculate = constraint === CONSTRAINT_TYPES.ALAP ? 'es' : 'ls';

    let lateFinishPredecessor = this.getForwardOrBackwardCalculations(
      Number(link.target),
      constraint
    );

    let lsDataForCalculation = -this.activity.duration;
    const lag = link.lag || 0;
    let lfDataForCalculation = -link.lag || 0;
    return this.calculateSucessorTimes(
      lateFinishPredecessor[propertyToCalculate],
      lsDataForCalculation,
      lfDataForCalculation,
      lag,
      LINK_TYPES.FS
    );
  }
  /**
   * Calculates the LS (Latest Start) and LF (Latest Finish) times for the successor activity in a SF (Start-to-Finish) link.
   * @param {Object} link - The link object containing information about the relationship.
   * @param {boolean} getFromForward - Whether to calculate LS using the early start from the forward path.
   * @returns {Object} Returns an object containing the calculated LS and LF times for the successor activity.
   * @throws Will throw an error if there's an issue with the calculation.
   */
  calculateSF(link, getFromForward = false) {
    let lateFinishSucessor = getFromForward
      ? this.getEarlyStartFromForwardPath(Number(link.target)).ef
      : this.getForwardOrBackwardCalculations(Number(link.target)).lf;

    let lsDataForCalculation = -link.lag || 0;
    let lfDataForCalculation = this.activity.duration;
    let lag = link.lag;

    return this.calculateSucessorTimes(
      lateFinishSucessor,
      lsDataForCalculation,
      lfDataForCalculation,
      lag,
      LINK_TYPES.SF
    );
  }
  /**
   * Retrieves the early start time from the forward path calculations for the current activity.
   * @returns {number} The early start time for the activity.
   */
  getEarlyStartFromForwardPath(link) {
    return this.forwardPathCalculations.get(link);
  }
  /**
   * Retrieves the value for the specified scheduling state (SS).
   * @param {object} link - The link object representing the dependency.
   * @param {boolean} alap - Flag indicating if the scheduling state is As Late As Possible (ALAP).
   * @returns {number} The value representing the specified scheduling state.
   */
  getValueForSS(link, alap) {
    if (alap) {
      return this.getEarlyStartFromForwardPath(Number(link.target)).es;
    }

    let propertyToCalculate = 'ls';

    let sucessor = this.getForwardOrBackwardCalculations(Number(link.target));
    return sucessor[propertyToCalculate];
  }
  /**
   * Calculates the end date based on the provided activity calendar, starting date, and duration.
   * @param {object} activityCalendar - The calendar object containing activity scheduling information.
   * @param {Date} date - The starting date for the activity.
   * @param {number} duration - The duration of the activity in days.
   * @returns {Date} The end date of the activity.
   */
  calculateDate(activityCalendar, date, duration) {
    let resetedDate = moment(date).clone();

    return addDurationToDate(
      activityCalendar,
      new Date(resetedDate),
      duration,
      this.activity
    );
  }

  /**
   * Retrieves the last working hour based on the provided date and activity calendar.
   * @param {Date} date - The date for which to find the last working hour.
   * @param {object} activityCalendar - The calendar object containing activity scheduling information.
   * @param {string} [dir='past'] - The direction in which to search for the last working hour. Default is 'past'.
   * @throws {Error} Throws an error if the provided date is not valid.
   * @returns {Date} The last working hour relative to the provided date.
   */
  getLastWorkingHour(date, activityCalendar, dir = 'past') {
    let resetedDate = moment(date).clone();
    if (this.isActivityInInitHour(activityCalendar, date) && dir === 'past') {
      resetedDate = resetedDate.hours(0).minutes(0).seconds(0).milliseconds(0);
    }

    let newDate = getNextWorkingHour({
      dateBaseToCalculate: new Date(resetedDate),
      direction: dir,
      activityCalendar: activityCalendar,
      activity: this.activity
    });
    return newDate;
  }
  /**
   * Determines if a given date corresponds to the initial work hour of an activity as defined in the activity calendar.
   * This function retrieves the first work interval from the activity calendar and compares the hour portion
   * of the input date to the start hour of the work interval.
   * @param {object} activityCalendar - The calendar object containing work hours and scheduling information for the activity.
   * @param {Date} date - The date to check against the activity's start hour.
   * @returns {boolean} True if the hour of the input date matches the initial work hour of the activity; otherwise, false.
   */
  isActivityInInitHour(activityCalendar, date) {
    let shifts = activityCalendar.getWorkHours(new Date(date));
    let firstWorkInterval = shifts[0];
    if (!firstWorkInterval) {
      return false;
    }

    if (!firstWorkInterval.includes('-')) return;

    let workStartHour = firstWorkInterval.split('-')[0];

    if (!workStartHour.includes(':')) return;

    let hoursMinutes = workStartHour.split(':');

    if (hoursMinutes.length < 2) return;
    workStartHour = parseInt(hoursMinutes[0], 10);

    return date.getHours() === workStartHour;
  }
  /**
   * Calculates the forward start (FS) and forward finish (FF) dates based on the provided parameters.
   * @param {object} options - An object containing the necessary parameters for the calculation.
   * @param {number} options.lag - The lag duration for the calculation.
   * @param {Date} options.workTime - The starting work time for the calculation.
   * @param {object} options.activityCalendar - The calendar object containing activity scheduling information.
   * @param {number} options.lsDataCalculation - The duration for calculating the forward start date.
   * @param {number} options.lfDataCalculation - The duration for calculating the forward finish date.
   * @param {boolean} [options.isMilestone=false] - Flag indicating if the activity is a milestone. Default is false.
   * @returns {object} An object containing the forward start (FS) and forward finish (FF) dates.
   */
  calculateFsLink({
    lag,
    workTime,
    activityCalendar,
    lsDataCalculation,
    lfDataCalculation,
    isMilestone = false
  }) {
    let lfPred = null;
    let lsPred = null;

    if (lag === 0) {
      const lsSucessor = this.getLastWorkingHour(
        workTime,
        activityCalendar,
        'past'
      );
      lfPred = addDurationToDate(
        activityCalendar,
        lsSucessor,
        lfDataCalculation,
        this.activity
      );
      lfPred = this.getLastWorkingHour(lfPred, activityCalendar, 'past');

      lsPred = addDurationToDate(
        activityCalendar,
        lfPred,
        lsDataCalculation,
        this.activity
      );

      return { ls: lsPred, lf: lfPred };
    }

    let lsSucessor = workTime;

    if (!isMilestone) {
      lsSucessor = this.getLastWorkingHour(workTime, activityCalendar);
    }

    lfPred = addDurationToDate(
      activityCalendar,
      lsSucessor,
      lfDataCalculation,
      this.activity
    );

    lsPred = addDurationToDate(
      activityCalendar,
      lfPred,
      lsDataCalculation,
      this.activity
    );

    if (!isMilestone) {
      lfPred = this.getLastWorkingHour(lfPred, activityCalendar, 'past', true);
    }

    return { ls: lsPred, lf: lfPred };
  }
  /**
   * Calculates the forward finish (FF) dates based on the provided parameters.
   * @param {object} options - An object containing the necessary parameters for the calculation.
   * @param {number} options.lag - The lag duration for the calculation.
   * @param {Date} options.workTime - The starting work time for the calculation.
   * @param {object} options.activityCalendar - The calendar object containing activity scheduling information.
   * @param {number} options.lsDataCalculation - The duration for calculating the forward start date.
   * @param {number} options.lfDataCalculation - The duration for calculating the forward finish date.
   * @returns {object} An object containing the forward start (FS) and forward finish (FF) dates.
   */
  calculateFFLink({
    lag,
    workTime,
    activityCalendar,
    lsDataCalculation,
    lfDataCalculation
  }) {
    let lfPred = null;
    let lsPred = null;

    if (lag === 0) {
      lfPred = addDurationToDate(
        activityCalendar,
        workTime,
        lfDataCalculation,
        this.activity
      );
      lsPred = addDurationToDate(
        activityCalendar,
        lfPred,
        lsDataCalculation,
        this.activity
      );
      return { ls: lsPred, lf: lfPred };
    }

    let lfSucessor = workTime;

    if (lag > 0) {
      lfSucessor = this.getLastWorkingHour(
        lfSucessor,
        activityCalendar,
        'past'
      );
    }
    lfPred = addDurationToDate(
      activityCalendar,
      lfSucessor,
      lfDataCalculation,
      this.activity
    );
    lsPred = addDurationToDate(
      activityCalendar,
      lfPred,
      lsDataCalculation,
      this.activity
    );
    return { ls: lsPred, lf: lfPred };
  }

  /**
   * Calculates the start and finish times for an activity based on the specified lag and work time.
   * If the lag is zero, the function directly calculates the times using the provided work time.
   * If the lag is negative and the activity is not a milestone, it adjusts the work time based on future working hours.
   * @param {object} options - Configuration object containing parameters needed for the calculation.
   * @param {number} options.lay - The lag time affecting the start and finish calculations.
   * @param {Date} options.workTime - The reference time from which calculations begin.
   * @param {object} options.activityCalendar - The calendar used to check work hours and holidays.
   * @param {number} options.lsDataCalculation - Duration to calculate the start time from the reference point.
   * @param {number} options.lfDataCalculation - Duration to calculate the finish time from the calculated start time.
   * @param {boolean} [options.isMilestone=false] - Indicates whether the current activity is a milestone.
   * @returns {object} An object with properties `ls` (start time) and `lf` (finish time).
   */
  calculateSSlink({
    lag,
    workTime,
    activityCalendar,
    lsDataCalculation,
    lfDataCalculation,
    isMilestone = false
  }) {
    let lfPred = null;
    let lsPred = null;

    if (lag === 0) {
      lsPred = addDurationToDate(
        activityCalendar,
        workTime,
        lsDataCalculation,
        this.activity
      );

      lfPred = addDurationToDate(
        activityCalendar,
        lsPred,
        lfDataCalculation,
        this.activity
      );
      return { ls: lsPred, lf: lfPred };
    }
    let lsSucessor = workTime;
    if (lag < 0 && !isMilestone) {
      lsSucessor = this.getLastWorkingHour(
        lsSucessor,
        activityCalendar,
        'future'
      );
    }

    lsPred = addDurationToDate(
      activityCalendar,
      lsSucessor,
      lsDataCalculation,
      this.activity
    );
    lfPred = addDurationToDate(
      activityCalendar,
      lsPred,
      lfDataCalculation,
      this.activity
    );
    return { ls: lsPred, lf: lfPred };
  }
  /**
   * Calculates the start (SF) and finish (LF) times for an activity based on the specified lag and work time.
   * This function handles both cases where lag is zero and when it's not, calculating times based on the given work time.
   * @param {object} options - Configuration object containing the necessary parameters for the calculation.
   * @param {number} options.lag - The lag time that influences the scheduling.
   * @param {Date} options.workBlock - The reference time from which scheduling starts.
   * @param {object} options.activityCalendar - The calendar object containing activity scheduling information.
   * @param {number} options.lsDataCalculation - The duration to calculate the start time.
   * @param {number} options.lfDataCalculation - The duration to calculate the finish time.
   * @returns {object} An object containing the start (ls) and finish (lf) times of the activity.
   */
  calculateSFLink({
    lag,
    workTime,
    activityCalendar,
    lsDataCalculation,
    lfDataCalculation
  }) {
    if (lag === 0) {
      lsPred = addDurationToDate(activityCalendar, workTime, lsDataCalculation);

      lfPred = addDurationToDate(
        activityCalendar,
        lsPred,
        lfDataCalculation,
        this.activity
      );
      return { ls: lsPred, lf: lfPred };
    }
    let lfPred = null;
    let lsPred = null;
    let lfSucessor = workTime;
    lsPred = addDurationToDate(
      activityCalendar,
      lfSucessor,
      lsDataCalculation,
      this.activity
    );
    lfPred = addDurationToDate(
      activityCalendar,
      lsPred,
      lfDataCalculation,
      this.activity
    );
    return { ls: lsPred, lf: lfPred };
  }
}

export default CalculationsGenericMethods;

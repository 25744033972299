/* eslint-disable quote-props */
import * as Sentry from '@sentry/react';
import { createHttpClient } from '../utils/httpUtils';

class ProcoreService {
  constructor() {
    this.http = createHttpClient();
  }

  async getFolderFromProject(token, folder, projectId) {
    const data = {
      token: token,
      folder: folder,
      projectId: projectId
    };
    const res = await this.http
      .post('plugins/procore/folders', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getDocument(token, documentId, projectId) {
    const data = {
      token: token,
      projectId: projectId
    };
    const res = await this.http
      .get(
        `plugins/procore/documents/${documentId}?token=${token}&project_id=${projectId}`
      )
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getCompanies() {
    const res = await this.http
      .get('plugins/procore/companies')
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getCompanyProjects(companiesIds) {
    try {
      const response = await this.http.post(
        'plugins/procore/companies/projects',
        { companiesIds }
      );
      return response.data;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('CompanyProjects', {
          message: error.message,
          companiesIds
        });
        Sentry.captureException(error);
      });
      console.error('An error occurred while saving Procore users:', error);
    }
  }

  async create(data) {
    const res = await this.http.post('locations', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async saveDocuments(data) {
    const res = await this.http
      .post('plugins/procore/documents/', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async check() {
    const res = await this.http.get('plugins/procore/check').catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async getProcoreProjects() {
    const res = await this.http
      .post('procoreprojects/getprojects')
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getAgaveLinkToken() {
    const res = await this.http
      .get('plugins/procore/getAgaveLinkToken')
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getAgaveLinkTokenSSO() {
    const res = await this.http
      .get('plugins/procore/getAgaveLinkTokenSSO')
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async putAgavePublicToken(publicToken) {
    const data = { agavePublicToken: publicToken };
    const res = await this.http
      .put('plugins/procore/putAgavePublicToken', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getAccountToken(public_token) {
    const res = await this.http
      .post('plugins/procore/getAgaveAccountToken', {
        public_token: public_token
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getAgaveLinkAccount(accountToken) {
    const res = await this.http
      .post('plugins/procore/getAgaveLinkAccount', { accountToken })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  /**
   * Orchestrate the create, update, and disconnect Procore projects operations.
   * @async
   * @param {Object} data - An object containing arrays of projects to create, update, and disconnect.
   * @returns {Promise} Resolves with the response data from the server or false if an error occurs.
   * @throws {Error} If there is no response from the server or an error occurs during the request.
   */
  async orchestrateProcoreProjects(data) {
    try {
      const response = await this.http.post(
        'procoreprojects/orchestrate',
        data
      );
      return {
        success: true,
        type: response.data
      };
    } catch (error) {
      console.error('Error orchestrating Procore projects:', error);
      return {
        success: false,
        type: 'orchestrate'
      };
    }
  }

  /**
   * Check if the user is a Procore admin.
   * @async
   * @returns {Promise} Resolves with the response data from the server or false if an error occurs.
   * @throws {Error} If there is no response from the server or an error occurs during the request.
   */
  async checkProcoreAdmin(accountToken) {
    try {
      const response = await this.http.post(
        'procoreprojects/checkProcoreAdmin',
        { agaveToken: accountToken }
      );
      return response.data;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('checkProcoreAdmin', {
          message: error.message,
          agaveToken: accountToken
        });
        Sentry.captureException(error);
      });

      return false;
    }
  }

  /**
   * Fetch submittals associated with the provided company, sector, project, and activity IDs.
   *
   * @async
   * @param {number} companyId - The unique ID of the company.
   * @param {number} sectorId - The unique ID of the sector.
   * @param {number} projectId - The unique ID of the project.
   * @param {number} activityId - The unique ID of the activity.
   * @throws Will throw an error if the HTTP request fails.
   * @return {Promise<Object>} The response data from the HTTP request.
   */
  async getSubmittals(companyId, sectorId, projectId) {
    const data = {
      companyId,
      sectorId,
      projectId
    };

    try {
      const { data: responseData } = await this.http.post(
        'procoreprojects/getSubmittals',
        data
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('getSubmittals', {
          message: error.message,
          ...data
        });
        Sentry.captureException(error);
      });
      throw error;
    }
  }

  /**
   * Asynchronously updates submittals by posting to a specified API endpoint.
   * Uses Sentry for exception tracking.
   *
   * @param {Array} submittals - An array of submittals to update.
   * @throws Will throw an error if the API request fails.
   * @return {Object} - Returns the API response data.
   */
  async updateSubmittals(submittals) {
    try {
      const { data: responseData } = await this.http.post(
        'procoreprojects/updateSubmittals',
        submittals
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('updateSubmittals', {
          message: error.message,
          submittals
        });
        Sentry.captureException(error);
      });
      console.error('An error occurred while updating submittals:', error);
      throw error;
    }
  }

  /**
   * Retrieves users associated with a given Procore project.
   *
   * This function makes an HTTP POST request to retrieve the users related to a specified Procore project.
   * In case of an error, the error is logged to Sentry and rethrown.
   *
   * @param {number} procoreProjectId - The ID of the Procore project for which to fetch users.
   * @returns {Promise<Object>} A promise that resolves with the response data containing the Procore users.
   * @throws {Error} Throws an error if the HTTP request fails or if there's any other error.
   */
  async getProcoreUsersByProject(procoreProjectId) {
    try {
      const { data: responseData } = await this.http.post(
        'procoreprojects/procoreProjectUsers',
        { procoreProjectId }
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('getProcoreUsersByProject', {
          message: error.message,
          procoreProjectId
        });
        Sentry.captureException(error);
      });
      console.error('An error occurred while getting Procore users:', error);
      throw error;
    }
  }

  /**
   * Saves user data to a specified Procore project.
   *
   * This function makes an HTTP POST request to save a list of users to a Procore project.
   * In the event of an error, the error details are captured by Sentry and then rethrown.
   *
   * @param {Object} invitationData - An object with the project and company names to which users will be saved.
   * @param {Array<Object>} users - An array of user objects to be saved.
   * @param {number} procoreProjectId - The ID of the Procore project where users will be saved.
   * @returns {Promise<Object>} A promise that resolves with the response data after saving the users.
   * @throws {Error} Throws an error if the HTTP request fails or if there's any other error.
   */
  async saveProcoreUsers(invitationData, users, procoreProjectId) {
    try {
      const { companyName, projectName } = invitationData;
      const { data: responseData } = await this.http.post(
        'procoreprojects/initializeProjectUsers',
        {
          companyName,
          projectName,
          users,
          procoreProjectId
        }
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('saveProcoreUsers', {
          message: error.message,
          users: users.map((user) => user.email)
        });
        Sentry.captureException(error);
      });
      console.error('An error occurred while saving Procore users:', error);
      throw error;
    }
  }

  /**
   * Retrieves the Procore base URL.
   *
   * This function makes an HTTP GET request to retrieve the Procore base URL.
   * In the event of an error, the error details are captured by Sentry and then rethrown.
   *
   * @param {number} projectId - The ID of the project.
   * @param {number} sectorId - The ID of the sector.
   * @returns {Promise<string>} A promise that resolves with the Procore base URL.
   * @throws {Error} Throws an error if the HTTP request fails or if there's any other error.
   */
  async getProcoreBaseUrl(projectId, sectorId) {
    try {
      const { data: responseData } = await this.http.post(
        'plugins/procore/getProcoreBaseUrl',
        {
          projectId,
          sectorId
        }
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('getCompanyZone', { message: error.message });
        Sentry.captureException(error);
      });
      throw error;
    }
  }

  /**
   * Retrieves the Agave link token for a specified company.
   *
   * This function makes an HTTP GET request to retrieve the Agave link token for a specified company.
   * In the event of an error, the error details are captured by Sentry and then rethrown.
   *
   * @returns {Promise<Object>} A promise that resolves with the response data containing the Agave link token.
   * @throws {Error} Throws an error if the HTTP request fails or if there's any other error.
   */
  async getUserInfo() {
    try {
      const { data: responseData } = await this.http.post(
        'procoreprojects/getUserInfo'
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('viewInfoModal', { message: error.message });
        Sentry.captureException(error);
      });
      console.error('An error occurred while getting user info:', error);
      throw error;
    }
  }

  async getCompaniesFromProcore(procoreProjectId) {
    try {
      const { data: responseData } = await this.http.get(
        `procoreprojects/companiesFromProcore/${procoreProjectId}`
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('getCompaniesFromProcore', {
          message: error.message,
          procoreProjectId
        });
        Sentry.captureException(error);
      });
      throw error;
    }
  }

  async createCompaniesFromProcore(outbuildProjectId, companies) {
    try {
      const { data: responseData } = await this.http.post(
        `procoreprojects/createCompaniesFromProcore/${outbuildProjectId}`,
        { companies }
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('createCompaniesFromProcore', {
          message: error.message,
          outbuildProjectId,
          companies
        });
        Sentry.captureException(error);
      });
      throw error;
    }
  }

  async updateAutoImportStatus(payload) {
    try {
      const { data: responseData } = await this.http.put(
        'procoreprojects/updateAutoImportStatus',
        payload
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('updateAutoImportStatus', {
          message: error.message,
          payload
        });
        Sentry.captureException(error);
      });
      throw error;
    }
  }

  async organizationImportStatus(resources) {
    try {
      const { data: responseData } = await this.http.post(
        'procoreprojects/organizationImportStatus',
        { resources }
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('organizationImportStatus', {
          message: error.message,
          resources
        });
        Sentry.captureException(error);
      });
      throw error;
    }
  }

  async getRoleMapping() {
    try {
      const { data: responseData } = await this.http.get(
        'procoreprojects/getRoleMapping'
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('getRoleMapping', {
          message: error.message
        });
        Sentry.captureException(error);
      });
      throw error;
    }
  }

  async updateRoleMapping(procoreCompanyId, resources) {
    try {
      const { data: responseData } = await this.http.post(
        `procoreprojects/updateRoleMapping/${procoreCompanyId}`,
        { resources }
      );
      return responseData;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('updateRoleMapping', {
          message: error.message,
          procoreCompanyId,
          resources
        });
        Sentry.captureException(error);
      });
      throw error;
    }
  }
}

export const procoreService = new ProcoreService();

import { isGrouped } from '../../GanttVisualization.helper';
import { hasConstraintHtml } from '../../../../utils/lookahead-common';

export const TemplateRoadblocks = (task) => {
  const SHOW_INDICATOR_CONSTRAINT = true;
  const gantt = window?.ganttVisualization;

  const renderRoadblocks = (roadblock = '') => {
    const { oddColsConfig } = gantt;
    let isOdd = false;
    if (oddColsConfig) {
      isOdd = oddColsConfig.name;
    }
    return `<div
                class="${isOdd ? 'odd-col' : 'non-odd-col'}" 
                style="overflow: visible">
                ${roadblock} 
            </div>`;
  };

  const ROADBLOCKS_DEFAULT = renderRoadblocks();

  const { isTask } = task;

  if (!gantt || !isTask) return ROADBLOCKS_DEFAULT;

  const renderRoadblock = (constraint = null) => {
    return `<div>
            <span class="task-actions"
                style="display: inline;visibility: visible">
                ${isGrouped() ? null : ` <span>${constraint} </span>`}
            </span>
        </div>`;
  };

  const renderActivityTask = () => {
    const { id, constraints } = task;

    const hasConstraintJsx = hasConstraintHtml(
      task,
      () => gantt.addConstraint(id),
      true,
      gantt.t,
      SHOW_INDICATOR_CONSTRAINT,
      task?.id
    );

    const ponderatorConstraintHTML = `<span class="roadblock-span">
                  ${constraints?.length ? hasConstraintJsx : '-'}
              </span>`;

    const taskName = renderRoadblock(ponderatorConstraintHTML);
    return `<div  class="columnExportPDFHide">
                  <div>
                      <span style="position: relative;width: 100%;text-align: center;display: block">
                          ${taskName}
                      </span>
                  </div>
              </div>`;
  };

  return renderRoadblocks(renderActivityTask());
};

/* eslint-disable no-magic-numbers */
import { useState, useCallback, useEffect, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';
import { procoreService } from '../../../../../../services/procore.service';
import { getBasicAmplitudEventProperties } from '../../../../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../../../../analytics/constants';
import { trackingEvent } from '../../../../../../analytics';

export const useProcoreCompaniesImport = () => {
  const [showProcoreCompaniesImportModal, setShowProcoreCompaniesImportModal] =
    useState(false);
  const [procoreProjectSelected, setProcoreProjectSelected] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceCopy, setDataSourceCopy] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [isNoMatchingRecords, setIsNoMatchingRecords] = useState(true);
  const [openPickerId, setOpenPickerId] = useState(null);
  const [colorSelected, setColorSelected] = useState({});
  const pickerRef = useRef(null);

  const getCompaniesQuery = useQuery({
    queryKey: ['procoreCompanies', procoreProjectSelected?.procoreProjectId],
    queryFn: () =>
      procoreService.getCompaniesFromProcore(
        procoreProjectSelected?.procoreProjectId
      ),
    enabled: Boolean(procoreProjectSelected?.procoreProjectId),
    refetchOnWindowFocus: false
  });

  const createCompaniesMutation = useMutation({
    mutationFn: (companies) =>
      procoreService.createCompaniesFromProcore(
        procoreProjectSelected?.procoreProjectId,
        companies
      ),
    retry: 3
  });

  const handleConfirmImport = useCallback(() => {
    if (
      procoreProjectSelected?.procoreProjectId &&
      selectedCompanies.length > 0
    ) {
      const companiesToImport = dataSource
        .filter((company) =>
          selectedCompanies.includes(company.associated_procore_id)
        )
        .map((company) => ({
          ...company,
          color: colorSelected[company.associated_procore_id] || company.color
        }));

      createCompaniesMutation.mutate(companiesToImport);
    }
  }, [
    procoreProjectSelected,
    selectedCompanies,
    createCompaniesMutation,
    dataSource,
    colorSelected
  ]);

  useEffect(() => {
    if (getCompaniesQuery.data) {
      setDataSource(getCompaniesQuery.data);
      setDataSourceCopy(getCompaniesQuery.data);
    }
  }, [getCompaniesQuery.data]);

  const handleChangeSearch = useCallback(
    (event) => {
      const value = event.target.value;

      const filterDataSource = dataSourceCopy.filter(
        (data) =>
          (data.name &&
            data.name.toLowerCase().includes(value.toLowerCase())) ||
          (data.email && data.email.toLowerCase().includes(value.toLowerCase()))
      );

      setSearchInput(value);
      setDataSource(filterDataSource);
      setIsNoMatchingRecords(filterDataSource.length === 0);
    },
    [dataSourceCopy]
  );

  const handleOnSelectItem = useCallback((record) => {
    setSelectedCompanies((prevSelected) => {
      if (prevSelected.includes(record.associated_procore_id)) {
        return prevSelected.filter((id) => id !== record.associated_procore_id);
      }
      return [...prevSelected, record.associated_procore_id];
    });
  }, []);

  const handleManageMultipleCompanyAssignments = useCallback(
    (selected, items) => {
      const procoreIds = items.map((item) => item.associated_procore_id);
      setSelectedCompanies((prev) => {
        if (selected) {
          return [...new Set([...prev, ...procoreIds])];
        }
        return prev.filter((id) => !procoreIds.includes(id));
      });
    },
    []
  );

  const handleTrackingEvent = (result) => {
    trackingEvent(
      'project_companies_import_process',
      {
        ...getBasicAmplitudEventProperties(),
        project_id: procoreProjectSelected?.outbuildProjectId,
        imported_companies_id: selectedCompanies,
        project_companies_import_process_result: result
      },
      AMPLITUDE_SERVICE
    );
  };

  const handleOpenModalImportCompanies = useCallback((procoreProject) => {
    setShowProcoreCompaniesImportModal(true);
    setProcoreProjectSelected(procoreProject);
  }, []);

  const handleCloseModalImportCompanies = useCallback((external = false) => {
    if (external) {
      handleTrackingEvent('canceled');
    }
    setProcoreProjectSelected(null);
    setShowProcoreCompaniesImportModal(false);
    setSearchInput('');
    setIsNoMatchingRecords(true);
    setSelectedCompanies([]);
    setDataSource([]);
    setDataSourceCopy([]);
    setColorSelected({});
  }, []);

  const handlePickerToggle = useCallback((associatedProcoreId) => {
    setOpenPickerId((prevId) =>
      prevId === associatedProcoreId ? null : associatedProcoreId
    );
  }, []);

  const handleColorChange = useCallback((associatedProcoreId, color) => {
    setColorSelected((prevColors) => ({
      ...prevColors,
      [associatedProcoreId]: color.hex
    }));
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setOpenPickerId(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return {
    dataSource,
    dataSourceCopy,
    isLoading: getCompaniesQuery.isLoading,
    isError: getCompaniesQuery.isError,
    isSuccess: createCompaniesMutation.isSuccess,
    refetch: getCompaniesQuery.refetch,
    isFetching: getCompaniesQuery.isFetching,
    isCreating: createCompaniesMutation.isLoading,
    createError: createCompaniesMutation.error,
    handleOpenModalImportCompanies,
    handleCloseModalImportCompanies,
    procoreProjectSelected,
    showProcoreCompaniesImportModal,
    selectedCompanies,
    handleOnSelectItem,
    handleManageMultipleCompanyAssignments,
    handleChangeSearch,
    searchInput,
    isNoMatchingRecords,
    handleTrackingEvent,
    handleConfirmImport,
    isDisabledConfirmImport:
      selectedCompanies.length === 0 ||
      getCompaniesQuery.isLoading ||
      createCompaniesMutation.isLoading,
    colorSelected,
    openPickerId,
    handlePickerToggle,
    handleColorChange,
    pickerRef
  };
};

import { useState, useEffect, useCallback } from 'react';
import { procoreService } from '../services/procore.service';
import { log } from '../monitor/monitor';
import * as Sentry from '@sentry/react';
import { getSectorCompanyAndProject } from '../analytics/utils';
import { trackingEvent } from '../analytics';
import { AMPLITUDE_SERVICE } from '../analytics/constants';
import { getBasicAmplitudEventProperties } from '../analytics/utils';
import { getSignedUser } from '../utils/userUtils';
import {
  PROCORE_AUTO_IMPORT_ACTIONS,
  PROCORE_AUTO_IMPORT_LEVEL,
  RESOURCES_TYPES_TO_ASSOCIATED_FIELDS
} from '../constants/procore.constants';

export const useProcoreData = (openNotification, t) => {
  const [haveProcore, setHaveProcore] = useState(null);
  const [publicToken, setPublicToken] = useState(false);
  const [endModalAgaveProcess, setEndModalAgaveProcess] = useState(false);
  const [showModalAddProject, setShowModalAddProject] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  const [showModalPermissions, setShowModalPermissions] = useState(false);
  const [showModalViewInfo, setShowModalViewInfo] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [showDirectoryModal, setShowDirectoryModal] = useState(false);
  const [showProcoreUserImportModal, setShowProcoreUserImportModal] =
    useState(false);
  const [selectedProcoreProjectId, setSelectedProcoreProjectId] =
    useState(null);
  const { currentSector, currentProject, currentCompany } =
    getSectorCompanyAndProject();
  const loggedUser = getSignedUser();
  const [procoreProjectConnected, setProcoreProjectConnected] = useState([]);
  const [procoreCompanyProjects, setProcoreCompanyProjects] = useState([]);
  const [externalProcoreProjects, setExternalProcoreProjects] = useState([]);

  const envConfigs = {
    sandbox: {
      urlIncludes: ['localhost', 'qa', 'dev'],
      agaveConfig: {
        sourceSystemEnvironment: 'sandbox',
        showProductionSourceSystems: false,
        showSandboxSourceSystems: true
      }
    },
    prod: {
      urlIncludes: [],
      agaveConfig: {
        sourceSystemEnvironment: 'prod',
        showProductionSourceSystems: true,
        showSandboxSourceSystems: false
      }
    }
  };

  const getAgaveConfig = useCallback(() => {
    const { agaveConfig } =
      Object.values(envConfigs).find(({ urlIncludes }) =>
        urlIncludes.some((urlPart) => window.location.href.includes(urlPart))
      ) || envConfigs.prod;

    return agaveConfig;
  }, []);

  const handleSuccess = useCallback(async (publicToken) => {
    const currentCompany = JSON.parse(sessionStorage.getItem('company'));

    const eventData = {
      event_source: 'Integration',
      company_name: currentCompany?.name,
      company_id: currentCompany?.id,
      user_who_tried_to_connect: loggedUser?.email,
      user_procore_email: userEmail
    };

    try {
      const {
        account_token: accountToken,
        linked_user: { email }
      } = await procoreService.getAccountToken(publicToken);
      const { isProcoreAdmin } =
        await procoreService.checkProcoreAdmin(accountToken);

      if (!isProcoreAdmin) {
        setShowModalPermissions(true);
        setUserEmail(email);
        trackingEvent(
          'Procore-Outbuild connection attempt',
          { ...eventData, result: 'Fail' },
          AMPLITUDE_SERVICE
        );
        return;
      }

      trackingEvent(
        'Procore-Outbuild connection attempt',
        { ...eventData, result: 'Success' },
        AMPLITUDE_SERVICE
      );

      setPublicToken(accountToken);
    } catch (error) {
      trackingEvent(
        'Procore-Outbuild connection attempt',
        { ...eventData, result: 'Error', error: error },
        AMPLITUDE_SERVICE
      );
      throw error;
    }
  }, []);

  const handleExit = useCallback(
    (error) => {
      setEndModalAgaveProcess(true);
      if (error) {
        openNotification(
          {
            title: t('procore.active_schedule_push_title_FAIL'),
            description: t('procore.active_schedule_push_description_FAIL'),
            type: 'warning',
            key: 'getAgaveLinkTokenResponseError'
          },
          5
        );
        setPublicToken(false);
      }
    },
    [t, openNotification]
  );

  const getAgaveLinkToken = useCallback(async () => {
    const getAgaveLinkTokenResponse = await procoreService.getAgaveLinkToken();

    if (getAgaveLinkTokenResponse?.link_token && window?.AgaveLink) {
      window.AgaveLink.openLink({
        linkToken: getAgaveLinkTokenResponse.link_token,
        sourceSystem: 'procore',
        ...getAgaveConfig(),
        sourceSystemOptions: {
          procore: { require_cross_company_access: true }
        },
        onSuccess: (publicToken) => {
          handleSuccess(publicToken);
        },
        onExit: handleExit
      });
    } else {
      handleExit(null);
    }
  }, [getAgaveConfig, handleSuccess, handleExit]);

  const addProjectModal = useCallback(() => {
    if (!companiesLoaded) {
      getProcoreCompanies();
    } else {
      setShowModalAddProject(true);
    }
  }, [companiesLoaded]);

  const importDirectoryModal = useCallback(async () => {
    if (!companiesLoaded) {
      getProcoreCompany();
      setShowDirectoryModal(true);
    } else {
      setShowDirectoryModal(true);
    }
    trackingEvent(
      'procore_directory_selection_made',
      {
        ...getBasicAmplitudEventProperties(),
        userId: loggedUser
      },
      AMPLITUDE_SERVICE
    );
  }, [companiesLoaded]);

  const openImportUsersModal = (procoreProject) => {
    setSelectedProcoreProjectId(procoreProject.procoreProjectId);
    trackingEvent(
      'procore_user_import_initiated',
      {
        ...getBasicAmplitudEventProperties(),
        destinationProjectID: procoreProject.projectId,
        userId: loggedUser
      },
      AMPLITUDE_SERVICE
    );
    localStorage.setItem(
      'currentProcoreProject',
      JSON.stringify(procoreProject)
    );
    setShowProcoreUserImportModal(true);
  };

  const handleImportUsers = async (selectedUsers) => {
    const procoreProject = JSON.parse(
      localStorage.getItem('currentProcoreProject')
    );

    try {
      const responseData = await procoreService.saveProcoreUsers(
        {
          companyName: currentCompany?.name,
          projectName: procoreProject?.outbuildProjectName
        },
        selectedUsers,
        selectedProcoreProjectId
      );

      trackingEvent(
        'procore_user_import_successful',
        {
          ...getBasicAmplitudEventProperties(),
          destinationProjectID: procoreProject.projectId,
          importedUserCount: responseData?.invitedUsers?.length,
          existingUserCount: responseData?.existingUsers?.length
        },
        AMPLITUDE_SERVICE
      );

      openNotification(
        {
          title: 'Procore users saved',
          description: `${responseData?.invitedUsers?.length} users imported, ${responseData?.existingUsers?.length} users already existed`,
          type: 'success'
        },
        5
      );
    } catch (error) {
      openNotification(
        {
          title: 'Error saving Procore users',
          description: error.message,
          type: 'error'
        },
        5
      );
      trackingEvent(
        'procore_user_import_failed',
        {
          ...getBasicAmplitudEventProperties(),
          destinationProjectID: procoreProject.projectId,
          errorDetails: error
        },
        AMPLITUDE_SERVICE
      );
      Sentry.captureException(error);
      log('Error saving Procore users', error, {
        company: currentCompany.id,
        project: currentProject.id,
        sector: currentSector.id,
        users: selectedUsers,
        procoreProjectId: selectedProcoreProjectId,
        error
      });
    }
  };

  const updateProcoreProjects = async () => {
    if (companies && companies.length > 0) {
      await getAndSetProcoreProjects(companies);
    }
  };

  const getAndSetProcoreProjects = async (procoreCompanies) => {
    try {
      const procoreCompanyIds = procoreCompanies.map(
        (company) => company.procoreCompanyId
      );
      const companyProjectsArrays =
        await procoreService.getCompanyProjects(procoreCompanyIds);

      if (!Array.isArray(companyProjectsArrays)) {
        throw new Error(
          'Expected companyProjectsArrays to be an array, but got undefined.'
        );
      }
      const companyProjects = companyProjectsArrays.flat();
      setProcoreCompanyProjects(companyProjects);

      const externalProjects = await procoreService.getProcoreProjects();
      setExternalProcoreProjects(externalProjects);
    } catch (error) {
      console.error(`Error loading Procore projects: ${error}`);
      Sentry.captureException(error);
      setProcoreCompanyProjects([]);
      setExternalProcoreProjects([]);
    }
  };

  const getProcoreCompany = async () => {
    const procoreCompanies = await procoreService.getCompanies();
    if (procoreCompanies.length > 0) {
      setCompanies(procoreCompanies);
      await getAndSetProcoreProjects(procoreCompanies);
      setCompaniesLoaded(true);
      return procoreCompanies;
    }
    return [];
  };

  useEffect(() => {
    procoreService.check().then(async (response) => {
      setHaveProcore(response.procore);
      await getProcoreCompany();
    });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.agaveapi.com/init.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const putAgavePublicTokenResponse = async (publicToken) => {
      const agavePublicTokenResponse =
        await procoreService.putAgavePublicToken(publicToken);
      if (agavePublicTokenResponse) {
        openNotification(
          {
            title: t('procore.active_schedule_push_title_OK'),
            description: t('procore.active_schedule_push_description_OK'),
            type: 'success',
            key: 'getAgaveLinkTokenResponseSuccessfull'
          },
          5
        );
        await getProcoreCompanies();
        setHaveProcore(true);
      } else {
        openNotification(
          {
            title: t('procore.active_schedule_push_title_FAIL'),
            description: t('procore.active_schedule_push_description_FAIL'),
            type: 'warning',
            key: 'getAgaveLinkTokenResponseError'
          },
          5
        );
      }
    };

    if (publicToken) {
      putAgavePublicTokenResponse(publicToken);
    }
  }, [publicToken]);

  useEffect(() => {
    const getStylesFromBody = async () => {
      window.document.body.style.overflow = 'hidden';
      window.document.body.style.height = '100vh';
    };
    getStylesFromBody();
  }, [endModalAgaveProcess]);

  const getProcoreCompanies = async () => {
    const procoreCompanies = await procoreService.getCompanies();
    setCompanies(procoreCompanies);
    await getAndSetProcoreProjects(procoreCompanies);
    setShowModalAddProject(true);
    setCompaniesLoaded(true);
  };

  const updateProcoreProjectImportStatus = async (
    procoreProjectId,
    action,
    resourceType
  ) => {
    try {
      const payload = {
        resources: [
          {
            action: action,
            resourceType: resourceType
          }
        ],
        level: PROCORE_AUTO_IMPORT_LEVEL.PROJECT,
        procoreProjectId: procoreProjectId
      };

      await procoreService.updateAutoImportStatus(payload);
      const fieldToUpdate = RESOURCES_TYPES_TO_ASSOCIATED_FIELDS[resourceType];

      setProcoreProjectConnected((prevProjects) =>
        prevProjects.map((project) =>
          project.procoreProjectId === procoreProjectId
            ? {
                ...project,
                [fieldToUpdate]: action === PROCORE_AUTO_IMPORT_ACTIONS.ACTIVATE
              }
            : project
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  return {
    haveProcore,
    publicToken,
    endModalAgaveProcess,
    showModalAddProject,
    companies,
    companiesLoaded,
    setPublicToken,
    setEndModalAgaveProcess,
    setShowModalAddProject,
    setCompanies,
    setCompaniesLoaded,
    getAgaveConfig,
    handleSuccess,
    handleExit,
    getAgaveLinkToken,
    addProjectModal,
    getProcoreCompanies,
    showModalPermissions,
    setShowModalPermissions,
    userEmail,
    importDirectoryModal,
    showDirectoryModal,
    setShowDirectoryModal,
    openImportUsersModal,
    handleImportUsers,
    showProcoreUserImportModal,
    setShowProcoreUserImportModal,
    selectedProcoreProjectId,
    procoreProjectConnected,
    setProcoreProjectConnected,
    procoreCompanyProjects,
    externalProcoreProjects,
    updateProcoreProjects,
    showModalViewInfo,
    setShowModalViewInfo,
    updateProcoreProjectImportStatus
  };
};

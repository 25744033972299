import React from 'react';
import { Dropdown, Menu } from 'antd';
import { ArrowDownIcon } from '../../../icons';

const { SubMenu } = Menu;

const DropdownSystem = ({
  theme = 'light',
  items,
  version,
  active = false,
  label,
  labelIcon,
  height,
  withRadioButtons = false,
  icon = false,
  isError = false,
  WithIcon = true,
  ...props
}) => {
  const subMenuItem = (item) => (
    <SubMenu
      key={item.key}
      title={item.label}
      popupClassName={`dropdown-design-system__submenu theme--${theme} ${version || ''}`}
      disabled={item.disabled}>
      {item.subitems.map((subitem) => (
        <Menu.Item
          key={subitem.key}
          className={`${subitem.active ? 'active' : ''} ${subitem.isDivider ? 'divider' : ''}`}
          disabled={subitem.disabled}
          onClick={() =>
            !subitem.disabled &&
            !subitem.isDivider &&
            subitem.onClick &&
            subitem.onClick()
          }>
          <div style={{ display: 'flex' }}>
            {subitem.icon && !subitem.isDivider && subitem.icon}
            <span>{subitem.label}</span>
            {subitem.isDivider && <div className="item__divider" />}
          </div>
        </Menu.Item>
      ))}
    </SubMenu>
  );

  const menuItem = (item) => (
    <Menu.Item
      key={item.key}
      className={`${item.active ? 'active' : ''} ${item.isDivider ? 'divider' : ''}`}
      disabled={item.disabled}
      onClick={() =>
        !item.disabled && !item.isDivider && item.onClick && item.onClick()
      }>
      <div style={{ display: 'flex' }}>
        {withRadioButtons && !item.isDivider && (
          <div className={`item__radio ${item.active ? 'active' : ''}`}>
            {item.active && <div className="radio" />}
          </div>
        )}
        {item.icon && !item.isDivider && item.icon}
        <span>{item.label}</span>
        {item.isDivider && <div className="item__divider" />}
      </div>
    </Menu.Item>
  );

  const menu = (
    <Menu>
      {items.map((item) =>
        item.subitems.length > 0 ? subMenuItem(item) : menuItem(item)
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlayClassName={`dropdown-design-system theme--${theme} ${version || ''}`}
      overlay={menu}
      {...props}>
      <div
        className={`dropdown-design-system__label theme--${theme} ${version || ''} ${active ? 'active' : ''} ${isError ? 'error' : ''}`}
        style={{ height: height || 24 }}>
        {WithIcon && (
          <div className="label__content">
            {labelIcon && <div>{labelIcon}</div>}
            <span>{label}</span>
          </div>
        )}
        {!icon ? (
          <ArrowDownIcon
            color={
              isError ? '#FA7676' : theme === 'light' ? '#121212' : '#FFFFFF'
            }
          />
        ) : (
          icon
        )}
      </div>
    </Dropdown>
  );
};

export default DropdownSystem;
